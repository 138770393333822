import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { HeadingContainer, ColorButton } from '../../../../../neoverv';
import PersonalDetails from './PersonalDetails';
import PaymentDetails from './PaymentDetails';
import ProfilePassword from './ProfilePassword';
import YourSubscription from './YourSubscription';
import PaymentHistory from './PaymentHistory';
import Legal from './Legal';
import { resetData } from '../../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import CompanyDetails from './CompanyDetails';
import NewSubscription from './NewSubscription';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang2 = Setting[Setting.language_default].newKeys;
const routes = [
  {
    name: 'company-details',
    route: '/profile/companyDetails',
    heading: lang.text_company_details,
  },
  {
    name: 'personal-details',
    route: '/profile/personalDetails',
    heading: lang.text_personal_user_details,
  },
  // {
  //   name: 'password',
  //   route: '/profile/profilePassword',
  //   heading: lang.text_password,
  // },
  {
    name: 'your-subscription',
    route: '/profile/yourSubscription',
    heading: lang.text_your_subscription,
  },
  {
    name: 'payment-details',
    route: '/profile/paymentDetails',
    heading: 'Zahlungen',
  },
  // {
  //   name: 'payment-history',
  //   route: '/profile/paymentHistory',
  //   heading: lang.text_payment_history,
  // },
  {
    name: 'legal',
    route: '/profile/legal',
    heading: lang2.text_legal,
  },
];

class Profile extends Component {
  changePage(route) {
    this.props.history.push(route);
  }
  logout = () => {
    this.handleLogout()
  }
  handleLogout = () => {
    //reset data and go to login
    // this.props.resetData();
    // localStorage.clear();
    Autoload.Utils.clear_session_data();
    window.Intercom('shutdown');
    // this.props.history.push('/login');
    window.location.href = "/login";
  }
  render() {
    let pathname = this.props.location.pathname;
    if (pathname === '/profile') {
      pathname = routes[0].route;
    }
    return (
      <div style={{height: '100%'}}>
        <HeadingContainer
          heading={lang.text_your_profile}
          className="profile-heading"
          rightContent={<ColorButton onClick={() => this.logout()} text={lang.text_log_out} className="purple-btn" />}
        />
        <div style={{ display: 'flex', cursor: 'pointer' }} className='custom-navbar'>
          {routes.map((btn, idx) => {
            return (
              <p
                key={idx}
                className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}`}
                style={{
                  color: btn.route === pathname ? '#111147' : '#727293',
                  borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                  fontSize: btn.route === pathname ? '14px' : '14px',
                  marginRight: '25px',
                  padding: '20px 0px',
                  whiteSpace: 'nowrap'
                }}
                onClick={() => {
                  this.changePage(btn.route);
                }}
              >
                {btn.heading}
              </p>
            );
          })}
        </div>
        <hr style={{ marginTop: '-18px' }} />
        <div className="profile-section">
          <div className="profile-content">
            <div className="tab-pane">
              <div className="section-container">
                <div className="section-content">
                  <Route path={this.props.match.url} exact component={CompanyDetails} />
                  <Route path={this.props.match.url + '/personalDetails'} exact component={PersonalDetails} />
                  <Route path={this.props.match.url + '/companyDetails'} exact component={CompanyDetails} />
                  {/* <Route path={this.props.match.url + '/profilePassword'} exact component={ProfilePassword} /> */}
                  <Route path={this.props.match.url + '/paymentDetails'} exact component={PaymentDetails} />
                  <Route path={this.props.match.url + '/yourSubscription'} exact component={YourSubscription} />
                  <Route path={this.props.match.url + '/subscriptionsView'} exact component={NewSubscription} />
                  {/* <Route path={this.props.match.url + '/paymentHistory'} exact component={PaymentHistory} /> */}
                  <Route path={this.props.match.url + '/legal'} exact component={Legal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const actions = {
  resetData
}
export default connect(null, actions)(Profile);
