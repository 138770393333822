import React, { Component } from 'react';
import { ColorButton, CustomModal } from '../../../../index';
import { Row, Col, Form } from 'react-bootstrap';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import moment from 'moment';
import Autoload from '../../../../Autoload';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
// import {updateRents} from '../../../'
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { invalidSize, emptyFieldValidation, checkProperty } = Autoload.MainHelper;

// var isMounted = false;

export class RentModal extends Component {
  initialState = {
    net: {
      value: '',
      err: false,
      msg: '',
    },
    advance: {
      value: '',
      err: false,
      msg: '',
    },
    start: {
      value: '',
      isSelected: false,
      err: false,
      msg: '',
    },
    end: {
      value: '',
      isSelected: false,
    },
    id: '',
    datePickerInFocus: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const { record } = this.props;
    // console.log("this.props",this.props);
    // alert('hello');
    // Autoload.MainHelper.cust_fb_element('cc_i_r_nr',3000);
    // Autoload.MainHelper.cust_fb_element('cc_i_r_nr',3000);
    // if( isMounted === false ) {
    // Autoload.MainHelper.cust_fb_element('cc_i_r_nr',3000);
    // isMounted = true;
    // }
    if (this.props.edit == true) {
      this.setState({
        net: {
          ...this.state.net,
          // value: checkProperty(record, 'net').toString(),
          value: checkProperty(record, 'net'),//Autoload.MainHelper.ConverterCurrencyDe(checkProperty(record, 'net').toString() + '', true, 'de'),
        },
        advance: {
          ...this.state.advance,
          // value: checkProperty(record, 'advance').toString(),
          value: checkProperty(record, 'advance'),
          // value: Autoload.MainHelper.ConverterCurrencyDe(
          //   checkProperty(record, 'advance').toString() + '',
          //   true,
          //   'de'
          // ),
        },
        start: {
          ...this.state.start,
          value: this.setDateFormat(checkProperty(record, 'start')),
          isSelected: checkProperty(record, 'start') == '' ? false : true,
        },
        end: {
          ...this.state.end,
          value: this.setDateFormat(checkProperty(record, 'end')),
          isSelected:
            checkProperty(record, 'end') == '' || checkProperty(record, 'end') == 'Invalid date' ? false : true,
        },
        id: checkProperty(record, 'id'),
      });
    }
  }

  componentDidUpdate(prevProps) {
    //update form data if it is an edit mode
    // console.log('componentDidUpdate');
    if (this.props !== prevProps) {
      const { record } = this.props;
      this.props.edit === true &&
        this.setState({
          net: {
            ...this.state.net,
            // value: checkProperty(record, 'net').toString(),
            value: checkProperty(record, 'net'),//Autoload.MainHelper.ConverterCurrencyDe(checkProperty(record, 'net').toString() + '', true, 'de'),
          },
          advance: {
            ...this.state.advance,
            // value: checkProperty(record, 'advance').toString(),
            value: checkProperty(record, 'advance'),
            // Autoload.MainHelper.ConverterCurrencyDe(
            //   checkProperty(record, 'advance').toString() + '',
            //   true,
            //   'de'
            // ),
          },
          start: {
            ...this.state.start,
            value: this.setDateFormat(checkProperty(record, 'start')),
            isSelected: checkProperty(record, 'start') == '' ? false : true,
          },
          end: {
            ...this.state.end,
            value: this.setDateFormat(checkProperty(record, 'end')),
            isSelected:
              checkProperty(record, 'end') == '' || checkProperty(record, 'end') == 'Invalid date' ? false : true,
          },
          id: checkProperty(record, 'id'),
        });
    }
  }
  setDateFormat = (date) => {
    if (date === '' || date == 'Invalid date') {
      return '';
    } else {
      return moment(date, 'DD.MM.YYYY')._d;
    }
  };

  onDateChange = (field, date) => {
    // console.log(date, 'date ');
    var getDate = date;

    // console.log(new Date(date).getMonth() + 1, new Date(date).getFullYear(), field, 'dateset');
    // var newDate = new Date(date);
    // console.log(newDate, 'new Date');
    // var getDate = new Date(`${newDate.getFullYear()}-${newDate.getMonth()}-01`);
    // console.log(getDate, 'get date aaaaaaaaaaa');
    this.setState({ [field]: { ...this.state[field], value: getDate, isSelected: true, err: false } });
  };

  resetForm = () => {
    this.setState({ ...this.initialState });
  };

  onInputChange = (field, value) => {
    // console.log('abc');
    this.setState({ [field]: { ...this.state[field], value: value, err: false } });
  };

  validateData = (data, field, fieldName, fieldType) => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(data.value)
    switch (fieldType) {
      case 'amount':
        invalidErr = invalidSize(data.value);
        break;
      default:
        break;
    }
    if (emptyErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: `${lang.general.text_enter} ${fieldName}` } });
      return true;
    } else if (invalidErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: `${lang.general.text_invalid} ${fieldName}` } });
      return true;
    } else {
      this.setState({ [field]: { ...this.state[field], err: false, msg: '' } });
      return false;
    }
  };

  handleSubmit = (e) => {
    // console.log(this.props, 'anc');
    const { net, advance, start } = this.state;
    console.log('handleSubmit: ', net, advance, start);
    e.preventDefault();
    let netErr = this.validateData(net, 'net', 'net rent', 'amount');
    let advanceErr = this.validateData(advance, 'advance', 'advance', 'amount');
    let startDate = this.validateData(start, 'start', 'start', 'date');
    if (netErr || advanceErr || startDate) {
      console.log('invalid data');
    } else {
      if (this.props.edit) {
        this.editRent();
      } else {
        this.addRent();
      }
      // this.resetForm();
      // if(this.props.edit){
      //     this.props.close()
      // }else{
      //     this.props.toggle();
      // }
    }
  };

  //api call function
  addRent = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    // const { nextRecordId } = this.props;
    // const { net, advance, start, end }=this.state;
    // let data= {
    //     id: nextRecordId,
    //     net: Autoload.MainHelper.ConverterLocaleNumber(this.state.net.value, "de"),
    //     advance: Autoload.MainHelper.ConverterLocaleNumber(this.state.advance.value, "de"),
    //     start: start.value =='' ? '' : moment(this.state.date).format('YYYY-MM-DD'),
    //     end: end.value =='' ? '' : moment(end.value).format('DD.MM.YYYY'),
    //     current:true,
    // }
    // // console.log('add',data)
    // this.props.sendRentData(data)
    let btnelement = document.getElementById('rent-unit-modal-footer');
    const { net, advance, start, end } = this.state;
    // console.log(start, 'date aaaaaaa');
    var data = JSON.stringify({
      rentId: null,
      tenantId: this.props.tenantId,
      startDate:
        start.value == '' || !this.isValidDate(start.value) ? '' : moment(start.value).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: this.props.moveout && this.props.moveout.value === '' ? null : moment(this.props.moveout.value).format('YYYY-MM-DDTHH:mm:ss'),
      // endDate:  this.state.updateRents[1].end==null||this.state.updateRents[1].end==null
      //   endDate: end.value == '' || !this.isValidDate(end.value) ? '' : moment(end.value).format('YYYY-MM-DDTHH:mm:ss'),
      netRent: net.value, //Autoload.MainHelper.ConverterLocaleNumber(net.value, 'de'),
      prePayments: advance.value//Autoload.MainHelper.ConverterLocaleNumber(advance.value, 'de'),
    });
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rents',
      'POST',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleAddRent',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleAddRent = (response) => {
    // console.log(response, this.state.rents, 'handleAddRent');
    // console.log(this.props.rentsinModal, 'this.props.rentsinModal,');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    var Data = response;

    if (this.props.rentsinModal.length > 0) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'Rents/' + this.props.rentsinModal[0].id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_rents_by_idModal',
        { Data: Data },
        false,
        '',
        {
          // 'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  };
  handle_get_rents_by_idModal = (response) => {
    // console.log(
    //   'date',
    //   this.props.rentsinModal[0].start,
    //   moment(this.props.rentsinModal[0].start, 'YYYY-MM-DD'),
    //   moment(this.props.rentsinModal[0].start, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
    //   response
    // );
    console.log('handle_get_rents_by_idModal: ', this.props.rentsinModal[0].start);
    var subtractedDate = moment(response.other.Data.result.startDate).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');

    var data = JSON.stringify({
      rentId: this.props.rentsinModal[0].id,
      tenantId: this.props.rentsinModal[0].tenantId,
      startDate: moment(this.props.rentsinModal[0].start, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      // start.value == '' || !this.isValidDate(start.value) ? '' : moment(start.value).format('YYYY-MM-DDTHH:mm:ss'),
      // endDate: moment(response.result.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: subtractedDate,
      netRent: this.props.rentsinModal[0].net,
      prePayments: this.props.rentsinModal[0].advance,
    });
    let btnelement = document.getElementById('rent-unit-modal-footer');
    // console.log(response, btnelement, 'abc');
    Autoload.HttpRequestAxios.HttpRequest(
      // 0 if redux
      Autoload.Setting.property_api_base_url + 'rents/' + this.props.rentsinModal[0].id,
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleEditRentinRentModal',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    // this.props.updateRents();
    // response.other.cancelbtn.click();
  };

  handleEditRentinRentModal = (response) => {
    // console.log('handleEditRentinRentModal put response part 3 ', response);
    //call function of get rents api in parent
    response.other.cancelbtn.click();
    this.props.updateRents();
    //Autoload.Redirection.reload();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };
  editRent = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('rent-unit-modal-footer');
    const { net, advance, start, end, id } = this.state;
    var data = JSON.stringify({
      rentId: id,
      tenantId: this.props.tenantId,
      startDate:
        start.value == '' || !this.isValidDate(start.value) ? '' : moment(start.value, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: this.props.moveout.value !== '' ? moment(this.props.moveout.value, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss') : null,
      // endDate: end.value == '' || !this.isValidDate(end.value) ? '' : moment(end.value).format('YYYY-MM-DDTHH:mm:ss'),
      netRent: net.value,//Autoload.MainHelper.ConverterLocaleNumber(net.value, 'de'),
      prePayments: advance.value//Autoload.MainHelper.ConverterLocaleNumber(advance.value, 'de'),
    });
    // console.log(data, 'payload put api 1');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rents/' + id,
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleEditRent',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleEditRent = (response) => {
    // console.log(response, 'handleEditRent put api 01 response');
    // console.log(this.props.AllRents, 'this.props.AllRents rent from props');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if (this.props.AllRents.length == 1) {
      this.props.updateRents();
      response.other.cancelbtn.click();
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }

    var Data = response;
    if (this.props.AllRents.length > 1) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'Rents/' + this.props.AllRents[1].id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_rents_by_id_edit_modal',
        { EditedData: Data },
        false,
        '',
        {
          // 'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  };
  handle_get_rents_by_id_edit_modal(response) {
    // console.log('get api response of rent by id  02', response);
    var subtractedDate = moment(response.other.EditedData.result.startDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DDTHH:mm:ss');

    var data = JSON.stringify({
      rentId: response.result.id,
      tenantId: response.result.tenantId,
      startDate: moment(response.result.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      // start.value == '' || !this.isValidDate(start.value) ? '' : moment(start.value).format('YYYY-MM-DDTHH:mm:ss'),
      // endDate: moment(response.result.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: subtractedDate,
      netRent: response.result.netRent,
      prePayments: response.result.prePayments,
    });
    // console.log(data, 'payload of put api part 02 - step 03 ');
    let btnelement = document.getElementById('rent-unit-modal-footer');
    // console.log(response, btnelement, 'abc');
    Autoload.HttpRequestAxios.HttpRequest(
      // 0 if redux
      Autoload.Setting.property_api_base_url + 'rents/' + response.result.id,
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleEditRentinRentModal',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { className, open, toggle, edit, close, AllRents, rentsinModal, moveout } = this.props;
    // console.log(moveout, 'moveout');
    const { net, advance, start, end, datePickerInFocus } = this.state;
    return (
      <CustomModal
        heading={edit ? lang.tenant.text_edit_rent : lang.tenant.text_add_rent}
        headingClassName={edit ? 'tenant-management-edit-rent-heading' : 'tenant-management-add-rent-heading'}
        open={open}
        toggle={() => {
          this.resetForm();
          if (edit) {
            close();
          } else {
            toggle();
          }
        }}
        className={className}
        style={{height: 'auto'}}
      >
        <div>
          <Form onSubmit={this.handleSubmit} noValidate>
            <div className="modal-inner-body rental-unit-modal">
              <p className="modal-rent-heading">
                {' '}
                {lang.tenant.text_payments}{' '}
                <span>
                  {'('}
                  {lang.newKeys.text_monthly_plan}
                  {')'}
                </span>{' '}
              </p>
              <Row>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label> {lang.tenant.text_net_rent} </Form.Label>
                    <NumericFormat 
                    className={`form-control ${net.err && 'is-invalid'}`}
                    value={net.value}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                      this.onInputChange('net', values.floatValue);
                    }}
                    suffix={' €'}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 €'
                    decimalScale={2}
                    fixedDecimalScale={2}
                    allowNegative={true}
                    />
                    {/* <Form.Control
                      type="text"
                      isInvalid={net.err}
                      placeholder="0,00€"
                      value={net.value}
                      id="cc_i_r_nr"
                      onChange={(e) => this.onInputChange('net', e.target.value)}
                      // onFocus={(e) => this.removeSymbol('net',e.target.value, '€')}
                      // onBlur={(e) => this.addSymbol('net',e.target.value, '€')}
                      onBlur={(e) =>
                        this.onInputChange(
                          'net',
                          Autoload.MainHelper.ConverterCurrencyDe(this.state.net.value + '', true, 'de')
                        )
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    /> */}
                    {net.err &&
                    <div className="err" type="invalid">
                      {'Eingabe notwendig'}
                      {/* {' '}
                      {net.msg}{' '} */}
                    </div>}
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label> {lang.tenant.text_advance_payments} </Form.Label>
                    <NumericFormat 
                    className={`form-control ${advance.err && 'is-invalid'}`}
                    value={advance.value}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                      this.onInputChange('advance', values.floatValue);
                    }}
                    suffix={' €'}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 €'
                    decimalScale={2}
                    fixedDecimalScale={2}
                    allowNegative={true}
                    />
                    {/* <Form.Control
                      type="text"
                      isInvalid={advance.err}
                      id="cc_i_r_ar"
                      placeholder="0,00€"
                      value={advance.value}
                      onChange={(e) => this.onInputChange('advance', e.target.value)}
                      // onFocus={(e) => this.removeSymbol('advance',e.target.value, '€')}
                      // onBlur={(e) => this.addSymbol('advance',e.target.value, '€')}
                      onBlur={(e) =>
                        this.onInputChange(
                          'advance',
                          Autoload.MainHelper.ConverterCurrencyDe(this.state.advance.value + '', true, 'de')
                        )
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    /> */}
                    {advance.err &&
                    <div className="err" type="invalid">
                      {'Eingabe notwendig'}
                      {/* {' '}
                      {advance.msg}{' '} */}
                    </div>}
                  </Form.Group>
                </Col>
              </Row>
              <p className="modal-rent-heading"> {lang.receipt.text_time_frame} </p>
              <Row>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label> {lang.tenant.text_start_date} </Form.Label>
                    {edit && AllRents.length === 1 && (AllRents[0] || rentsinModal[0]) ? (
                      <div className="calendar-input rent-modal-month-picker" style={{backgroundColor: '#f7f7fa'}}>
                        <img src={calendar} alt="calendar" />
                        <DatePicker
                          locale="de"
                          // className="rent-modal-month-picker"
                          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                          // dateFormat="MM.yy"
                          customInput={<Form.Control required type="text" placeholder="MM.JJ" />}
                          style={{ display: 'block !important' }}
                          placeholderText={!start.isSelected && 'MM.JJ'}
                          selected={start.isSelected && start.value}
                          dateFormat="MM/yyyy"
                          // onChange={(e) => this.onDateChange('start', e)}
                          // minDate={
                          //   new Date(
                          //     moment(
                          //       edit ? AllRents[0].start || rentsinModal[0].start : rentsinModal[0].start,
                          //       'DD.MM.YYYY'
                          //     )
                          //       // .add(1, 'month')
                          //       .format('YYYY-MM-DD')
                          //   )
                          // }
                          // dateFormat="MM/yyyy"
                          // showMonthYearPicker
                          // //showFullMonthYearPicker
                          // maxDate={new Date(moment(!edit && moveout.value, 'DD.MM.YYYY').format('YYYY-MM-DD'))}
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="calendar-input rent-modal-month-picker" onFocus={()=> this.setState({datePickerInFocus: true})} onBlur={()=> this.setState({datePickerInFocus: false})} style={{backgroundColor: edit && !this.props.lastRent && !this.props.firstRent && '#f7f7fa', border: !datePickerInFocus && start.err && '1px solid rgb(220, 53, 69)'}}>
                        <img src={calendar} alt="calendar" />
                        <DatePicker
                          locale="de"
                          // className="rent-modal-month-picker"
                          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                          // dateFormat="MM.yy"
                          customInput={<Form.Control required type="text" placeholder="MM/JJJJ" />}
                          style={{ display: 'block !important' }}
                          placeholderText={!start.isSelected && 'MM/JJJJ'}
                          selected={start.isSelected && start.value}
                          onChange={(e) => this.onDateChange('start', e)}
                          // minDate={moment(AllRents[0].start, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')}
                          // minDate={moment(this.props.record.start, 'YYYY-MM-DD')}
                          minDate={
                            new Date(
                              moment(
                                edit ? (AllRents[0].start || rentsinModal[0].start) : rentsinModal[0].start, 'DD.MM.YYYY'
                              ).startOf('month')
                              .add(1, 'month')
                              .format('YYYY-MM-DD')
                            )
                          }
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          //showFullMonthYearPicker
                          maxDate={new Date(moment(moveout.value, 'DD.MM.YYYY').format('YYYY-MM-DD'))}
                          disabled={edit && !this.props.lastRent && !this.props.firstRent}
                        />
                        {console.log('lastRent: ' +this.props.lastRent+ ', firstRent: ' + this.props.firstRent)}
                      </div>
                    )}

                    {start.err && (
                      <div className="err">
                        {/* {start.msg} */}
                        {'Eingabe notwendig'}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>  {lang.tenant.text_end_date_opt} </Form.Label>
                                        <div className='calendar-input'>
                                            <img src={calendar} alt='calendar' />
                                            <DatePicker 
locale="de"
                                                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                                                dateFormat="dd.MM.yy"
                                                customInput={<Form.Control required type="text" placeholder='DD.MM.YYYY' />}
                                                style={{ display: 'block !important' }}
                                                placeholderText={!end.isSelected && 'DD.MM.YYYY'}
                                                selected={end.isSelected && end.value}
                                                onChange={(e) => this.onDateChange("end", e)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col> */}
              </Row>
            </div>
            <div className="center-spacing rental-unit-modal-footer" id="rent-unit-modal-footer" style={{position: 'relative'}}>
              <ColorButton
                onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  if (this.props.edit) {
                    this.props.close();
                  } else {
                    toggle();
                  }
                }}
                className="gray-btn"
                text={lang.general.text_cancel}
              />
              <ColorButton
                buttonConfig={{ type: 'submit' }}
                className="purple-btn"
                text={edit ? lang.tenant.text_save_payments : lang.newKeys.text_add_payments_rent}
              />
            </div>
          </Form>
        </div>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentRecord: state.tenantDataReducer.currentTenant,
    formData: state.tenantDataReducer.addEditFormData,
  };
};

export default connect(mapStateToProps)(RentModal);
