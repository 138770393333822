import Autoload from '../../neoverv/Autoload';
import DashboardIcon from '../../assets/images/module/general/DashboardIcon.svg';
import MeterIcon from '../../assets/images/module/general/MeterIcon.svg';
import TenantsIcon from '../../assets/images/module/general/TenantsIcon.svg';
import ReceiptsIcon from '../../assets/images/module/general/ReceiptsIcon.svg';
import PropertyIcon from '../../assets/images/module/general/PropertyIcon.svg';
import UtilityIcon from '../../assets/images/module/general/UtilityIcon.svg';
import MessagingIcon from '../../assets/images/module/general/MessagesIconGrey.svg';
import DocumentsIcon from '../../assets/images/module/general/DocumentsIcon.svg';
import TenantPortalIcon from '../../assets/images/module/general/TenantPortalIconGrey.svg';
import Help from '../../assets/images/module/general/Help.svg';
import Avatar from '../../assets/images/module/general/Avatar.svg';

var Setting = Autoload.Setting;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  top: [
    {
      name: Setting[Setting.language_default].general.text_dashboard,
      url: '/dashboard',
      image: DashboardIcon,
    },
    {
      name: Setting[Setting.language_default].general.text_property,
      url: '/property',
      image: PropertyIcon,
    },

    {
      name: Setting[Setting.language_default].general.text_tenants,
      url: '/user',
      image: TenantsIcon,
    },
    {
      name: 'Buchhaltung',
      url: '/accounting',
      image: ReceiptsIcon,
    },
    {
      name: Setting[Setting.language_default].general.text_meter,
      url: '/meter',
      image: MeterIcon,
    },
    {
      name: Setting[Setting.language_default].general.text_utilitybilling,
      url: '/utility-billing',
      image: UtilityIcon,
    },
    {
      name: 'Nachrichten',
      url: '/messaging',
      image: MessagingIcon,
    },
    {
      name: 'Dokumente',
      url: '/documents',
      image: DocumentsIcon,
    },
    {
      name: 'Mieterportal',
      url: '/tenantPortal',
      image: TenantPortalIcon,
    },
    {
      divider: true,
    },
  ],
  bottom: [
    {
      name: Setting[Setting.language_default].general.text_help,
      url: '/',
      image: Help,
      // external: true,
      modal: true,
      target: '_blank',
    },
    {
      name: Setting[Setting.language_default].general.text_Account,
      url: '/profile',
      account: true,
      image: Avatar,
    },
  ],
};
