import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { Form, Row, Col } from 'react-bootstrap';
import calendar from '../../../../assets/images/module/general/CalendarIcon.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RentIcon from '../../../../assets/images/module/general/RentIcon.svg';
import addicons from '../../../../assets/images/module/general/AddIcon.svg';
import subtracticon from '../../../../assets/images/module/general/subtracticon.svg';
import TenantSingleIcon from '../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantSingleIconBlue from '../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import buildingIcon from '../../../../assets/images/module/general/building_icon.svg';
import buildingIconBlue from '../../../../assets/images/module/general/building_icon_blue.svg';
import SelfIcon from '../../../../assets/images/module/general/SelfIcon.svg';
import SelfIconBlue from '../../../../assets/images/module/general/SelfIconBlue.svg';
import GoldenLockIcon from '../../../../assets/images/module/general/GoldenLockIcon.svg';
import PartnerDashboardIcon from '../../../../assets/images/module/general/PartnerDashboardIcon.svg';
import PartnerPopOverCloseIcon from '../../../../assets/images/module/general/PartnerPopOverCloseIcon.svg';

import { setAddEditFormData, setAddEditFormValidation } from '../../../../redux/action/Tenant/TenantActions';
import moment from 'moment';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import { ColorButton, EmptyStateContainer, RentModal, RentList } from '../../../../neoverv';
import { useLocation } from 'react-router-dom';
import { Group, Popover, Skeleton, Stack } from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import CardSelection from '../../../../neoverv/components/Module/General/CardSelection/CardSelection';
import PartnerContent from '../../../../assets/partner-content.json';
import { NumericFormat } from 'react-number-format';
import TenantTypeChangeToSelfAlertModal from '../../../../neoverv/components/Module/General/CustomModal/TenantTypeChangeToSelfAlertModal';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const {loader, requiredFields} = Autoload.MainHelper;

const { checkProperty, isValidDate, invalidEmail, invalidName, emptyFieldValidation, invalidPhone } =
  Autoload.MainHelper;

// creating tenant from tenant and go on dahsboard then edit through dashboard , tenant id was missing
// so having tenant id from url to edit through tenant module or from dashboard
export class TenantInfo extends Component {
  state = {
    type: 'private',
    tenantId: Autoload.Utils.getUrlParameterByName('tenantId'),
    fullname: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    fullnameOld: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    num: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    numOld: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    email: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    numberOfOccupants: {
      value: null,
      err: false,
      touched: false,
      msg: '',
    },
    emailOld: {
      value: '',
      err: false,
      touched: false,
      msg: '',
    },
    numberOfOccupantsOld: {
      value: null,
      err: false,
      touched: false,
      msg: '',
    },
    moveIn: {
      value: '',
      isSelected: false,
      err: false,
      msg: '',
    },
    moveOut: {
      value: '',
      isSelected: false,
      err: false,
      msg: '',
    },
    rentModalOpen: false,
    rents: [],
    id: '',
    lastRealTenant: '',
    autosaveEnable: false,
    skeletonLoading: false,
    allTenants: [],
    hover1: false,
    hover2: false,
    hover3: false,
    hoverOnStarter: false,
    tenantPartner: null,
    openPartner: false,
    showPartner: false,
    tenantTypeChangeToSelfAlertModalOpen: false
    // isMoveOutDateSelected:false
  };
  tenantIndex=0

  componentDidMount() {
    // creating tenant from tenant and go on dahsboard then edit through dashboard , tenant id was missing
    console.log(this.state.tenantId, 'tenantId');
    // so having tenant id from url to edit through tenant module or from dashboard works fine
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    
    if (this.state.tenantId) {
      console.log("DATA PRESENT!");
      console.log(this.state.tenantId);

      this.getRentsApi(this.state.tenantId);
      this.getTenantById(this.state.tenantId);
    }

    this.setState({tenantPartner: PartnerContent.find((content)=> content['tenant-visible'])})
  }

  getAllTenants(rentalUnitId){
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + rentalUnitId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_tenants',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_list_rental_tenants=(response)=>{
    if(response.status == false){
      Autoload.Redirection.redirection_error();
    }
    var allTenants = response.result.filter((tenant)=> tenant.id !== this.state.tenantId);
    this.setState({allTenants: response.result}, ()=> this.tenantIndex = response.result.findIndex((tenant)=> tenant.id === this.state.tenantId));
  }
  getTenantById(tenantId) {
    // loader(true);

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + `Tenants/${tenantId}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenantByIdResponse',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  // TENANT response is 404 here
  handleGetTenantByIdResponse(response) {
    console.log('TENANT: handleGetTenantByIdResponse ', checkProperty(this.props.formData, 'id'), response);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error : ' + response.message);
    }
    this.setState({
      type: checkProperty(response.result, 'contractType'),
      fullname: {
        ...this.state.fullname,
        value: checkProperty(response.result, 'fullName'),
      },
      fullnameOld: {
        ...this.state.fullnameOld,
        value: checkProperty(response.result, 'fullName'),
      },
      num: {
        ...this.state.num,
        value: checkProperty(response.result, 'phone'),
      },
      numOld: {
        ...this.state.numOld,
        value: checkProperty(response.result, 'phone'),
      },
      email: {
        ...this.state.email,
        value: checkProperty(response.result, 'email'),
      },
      emailOld: {
        ...this.state.emailOld,
        value: checkProperty(response.result, 'email'),
      },
      moveIn: {
        ...this.state.moveIn,
        value: this.setDateFormat(moment(checkProperty(response.result, 'moveInDate')).format('DD.MM.YYYY')),
        // this.setDateFormat(checkProperty(response.result, 'moveInDate')),
        isSelected: moment(checkProperty(response.result, 'moveInDate')).format('DD.MM.YYYY') == '' ? false : true,
      },

      moveOut: {
        ...this.state.moveOut,
        value:
          response.result.moveOutDate === null
            ? ''
            : this.setDateFormat(moment(checkProperty(response.result, 'moveOutDate')).format('DD.MM.YYYY')),
        isSelected: moment(checkProperty(response.result, 'moveOutDate')).format('DD.MM.YYYY') == '' ? false : true,
      },
      showPartner: response.result.moveOutDate ? true : false,
      lastRealTenant: checkProperty(response.result, 'isLastRealTenant'),
      numberOfOccupants: {
        ...this.state.numberOfOccupants,
        value: checkProperty(response.result, 'numberOfOccupants')
      },
      numberOfOccupantsOld: {
        ...this.state.numberOfOccupantsOld,
        value: checkProperty(response.result, 'numberOfOccupants')
      }
    });
    console.log(
      this.setDateFormat(moment(checkProperty(response.result, 'moveOutDate')).format('DD.MM.YYYY')),
      moment(checkProperty(response.result, 'moveOutDate')).format('DD.MM.YYYY'),
      checkProperty(response.result, 'moveOutDate'),
      response.result.moveOutDate,
      '======date======'
    );
    this.setState({skeletonLoading: false});
    this.getAllTenants(response.result.rentalUnitId)
  }
  getRentsApi = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + this.state.tenantId,
      // checkProperty(this.props.formData, 'id'),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rents',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_rents = (response) => {
    // console.log('getRentsApi tenant doc', response);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error : ' + response.message);
    }
    const { formData } = this.props;
    console.log(formData, 'form data');
    let rents = [];
    // let dashMoveOut = response.result.filter((item) => item.out === '');
    // let filledMovedout = response.result.filter((item) => item.out !== '');
    // filledMovedout.map((item) => dashMoveOut.push(item));
    // let updatedrents = [...dashMoveOut];
    if (response.result.length > 0) {
      rents = response.result.map((rent, idx) => ({
        id: checkProperty(rent, 'id'),
        net: checkProperty(rent, 'netRent'),
        advance: checkProperty(rent, 'prePayments'),
        start: moment(checkProperty(rent, 'startDate')).format('DD.MM.YYYY')
          ? moment(checkProperty(rent, 'startDate')).format('DD.MM.YYYY')
          : '',
        end: moment(checkProperty(rent, 'endDate')).format('DD.MM.YYYY')
          ? moment(checkProperty(rent, 'endDate')).format('DD.MM.YYYY')
          : '',
        current: idx == 0,
        createdOn: checkProperty(rent, 'createdOn'),
        tenant: checkProperty(rent, 'tenant'),
        tenantId: checkProperty(rent, 'tenantId'),
        updatedOn: checkProperty(rent, 'updatedOn'),
      }));
    }
    // this.setState({ rents: rents });
    this.setData({ ...formData, rents });
    // console.log(this.state.rents, 'updated rents');
  };

  setData = (data) => {
    console.log(data, '============data=============');
    this.setState({
      // type: checkProperty(data, 'type', 'private'),
      // fullname: {
      //   ...this.state.fullname,
      //   value: checkProperty(data, 'name'),
      // },
      // num: {
      //   ...this.state.num,
      //   value: checkProperty(data, 'phone'),
      // },
      // email: {
      //   ...this.state.email,
      //   value: checkProperty(data, 'email'),
      // },
      // moveIn: {
      //   ...this.state.moveIn,
      //   value: this.setDateFormat(checkProperty(data, 'in')),
      //   isSelected: checkProperty(data, 'in') == '' ? false : true,
      // },

      // moveOut: {
      //   ...this.state.moveOut,
      //   value: this.setDateFormat(checkProperty(data, 'out')),
      //   isSelected: checkProperty(data, 'out') == '' ? false : true,
      // },
      rents: checkProperty(data, 'rents', []),
      id: checkProperty(data, 'id'),
    });
    this.ShowLoader();
  };

  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-info-tenant-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('info-tenant-page');
    // dataDiv.style.display = 'block';
  };

  setDateFormat = (date) => {
    if (date !== '') {
      return moment(date, 'DD.MM.YYYY')._d;
    } else {
      return '';
    }
  };

  handleRentDeleteApi = (response) => {
    // console.log('rent delete', response);
    // // // this.getRentsApi();
    // console.log(this.props.currentRecord, 'currentRecord in tenant');
    // console.log(this.state.rents, 'rents after delete ');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }

    if (response.result > 0) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'Rents/' + this.state.rents[1].id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_rents_by_Id',
        null,
        false,
        '',
        {
          // 'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
    // response.other.cancelbtn.click();
  };
  handle_get_rents_by_Id(response) {
    console.log('handle_get_rents_by_Id', response);
    // const { net, advance, start, end, id } = this.state;
    var data = JSON.stringify({
      rentId: this.state.rents[1].id,
      // tenantId: this.state.rents[1].tenantId,
      tenantId: this.state.tenantId,
      startDate: moment(this.state.rents[1].start, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      // start.value == '' || !this.isValidDate(start.value) ? '' : moment(start.value).format('YYYY-MM-DDTHH:mm:ss'),
      // endDate: moment(this.state.rents[0].end, 'YYYY-MM--DD').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: this.state.moveOut.value !== '' ? moment(this.state.moveOut.value, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss') : null,
      netRent: this.state.rents[1].net,
      prePayments: this.state.rents[1].advance,
    });
    let btnelement = document.getElementById('rent-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      // 0 if redux
      Autoload.Setting.property_api_base_url + 'rents/' + this.state.rents[1].id,
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleRentAfterDelete',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // this.props.updateRents();
    // response.other.cancelbtn.click();

    // this.getRentsApi();
  }
  handleRentAfterDelete(response) {
    // console.log('handleRentAfterDelete', response);
    //Autoload.Redirection.reload();
    this.getRentsApi();
    response.other.cancelbtn.click();
  }

  //save data to redux everytime state changes
  componentDidUpdate(prevProps, prevState) {
    // console.log('tenantinfo did Update')

    if (this.state !== prevState && this.state.autosaveEnable) {
      const { fullname, num, email, moveIn, moveOut, type, rents, numberOfOccupants } = this.state;
      let data = {
        ...this.props.formData,
        name: fullname.value,
        email: email.value,
        phone: num.value,
        in: moveIn.value == '' ? '' : moment(moveIn.value).format('DD.MM.YYYY'),
        out: moveOut.value == '' || moveOut.value === null ? '' : moment(moveOut.value).format('DD.MM.YYYY'),
        rents: rents,
        type: type,
        numberOfOccupants: numberOfOccupants.value
      };
      // console.log('tenantinfo data store in redux', data);
      //save data to redux
      this.props.setAddEditFormData(data);
      //save form state (valid or invalid) in redux
      this.props.setAddEditFormValidation(!fullname.err && !num.err && !email.err && !moveOut.err);
      // if(prevState.moveOut.value !== moveOut.value || prevState.moveIn.value !== moveIn.value){
      //   console.log('tenantinfo did Update: prevState: ', prevState)
      //   console.log('tenantinfo did Update: state: ', this.state)
      //   setTimeout(() => {
      //     this.getRentsApi();
      //   }, 1000);        
      // }
    }
  }

  onInputChange = (field, value, buttons=false) => {
    this.setState({ [field]: { ...this.state[field], value: value }}, ()=> 
      {
        buttons && this.onTouch(`${field}`);
        setTimeout(() => {
          buttons && this.onTextBlur(this.state[field], `${field}`, `${field}`, `${field}`);  
        }, 500);
      }
    );
  };

  onTouch = (field) => {
    this.setState({ [field]: { ...this.state[field], touched: true } });
  };

  //for dynamic validation
  onTextBlur = (data, field, fieldName, fieldType) => {
    let invalidErr, emptyErr;
    if (data.touched) {
      if (fieldType !== 'email' && fieldType !== 'phone' && fieldType !== 'numberOfOccupants') {
        emptyErr = emptyFieldValidation(data.value);
      }
      switch (fieldType) {
        case 'email':
          if (data.value !== '') {
            invalidErr = invalidEmail(data.value);
          }
          break;
        case 'name':
          invalidErr = invalidName(data.value);
          break;
        case 'phone':
          if (data.value !== '') {
            invalidErr = invalidPhone(data.value);
          }
          break;
        default:
          break;
      }
      console.log('emptyErr: ', emptyErr, invalidErr);
      if (emptyErr) {
        this.setState({ [field]: { ...this.state[field], err: true, msg: `${lang.general.text_enter} ${fieldName}` } });
      } else if (invalidErr) {
        this.setState({
          [field]: { ...this.state[field], err: true, msg: `${lang.general.text_invalid} ${fieldName}` },
        });
      } else {
        console.log('onTextBlur: ', this.state[`${field}Old`], field, this.state[field]);
        if(this.state[`${field}Old`].value !== this.state[field].value){
          console.log('working....', true);
          this.setState({ 
            [field]: { ...this.state[field], err: false, msg: '' },
            [`${field}Old`]: { ...this.state[field], err: false, msg: '' },
          }, ()=> this.addTenantBtn());
        }
      }
    } else {
      return;
    }
  };

  validateMoveOutDate = (date) => {
    const { moveIn, moveOut } = this.state;
    // console.log('dates', moveIn, date);
    // console.log('check date', moment(date).isSameOrAfter(moment(moveIn.value)));
    if (moment(date).isSameOrAfter(moment(moveIn.value))) {
      this.setState({ moveOut: { ...moveOut, err: false, msg: '', value: date, isSelected: true } });
    }
    // else if(moveOut===null){
    //   this.setState(
    //     {
    //       moveOut: {
    //         ...moveOut,
    //         err: false,
    //         value: null,
    //         isSelected: true,
    //         // msg: `${lang.general.text_invalid} move out date`,
    //       },
    //     },
    //     () => console.log('setState22', this.state.moveOut)
    //   );
    // }
    else {
      this.setState({
        moveOut: {
          ...moveOut,
          err: false,
          value: null,
          isSelected: true,
          msg: `${lang.general.text_invalid} move out date`,
        },
      });
    }
  };

  onDateChange = (field, date, isMoveOutDate = false) => {
    // var strdate = date,
    //   dateto = this.setCharAt(strdate, 4, 'a');
    // alert(dateto);
    if (isMoveOutDate) {
      this.validateMoveOutDate(date);
      return;
    }
    this.setState({ [field]: { ...this.state[field], value: date, isSelected: true, err: false }});
  };

  // setCharAt = (str, index, chr) => {
  //   if (index > str.length - 1) return str;
  //   return str.substring(0, index) + chr + str.substring(index + 1);
  // };

  onRadioChange = (field, value) => {
    this.setState({ [field]: value }, ()=> this.addTenantBtn());
  };

  toggleRentModal = () => {
    // console.log('tenantinfo toggle add rend')

    this.setState({ rentModalOpen: !this.state.rentModalOpen });
  };

  //set rents in state with the deleted data
  dataToDelete = (rent) => {
    this.handleRentDelete(rent);
  };
  handleRentDelete = (rent) => {
    let filteredRents = this.state.rents.filter((item) => item.id != rent.id);
    this.setState({ rents: filteredRents });
  };

  // data received from modal to add it in rent state (for add and edit)
  rentFormData = (rent) => {
    // console.log('tenantinfo rent received', rent);
    let found = this.state.rents.find((item) => item.id == rent.id);
    if (found) {
      this.editRent(rent);
    } else {
      this.addRent(rent);
    }
  };
  addRent = (rent) => {
    //data was unique so merging it
    this.setState({ rents: [rent, ...this.state.rents] });
  };
  editRent = (rent) => {
    //replace old data with new
    let updatedRents = this.state.rents.map((item) => {
      if (item.id == rent.id) {
        return rent;
      } else {
        return item;
      }
    });
    this.setState({ rents: updatedRents });
  };

  // ShowLoader = () => {
  //   var loader = document.getElementById('hello-housing-loader');
  //   loader.style.display = 'none';
  //   var emptyDiv = document.getElementById('empty-info-tenant-page');
  //   emptyDiv.style.display = 'none';
  //   var dataDiv = document.getElementById('info-tenant-page');
  //   dataDiv.style.display = 'block';
  // };

  addTenantBtn = () => {
    console.log('working....', (this.props.isFormValid && requiredFields(this.props.formData, ['name', 'in']) && (this.props.formData.in !== 'Invalid date' && this.props.formData.in !== '' && this.props.formData.in !== null)));
    loader(true);
    //check if form values are valid and data is not empty ( requiredFields(this.props.formData,['name', 'email', 'phone','in','out', 'address', 'city', 'zip', 'area']) )
    // if (this.props.isFormValid && requiredFields(this.props.formData, ['name', 'email', 'phone','in'])) {
    if (this.props.isFormValid && requiredFields(this.props.formData, ['name', 'in']) && (this.props.formData.in !== 'Invalid date' && this.props.formData.in !== '' && this.props.formData.in !== null)) {
      //for edit 'current data' is empty object
      if (Object.keys(this.props.currentRecord).length > 0) {
        console.log('addTenantBtn');
        //edit data
        this.editTenant();
      }else{
        loader(false);
      }
      // else {
      //   // add data
      //   this.addNewTenant()
      // }
      //after add edit go back to main screen
      //this.goBack();
    } else {
      // toast.dark('Data is invalid or empty', {
      //   position: 'top-center',
      //   autoClose: false,
      //   className: 'neoverv-toast',
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // <ErrorMsg errorMsg="Data is invalid or empty" />;
      if(this.props.formData.in === 'Invalid date' || this.props.formData.in === '' || this.props.formData.in === null){
        this.setState({moveIn: {...this.state.moveIn, err: true}});
      }
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_invalid_or_empty_data);
      loader(false);
      // alert('data is empty or not valid')
      //  console.log('data is empty or not valid')
    }
  };

  editTenant = () => {
    hideNotification('saveTenantData');
    console.log('form data editTenant:', this.props.formData);
    const {type} = this.state;
    var data = JSON.stringify({
      tenantId: checkProperty(this.props.formData, 'id'),
      rentalUnitId: checkProperty(this.props.formData, 'rentalUnitId'),
      fullName: checkProperty(this.props.formData, 'name'),
      contractType: type,
      vacant: false,
      phone: checkProperty(this.props.formData, 'phone'),
      email: checkProperty(this.props.formData, 'email'),
      moveInDate:
        checkProperty(this.props.formData, 'in') == ''
          ? ''
          : moment(checkProperty(this.props.formData, 'in'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      moveOutDate:
        checkProperty(this.props.formData, 'out') == null || checkProperty(this.props.formData, 'out') == ''
          ? null
          : moment(checkProperty(this.props.formData, 'out'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      notes: checkProperty(this.props.formData, 'note'),
      numberOfOccupants: checkProperty(this.state.numberOfOccupants, 'value'),
    });
    console.log('form data', data);
    // debugger
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants/' + (this.props.formData.id !== undefined ? this.props.formData.id : this.props.formData.tenantId !== undefined ? this.props.formData.tenantId : this.props.formData.value !== undefined ? this.props.formData.value : ''),
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      'handleEditTenant',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    // this.handleEditTenant()
  };
  handleEditTenant = (response) => {
    console.log('response edit tenant api', response);
    // const unitId = checkProperty(this.props.history.location.state, 'unitId')
    // let record = {
    //   ...this.props.formData,
    // }
    // this.props.editTenant(unitId, record)
    if(response.status === true && response.statuscode === 200){
      showNotification({
        id: 'saveTenantData',
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      this.getRentsApi();
    }
    loader(false);
  };

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  render() {
    const { fullname, num, email, moveIn, moveOut, type, skeletonLoading, lastRealTenant, allTenants, numberOfOccupants, hover1, hover2, hover3, hoverOnStarter, openPartner, tenantPartner, showPartner, tenantTypeChangeToSelfAlertModalOpen } = this.state;
    const {currentSubscription, accountData} = this.props;
    console.log("-----------------------");
    console.log("FULL STATE:");
    console.log(this.state);
    console.log("-----------------------");

    const moveoutSetDate = moment(moveIn.value, 'YYYY-MM-DDTHH:mm:ss').add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
    var moveOutMinDateRestriction = new Date(this.state.moveIn.value);
    moveOutMinDateRestriction.setDate(moveOutMinDateRestriction.getDate() + 1);
    var moveInDateRestriction = new Date(this.state.moveOut.value);
    moveInDateRestriction.setDate(moveInDateRestriction.getDate() - 1);
    // console.log(this.state.rents, this.props.rentalUnits, moveOut, moment('15.9.21').isValid(), 'rents');
    // console.log(this.props, 'add tenant props');
    return (
      <>
        <div id="empty-info-tenant-page"></div>
        <div className="tenant-info-section" id="info-tenant-page" style={{ display: 'block' }}>
          <Form noValidate onClick={()=> this.setState({autosaveEnable: true})}>
            <p className="section-heading"> {lang.tenant.text_edit_tenant_as}</p>
            <Row>
              <Col xl={4} lg={6} md={6} xs={10}>
                <Form.Group>
                  <div className="radio-btns">
                    <Group noWrap>
                      <div>
                        <CardSelection
                        title={'Privater Mieter'}
                        subText={'Privater Mieter oder gewerblicher Mieter ohne MwSt.-Ausweis.'}
                        type={'private'}
                        selectedType={type}
                        onClick={()=> this.onRadioChange('type', 'private')}
                        hover={hover1}
                        hoverName={'hover1'}
                        handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                        handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                        icon={TenantSingleIcon}
                        blueIcon={TenantSingleIconBlue}
                        />
                      </div>
                      <Popover
                      opened={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter}
                      onClose={() => this.setState({hoverOnStarter: false})}
                      position="top"
                      placement="center"
                      withArrow
                      trapFocus={false}
                      closeOnEscape={false}
                      transition="pop-top-left"
                      width={300}
                      target={
                        <div onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})} >
                          <CardSelection
                          title={'Gewerbe'}
                          subText={'Gewerbliche Mieter mit MwSt.-Ausweis verwalten.'}
                          type={'commercial'}
                          selectedType={type}
                          onClick={()=> currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && this.onRadioChange('type', 'commercial')}
                          hover={hover2}
                          hoverName={'hover2'}
                          handleMouseEnter={(hoverName)=> currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && this.handleMouseEnter(hoverName)}
                          handleMouseLeave={(hoverName)=> currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && this.handleMouseLeave(hoverName)}
                          icon={buildingIcon}
                          blueIcon={buildingIconBlue}
                          paidFeature={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER'}
                          disabled={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER'}
                          />
                        </div>
                      }
                      >
                        <Stack style={{padding: '10px'}} spacing={15} onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})} >
                          <Group>
                            <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>Gewerbliche Mieter abrechnen</span>
                          </Group>
                          <Group style={{justifyContent: 'center'}} noWrap>
                            <img src={GoldenLockIcon} width='18px'/>
                            <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>Verwalten Sie gewerbliche Mieter mit MwSt.-Ausweis mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
                          </Group>
                          <Group>
                              <ColorButton
                              text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                              onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                              className={'purple-btn'}
                              />
                          </Group>
                        </Stack>
                      </Popover>
                      <div>
                        <CardSelection
                        title={'Selbstnutzer'}
                        subText={'Selbstnutzer ohne Mietzahlung und Nebenkostenvorauszahlung.'}
                        type={'self'}
                        selectedType={type}
                        onClick={()=> this.setState({tenantTypeChangeToSelfAlertModalOpen: true})}
                        hover={hover3}
                        hoverName={'hover3'}
                        handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                        handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                        icon={SelfIcon}
                        blueIcon={SelfIconBlue}
                        iconStyle={{width: '25px'}}
                        />
                      </div>
                    </Group>
                    {/* <div
                      //  className="form-check form-check-inline"
                      className={`form-check form-check-inline tenant-radio-options ${this.state.type === 'private' ? `checked` : ``
                        } `}
                    >
                      <input
                        checked={type === 'private'}
                        id="private-tenant"
                        name="tenant-type"
                        type="radio"
                        className="form-check-input"
                        value="private"
                        onChange={(e) => this.onRadioChange('type', e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="private-tenant">
                        {' '}
                        {<Skeleton visible={skeletonLoading}>{lang.tenant.text_private}</Skeleton>}{' '}
                      </label>
                    </div> */}
                    
                    {/* <div
                      // className="form-check form-check-inline"
                      className={`form-check form-check-inline tenant-radio-options ${this.state.type === 'commercial' ? `checked` : ``
                        } `}
                    >
                      <input
                        checked={type === 'commercial'}
                        id="commercial-tenant"
                        name="tenant-type"
                        type="radio"
                        className="form-check-input"
                        value="commercial"
                        onChange={(e) => this.onRadioChange('type', e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="commercial-tenant">
                        {' '}
                        {<Skeleton visible={skeletonLoading}>{lang.tenant.text_commercial}</Skeleton>}{' '}
                      </label>
                    </div> */}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <p className="section-heading"> {'Mieterdaten'}</p>
            <Row>
              <Col xl={2} lg={3} md={3} xs={6}>
                <Form.Group>
                  <Form.Label> {type === 'commercial' ? 'Firma' : lang.general.text_full_name} </Form.Label>
                  <Skeleton visible={skeletonLoading}>
                    <Form.Control
                      id="tenant-full-name"
                      name="tenant-full-name"
                      isInvalid={fullname.err}
                      type="text"
                      placeholder=""
                      value={fullname.value}
                      onFocus={() => this.onTouch('fullname')}
                      onBlur={() => this.onTextBlur(fullname, 'fullname', 'full name')}
                      onChange={(e) => this.onInputChange('fullname', e.target.value)}
                    />
                  </Skeleton>
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {fullname.msg}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={2} lg={3} md={3} xs={6}>
                <Form.Group>
                  <Form.Label> {lang.general.text_phone_number} </Form.Label>
                  <Skeleton visible={skeletonLoading}>
                    <Form.Control
                      id="tenant-phone"
                      name="tenant-phone"
                      isInvalid={num.err}
                      type="text"
                      placeholder=""
                      value={num.value}
                      onFocus={() => this.onTouch('num')}
                      onBlur={() => this.onTextBlur(num, 'num', 'phone number', 'phone')}
                      onChange={(e) => this.onInputChange('num', e.target.value)}
                    />
                  </Skeleton>
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {num.msg}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label> {lang.general.text_email} </Form.Label>
                  <Skeleton visible={skeletonLoading}>
                    <Form.Control
                      id="tenant-email"
                      name="tenant-email"
                      isInvalid={email.err}
                      type="email"
                      value={email.value}
                      onFocus={() => this.onTouch('email')}
                      onBlur={() => this.onTextBlur(email, 'email', 'email address', 'email')}
                      onChange={(e) => this.onInputChange('email', e.target.value)}
                    />
                  </Skeleton>
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {email.msg}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {this.props.propertySelected.propertyType === 'REGULAR' &&
            <>
              <p className="section-heading tooltips tenant-number-of-occupants-title"> {'Personenanzahl'} </p>
              <Row>
                <Col xl={2} lg={3} md={3} xs={6}>
                  <Form.Group>
                      <div className='tooltips tenant-number-of-occupants-label'><Form.Label style={{whiteSpace: 'nowrap'}}>{'Personenanzahl (optional)'}</Form.Label></div>
                        <Skeleton visible={skeletonLoading}>
                          {/* <Form.Control
                            className={`form-control`}
                            onFocus={() => this.onTouch('numberOfOccupants')}
                            onBlur={() => this.onTextBlur(numberOfOccupants, 'numberOfOccupants', 'numberOfOccupants', 'numberOfOccupants')}
                            value={numberOfOccupants.value}
                            type="number"
                            placeholder=""
                            onChange={(e) => this.onInputChange('numberOfOccupants', parseInt(e.target.value))}
                          /> */}
                          <NumericFormat 
                          className='form-control'
                          value={numberOfOccupants.value}
                          thousandSeparator="." 
                          decimalSeparator=","
                          onValueChange={(values, sourceInfo) => {
                              //this.setState({vatAmount: values.floatValue})
                              this.onInputChange('numberOfOccupants', values.floatValue)
                          }}
                          onFocus={() => this.onTouch('numberOfOccupants')}
                          onBlur={() => this.onTextBlur(numberOfOccupants, 'numberOfOccupants', 'numberOfOccupants', 'numberOfOccupants')}
                          //onKeyPress={this.handleEnterKeyPress}
                          placeholder='0,00'
                          decimalScale={2}
                          fixedDecimalScale={2}
                          allowNegative={false}
                          />
                          <img
                            src={addicons}
                            onClick={(e) => this.onInputChange('numberOfOccupants', numberOfOccupants.value ? numberOfOccupants.value + 1 : 1, true) //this.setState({ totalOccupantsCount: this.state.totalOccupantsCount ? this.state.totalOccupantsCount + 1 : 1 }, ()=> this.validateForm())
                            }
                            alt=""
                            className="addbtn-icon"
                            style={{right: '10px'}}
                          />
                          <img
                            src={subtracticon}
                            alt=""
                            onClick={(e) => this.onInputChange('numberOfOccupants', numberOfOccupants.value && numberOfOccupants.value > 0 && numberOfOccupants.value - 1, true) //this.state.totalOccupantsCount > 0 && this.setState({ totalOccupantsCount: this.state.totalOccupantsCount - 1 }, ()=> this.validateForm())
                            }
                            className="subbtn-icon"
                            style={{right: '40px'}}
                          />
                          <Form.Control.Feedback style={{display: this.state.numberOfRentalUnitsErrMsg && 'block'}} className="err" type="invalid">
                            {lang.property.generalinfo_propertyRentalUnitsErr}
                          </Form.Control.Feedback>
                        </Skeleton>
                  </Form.Group>
                </Col>
              </Row>
            </>}
            <p className="section-heading occupancy-dates"> {lang.tenant.text_occupancy_dates} </p>
            <Row>
              <Col xl={2} lg={3} md={3} xs={6}>
                <Form.Group>
                  <Form.Label> {lang.tenant.text_move_in_date} </Form.Label>
                  {(this.state.lastRealTenant === false || true) && (
                    
                    skeletonLoading ?
                    <Skeleton visible={skeletonLoading} className="calendar-input" style={{height: '36px', border: 'none'}}/>
                    :
                    <div
                    className="calendar-input"
                    onFocus={(e) => console.log('date in focus', e)}
                    //onBlur={(e) => console.log('date in blurr', e)}
                    style={{border: moveIn.err && '1px solid #dc3545'}}
                  >
                    <img src={calendar} alt="calendar" />
                    <DatePicker
                      locale="de"
                      yearItemNumber="1"
                      yearDropdownItemNumber="1"
                      // dateFormatCalendar="LLLL"
                      onFocus={(e) => console.log('date in focus', e)}
                      onBlur={(e) => this.addTenantBtn()}
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      dateFormat="dd.MM.yyyy"
                      // customInput={<Form.Control required type="text" placeholder="DD.MM.YYYY" />}
                      customInput={<Form.Control required type="text" />}
                      style={{ display: 'block !important' }}
                      placeholderText={!moveIn.isSelected && 'DD.MM.YYYY'}
                      selected={moveIn.isSelected && moveIn.value}
                      onChange={(e) => this.onDateChange('moveIn', e)}
                      onSelect={()=> setTimeout(() => {
                        this.addTenantBtn();
                      }, 500)}
                      maxDate={moveOut.isSelected && moveInDateRestriction}
                      minDate={allTenants[this.tenantIndex +1]?.vacant ? (allTenants.length-1) !== (this.tenantIndex +1) && new Date(allTenants[this.tenantIndex +1]?.moveInDate) : new Date(allTenants[this.tenantIndex +1]?.moveOutDate).setDate(new Date(allTenants[this.tenantIndex +1]?.moveOutDate).getDate()+1)} //: new Date(moveIn.value)}
                      //disabled={!this.state.lastRealTenant}
                    />
                    </div>
                  )}
                  {moveIn.err &&
                  <div className="err">
                    {'Ungültiges Einzugsdatum'}
                  </div>}
                </Form.Group>
              </Col>
              <Col xl={2} lg={3} md={3} xs={6}>
                { }
                <Form.Group>
                  <Form.Label> {lang.tenant.text_move_out_date} </Form.Label>
                  {/* {
                  this.state.lastRealTenant === false ? (
                    <div className="calendar-input"
                    style={{backgroundColor: !this.state.lastRealTenant && '#f7f7fa'}}>
                      <img src={calendar} alt="calendar" />
                      <DatePicker
                        locale="de"
                        customInput={<Form.Control required type="text" />}
                        // placeholderText={!moveIn.isSelected && 'DD.MM.YYYY'}
                        // selected={moveIn.isSelected && moveIn.value}
                        placeholderText={!moveOut.isSelected && 'TT.MM.JJJJ'}
                        selected={moveOut.isSelected && moveOut.value}
                        dateFormat="dd.MM.yyyy"
                        disabled
                      />
                    </div>
                  ) :  */}
                  {/* ( */}
                    {skeletonLoading ?
                    <Skeleton visible={skeletonLoading} className="calendar-input" style={{height: '36px', border: 'none'}}/>
                    :
                    <div className="calendar-input">
                      <img src={calendar} alt="calendar" />
                      <DatePicker
                        locale="de"
                        yearItemNumber="1"
                        yearDropdownItemNumber="1"
                        // dateFormatCalendar="LLLL"
                        onFocus={()=> this.setState({showPartner: true, openPartner: true})}
                        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                        dateFormat="dd.MM.yyyy"
                        customInput={<Form.Control required type="text" placeholder="DD.MM.YYYY" />}
                        style={{ display: 'block !important' }}
                        placeholderText={!moveOut.isSelected && 'TT.MM.JJJJ'}
                        selected={moveOut.isSelected && moveOut.value}
                        onChange={(e) => this.onDateChange('moveOut', e, true)}
                        minDate={moveIn.isSelected ? moveOutMinDateRestriction : new Date(moveoutSetDate)}
                        maxDate={
                          allTenants.length !== 0 && (this.tenantIndex -1) >= 0 ? allTenants[this.tenantIndex - 1].vacant ? allTenants[this.tenantIndex - 1].moveOutDate && new Date(allTenants[this.tenantIndex - 1].moveOutDate) : new Date(allTenants[this.tenantIndex - 1].moveInDate).setDate(new Date(allTenants[this.tenantIndex - 1].moveInDate).getDate()-1)
                          :
                          null
                        }
                        onBlur={()=>this.addTenantBtn()}
                        onSelect={()=> setTimeout(() => {
                          this.addTenantBtn();
                        }, 500)}
                      />
                    </div>}
                  {/* )} */}
                  {moveOut.err && <div className="error err"> {moveOut.msg} </div>}
                </Form.Group>
              </Col>
              {showPartner &&
              <Col xl={2} lg={3} md={3} xs={6} style={{padding: 0}}>
                <Group style={{marginTop: '40px'}}>
                  <Popover
                  opened={tenantPartner && openPartner}
                  target={<img src={PartnerDashboardIcon} onMouseEnter={()=> this.setState({openPartner: true})} />}
                  zIndex={999}
                  position='right'
                  withArrow
                  styles={{
                    popover: {
                      width: '425px'
                    },
                    inner: {
                      padding: '3px 15px 15px'
                    },
                    body: {
                      borderRadius: '10px'
                    }
                  }}
                  >
                    {tenantPartner && <Stack spacing={0}>
                      <Group position='right'>
                        <img src={PartnerPopOverCloseIcon} width={'12px'} style={{marginRight: '-11px', cursor: 'pointer'}} onClick={()=> this.setState({openPartner: false})}/>
                      </Group>
                      <Group noWrap>
                        <span className='partner-receipt-popover-text'>{tenantPartner['tenant-text']}</span>
                        <div>
                          <button onClick={()=> this.props.history.push('/partner?partnerId='+tenantPartner['partner-id'])} className='partner-dashboard-card-button'>
                            <span style={{whiteSpace: 'nowrap'}}>{'Mehr erfahren'}</span>
                          </button>
                        </div>
                      </Group>  
                    </Stack>}
                    
                  </Popover>
                </Group>
              </Col>}
            </Row>
          </Form>

          {/* rents section */}

          {type !== 'self' &&
          <div className="rent-section-header">
            <p className="section-heading">
              {' '}
              {lang.tenant.text_rent}{' '}
              <span>
                {'(monatlich)'}
                {/* {'('} */}
                {/* {lang.tenant.text_billed_monthly} */}
                {/* {')'} */}
              </span>{' '}
            </p>
            {
              // moveOut.isSelected==false ? (

              this.state.rents.length > 0 ? (
                <Skeleton visible={skeletonLoading} style={{width: '100px'}}>
                  <ColorButton
                    text={'Hinzufügen'}
                    className="gray-btn add-rent-btn"
                    onClick={this.toggleRentModal}
                    disabled={type === 'self'}
                  />
                </Skeleton>
              ):
              <Skeleton visible={skeletonLoading} style={{width: '100px', marginBottom: '11px'}}>
                  <ColorButton
                    text={'Hinzufügen'}
                    className="gray-btn add-rent-btn"
                    onClick={this.toggleRentModal}
                    disabled={type === 'self'}
                  />
              </Skeleton>
              // ) : (
              //   <ColorButton text={lang.tenant.text_add_rent} class="purple-btn add-rent-btn" disabled />
              // )
            }
          </div>}
          {type !== 'self' &&
          <Row className="add-rent-table-section">
            <Col xs={12}>
              {this.state.rents.length > 0 ? (
                <RentList
                  parent={this}
                  updateRents={this.getRentsApi}
                  className="rent-list"
                  dataToDelete={this.dataToDelete}
                  sendRentData={this.rentFormData}
                  data={this.state.rents}
                  labelClassName={['rent-time-frame', 'rent-net', 'rent-advance', 'total-rent']}
                  labels={[
                    lang.receipt.text_time_frame,
                    lang.newKeys.text_net_rent,
                    lang.newKeys.text_advance_rent,
                    'Warmmiete(EUR)',
                    '',
                  ]}
                  moveout={this.state.moveOut}
                  disabled={type === 'self'}
                />
              ) : (skeletonLoading ? 
                <>
                  <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px'}}/>
                  <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '10px'}}/>
                </>
                :
                <div className="no-rental-container">
                  <EmptyStateContainer
                    className={'no-rent-state'}
                    heading={lang.tenant.text_no_rent_defined}
                    btnText={lang.tenant.text_add_rent}
                    icon={RentIcon}
                    btnHandler={this.toggleRentModal}
                  />
                </div>
              )}
            </Col>
          </Row>}
          {/* add rent modal */}
          {this.state.rentModalOpen && (
            <RentModal
              updateRents={this.getRentsApi}
              sendRentData={this.rentFormData}
              className="rent-modal"
              record={null}
              edit={false}
              tenantId={this.state.tenantId}
              open={this.state.rentModalOpen}
              toggle={this.toggleRentModal}
              rentsinModal={this.state.rents}
              moveout={this.state.moveOut}
            />
          )}
          {tenantTypeChangeToSelfAlertModalOpen &&
          <TenantTypeChangeToSelfAlertModal
            open={tenantTypeChangeToSelfAlertModalOpen}
            toggle={() => this.setState({tenantTypeChangeToSelfAlertModalOpen: false})}
            proceed={() => this.setState({tenantTypeChangeToSelfAlertModalOpen: false}, ()=> this.onRadioChange('type', 'self'))}
          />}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentRecord: state.tenantDataReducer.currentTenant,
    formData: state.tenantDataReducer.addEditFormData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    isFormValid: state.tenantDataReducer.addEditFormValid,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    propertySelected: state.generalReducer.property,
  };
};
const actions = {
  setAddEditFormData,
  setAddEditFormValidation,
};

export default connect(mapStateToProps, actions)(TenantInfo);
