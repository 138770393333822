import React, { Component } from 'react';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { CustomDateInput, PageHeader } from '../../../../neoverv/index.js';
import { ColorButton, DeleteModal, EmptyStateContainer, AddPropertyModal } from '../../../../neoverv';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// import de from
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import Info from '../../../../assets/images/module/general/Info.svg';
import housingphoto from '../../../../assets/images/module/general/house-log.png';
import emptyPropertyHome from '.././../../../assets/images/module/general/emptyPropertyHome.svg';
import addicons from '../../../../assets/images/module/general/AddIcon.svg';
import subtracticon from '../../../../assets/images/module/general/subtracticon.svg';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  updatePropetyInfo,
  editProfileData,
  deleteProperty,
  setPropertyList,
  changeCurrentProperty,
} from '../../../../redux/action/General/generalActions';
import PropertyDataHandler from '../../../../neoverv/helpers/DataHandler/Property.js';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices.js';
import { hideNotification, showNotification } from '@mantine/notifications';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import { Skeleton } from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import accounting from 'accounting';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
registerLocale('de', de);
const MOBILE_SIZE = 500;
class HeatingInformation extends Component {
  state = {
    addPropertyModal: false,
    id: '',
    propertyName: '',
    propertyNameErr: false,
    propertyAddress: '',
    propertyAddressErr: false,
    propertyCity: '',
    propertyCityErr: false,
    propertyZipCode: '',
    propertyZipCodeErr: false,
    propertyNoOfUnits: '',
    propertyNoOfUnitsErr: false,
    propertyAreaOfUnits: '',
    propertyAreaOfUnitsErr: false,
    emptyProperty: false,
    dropdownValueErr: '',
    heatingType: '',
    // heatingTemp: 0,
    date: {
      value: '',
      isSelected: false,
    },
    // date: new Date(new Date().getFullYear(), 11, 31),
    radiobtnValue: '',
    file: '',
    fileToUpload: '',
    fileUploadErr: { state: false, msg: '' },
    DropdownState: 'true',
    DropdownStateTwo: 'true',
    selectedOptionvalue: 0,
    heatingTemperature: null,
    energyFuelType: null,
    consumptionValue: 0,
    firstTimeConsumptionValue: true,
    // customConsumption: null,
    heatingTypeDropDown: [
      // {
      //   id: 1,
      //   heatingType: 'Centralized with warm water',
      // },
      // {
      //   id: 2,
      //   heatingType: 'Centralized with out warm water',
      // },
      // {
      //   id: 3,
      //   heatingType: 'Decentralized',
      // },
      {
        id: 1,
        heatingType: lang.property.generalinfo_warm_water,
      },
      {
        id: 2,
        heatingType: lang.property.generalinfo_without_warm_water,
      },
      // {
      //   id: 3,
      //   heatingType: lang.property.generalinfo_decentralized,
      // },
    ],
    deleteUnitModal: false,
    fuelTypes: [],
    skeletonLoading: false,
    selectedAllocation: null,
    selectedAllocationErr: false,
    allocationOptions: [],
    dropdownStateTwo: 'true',
    oldHeatingType: '',
    oldHeatingTemperature: '',
    oldConsumptionShare: '',
    oldEnergyFuelType: '',
    oldCentralWarmwaterAllocationKey: '',
    heatedFloorSpace: null,
    oldHeatedFloorSpace: null,
    heatedFloorSpaceInFocus: false,
    propertyOriginalData: null,
    isMobile: window.innerWidth <= MOBILE_SIZE
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    if(this.props.propertyData && this.props.propertyData.id !== undefined){
      this._fetchGeneralInfoData();
      this._getPropertyPicture();
    }else{
      this.ShowLoader();
    }
    this._getFuelTypes();
    this.getAllocationOptions();

    console.log('propertySeleceted: ', this.props.propertySelected);
    // var emptyComponent=
    // var forgotForm = document.getElementById('empty-generalinfo-page');
    // var confirmationBox = document.getElementById('ins-sent');
    // forgotForm.style.display = 'none';
    // confirmationBox.style.display = 'block';
  }
  getAllocationOptions = () => {
    GlobalServices._getAllocationKeysByProperty(this, '_handleGetAllocationOptions', this.props.propertySelected);
  };
  _handleGetAllocationOptions = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_system_fuel_not_found);
    }

    this.setState({ allocationOptions: response.result.filter((key)=> key.meterType === 'RENTAL_UNIT') });
  };
  allocationChange = (option) => {
    this.setState({
      selectedAllocation: {
        id: option.id,
        allocationKeyName: option.allocationKeyName,
        conversionkWh: Autoload.MainHelper.ConverterCurrencyDe(option.conversionkWh + '', false),
        units: option.units,
        propertyId: option.propertyId,
        metered: option.metered,
        systemAllocationKey: option.systemAllocationKey,
        type: option.type
      },
      selectedAllocationErr: false
    }, ()=> {
      if((this.state.oldCentralWarmwaterAllocationKey === null) || (this.state.oldCentralWarmwaterAllocationKey.id !== option.id)){
        this.setState({oldCentralWarmwaterAllocationKey: {
            id: option.id,
            allocationKeyName: option.allocationKeyName,
            conversionkWh: Autoload.MainHelper.ConverterCurrencyDe(option.conversionkWh + '', false),
            units: option.units,
            propertyId: option.propertyId,
            metered: option.metered,
            systemAllocationKey: option.systemAllocationKey,
            type: option.type
          }
        });
        this.validateForm();
      }
    });
  };
  toggleDeleteUnitModal = (id) => {
    console.log('delete modal');
    this.setState({ deleteUnitModal: !this.state.deleteUnitModal });
  };
  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleFuelTypeResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    const fuelTypes = response.result.map((fuelType) => {
      return {
        id: Autoload.MainHelper.checkProperty(fuelType, 'id'),
        fuelTypeName: Autoload.MainHelper.checkProperty(fuelType, 'fuelTypeName'),
        conversionkWh: Autoload.MainHelper.checkProperty(fuelType, 'conversionkWh'),
      };
    });
    this.setState({ fuelTypes });
  }

  _getPropertyPicture() {
    const propertyID = this.props.propertyData.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getPropertyPictureResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _getPropertyPictureResponse(response) {
    if (response.status == true && response.statuscode == 200) {
      this.setState({ file: response.result });
    }
  }

  _fetchGeneralInfoData() {
    const propertyID = this.props.propertyData.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_property_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_property_data(response) {
    console.log(response, 'property api get data working fine ');
    if (response.status == true && response.statuscode == 200) {
      let isCustomConsumption = false;
      if (
        response.result.consumptionShare !== 0 &&
        response.result.consumptionShare !== 50 &&
        response.result.consumptionShare !== 70 &&
        response.result.consumptionValue !== '70' &&
        response.result.consumptionValue !== '50' &&
        response.result.consumptionValue !== '0'
      ) {
        isCustomConsumption = true;
      }

      const HeatingCostGerman = Autoload.MainHelper.checkProperty(response.result, 'heatingType');
      const textHeatingType =
        HeatingCostGerman === 'central with warm water'
          ? lang.property.generalinfo_warm_water
          : HeatingCostGerman === 'central without warm water'
            ? lang.property.generalinfo_without_warm_water
            : HeatingCostGerman === 'decentralized'
              ? lang.property.generalinfo_decentralized
              : '';
      // // ;
      this.setState(
        {
          propertyOriginalData: response.result,
          id: Autoload.MainHelper.checkProperty(response.result, 'id'),
          accountId: Autoload.MainHelper.checkProperty(response.result, 'accountId'),
          propertyName: Autoload.MainHelper.checkProperty(response.result, 'name'),
          propertyAddress: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'streetno'
          ),
          propertyZipCode: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'zipCode'
          ),
          propertyCity: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'city'
          ),

          propertyAreaOfUnits:
            Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace').toString() === ''
              ? Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace')
              : Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace').toString() + ' m²',

          propertyNoOfUnits: Autoload.MainHelper.checkProperty(response.result, 'numberOfRentalUnits'),

          heatingType: textHeatingType, // german????
          heatingTemperature:
            Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() === null
              ? Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature')
              : Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() + ' °C',
          // Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature'),
          consumptionValue: Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', '0'),
          // customConsumption: Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', '0'),
          energyFuelType: Autoload.MainHelper.checkProperty(response.result, 'energyFuelType', null),
          selectedAllocation: Autoload.MainHelper.checkProperty(response.result, 'centralWarmWaterAllocationKey', null),
          
          //needs formatting
          date: {
            isSelected:
              response.result.cutOffDate && response.result.cutOffDate.day && response.result.cutOffDate.month
                ? true
                : false,
            value: moment(
              `${Autoload.MainHelper.checkProperty(
                Autoload.MainHelper.checkProperty(response.result, 'cutOffDate'),
                'day',
                '31'
              )} ${moment()
                .month(
                  parseInt(
                    Autoload.MainHelper.checkProperty(
                      Autoload.MainHelper.checkProperty(response.result, 'cutOffDate'),
                      'month',
                      '12'
                    )
                  ) - 1
                )
                .format('MMMM')}`,
              'DD MMMM'
            )._d,
          },
          // date:{isSelected: response.result.cutOffDate !='' ? true: false, value: response.result.cutOffDate } ,

          selectedOptionvalue: isCustomConsumption
            ? 'custom'
            : Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', 0),

          oldHeatingType: textHeatingType,
          oldHeatingTemperature: Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() === null
          ? Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature')
          : Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() + ' °C',
          oldConsumptionShare: Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', '0'),
          oldEnergyFuelType: Autoload.MainHelper.checkProperty(response.result, 'energyFuelType', null),
          oldCentralWarmwaterAllocationKey: Autoload.MainHelper.checkProperty(response.result, 'centralWarmWaterAllocationKey', null),
          heatedFloorSpace: Autoload.MainHelper.checkProperty(response.result, 'heatedFloorSpace', null),
          oldHeatedFloorSpace: Autoload.MainHelper.checkProperty(response.result, 'heatedFloorSpace', null)
        },
        () => {
          console.log('this.astat', this.state);
          // const { consumptionValue } = this.state;
          // if( consumptionValue !== 70 && consumptionValue !== '70' && consumptionValue !== 50 && consumptionValue !== '50' && consumptionValue !== 0 && consumptionValue !== '0' ) {
          //   this.setState({ consumptionValue: 'custom' })
          // }
        }
      );
    }
    this.ShowLoader();
  }

  _addGeneralInfo() {
    hideNotification('save_heating_info');
    const {propertySelected} = this.props;
    const {propertyOriginalData} = this.state;
    console.log('propertySelected: ', propertySelected);
    let heatingValue =
      this.state.heatingType === lang.property.generalinfo_warm_water
        ? 'central with warm water'
        : this.state.heatingType === lang.property.generalinfo_without_warm_water
          ? 'central without warm water'
          : this.state.heatingType === lang.property.generalinfo_decentralized
            ? 'decentralized'
            : '';
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const propertyID = this.props.propertyData.id;
    const accountID = this.props.profileData.accountId;
    const generalInfoData ={
      ...propertyOriginalData,
      propertyId: propertyID,
      accountId: accountID,
      name: propertySelected.name,
      propertyAdress: {
        streetno: propertySelected.propertyAddress,
        city: propertySelected.propertyCity,
        zipCode: propertySelected.propertyZipCode,
      },
      totalRentingSpace: propertySelected.totalRentingSpace,
      numberOfRentalUnits: propertySelected.numberOfRentalUnits,
      cutOffDate: propertySelected.cutOffDate,
      heatingType: heatingValue ? heatingValue.toLowerCase() : '',
      heatingTemperature: this.state.heatingTemperature !== '' ? parseInt(this.state.heatingTemperature) : 0,
      consumptionShare: this.state.consumptionValue,
      energyFuelType: this.state.energyFuelType,
      receiptEmail: propertySelected.receiptEmail,
      meterReadingsEmail: propertySelected.meterReadingsEmail,
      centralWarmwaterAllocationKey: this.state.selectedAllocation,
      heatedFloorSpace: this.state.heatedFloorSpace,
      meterReadingConducted: propertySelected.meterReadingConducted,
      propertyType: propertySelected.propertyType,
      occupantsConfiguration: propertySelected.occupantsConfiguration,
      isConsumptionSectionHidden: propertySelected.isConsumptionSectionHidden,
      isDocumentSectionHidden: propertySelected.isDocumentSectionHidden,
      isMessageSectionHidden: propertySelected.isMessageSectionHidden
    }
    console.log('generalInfoData', generalInfoData);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'PUT',
      generalInfoData,
      null,
      false,
      true,
      false,
      this,
      '_PutGeneralInfoResponse',
      { generalInfoData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _PutGeneralInfoResponse(response) {
    showNotification({
      id: 'save_heating_info',
      icon: <img src={GreenTick} alt='green-tick'/>,
      message: 'Die Änderungen wurden übernommen.',
    });
    if (this.state.file) {
      if (this.state.fileToUpload) {
        this.putPropertyPicture(this.props.propertyData.id, response.other.generalInfoData);
      } else {
        this.updateProperties(response.other.generalInfoData);
      }
    } else {
      this.deletePropertyPicture(this.props.propertyData.id, response.other.generalInfoData);
    }
  }

  // handlePropertyData(response) {
    // if (response.status === true && response.statuscode === 200) {
  //     const propertyData = PropertyDataHandler.getPropertiesData(response.result)
  //     // this.props.setPropertyList(propertyData);
  //     var _newUpdatedProperty = {
  //       id: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyId'),
  //       propertyName: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'name'),
  //       propertyAddress: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'streetno'),
  //       propertyCity: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'city'),
  //       propertyZipCode: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'zipCode'),
  //       propertyNoOfUnits: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'numberOfRentalUnits'),
  //       propertyAreaOfUnits: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'totalRentalSpace'),
  //       cutOfDate: `${Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'cutOffDate'), 'day', '31')} ${moment()
  //         .month(parseInt(Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'cutOffDate'), 'month', '12')) - 1)
  //         .format('MMMM')}`,
  //       heatingType: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'heatingType'),
  //       heatingTemp: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'heatingTemperature'),
  //       consumptionValue: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'consumptionShare',0),
  //       energyFuelType: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'energyFuelType'),
  //     };
  //     this.props.changeCurrentProperty(_newUpdatedProperty);
  //     Autoload.MainHelper.redirection('/dashboard');
  //     // this.props.history.push('/dashboard');
  //   }
  // }

  updateProperties(updated_property_response, deleteProperty=false) {
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      heatingType: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingType'),
      heatingTemp: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      heatingTemperature: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      consumptionValue: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      consumptionShare: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      energyFuelType: Autoload.MainHelper.checkProperty(updated_property_response, 'energyFuelType', null),
      centralWarmWaterAllocationKey: Autoload.MainHelper.checkProperty(updated_property_response, 'centralWarmwaterAllocationKey', null),
      heatedFloorSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'heatedFloorSpace', null)
    }
    console.log('updateProperties: ', propertySelected);
    this.props.changeCurrentProperty(deleteProperty ? this.props.allProperties[0] : propertySelected);

    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    
    //this.props.history.push('/dashboard');
  }

  deleteProperty = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    //let btnelement = document.getElementById('delete-data-property');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + id,
      'DELETE',
      null,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      'handleDelete',
      { //cancelbtn: btnelement.children[0], 
        id: id },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDelete = (response) => {
    console.log('delete property', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      //
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.deletePropertyPicture(this.props.propertyData.id);
    this.props.deleteProperty(response.other.id);
    // this._getPropertyListData();
  };

  _getPropertyListData() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'properties?accountId=' +
      this.props.profileData.id +
      '&offset=0&fetch=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyData',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handlePropertyData(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const propertyData = PropertyDataHandler.getPropertiesData(response.result);
      this.props.setPropertyList(propertyData);
      if (propertyData.length != 0) {
        this.props.changeCurrentProperty(propertyData[0]);
      }
      // this.props.history.push('/dashboard');
    }
    // this.ShowLoader();
  }
  ShowLoader = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-generalinfo-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('respond-generalinfo-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  onDateChange = (field, date) => {
    this.setState({ [field]: { isSelected: true, value: date } });
  };

  onInputChange = (field, value) => {
    // this.setState({ [field]: value });
    this.setState({ [field]: value }, () => this.validatePropertyData());
  };

  handlePictureUpload_general = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      const props = this.props;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${fileDimensionErr ? 'dimension' : ''
              }`;
            classContext.setState({ fileUploadErr: { state: true, msg } });
          } else {
            classContext.setState({
              file: URL.createObjectURL(file),
              fileToUpload: file,
              fileUploadErr: { state: false, msg: '' },
            });
            // const payload = new FormData();
            // payload.append('fil', file, file.name);
            // Autoload.HttpRequestAxios.HttpRequest(
            //   Autoload.Setting.property_api_base_url + 'Properties/' + props.propertyData.id + '/Picture',
            //   'PUT',
            //   payload,
            //   null,
            //   false,
            //   true,
            //   false,
            //   classContext,
            //   '_handlePictureUpload',
            //   null,
            //   false,
            //   '',
            //   {
            //     'Content-Type': 'application/json',
            //     Authorization: Autoload.Utils.getAuthToken(),
            //   }
            // );
          }
        };
      };
    }
  };

  deletePropertyPicture(id, updated_property_response= null) {
    //let btnelement = document.getElementById('delete-propertypicture-button_id');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'Properties/' + id + '/Picture',
      'DELETE',
      null,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      'handleDeletePropertyPicture',
      { //cancelbtn: btnelement.children[0], 
        updated_property_response: updated_property_response },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeletePropertyPicture(response) {
    console.log('delete property picture', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.setState({
      file: null,
    });
    this.updateProperties(response.other.updated_property_response, response.other.updated_property_response === null && true);
  }

  putPropertyPicture(id, updated_property_response) {
    const payload = new FormData();
    payload.append('file', this.state.file ? this.state.fileToUpload : null);
    //let btnelement = document.getElementById('save_property_picture');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + id + '/Picture',
      'PUT',
      payload,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      'putPropertyPictureResponse',
      { updated_property_response: updated_property_response },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  putPropertyPictureResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.updateProperties(response.other.updated_property_response);
  }

  validatePropertyData = () => {
    this.setState({
      propertyNameErr: false,
      // propertyAddressErr: false,
      // propertyCityErr: false,
      // propertyZipCodeErr: false,
      // propertyNoOfUnitsErr: false,
      // propertyAreaOfUnitsErr: false,
    });
    let propertyNameErr,
      // propertyAddressErr,
      // propertyCityErr,
      // propertyZipCodeErr,
      // propertyNoOfUnitsErr,
      // propertyAreaOfUnitsErr,
      fileErr = false;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    const validNum = /^[0-9]/;

    if (!this.state.propertyName) {
      propertyNameErr = true;
    }
    // if (!this.state.propertyAddress) {
    //   propertyAddressErr = true;
    // }
    // if (!this.state.propertyCity) {
    //   propertyCityErr = true;
    // }
    // if (!this.state.propertyZipCode || this.state.propertyZipCode === null) {
    //   propertyZipCodeErr = true;
    // }
    // if (!this.state.propertyNoOfUnits || this.state.propertyNoOfUnits === null) {
    //   propertyNoOfUnitsErr = true;
    // }
    // if (
    //   !this.state.propertyAreaOfUnits ||
    //   this.state.propertyAreaOfUnits === null ||
    //   !validNum.test(this.state.propertyAreaOfUnits.replace('m²', ''))
    //   // !validNum.test(Number(this.state.propertyAreaOfUnits.replace('m≤', '')))
    // ) {
    //   propertyAreaOfUnitsErr = true;
    // }
    if (
      this.state.fileToUpload !== null &&
      this.state.fileToUpload !== '' &&
      this.state.fileToUpload !== undefined &&
      this.state.fileUploadErr.state
    ) {
      fileErr = true;
    }

    this.setState({
      propertyNameErr,
      // propertyAddressErr,
      // propertyCityErr,
      // // propertyZipCodeErr,
      // propertyNoOfUnitsErr,
      // propertyAreaOfUnitsErr,
    });
    if (
      propertyNameErr ||
      // propertyAddressErr ||
      // propertyCityErr ||
      // propertyZipCodeErr ||
      // propertyNoOfUnitsErr ||
      // propertyAreaOfUnitsErr ||
      fileErr
    ) {
      return false;
    }
    return true;
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>
        Anteil der Heizkosten die verbrauchsabhängig umgelegt werden. Mehr Informationen im{' '}
        <a
          target="_blank"
          href="https://wissen.hellohousing.de/welcher-kostenanteil-soll-nach-verbrauch-umgelegt-werden"
        >
          Hilfe-Center
        </a>
        .
      </span>
    </Tooltip>
  );

  removeSymbol = (field, value, symbol) => {
    console.log('remove symbol', value.replaceAll(symbol, ''));

    this.setState(
      this.state.firstTimeConsumptionValue
        ? { [field]: value.replaceAll(symbol, ''), firstTimeConsumptionValue: false }
        : { [field]: value.replaceAll(symbol, '') }
    );
  };

  addSymbol = (field, value, symbol) => {
    if (value.toString().includes(symbol)) {
      if(this.state.oldHeatingTemperature !== `${value} °C`){
        this.setState({oldHeatingTemperature: `${value}${symbol}`});
        this.validateForm();
      }
      return;
    }
    if (!isNaN(parseFloat(value))) {
      this.setState({ [field]: `${value}${symbol}` }, ()=> {
        if(this.state.oldHeatingTemperature !== `${value} °C`){
        this.setState({oldHeatingTemperature: `${value}${symbol}`});
        this.validateForm();
        }
      });
    } else {
      this.setState({ [field]: value.replaceAll(symbol, '') }, ()=> {
        if(this.state.oldHeatingTemperature !== `${value} °C`){
          this.setState({oldHeatingTemperature: `${value}${symbol}`});
          this.validateForm();
        }
      });
    }
  };

  validateForm = () => {
      // console.log('validate form');
      this.setState({
        propertyNameErrMsg: false,
        // addressErrMsg: false,
        // areaOfUnitsErrMsg: false,
        // noOfUnitsErrMsg: false,
        // cityErrMsg: false,
        // zipCodeErrMsg: false,
        fuelTypeErrMsg: false,
        heatingTempErrMsg: false,
        heatingTypeErrMsg: false,
        selectedOptionErrMsg: false,
      });
      let propertyNameErrMsg,
        // addressErrMsg,
        // areaOfUnitsErrMsg,
        // cityErrMsg,
        // zipCodeErrMsg,
        // noOfUnitsErrMsg,
        fuelTypeErrMsg,
        heatingTempErrMsg,
        heatingTypeErrMsg,
        selectedOptionErrMsg = false;
      const validNum = /^[0-9]/;
      //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
      const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
      // if (!this.state.propertyName) {
      //   propertyNameErrMsg = true;
      // }
  
      if(this.state.heatingType !== lang.property.generalinfo_decentralized && (!this.state.energyFuelType || !this.state.energyFuelType.fuelTypeName || this.state.energyFuelType.fuelTypeName === lang.newKeys.text_select)) {
        fuelTypeErrMsg = true;
      }
      // if (!this.state.address) {
      //   addressErrMsg = true;
      // }
      // if (!this.state.city) {
      //   cityErrMsg = true;
      // }
      // if (!this.state.zipCode) {
      //   zipCodeErrMsg = true;
      // }
      // if (!this.state.areaOfUnits || !validNum.test(this.state.areaOfUnits)) {
      //   areaOfUnitsErrMsg = true;
      // }
      // if (
      //   !this.state.noOfUnits ||
      //   !validNum.test(this.state.noOfUnits) ||
      //   !validNum.test((this.state.noOfUnits + '').replace('m²', ''))
      // ) {
      //   noOfUnitsErrMsg = true;
      // }
  
      if (
        !this.state.heatingTemp ||
        !validNum.test(this.state.heatingTemp) ||
        !validNum.test((this.state.heatingTemp + '').replace('°C', ''))
      ) {
        heatingTempErrMsg = true;
      }
      if (
        !this.state.selectedOption ||
        !validNum.test(this.state.selectedOption) ||
        !validNum.test((this.state.selectedOption + '').replace('°%', ''))
      ) {
        selectedOptionErrMsg = true;
      }
  
      if (!this.state.heatingTypeErrMsg || this.state.heatingTypeErrMsg === lang.newKeys.text_select) {
        heatingTypeErrMsg = true;
      }
  
      this.setState({
        //propertyNameErrMsg,
        // addressErrMsg,
        // areaOfUnitsErrMsg,
        // noOfUnitsErrMsg,
        // cityErrMsg,
        // zipCodeErrMsg,
        fuelTypeErrMsg,
        heatingTypeErrMsg,
        selectedOptionErrMsg,
        heatingTempErrMsg,
      });
  
      if (!fuelTypeErrMsg) {
        this._addGeneralInfo()
      } else {
        showNotification({
          message: 'Bitte ergänzen Sie die fehlenden Informationen.',
          icon: <img src={RedCross} alt='red-tick'/>,
        });
      }
  }

  render() {
    const {
      heatingType,
      heatingTemperature,

      heatingTypeDropDown,
      fuelTypes,
      consumptionValue,
      selectedOptionvalue,
      skeletonLoading,
      heatedFloorSpace,
      oldHeatedFloorSpace,
      heatedFloorSpaceInFocus,
      isMobile
    } = this.state;
    console.log('propertySelected: ', this.props.propertySelected);
    return (
      <>
        <div id="empty-generalinfo-page"></div>
        <div className="generalinfo-page" id="respond-generalinfo-page" style={{ display: 'block' }}>
        <PageHeader
            heading={<Skeleton visible={skeletonLoading}>{'Heizung'}</Skeleton>}
            headingClassName={'tooltips property-heating-heading'}
            hideDivider={true}
            hideFilters={isMobile}
          />
          {this.props.allProperties.length > 0 ? (
            <div>
              <Row>
                <Col xl={4} lg={6} md={6} xs={12}>
                  <Form.Group>
                  <div className='tooltips property-heating-type-edit'><Form.Label>{lang.property.generalinfo_heating_type}</Form.Label></div>
                    {this.state.skeletonLoading ? 
                    <Skeleton visible={this.state.skeletonLoading} style={{height: '36px', width: '355px'}}>
                    </Skeleton>
                    :
                    <UncontrolledDropdown
                      id="myid"
                      onBlur={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle className="property-single_dropdown-allocation-modal">
                        {heatingType === '' || heatingType === null ? lang.newKeys.text_select : heatingType}
                        <div>
                          {this.state.DropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu">
                        {heatingTypeDropDown.map((heatType) => {
                          return (
                            <DropdownItem
                              key={heatType.id}
                              onClick={() => 
                                this.setState({ heatingType: heatType.heatingType }, ()=> {
                                  if(this.state.oldHeatingType !== heatType.heatingType){
                                    this.setState({oldHeatingType: heatType.heatingType});
                                    this.validateForm();
                                  }
                                }
                              )}
                              className="px-0 "
                            >
                              {/* <span className="property-single-dropdown-options">{lang.property.generalinfo_warm_water}</span> lang.property.generalinfo_warm_water  lang.property.generalinfo_decentralized*/}
                              <span className="property-single-dropdown-options">{heatType.heatingType}</span>
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={4} lg={6} md={6} xs={12} className="add_property_modal_dropdown">
                  <Form.Group>
                  <div className='tooltips property-energy-source-edit'><Form.Label>{lang.newKeys.text_Energy_source}</Form.Label></div>
                    {//(heatingType.toLowerCase() === lang.property.generalinfo_without_warm_water.toLowerCase()) || (heatingType.toLowerCase() === lang.property.generalinfo_warm_water.toLowerCase()) ? (
                      <>
                      {this.state.skeletonLoading ? 
                      <Skeleton visible={this.state.skeletonLoading} style={{height: '36px', width: '355px'}}>
                      </Skeleton>
                      :
                      <UncontrolledDropdown
                        direction="down"
                        id="myidtwo"
                        onBlur={() => {
                          this.setState({
                            DropdownStateTwo: document
                              .getElementById('myidtwo')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            DropdownStateTwo: document
                              .getElementById('myidtwo')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                      >
                        <DropdownToggle
                          className="property-single_dropdown"
                          style={{
                            border: this.state.fuelTypeErrMsg === true ? '1px solid #dc3545' : null,
                          }}
                        >
                          <div style={{verticalAlign: 'center'}}>
                            {this.state.energyFuelType ? Object.keys(this.state.energyFuelType).length === 0
                              ? lang.newKeys.text_select
                              : this.state.energyFuelType.fuelTypeName : lang.newKeys.text_select}
                          </div>
                          <div>
                            {this.state.DropdownStateTwo === 'true' ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={uparrow} alt="" />
                            )}
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="property-single-dropdown-menu">
                          {fuelTypes.map((fuelType) => {
                            return (
                              <DropdownItem
                                className="px-0"
                                onClick={() => {
                                  this.setState({
                                    energyFuelType: {
                                      id: fuelType.id,
                                      fuelTypeName: fuelType.fuelTypeName,
                                      conversionkWh: fuelType.conversionkWh,
                                    },
                                    fuelTypeErrMsg: false
                                  }, ()=> {
                                    if((this.state.oldEnergyFuelType === null) || (this.state.oldEnergyFuelType.id !== fuelType.id)){
                                      this.setState({oldEnergyFuelType: fuelType});
                                      this.validateForm();
                                    }
                                  });
                                }}
                              >
                                <span className="property-single-dropdown-options">{fuelType.fuelTypeName}</span>
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>}
                      <div className="heatingDropdown_errormsg">
                      {this.state.fuelTypeErrMsg ? (
                        <p>{lang.newKeys.text_fuelType_error}</p>
                      ) : null}
                      </div>
                      </>
                    // ) : (
                    //   <Skeleton visible={this.state.skeletonLoading}>
                    //   <Form.Control disabled 
                    //   placeholder={this.state.heatingType === lang.property.generalinfo_decentralized ? 'Nicht benötigt' : lang.newKeys.text_select}
                    //   />
                    //   </Skeleton>
                    // )
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={2} lg={4} md={3} xs={6}>
                  <Form.Group style={{width: '170px'}}>
                  <div className='tooltips property-heating-space'><Form.Label className="cp-amount-label"><span>Beheizte Wohnfläche</span></Form.Label></div>
                    {this.state.skeletonLoading ? 
                    <Skeleton visible={this.state.skeletonLoading} style={{height: '36px', width: '170px'}}>
                    </Skeleton>
                    :
                    <NumericFormat 
                    className='form-control'
                    value={heatedFloorSpace}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => this.setState({heatedFloorSpace: values.floatValue})}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 m²'
                    suffix={' m²'}
                    onFocus={()=> this.setState({heatedFloorSpaceInFocus: true})}
                    onBlur={(e)=> {
                      let value = accounting.unformat(e.target.value.split(' ')[0], ',');
                      if(value !== oldHeatedFloorSpace){
                        this.setState({heatedFloorSpace: value, oldHeatedFloorSpace: value}, ()=> this.validateForm());
                      }
                      this.setState({heatedFloorSpaceInFocus: false});
                    }}
                    decimalScale={heatedFloorSpaceInFocus ? 5 : 2}
                    fixedDecimalScale={heatedFloorSpaceInFocus ? false : true}
                    allowNegative={false}
                    />}
                    <Form.Control.Feedback className="err" type="invalid">
                      {/* {amount.msg} */}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {heatingType === lang.property.generalinfo_warm_water &&
                <>
                <Row>
                  <Col xl={2} lg={4} md={3} xs={6}>
                    <Form.Group>
                    <div className='tooltips property-temperature-edit'><Form.Label>{
                    'Vorlauftemperatur'//lang.property.generalinfo_heating_temp
                    }</Form.Label></div>
                      {(heatingType.toLowerCase() === lang.property.generalinfo_warm_water.toLowerCase()) || (heatingType.toLowerCase() === lang.property.generalinfo_warm_water.toLowerCase()) ? (
                        <div style={{width: '170px'}}>
                          <Skeleton visible={this.state.skeletonLoading}>
                          <Form.Control
                            isInvalid={false}
                            value={this.state.heatingTemperature}
                            type="text"
                            placeholder="60 &#8451;"
                            onFocus={(e) => this.removeSymbol('heatingTemperature', e.target.value, ' °C')}
                            onBlur={(e) => this.addSymbol('heatingTemperature', e.target.value, ' °C')}
                            //onChange={(e) => this.setState({ heatingTemperature: e.target.value })}
                            onChange={(e) => {
                              this.onInputChange('heatingTemperature', e.target.value, true);
                            }}
                          />
                          </Skeleton>
                        </div>
                      ) : (
                        <Skeleton visible={this.state.skeletonLoading}>
                        <Form.Control disabled placeholder="60 &#8451;" />
                        </Skeleton>
                      )}
                    </Form.Group>
                  </Col>
                </Row>   
                <Row>
                  <Col xl={4} lg={6} md={6} xs={12} className="add_property_modal_dropdown">
                    <Form.Group>
                    <div className='tooltips property-warm-water-edit'><Form.Label className="cp-cost-position-label"> {'Einheit Warmwasserboiler'}</Form.Label></div>
                    {this.state.skeletonLoading ? 
                      <Skeleton visible={this.state.skeletonLoading} style={{height: '36px', width: '355px'}}>
                      </Skeleton>
                      :
                      heatingType === lang.property.generalinfo_warm_water ? 
                      <UncontrolledDropdown
                      id="keyid2"
                      onBlur={() => {
                        this.setState({
                          dropdownStateTwo: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          dropdownStateTwo: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle className="dropdown-select form-control">
                        <span style={{minWidth: 0, overflow: 'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}> {this.state?.selectedAllocation && this.state?.selectedAllocation.allocationKeyName ? `${this.state?.selectedAllocation?.allocationKeyName} ${(this.state?.selectedAllocation?.units && this.state?.selectedAllocation?.units !== '-') ? `(${this.state?.selectedAllocation?.units})` : ''}` : lang.newKeys.text_select}</span>
                        {/* <span style={{paddingRight: 10}}> {this.state?.selectedAllocation?.units && `(${this.state?.selectedAllocation?.units})`}</span> */}
                        <span>
                          {' '}
                          {this.state.dropdownStateTwo === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu">
                        {this.state.allocationOptions.map((option) => option.type === 'WARMWATER' && (option.allocationKeyName !== 'Warmwasserverbrauch') && (
                          <DropdownItem
                            onClick={() => {
                              this.allocationChange(option);
                            }}
                            className="cpRow-options"
                          >
                            {console.log('option: ', option)}
                            <span className="property-single-dropdown-options">{`${option.allocationKeyName} ${(option.units && option.units !== '-') ? `(${option.units})` : ''}`}</span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    :
                    <div>
                      <Form.Control
                        disabled
                        value={this.state.selectedAllocation != null ? this.state.selectedAllocation : ''}
                        placeholder={heatingType === lang.property.generalinfo_without_warm_water ? 'Nicht benötigt' : lang.newKeys.text_select}
                        className="disbaled-temperature"
                        // onChange={() => {
                        //   this.setState({
                        //     energyFuelType: null,
                        //   });
                        // }}
                      />
                    </div>}
                    {!this.state.selectedAllocation?.allocationKeyName && this.state.selectedAllocationErr && <span style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '80%',
                      color: '#dc3545'
                      }}>Einheit erforderlich</span>}
                    </Form.Group>
                  </Col>
                </Row>
              </>}
              <Col xl={4} lg={6} md={6} xs={12} className="tooltips text-icon">
                <Form.Group className="add-property-modal-text">
                  <Form.Label //className="add-property-modal-text-INFO property-consumption-percentage-edit"
                  className='property-consumption-percentage-edit'>
                    Verbrauchsabhängiger Kostenanteil
                    {/* {lang.property.generalinfo_text_one} <br /> {lang.property.generalinfo_text_two} */}
                  </Form.Label>
                  {/* <div className="add-property-modal-text-Icon"> */}
                    {/* <OverlayTrigger placement="top" delay={{ show: 200, hide: 400 }} overlay={this.renderTooltip}>
                      <img
                        src={Info}
                        alt="info"
                        width="16px"
                        height="16px"
                        title={lang.newKeys.Property_consumption_Tooltip}
                      />
                    </OverlayTrigger> */}
                  {/* </div> */}
                </Form.Group>
              </Col>
              <div className="generalInfo_radiobtns">
                <div
                  className={`radio hello-housing-radio-options ${selectedOptionvalue === 70 || selectedOptionvalue === '70' ? `checked` : ``
                    } `}
                >
                  <label className="d-flex">
                    <input
                      type="radio"
                      value="70"
                      className="pr-0"
                      checked={selectedOptionvalue === 70 || selectedOptionvalue === '70'}
                      onChange={(e) => {
                        this.setState({
                          consumptionValue: e.target.value,
                          // customConsumption: e.target.value,
                          // selectedOption: e.target.value,
                          selectedOptionvalue: 70,
                        }, ()=> this.validateForm());
                      }}
                    />
                    <span className="profile_modal_radiobtns-label"><Skeleton visible={this.state.skeletonLoading}>70%</Skeleton></span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${selectedOptionvalue === 50 || selectedOptionvalue === '50' ? `checked` : ``
                    } `}
                >
                  <label className="d-flex">
                    <input
                      type="radio"
                      value="50"
                      className="pr-0"
                      checked={selectedOptionvalue === 50 || selectedOptionvalue === '50'}
                      onChange={(e) => {
                        this.setState({
                          consumptionValue: e.target.value,
                          // customConsumption: e.target.value,
                          // selectedOption: e.target.value,
                          selectedOptionvalue: '50',
                        }, ()=> this.validateForm());
                      }}
                    />
                    <span className="profile_modal_radiobtns-label"><Skeleton visible={this.state.skeletonLoading}>50%</Skeleton></span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${selectedOptionvalue === 0 || selectedOptionvalue === '0' ? `checked` : ``
                    } `}
                >
                  <label className="d-flex">
                    <input
                      type="radio"
                      value="0"
                      className="pr-0"
                      checked={selectedOptionvalue === 0 || selectedOptionvalue === '0'}
                      onChange={(e) => {
                        this.setState({
                          consumptionValue: e.target.value,
                          // customConsumption: e.target.value,
                          // selectedOption: e.target.value,
                          selectedOptionvalue: 0,
                        }, ()=> this.validateForm());
                      }}
                    />
                    <span className="profile_modal_radiobtns-label"><Skeleton visible={this.state.skeletonLoading}>0%</Skeleton></span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOptionvalue == 'custom' ? `checked` : ``
                    } `}
                >
                  <label className="d-flex">
                    <input
                      type="radio"
                      className="pr-0"
                      value={consumptionValue}
                      checked={selectedOptionvalue === 'custom'} //!== 70 && selectedOptionvalue !== '70' && selectedOptionvalue !== 50 && selectedOptionvalue !== '50' && selectedOptionvalue !== 0 && selectedOptionvalue !== '0'}
                      onChange={(e) => {
                        this.setState({
                          consumptionValue: e.target.value,
                          selectedOptionvalue: 'custom',
                          // selectedOption: -1,
                        });
                      }}
                    />
                    <span className="profile_modal_radiobtns-label"><Skeleton visible={this.state.skeletonLoading}>{lang.property.generalinfo_custom}</Skeleton></span>
                  </label>
                </div>
              </div>
              <Row>
                <Col xs={4} md={2}>
                  {selectedOptionvalue === 'custom' ? (
                    <div className="custom-input_margin" style={{ position: 'relative', top: 0, left: 0 }}>
                      <Form.Control
                        value={consumptionValue}
                        // value={
                        //   consumptionValue == 0
                        //     ? consumptionValue
                        //     : consumptionValue.toString().includes('%')
                        //     ? consumptionValue
                        //     : this.state.firstTimeConsumptionValue
                        //     ? consumptionValue + ' %'
                        //     : consumptionValue
                        // }
                        placeholder=""
                        type="text"
                        // onFocus={(e) => this.removeSymbol('consumptionValue', e.target.value, ' %')}
                        // onBlur={(e) => this.addSymbol('consumptionValue', e.target.value, ' %')}
                        onChange={(e) => {
                          this.onInputChange('consumptionValue', e.target.value, true);
                          // this.setState({ consumptionValue: e.target.value });
                        }}
                        onBlur={()=> this.validateForm()}
                      />
                      <span style={{ position: 'absolute', top: '8px', right: '13px' }}>%</span>
                    </div>
                  ) : null}
                </Col>
              </Row>
              {/* <Row style={{ marginTop: '15px' }}>
                <Col xl={4} lg={6} md={3} xs={12} className="generalinfo-buttons">
                  <Skeleton visible={this.state.skeletonLoading}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <ColorButton
                        onClick={() => this.props.history.push('/dashboard')}
                        className="gray-btn"
                        text={lang.general.text_cancel}
                        buttonStyle={{maxWidth: '100px'}}
                      />
                      <ColorButton
                        onClick={() => {
                          this.validateForm()
                        }}
                        buttonConfig={{ type: 'submit' }}
                        className="purple-btn"
                        text={lang.general.text_Save_Changes}
                        buttonStyle={{maxWidth: '100px'}}
                      />
                      </div>
                  </Skeleton>
                </Col>
              </Row> */}
              <div>
                {/* <h3 className="danger-text">{lang.general.text_danger_zone}</h3> */}
                {/* <div
                  //id="delete-data-property"
                  style={{ marginBottom: '50px' }}
                >
                  <button className="delete-button" onClick={() => this.toggleDeleteUnitModal()}>
                    <Skeleton visible={this.state.skeletonLoading}>{lang.property.generalinfo_delete_property}</Skeleton>
                  </button>
                </div> */}
              </div>
              <DeleteModal
              open={this.state.deleteUnitModal}
              className="delete-rent-modal"
              toggle={this.toggleDeleteUnitModal}
              record={this.props.propertyData.id}
              handleDelete={() => this.deleteProperty(this.props.propertyData.id)}
              // showInfo={[this.props.propertyData.name,]}
              type={lang.property.text_property}
              showtext={false}
              showDeleteText={false}
              textShowModal={'Immobilie löschen'}
              typeText={
                'Sind Sie sicher, dass Sie diese Immobilie löschen wollen? Dabei werden auch sämtliche Daten und hochgeladene Dokumente der Immobilie unwiderruflich gelöscht!'
              }
              modalId="delete-data-property"
            />
            </div>
          ) : (
            // <EmptyStateDashboard
            //   heading="Add New property"
            //   // subheading="You must add property first to manage receipts."
            //   className="emptystate-dashboard empty-state-btn-added "
            //   icon={emptyPropertyHome}
            //   btnHandler={() => {}}
            //   btnText="Add Property"
            //   blueButtonDisable={true}
            //   buttonDisable={false}
            // />
            <EmptyStateContainer
              heading={lang.newKeys.text_add_new_property}
              subheading="Hier können Sie später Ihre Immobilie verwalten."
              icon={emptyPropertyHome}
              btnHandler={() => {
                this.toggleAddPropertyModal();
              }}
              btnText={lang.general.text_add_property}
            />
          )}
        </div>
        <AddPropertyModal
          className="add_proprty_modal"
          edit={false}
          open={this.state.addPropertyModal}
          toggle={() => {
            this.toggleAddPropertyModal();
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertyData: state.generalReducer.property,
    profileData: state.generalReducer.profile,
    allProperties: state.generalReducer.allProperties,
    // propertySelected: state.generalReducer.property,
    propertySelected: state.generalReducer.property,
  };
};

const actions = {
  updatePropetyInfo,
  deleteProperty,
  editProfileData,
  setPropertyList,
  changeCurrentProperty,
  updatePropetyInfo,
};

export default connect(mapStateToProps, actions)(HeatingInformation);
