import React, { Component } from 'react';
import {
  ColorButton,
  SearchContainer,
  SortContainer,
  Divider,
  RentalUnitModal,
  AddPropertyModal,
  DeleteModal,
  EmptyStateContainer,
  AddTenantModal,
  SortAlphabetically,
} from '../../../../neoverv';
import { TenantRecordRow, PageHeader, PageHeaderNew } from '../../../elements/index';
import {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  loadingTenantsData
} from '../../../../redux/action/Tenant/TenantActions';
import {
  setRefreshTenantDocumentsData
} from '../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import UnitsIcon from '../../../../assets/images/module/general/UnitsIcon.svg';
import BlueUnitsIcon from '../../../../assets/images/module/general/BlueEmptyTenant.svg';
import HelpButton from '../../../../assets/images/module/general/HelpButton.svg';
import TourButton from '../../../../assets/images/module/general/TourButton.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import moment from 'moment';
import Autoload from '../../../../neoverv/Autoload';
import { Group, Popover, Skeleton, Stack } from '@mantine/core';
import { Button, ButtonGroup } from 'react-bootstrap';
import FilteredTenantsList from './FilteredTenantsList';
import TenantAdministration from './TenantAdministration';
import TenantsList from './TenantsList';
import BuyPremiumModal from '../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import dayjs from 'dayjs';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add, CreateNewFolder, NoteAdd } from '@material-ui/icons';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import { CSVLink } from 'react-csv';
import { browserName } from 'react-device-detect';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { downloadExcel } from 'react-export-table-to-excel';
import * as XLSX from 'xlsx';
import RentalIncomeExportRangeModal from '../../../../neoverv/components/Module/General/CustomModal/RentalIncomeExportRangeModal';
import TenantRentalIncomeTracking from './TenantRentalIncomeTracking';
// import {
//   getRentaUnitsApi,
//   getTenantsApi,
// } from '../../../../neoverv/helpers/services/tenantServices';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const {
  filterBySearchBarRentalTenants,
  deleteRecord,
  checkProperty,
  mergeArrayByProperty,
  nestedAlphabeticSort,
  filterTenantsAndUnits,
  filterByDateUtility,
} = Autoload.MainHelper;

let rentalListData = [];
let rentalUnits = [];
let tenants = [];

const routes = [
  {
    name: 'tenantsRecord',
    className: 'tooltips tenants-record',
    route: '/user',
    label: 'Mieter',
    heading: lang.newKeys.text_heading_meter_readings,
  },
  {
    name: 'rentIncomeTracking',
    className: 'tooltips tenants-rent-income-tracking',
    route: '/user/rentIncomeTracking',
    label: 'Mieteingang',
    heading: 'Mieteingang',
  },
  // {
  //   name: 'tenantsAdministration',
  //   className: 'tooltips tenants-administration',
  //   route: '/user/administration',
  //   label: 'Mieterportal',
  //   heading: 'Mieterportal',
  // },
];
const MOBILE_SIZE = 992;

class List extends Component {
  constructor(props) {
    super(props);
    // console.log('constructor')
    this.state = {
      rentalUnits: [],
      addUnitModal: false,
      deleteUnitModal: false,
      editUnit: false,
      editUnitModal: false,
      currentUnit: {},
      ascendingOrder: false,
      searchValue: '',
      focus: false,
      showAllTenants: false,
      addPropertyModal: false,
      rentalUnit: [],
      rentalTenants: [],
      addTenantModal: false,
      currentRentalUnitId: '',
      TenantPostAddTenant: false,
      skeletonLoading: false,
      showArticleCondition: '',
      isMobile: window.innerWidth <= MOBILE_SIZE,
      buyPremiumModalOpen: false,
      exportTenantsTablePopoverOpen: false,
      csvBody: [],
      exportRentalIncomePopoverOpen: false,
      openRentalIncomeExportRangeModal: false,
      selectedMeansOfExport: null,
      exportRentalIncomeTablePopoverOpen: false,
      allRents: []
    };

    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    console.log('component did mount ');
    window.addEventListener('resize', this.handleResize);
    //loader
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true, showArticleCondition: this.props.propertySelected && this.props.rentalUnits.length > 0 ? 'property_created && rental_unit_created' : 'property_created && !rental_unit_created'}, ()=> console.log('showArticleCondition: ', this.state.showArticleCondition));
    //filter

    //call api
    this.props.getRentalUnitTenants([]);
    this.props.propertySelected && this.props.propertySelected.id !== undefined ? this.props.history.location.pathname === '/user/rentIncomeTracking' ? this.bind_rental_unit_list_data() : this.ShowLoader() : this.ShowLoader();
    //this.props.propertySelected && this.props.propertySelected.id !== undefined ? this.bind_rental_unit_list_data() : this.ShowLoader();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.rentalUnits.length !== this.props.rentalUnits.length){
      this.setState({showArticleCondition: this.props.propertySelected && this.props.rentalUnits.length > 0 ? 'property_created && rental_unit_created' : 'property_created && !rental_unit_created'}, ()=> console.log('showArticleCondition: ', this.state.showArticleCondition));
    }
    if(this.props.refreshTenantDocumentsData){
      this.bind_rental_unit_list_data();
      this.props.setRefreshTenantDocumentsData(null);
    }
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
  showAllTenantFunction = (value) => {
    console.log(value, 'abc TenantPostAddTenant value');
    this.setState({
      TenantPostAddTenant: value,
    });
  };

  bind_rental_unit_list_data = () => {
    this.props.loadingTenantsData(true);
    this.setState({skeletonLoading: true});
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  ChangeDateFormat = (dateString) => {
    const dateArray = dateString.split('.');
    return `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  };
  handle_get_list_rental_unit = (response) => {
    if (response.result === null || (response.result && response.result.length === 0)) {
      this.ShowLoader();
      this.props.loadingTenantsData(false);
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      this.props.loadingTenantsData(false);
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //   true,
      //   lang.newKeys.text_error_not_found
      //   // + response.message
      // );
    }else{
      this.setState({rentalUnits: response.result.map((ru) => 
        (
          {
            id: checkProperty(ru, 'id'),
            name: checkProperty(ru, 'name'),
            size: checkProperty(ru, 'space'),
            propertyId: checkProperty(ru, 'propertyId'),
            tenantsData: [],
          }
        ))
      }, ()=> 
        response.result.map((ru, index) => {
          // console.log('ru', ru)
          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
            'GET',
            null,
            null,
            false,
            true,
            false,
            this,
            'handle_get_list_rental_tenants',
            {index, rentalUnitsLength: response.result.length, eol: index === (response.result.length - 1)},
            false,
            '',
            {
              //'Content-Type': 'application/json',
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
        })
      );
    }
  };
  handle_get_list_rental_tenants = (response) => {
    // this.ShowLoader();
    console.log('last api function  handle_get_list_rental_tenants', response);
    let { rentalUnits } = this.state;

    if (response.result.length > 0) {
      // let tenantsData = response.result.sort(function (a, b) {
      //   return new Date(a.moveInDate) - new Date(b.moveInDate);
      // });
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;

        //console.log(dateCompare, moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY'),'dateCompare')
        // if(dateCompare==false&&response.result.moveOutDate!=='-'){
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          console.log('testingCurrentTenants: ', tenant);
          //
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          // rentalUnit: tenant.rentalUnit,
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;

        // let moveoutDate=tenantsData[0].out;
        // let dateCompare = moment(moment(moveoutDate).format('DD.MM.YYYY')).isSameOrAfter(moment(new Date()).format('DD.MM.YYYY'));
        // console.log(moveoutDate,dateCompare,tenantsData[0],'====abc======')
        // if(dateCompare===true){
        //   tenantsData[0].current = true;
        // }
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        // console.log('abc', firstOriginalDataIndex, tenantsData[1]);
        // tenantsData[firstOriginalDataIndex - 1].current = true;
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits= rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          //  console.log('worked', tenantsData)
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });
      this.setState({
        rentalUnits
      }, ()=> {
        if(response.other.eol){//(response.other.rentalUnitsLength-1) === response.other.index){
          console.log('tenant of rental unit', tenantsData, rentalUnits);
          setTimeout(() => {
            this.props.getRentalUnitTenants(rentalUnits);
            this.getRentsApi();  
          }, 1000);
        }
      });
    }

    // console.log('data formated', data);
    //   rentalListData.push(data);
    //   let sorted=  rentalListData.sort(function(a, b) {
    //     return a.name.localeCompare(b.name);
    //  });
    // console.log('global array', rentalListData, sorted )
    // const sortedData = nestedAlphabeticSort(rentalListData, 'name', true, null);
    //pass data received to redux
    
  };
  ShowLoader = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-main-tenant-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('main-tenant-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
    this.props.loadingTenantsData(false);
  };
  deleteUnit = (unitId) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    //call delete api for rental unit
    let btnelement = document.getElementById('unit-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'RentalUnits/' + unitId,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleDelete',
      { cancelbtn: btnelement.children[0], id: unitId },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleDelete = (response) => {
    // console.log(response , 'delete unit tenant api')

    //handle api response
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';

    //action to delete unit
    this.props.DeleteUnit(response.other.id);
    response.other.cancelbtn.click();

    // this.bind_rental_unit_list_data();
  };
  setCurrentUnit = (id) => {
    let data = this.props.rentalUnits.filter((rc) => rc.id == id);
    this.setState({ currentUnit: { ...data[0] } });
  };
  changePage = (route, state) => {
    this.props.history.push(route, state);
  };
  toggleEditUnitModal = (id) => {
    this.setCurrentUnit(id);
    this.setState({ editUnitModal: !this.state.editUnitModal });
  };
  toggleDeleteUnitModal = (id) => {
    if (id !== undefined) {
      this.setCurrentUnit(id);
    }
    this.setState({ deleteUnitModal: !this.state.deleteUnitModal });
  };
  toggleAddUnitModal = () => {
    this.setState({ addUnitModal: !this.state.addUnitModal });
  };
  addTenant = (id) => {
    this.props.setCurrentTenant(
      {},
      {
        name: '',
        email: '',
        phone: '',
        in: '',
        out: '',
        type: 'private',
        // address: '',
        // city: '',
        // zip: null,
        // area: '',
        // //doc: pdf,
        note: '',
        rents: [],
      }
    );
    console.log('add tenant', id);

    // this.changePage('/user/tenant', { unitId: id });
    this.toggleAddTenantModal(id);
  };
  handleSort = (data, param, ascendingOrder) => {
    // const sortedList = nestedAlphabeticSort(data, param, false);
    const sortedList = data.sort((a, b) => (a[param] || '').toString().localeCompare((b[param] || '').toString()));
    console.log('handle sort', sortedList);
    this.props.updateFilteredList(sortedList);
    this.setState({ ascendingOrder: !this.state.ascendingOrder });
    // this.setState({ ascendingOrder: !this.state.ascendingOrder }, () => this.handleSearchAndSort(this.state.searchValue, this.state.ascendingOrder));
  };
  handleSearchChange = (e) => {
    this.props.updateFilteredList(this.props.rentalUnits);
    let show = false;
    if (e.target.value == '') {
      show = false;
    } else {
      show = true;
    }
    this.setState({ searchValue: e.target.value, showAllTenants: show }, () => this.filterListBySearch());

    // this.setState({ searchValue: e.target.value, showAllTenants: show }, () => this.handleSearchAndSort(this.state.searchValue, this.state.ascendingOrder));
  };
  handleSearchAndSort = (value, order) => {
    //set searched value and order of sort in state
    //run search funtion and get list
    //apply sort on that list
    //save it as filtered list
  };
  handleDeleteTenant = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    response.other.cancelbtn.click();
    this.bind_rental_unit_list_data();
  };
  filterListBySearch = (e) => {
    let tenantsSearch;
    tenantsSearch = filterBySearchBarRentalTenants(this.state.searchValue, this.props.filteredList);
    // console.log('filtered list after search', tenantsSearch)
    this.props.updateFilteredList(tenantsSearch);
  };
  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddTenantModal = (id) => {
    // console.log('toggle add tenant modal', id)
    // match id and get tenants
    this.setState({ addTenantModal: !this.state.addTenantModal, currentRentalUnitId: id });
  };
  sortAlphabetically = () => {
    // A to Z
    let filteredArray = [];
    let field = 'name';
    filteredArray = this.props.filteredList.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.props.updateFilteredList(filteredArray);
  };
  sortReverseAlphabetically = () => {
    // Z to A
    let filteredArray = [];
    let field = 'name';
    filteredArray = this.props.filteredList.sort((a, b) =>
      (b[field] || '').toString().localeCompare((a[field] || '').toString())
    );
    this.props.updateFilteredList(filteredArray);
  };

  downloadCsvTable = () => {
    const { rentalUnits } = this.props;
    console.log('downloadCsvTable: ', rentalUnits);
    var body = [];
    let allRents = [];
    
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
      if(tenant.vacant === false && tenant.current){
        if(tenant.tenantRent !== undefined && tenant.tenantRent.length > 0){
          allRents.push(tenant.tenantRent[0]);
        }
      }
    }));

    rentalUnits.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((rentalUnit)=> {
      rentalUnit.tenantsData.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((tenant)=> {
        if(tenant.vacant === false && tenant.current){
          let rent = (tenant.tenantRent !== undefined && tenant.tenantRent.length > 0) ? tenant.tenantRent[0] : null
          console.log('rent: ', rent, tenant.tenantRent !== undefined);
          body.push({
            'Mieteinheit': rentalUnit.name,
            'Mietfläche (m2)': rentalUnit.size,
            'Mieter': tenant.name,
            'Einzugsdatum': tenant.in,
            'Auszugsdatum': tenant.out,
            'Mtl. Kaltmiete (EUR)': rent?.netRent,
            'Mtl. Nebenkosten (EUR)': rent?.prePayments,
            'Mtl. Warmmiete (EUR)':  rent !== null ? rent.prePayments + rent.netRent : '',
          });
        }
      });
    })
    console.log('downloadCsvTable: ', body);
    body.push({
      'Mieteinheit': 'Gesamt',
      'Mietfläche (m2)': rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantsData.find((tenant)=> tenant.vacant === false && tenant.current) !== undefined ? (a.size ? a.size : null) : 0), 0),
      'Mieter': '',
      'Einzugsdatum': '',
      'Auszugsdatum': '',
      'Mtl. Kaltmiete (EUR)': allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent ? a.netRent : null), 0),
      'Mtl. Nebenkosten (EUR)': allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prePayments ? a.prePayments : null), 0),
      'Mtl. Warmmiete (EUR)':  allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent + a.prePayments), 0),
    });
    this.setState({csvBody: body},()=> {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      })
    });
  }

  getRentsApi = () => {
    this.state.rentalUnits.map((rentalUnit, rentalUnitIndex)=> rentalUnit?.tenantsData.map((tenant, tenantIndex)=> {
      if(tenant){
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + tenant.id,
          // checkProperty(this.props.formData, 'id'),
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_rents',
          {eolRentalUnits: (this.state.rentalUnits.length-1) === rentalUnitIndex,  eolTenants: (rentalUnit?.tenantsData.length-1) === tenantIndex},
          false,
          '',
          {
            // 'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }
    }))
    
  };

  handle_get_rents(response){
    
  }

  handle_get_rents(response){
    let {allRents, rentalUnits} = this.state;
    if(response.status && response.statuscode === 200 && response.result.length > 0){
      allRents.push(response.result[0]);
      rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.id === response.result[0].tenantId){
          tenant.tenantRent = response.result;
        }
      }));
      if(response.other.eolRentalUnits && response.other.eolTenants){
        
      }
    }
    this.setState({allRents});

    let newRentalUnits = [];
    if(response.status && response.statuscode === 200){
      rentalUnits.map((rentalUnit)=> {
        let newTenantsData = [];
        rentalUnit?.tenantsData.map((tenant)=> {
          if(tenant){
            if(response.result.length > 0){
              let tenantRent = response.result.find((rent)=> rent.tenantId === tenant.id);
              if(tenantRent !== undefined){
                newTenantsData.push({
                  ...tenant,
                  tenantRent: response.result
                })
              }else{
                newTenantsData.push(tenant);
              }
            }else{
              newTenantsData.push(tenant);
            }
          }
        });

        console.log('newTenantsData: ', newTenantsData);
        newRentalUnits.push({
          ...rentalUnit,
          tenantsData: newTenantsData
        });
      });
      this.setState({rentalUnits: newRentalUnits}, ()=> 
      setTimeout(() => {
        this.props.getRentalUnitTenants(newRentalUnits);
      }, 1000));
      console.log('newRentalUnits: ', newRentalUnits);
    }
    if(response.other.eolRentalUnits && response.other.eolTenants){
      this.ShowLoader();
    }
  }

  downloadExcelTable = () => {
    const { rentalUnits } = this.props;
    console.log('downloadExcelTable: ', rentalUnits);
    let firstRow = `Mieteraufstellung ${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}, ${this.props.propertySelected?.propertyZipCode} ${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name} (${moment().format('DD.MM.YYYY')})`;
    let allRents = [];
    
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
      if(tenant.current){// tenant.vacant === false &&  --- ticket-1731
        if(tenant.tenantRent !== undefined && tenant.tenantRent.length > 0){
          allRents.push(tenant.tenantRent[0]);
        }
      }
    }));
    

    var aoa = [
      [firstRow, "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["Mieteinheit", "Mietfläche (m2)", "Mieter", "Einzugsdatum", "Auszugsdatum", "Mtl. Kaltmiete (EUR)", "Mtl. Nebenkosten (EUR)", "Mtl. Warmmiete (EUR)"]
    ];

    rentalUnits.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((rentalUnit)=> {
      rentalUnit.tenantsData.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((tenant)=> {
        if(tenant.current){// tenant.vacant === false &&  --- ticket-1731
          if(tenant.tenantRent !== undefined && tenant.tenantRent.length > 0){
            tenant.tenantRent.map((rent)=> {
              if((dayjs().isSame(dayjs(rent.startDate)) || dayjs().isAfter(dayjs(rent.startDate))) && (rent.endDate !== null ? (dayjs().isSame(dayjs(rent.endDate)) || dayjs().isBefore(dayjs(rent.endDate))) : true)){//(dayjs().isSame(dayjs(rent.startDate)) || dayjs().isAfter(dayjs(rent.startDate))) && (rent.endDate !== null ? (dayjs().isSame(dayjs(rent.endDate)) || dayjs().isBefore(dayjs(rent.endDate))) : true)
                aoa.push([
                  rentalUnit.name,
                  rentalUnit.size,
                  tenant.name,
                  tenant.in,
                  tenant.out,
                  rent !== null ? rent?.netRent : null,
                  rent !== null ? rent?.prePayments : null,
                  rent !== null ? rent.prePayments + rent.netRent : null,
                ]);
              }
            })
          }else{
            aoa.push([
              rentalUnit.name,
              rentalUnit.size,
              tenant.name,
              tenant.in,
              tenant.out,
              null,
              null,
              null,
            ]);
          }
        }
      });
    });

    aoa.push([
      'Gesamt',
      rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantsData.find((tenant)=> (dayjs(tenant.out, 'DD.MM.YYYY').isAfter(dayjs()) || tenant.out === '' || tenant.out === null)) !== undefined ? (a.size ? a.size : null) : 0), 0),
      '',
      '',
      '',
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent ? a.netRent : null), 0),
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prePayments ? a.prePayments : null), 0),
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent + a.prePayments), 0),
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = ["B", "F", "G", "H"];
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:12},
      {wch:12},
      {wch:20},
      {wch:20},
      {wch:20},
    ];
    worksheet['!cols'] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Mieteraufstellung");
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);
  }

  downloadRentalIncomeExcelTable = (date) => {
    const { rentalUnits } = this.props;
    console.log('downloadExcelTable: ', rentalUnits);
    let firstRow = `Mieteinnahmen ${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}, ${this.props.propertySelected?.propertyZipCode} ${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name} (${moment().format('DD.MM.YYYY')})`;
    let allRents = this.getMonthNames(new Date(date.start), new Date(date.end));
    let allMonths = this.getMonthNames(new Date(date.start), new Date(date.end));

    allRents = allRents.map((month)=> {
      return {
        month,
        netRent: null,
        prePayments: null
      }
    })
    console.log('downloadRentalIncomeExcelTable: date: ', this.getMonthNames(new Date(date.start), new Date(date.end)));
    dayjs.locale('de');
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
      if(tenant.vacant === false){
        if(tenant.tenantRent !== undefined && tenant.tenantRent.length > 0){
          console.log('tenant.tenantRent: ', tenant.tenantRent);
          tenant.tenantRent.map((tenantRent)=> {
            // if(dayjs(tenantRent.startDate).isSame(date.startDate) || dayjs(tenantRent.startDate).isAfter(date.startDate)){

            // }
            allMonths.map((month)=> {
              if((dayjs(month, 'MMMM YYYY').isSame(dayjs(tenantRent.startDate)) || dayjs(month, 'MMMM YYYY').isAfter(dayjs(tenantRent.startDate))) && (tenantRent.endDate !== null ? (dayjs(month, 'MMMM YYYY').isSame(dayjs(tenantRent.endDate)) || dayjs(month, 'MMMM YYYY').isBefore(dayjs(tenantRent.endDate))) : true)){
                console.log('step: 1');
                console.log('step:', dayjs(tenantRent.startDate), dayjs(date.start));
                //if((dayjs(tenantRent.startDate).isSame(dayjs(date.start)) || dayjs(tenantRent.startDate).isAfter(dayjs(date.start))) && (dayjs(tenantRent.endDate).isSame(dayjs(date.end)) || dayjs(tenantRent.endDate).isBefore(dayjs(date.end)))){//if((dayjs(month, 'MMMM YYYY').isSame(dayjs(date.start)) || dayjs(month, 'MMMM YYYY').isAfter(dayjs(date.start))) && (dayjs(month, 'MMMM YYYY').isSame(dayjs(date.end)) || dayjs(month, 'MMMM YYYY').isBefore(dayjs(date.end)))){
                  console.log('step: 2: ', tenantRent.netRent);
                  let newAllRents = allRents;
                  newAllRents = newAllRents.map((rent)=> {
                    if(rent.month === month){
                      rent.netRent = rent.netRent ? rent.netRent + tenantRent.netRent : tenantRent.netRent;
                      rent.prePayments = rent.prePayments ? rent.prePayments + tenantRent.prePayments : tenantRent.prePayments;
                      return rent;
                    }else{
                      return rent;
                    }
                  });
                  allRents = newAllRents;
                //}
              }
            })
          });
        }
      }
    }));

    console.log('downloadRentalIncomeExcelTable: allRents: ', allRents);
    

    // var aoa = [
    //   [firstRow, "", "", "", "", "", "", ""],
    //   ["", "", "", "", "", "", "", ""],
    //   ["", ...allRents.map((rent)=> rent.month), `Gesamt (${dayjs(date.start).format('MM.YYYY')} - ${dayjs(date.end).format('MM.YYYY')})`],
    //   ["Kaltmieten", ...allRents.map((rent)=> rent.netRent), allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent ? a.netRent : null), 0)],
    //   ["Vorauszahlungen", ...allRents.map((rent)=> rent.prePayments), allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prePayments ? a.prePayments : null), 0)],
    //   ["Gesamtmieten", ...allRents.map((rent)=> rent.netRent + rent.prePayments), allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.prePayments ? a.prePayments : null) + (a.netRent ? a.netRent : null)), 0)],
    // ];

    var aoa = [
      [firstRow, "", "", ""],
      ["", "", "", ""],
      ["", "Kaltmieten (EUR)", "Vorauszahlungen (EUR)", "Gesamtmieten (EUR)"]
    ];

    allRents.map((rent)=> {
      aoa.push([rent.month, rent.netRent, rent.prePayments, rent.netRent + rent.prePayments]);
    });
    
    aoa.push([
      `Gesamt (${dayjs(date.start).format('MM.YYYY')} - ${dayjs(date.end).format('MM.YYYY')})`, 
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent ? a.netRent : null), 0),
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prePayments ? a.prePayments : null), 0),
      allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.prePayments ? a.prePayments : null) + (a.netRent ? a.netRent : null)), 0)
    ]);

    // rentalUnits.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((rentalUnit)=> {
    //   rentalUnit.tenantsData.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((tenant)=> {
    //     if(tenant.vacant === false && tenant.current){
    //       let rent = (tenant.tenantRent !== undefined && tenant.tenantRent.length > 0) ? tenant.tenantRent[0] : null
    //       console.log('rent: ', rent, tenant.tenantRent !== undefined);
    //       aoa.push([
    //         rentalUnit.name,
    //         rentalUnit.size,
    //         tenant.name,
    //         tenant.in,
    //         tenant.out,
    //         rent !== null ? rent?.netRent : null,
    //         rent !== null ? rent?.prePayments : null,
    //         rent !== null ? rent.prePayments + rent.netRent : null,
    //       ]);
    //     }
    //   });
    // });

    // aoa.push([
    //   'Gesamt',
    //   rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantsData.find((tenant)=> tenant.vacant === false && tenant.current) !== undefined ? (a.size ? a.size : null) : 0), 0),
    //   '',
    //   '',
    //   '',
    //   allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent ? a.netRent : null), 0),
    //   allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prePayments ? a.prePayments : null), 0),
    //   allRents.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.netRent + a.prePayments), 0),
    // ]);

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = ["B", "C", "D"];
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [
      {wch:27},
      {wch:15},
      {wch:20},
      {wch:20}
    ];
    worksheet['!cols'] = wscols;
  

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Mieteinnahmen");
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);
  }

  getMonthNames(start= new Date(), end= new Date(), lang= 'de') {
    let d = new Date(start.getFullYear(), start.getMonth());
    let months = [];
    while (d <= end) {
      months.push(d.toLocaleString(lang, {month:'long', year:'numeric'}));
      d.setMonth(d.getMonth() + 1);
    }
    return months;
  }

  renderAddRentalUnitButton=()=>{
    const {skeletonLoading, isMobile} = this.state;
    const {propertySelected} = this.props;
    return (
      propertySelected && propertySelected.propertyType === 'REGULAR' && 
      <div style={{marginTop: isMobile && '15px'}}>
        <Skeleton visible={skeletonLoading} style={{width: !isMobile && '150px'}}>
          <ColorButton
          onClick={()=> this.props.currentSubscription ? (this.props.rentalUnits.length < this.props.currentSubscription.limitRentalUnits ? this.toggleAddUnitModal() : this.setState({buyPremiumModalOpen: true})) : this.toggleAddUnitModal()}
          text={lang.tenant.text_add_rental_unit}
          color="purple-btn"
          className={'purple-btn'}
          buttonStyle={{marginLeft: !isMobile && '10px'}}
          disabled={this.props.properties.length == 0}
          />
        </Skeleton>
      </div>
    );
  }

  renderTabs=()=>{
    const {isMobile} = this.state;
    const {propertySelected} = this.props;
    let pathname = this.props.location.pathname;
    return(propertySelected && propertySelected.propertyType === 'REGULAR' &&
    <ButtonGroup 
      style={{
        background: '#e9e9f0',
        height: '36px',
        width: 'auto',
        padding: '0px 7px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center'
      }}
      >
      {routes.map((btn, idx) => {
        return (
          <Button
            key={idx}
            style={{
              minWidth: isMobile ? '90px' : '110px',
              height: '26px',
              margin: '3% auto',
              paddingTop: '1%',
              paddingBottom: '1%',
              borderRadius: '4px',
              background: btn.route === pathname ? 'white' : 'transparent',
              color: '#252563',
              border: 'none',
              boxShadow: 'none',
            }}
            onClick={() => {
              this.changePage(btn.route);
            }}
          >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span>
                {btn.label}
              </span>
            </div>
          </Button>
        );
      })}
    </ButtonGroup> 
    )
  }

  render() {
    const { addUnitModal, editUnitModal, currentUnit, deleteUnitModal, skeletonLoading, showArticleCondition, isMobile, buyPremiumModalOpen, exportTenantsTablePopoverOpen, exportRentalIncomePopoverOpen, openRentalIncomeExportRangeModal, selectedMeansOfExport, exportRentalIncomeTablePopoverOpen } = this.state;
    const { rentalUnits, filteredList, propertySelected, currentSubscription } = this.props;
    let pathname = this.props.location.pathname;

    console.log(rentalUnits, 'props');
    console.log(pathname, 'pathname');
    console.log(this.state.rentalUnits, this.state.tenantsData, 'data rental unit');
    console.log('this.props.subscription: ', this.props.subscription);
    console.log('currentSubscription: ', this.props.currentSubscription);
    console.log('this.props.propertySelected: ', this.props.propertySelected);

    return (
      <>
        <div id="empty-main-tenant-page"></div>

        <div className="main-tenant-page" id="main-tenant-page" style={{ display: 'block' }}>
          {/* page header */}
          {isMobile && 
          <div className='mb-4'>
            {this.renderTabs()}
          </div>}
          <PageHeaderNew
            heading={pathname === '/user/rentIncomeTracking' ? 'Mieteingangskontrolle' : lang.tenant.text_tenant}
            headingClassName={pathname === '/user/rentIncomeTracking' ? "main-tenant-rent-income-tracking-heading" : "main-tenant-heading" }
            hideDivider={pathname === '/user/rentIncomeTracking'}
            rightContent={!isMobile && this.renderTabs()}
            secondRowContent={
              <div style={{display: 'flex'}} //className={this.props.rentalUnits.length == 0 || this.props.properties.length == 0 ? 'emptyState' : ''}
              >
                {/* <Skeleton visible={skeletonLoading}>
                  <SearchContainer
                    placeholder={`${lang.general.text_search}...`}
                    value={this.state.searchValue}
                    onChange={this.handleSearchChange}
                    disabled={false}
                    onFocus={() => this.setState({ focus: true })}
                    onBlur={() => this.setState({ focus: false })}
                    // disabled={this.props.rentalUnits.length == 0 || this.props.properties.length == 0}
                  />
                </Skeleton> */}
                {!skeletonLoading && breadcrumbsContent.map((item)=> (this.props.history.location.pathname === '/user/rentIncomeTracking' ? item.area === '/user/rentIncomeTracking' : item.area === '/user') && 
                item.breadcrumbs.filter((breadcrumb)=> propertySelected.propertyType !== 'REGULAR' ? breadcrumb.label !== 'Mieteinheit anlegen' : breadcrumb).map((breadcrumb)=> 
                breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                  <img src={TourIcon} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                :
                breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                  <img src={HelpLogo} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                )
                
                // <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer'}} onClick={()=> breadcrumbsContent.map((item)=> item.area === 'tenants' && item.breadcrumbs.find((breadcrumb)=> breadcrumb.view === 'desktop' && breadcrumb.type === 'tour' && breadcrumb.condition === showArticleCondition && window.Intercom('showArticle', breadcrumb.id)))}>
                //   <img src={TourIcon} />
                //   <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{'Mache eine Tour'}</span>
                // </div>
                )}
              </div>
            }
            // sortElement={
            //   <div
            //     className={this.props.rentalUnits.length == 0 || this.props.properties.length == 0 ? 'emptyState' : ''}
            //   >
            //     <SortContainer
            //       sortingParam="alphabet"
            //       ascendingOrder={this.state.ascendingOrder}
            //       filterHandler={() =>
            //         this.props.rentalUnits.length == 0 || this.props.properties.length == 0
            //           ? () => {}
            //           : this.handleSort(this.props.rentalUnits, 'name', this.state.ascendingOrder)
            //       }
            //     />
            //   </div>
            // }
            // sortElement={
            //   <div >
            //     {pathname !== '/user/administration' &&  !isMobile &&
            //     // <div className={'section-1'} style={{display: 'flex'}}>
            //     // <Skeleton visible={skeletonLoading} style={{width: isMobile ? 'auto' : '139px'}}>
            //     //   <SearchContainer
            //     //     placeholder={`${lang.general.text_search}...`}
            //     //     value={this.state.searchValue}
            //     //     onChange={this.handleSearchChange}
            //     //     disabled={false}
            //     //     onFocus={() => this.setState({ focus: true })}
            //     //     onBlur={() => this.setState({ focus: false })}
            //     //     // disabled={this.props.rentalUnits.length == 0 || this.props.properties.length == 0}
            //     //   />
            //     // </Skeleton>
            //     // <ColorButton
            //     //   onClick={this.toggleAddUnitModal}
            //     //   text={lang.tenant.text_add_rental_unit}
            //     //   color="purple-btn"
            //     //   className={'purple-btn'}
            //     //   buttonStyle={{marginLeft: '10px'}}
            //     //   disabled={this.props.properties.length == 0}
            //     // />
            //     <Skeleton visible={skeletonLoading} style={{display: 'flex', alignItems: 'center'}}>
            //     <SortAlphabetically
            //       sortingParam="alphabet"
            //       handleAscending={this.sortAlphabetically}
            //       handleDescending={this.sortReverseAlphabetically}
            //     />
            //     {/* <ColorButton
            //       onClick={this.toggleAddUnitModal}
            //       text={lang.tenant.text_add_rental_unit}
            //       color="purple-btn"
            //       className={'purple-btn'}
            //       buttonStyle={{marginLeft: '10px'}}
            //       disabled={this.props.properties.length == 0}
            //     /> */}
            //     </Skeleton>
            //     }
            //   </div>
            // }
            thirdRowContent={!window.location.href.includes('/user/rentIncomeTracking') &&
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: isMobile && 'column'}}>
                  <Group>
                    <Skeleton visible={skeletonLoading} style={{width: isMobile ? '100%' : '300px'}}>
                      <SearchContainer
                        placeholder={`${lang.general.text_search}...`}
                        value={this.state.searchValue}
                        onChange={this.handleSearchChange}
                        disabled={false}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                        // disabled={this.props.rentalUnits.length == 0 || this.props.properties.length == 0}
                      />
                    </Skeleton>
                  </Group>

                  {isMobile && 
                  <Group position='right'>
                    {this.renderAddRentalUnitButton()}
                  </Group>}
                  <Group noWrap spacing={0} position={isMobile && 'right'} style={{marginTop: isMobile && '15px'}}>
                    <Group spacing={5}>
                        <span style={{color: '#454576', marginTop: '-2px'}}>Mieteinnahmen: </span>
                        <Popover
                        opened={exportRentalIncomePopoverOpen}
                        width={'auto'}
                        position="bottom"
                        withArrow
                        onClose={() => this.setState({exportRentalIncomePopoverOpen: false})}
                        target={
                        <Tooltip title={'Mieteinnahmen exportieren'}>
                          <IconButton 
                            aria-label="filter list"
                            disabled={rentalUnits.length === 0}
                            onClick={()=> this.setState({exportRentalIncomePopoverOpen: true})} 
                            style={{
                              background: 'white',//'#5655FE',
                              border: '1px solid #DADAE5', 
                              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                              padding: '5px',
                              cursor: 'pointer',
                              marginRight: '10px',
                              opacity: rentalUnits.length === 0 ? 0.5 : 1
                              }}>
                              <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>
                          </IconButton>
                        </Tooltip>
                        }
                        styles={{
                          inner: { padding: '5px'},
                        }}>
                          <Stack spacing={0}>
                            <Group 
                            style={{cursor: 'pointer'}}
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> rentalUnits.length > 0 && this.setState({openRentalIncomeExportRangeModal: true, selectedMeansOfExport: 'Excel'})}
                            >
                              {/* <NoteAdd 
                              style={{
                                color: '#5655FE',
                                fontSize: '20px'
                              }}/> */}
                              <img src={XlsxIcon} width={'20px'} />
                              <span>Excel exportieren</span>
                            </Group>
                            <Group 
                            style={{cursor: 'pointer'}} 
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> rentalUnits.length > 0 && this.setState({openRentalIncomeExportRangeModal: true, selectedMeansOfExport: 'Pdf'})}
                            >
                              {/* <CreateNewFolder
                              style={{
                                color: '#5655FE',
                                fontSize: '20px'
                              }}/> */}
                              <img src={PdfIcon} width={'20px'} />
                              <span>PDF exportieren</span>
                            </Group>
                          </Stack>
                        </Popover>
                    </Group>
                    <Group spacing={5}>
                        <span style={{color: '#454576', marginTop: '-2px'}}>Mieteraufstellung: </span>
                        <Popover
                        opened={exportTenantsTablePopoverOpen}
                        width={'auto'}
                        position="bottom"
                        withArrow
                        onClose={() => this.setState({exportTenantsTablePopoverOpen: false})}
                        target={
                        <Tooltip title={'Mieteraufstellung exportieren'}>
                          <IconButton 
                            aria-label="filter list"
                            disabled={rentalUnits.length === 0}
                            onClick={()=> this.setState({exportTenantsTablePopoverOpen: true})} 
                            style={{
                              background: 'white',//'#5655FE',
                              border: '1px solid #DADAE5', 
                              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                              padding: '5px',
                              cursor: 'pointer',
                              marginRight: '10px',
                              opacity: rentalUnits.length === 0 ? 0.5 : 1
                              }}>
                              <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>
                          </IconButton>
                        </Tooltip>
                        }
                        styles={{
                          inner: { padding: '5px'},
                        }}>
                          <Stack spacing={0}>
                            <Group 
                            style={{cursor: 'pointer'}}
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> rentalUnits.length > 0 && this.downloadExcelTable()}
                            >
                              {/* <NoteAdd 
                              style={{
                                color: '#5655FE',
                                fontSize: '20px'
                              }}/> */}
                              <img src={XlsxIcon} width={'20px'} />
                              <span>Excel exportieren</span>
                            </Group>
                            <Group 
                            style={{cursor: 'pointer'}} 
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> window.open('/tenantsPdfExport', '_blank')}
                            >
                              {/* <CreateNewFolder
                              style={{
                                color: '#5655FE',
                                fontSize: '20px'
                              }}/> */}
                              <img src={PdfIcon} width={'20px'} />
                              <span>PDF exportieren</span>
                            </Group>
                          </Stack>
                        </Popover>
                    </Group>
                    {!isMobile && this.renderAddRentalUnitButton()}
                  </Group>  
              </div>
            }
          />
          <>
          <Route path={this.props.match.url} exact component={FilteredTenantsList} />
          <Route path={this.props.match.url + '/rentIncomeTracking'} exact component={TenantRentalIncomeTracking} />
          {/* <Route path={this.props.match.url + '/administration'} exact component={TenantAdministration} /> */}
          </>
          {/* list of record */}
          {/* {filteredList &&
            filteredList.map((unit, index) => {
              return (
                <div key={index}>
                  <TenantRecordRow
                    parent={this}
                    showAll={this.state.showAllTenants}
                    history={this.props.history}
                    handleEditUnit={this.toggleEditUnitModal}
                    handleUnitDelete={this.toggleDeleteUnitModal}
                    handleAddTenant={() => {
                      // console.log('main', unit)
                      this.addTenant(unit.id);
                    }}
                    TenantsAfterAddModal={this.state.TenantPostAddTenant}
                    // showAllTenants={(value) => this.showAllTenants(value)}
                    unitData={unit}
                  />
                  {filteredList.length - 1 !== index && <Divider />}
                </div>
              );
            })} */}

            {/* <FilteredTenantsList location={this.props.location}/> */}
            
          {/* {this.props.filteredList.length == 0 && this.props.properties.length > 0 && (
            <EmptyStateContainer
              heading={lang.tenant.text_no_rental_units}
              icon={UnitsIcon}
              btnHandler={() => {
                this.toggleAddUnitModal();
              }}
              btnText={lang.tenant.text_add_rental_unit}
            />
          )} */}
          {/* {this.props.properties.length === 0 && (
            <EmptyStateContainer
              heading={lang.tenant.text_no_rental_units}
              subheading={lang.tenant.text_no_unit_and_property}
              icon={BlueUnitsIcon}
              btnHandler={() => {
                this.toggleAddPropertyModal();
              }}
              btnText={lang.general.text_add_property}
            />
          )} */}
          <BuyPremiumModal
          heading={'Premium'}
          open={buyPremiumModalOpen}
          text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center'}}>Sie haben die Maximalanzahl an Mieteinheiten im Starter-Paket erreicht. Schalten Sie weitere Mieteinheiten und neue Funktionen mit dem <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium-Paket' : 'Professional-Paket'}</span> frei.</span>}
          onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
          onCancel={()=> this.setState({buyPremiumModalOpen: false})}
          />
          {/* add and edit rental unit modal */}
          {/* {addUnitModal &&  */}
          <RentalUnitModal
            record={null}
            edit={false}
            open={addUnitModal}
            toggle={this.toggleAddUnitModal}
            updateStore={this.bind_rental_unit_list_data}
            className="add-rental-unit-modal"
          />
          {/* } */}
          <RentalUnitModal
            record={currentUnit}
            edit={true}
            open={editUnitModal}
            toggle={this.toggleEditUnitModal}
            updateStore={this.bind_rental_unit_list_data}
            className="add-rental-unit-modal"
          />
          {/* delete rental unit modal */}
          <DeleteModal
            open={deleteUnitModal}
            className="delete-rent-modal"
            toggle={this.toggleDeleteUnitModal}
            record={currentUnit}
            handleDelete={() => this.deleteUnit(currentUnit.id)}
            // showInfo={[currentUnit.name, `${currentUnit.size} m²`]}
            type={lang.tenant.text_rental_unit}
            modalId="unit-delete-modal-footer"
            // type={lang.property.text_property}
            showtext={false}
            typeText={
              'Sind Sie sicher, dass Sie diese Mieteinheit löschen wollen? Dadurch werden auch alle gespeicherten Mietverhältnisse dieser Mieteinheit entfernt.'
            }
            textShowModal={"Mieteinheit löschen"}
            showDeleteText={false}
          />
          {/* add property */}
          <AddPropertyModal
            className="add_proprty_modal"
            edit={false}
            open={this.state.addPropertyModal}
            toggle={() => {
              this.toggleAddPropertyModal();
            }}
          />
          {/* add tenant */}
          {this.state.addTenantModal == true && (
            <AddTenantModal
              updateStore={this.bind_rental_unit_list_data}
              rentalId={this.state.currentRentalUnitId}
              className="add_tenant_modal"
              unitData={filteredList}
              open={this.state.addTenantModal}
              toggle={() => {
                this.toggleAddTenantModal();
              }}
              showAllTenants={(value) => {
                console.log(value, 'value');
                this.showAllTenantFunction(value);
              }}
              history={this.props.history}
            />
          )}

          {openRentalIncomeExportRangeModal &&
          <RentalIncomeExportRangeModal
          open={openRentalIncomeExportRangeModal}
          toggle={()=> this.setState({openRentalIncomeExportRangeModal: false})}
          downloadTabel={(date)=> {
            if(selectedMeansOfExport === 'Pdf'){
              var newWindow = window.open('/rentalsPdfExport', '_blank');
              newWindow.date = date;
              newWindow.rentalUnits = rentalUnits;
            }
            if(selectedMeansOfExport === 'Excel'){
              this.downloadRentalIncomeExcelTable(date);
            }
            this.setState({openRentalIncomeExportRangeModal: false, selectedMeansOfExport: null})
          }}
          />}
          <CSVLink data={this.state.csvBody.length!==0 ? this.state.csvBody : []} separator=";" ref={this.csvLinkEl} filename={`Mieteraufstellung-${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}_${this.props.propertySelected?.propertyZipCode}_${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name}-${moment().format('YYYYMMDD')}.csv`}  />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rentalUnits: state.tenantDataReducer.rentalUnits,
    filteredList: state.tenantDataReducer.filteredList,
    properties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
    subscription: state.generalReducer.subscription,
    currentSubscription: state.generalReducer.currentSubscription,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData
  };
};
const actions = {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  setRefreshTenantDocumentsData,
  loadingTenantsData
};
export default connect(mapStateToProps, actions)(List);
