import React, { Component } from 'react';
import { ColorButton, EmptyStateContainer, ProfileHistoryRecord } from '../../../../../neoverv';
import { Row, Col, Form } from 'react-bootstrap';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import { setCookie, getCookie, delete_cookie } from '../../../../../neoverv/helpers/Utils';

import greenCross from '../../../../../assets/images/module/general/green-cross.svg';
import RentIcon from '../../../../../assets/images/module/general/RentIcon.svg';
import redCross from '../../../../../assets/images/module/general/red-cross.png';
import NotVerified from '../../../../../assets/images/module/general/nonVerified.png';
import RedVerified from '../../../../../assets/images/module/general/RedTick.svg';
import Verified from '../../../../../assets/images/verified.svg';
import { Group, Skeleton, Stack } from '@mantine/core';
import paypal from '../../../../../assets/images/module/general/paypal.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import amex from '../../../../../assets/images/module/general/amex.svg';
import visa from '../../../../../assets/images/module/general/visa.svg';
import PaymentTemplateModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentTemplateModal';
import PaymentTemplateSuccessModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentTemplateSuccessModal';
import {
  setSelectedSubscriptionForPaymentConfirmation
} from '../../../../../redux/action/General/generalActions';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { loader } = Autoload.MainHelper;
export class PaymentDetails extends Component {
  paymentLoop = 0;
  state = {
    holderName: '',
    holderCardNo: '',
    accountCreated: '',
    accountStatus: '',
    paymentStatus: '',
    heading: '',
    showPaymentMessage: false,
    historyData: [],
    type: 'authorize',
    openPaymentRedirectModal: false,
    openPaymentStatusModal: false,
    skeletonLoading: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    consumerAccount: null,
    consumerBic: null,
    cardNumber: null,
    cardLabel: null,
    cardExpiryDate: null,
  };

  componentDidMount() {
    //loader(true);
    this.setState({skeletonLoading: true});
    this.getPaymentDetails();
    this.getPaymentHistoryTable();

    window.addEventListener('resize', this.handleResize);

    // let paymentReAuthorizeInProgress = getCookie('PaymentReAuthorizeInProgress');
    // if (paymentReAuthorizeInProgress === '1') {
    //   setCookie('PaymentReAuthorizeInProgress', '0');
    //   this.getPaymentStatus();
    // }
    let paymentAuthorizeInProgress = getCookie('PaymentAuthorizeInProgress');
    let paymentReAuthorizeInProgress = getCookie('paymentReAuthorizeInProgress');
    console.log('paymentAuthorizeInProgress, paymentReAuthorizeInProgress: ', paymentAuthorizeInProgress, paymentReAuthorizeInProgress);
    if (paymentAuthorizeInProgress === '1') {
      let route = getCookie('PaymentAuthorizeRoute');
      if(route === '/profile/yourSubscription'){
        this.props.history.push(route);
      }else{
        //setCookie('PaymentAuthorizeInProgress', '0');
        this.setState({type: 'authorize'});
        // setTimeout(() => {
        //   this.getPaymentStatus();
        // }, 3000);
        this.setState({openPaymentStatusModal: true});
      }
    }else if(paymentReAuthorizeInProgress === '1'){
      let route = getCookie('PaymentAuthorizeRoute');
      if(route === '/profile/yourSubscription'){
        this.props.history.push(route);
      }else{
        //setCookie('paymentReAuthorizeInProgress', '0');
        this.setState({type: 'reAuthorize'});
        // setTimeout(() => {
        //   this.getPaymentStatus();
        // }, 3000);
        this.setState({openPaymentStatusModal: true});
      }
    }else{
      this.getAccountDetails();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: false, isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
    
  };

  getPaymentDetails = () => {
    console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
    const userAccountID = this.props.profile.accountId;
    // console.log(userAccountID, 'userAccountID from redux - payment details page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_account_payment_details(response) {
    console.log('handle_account_payment_details response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        holderName: Autoload.MainHelper.checkProperty(response.result, 'holderName'),
        holderCardNo: Autoload.MainHelper.checkProperty(response.result, 'holderAccount'),
        // accountCreated: Autoload.MainHelper.checkProperty(response.result, 'createdAt'),
        consumerAccount: Autoload.MainHelper.checkProperty(response.result, 'consumerAccount'),
        consumerBic: Autoload.MainHelper.checkProperty(response.result, 'consumerBic'),
        cardNumber: Autoload.MainHelper.checkProperty(response.result, 'cardNumber'),
        cardLabel: Autoload.MainHelper.checkProperty(response.result, 'cardLabel'),
        cardExpiryDate: Autoload.MainHelper.checkProperty(response.result, 'cardExpiryDate'),
        accountCreated: moment(Autoload.MainHelper.checkProperty(response.result, 'createdAt')).format('DD.MM.YYYY'),
        accountStatus: Autoload.MainHelper.checkProperty(response.result, 'mandateStatus'),
        heading: Autoload.MainHelper.checkProperty(response.result, 'paymentMethod'),
      });
    }

    this.ShowLoader();
  }

  getAccountDetails() {
    const userAccountID = this.props.profile.accountId;

    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.user_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
      Autoload.Setting.account_api_baseurl + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_user_Data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_get_user_Data(response) {
    console.log('response of handle_get_user_Data', response);
    if(response.status && response.statuscode === 200){
      this.setState({type: response.result.accountPaymentStatus ? 'reAuthorize' : 'authorize'});
    }
  }

  changePaymentOption = (e) => {
    console.log('changePaymentOption');
    e.preventDefault();
    window.open('/paymentInitialize?type=reauthorize', '_blank')
    // loader(true);
    // let accountId = this.props.profile.accountId;
    // let payload = { accountId: accountId, paymentMethod: 'directdebit' };

    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.api_base_url + `user-api/Accounts/${accountId}/reauthorizePayment`,
    //   'PUT',
    //   payload,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handleChangePaymentOptionResponse',
    //   null,
    //   false,
    //   '',
    //   { Authorization: Autoload.Utils.getAuthToken() }
    // );
  };
  handleChangePaymentOptionResponse = (response) => {
    loader(false);
    // debugger;

    if (response.result) {
      setCookie('PaymentReAuthorizeInProgress', '1');
      window.location.href = response.result;
    }
  };

  getPaymentStatus = () => {
    //loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePaymentStatusResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handlePaymentStatusResponse(response) {
    console.log('okk')
    this.paymentLoop = this.paymentLoop + 1;
    if (response.status) {
      const status = Autoload.MainHelper.checkProperty(response.result, this.state.type === 'reAuthorize' ? 'accountReAuthorizedPaymentStatus' : 'accountPaymentStatus');

      switch (status) {
        case 'INITIATED':
          if (this.paymentLoop < 4) {
            setTimeout(() => {
              this.getPaymentStatus();
            }, 5000);
            break;
          } else {
            this.setState({ paymentStatus: 'PAYMENT_ERROR' });
            //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
            loader(false);
            //this.setState({ showPaymentMessage: true });
            break;
          }
        case 'AUTHORIZED':
          this.setState({ paymentStatus: status });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_success);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
        case 'PAYMENT_ERROR':
          this.setState({ paymentStatus: 'PAYMENT_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
        default:
          this.setState({ paymentStatus: 'SERVER_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
      }
    } else {
      this.setState({ paymentStatus: 'SERVER_ERROR' });
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
      //this.setState({ showPaymentMessage: true });
      loader(false);
    }

    this.setState({openPaymentStatusModal: true});
  };

  renderPaymentChangeResponse() {
    const { paymentStatus } = this.state;

    if (paymentStatus === 'SERVER_ERROR') {
      return null;
    } else if (this.state.paymentStatus !== 'AUTHORIZED') {
      return (
        <div className="failed-div">
          <div className="payment-details-div">
            <div className="payment-left-icon">
              <img src={NotVerified} alt="icon" />
            </div>
            <div className="payment-center-text">{lang.user.text_unauthorize_payment}</div>
            <div className="payment-right-icon">
              <img src={redCross} alt="icon" />
            </div>
          </div>
          {/* <div className="payment-detail-row-two">
            <p className="payment-text-two">{lang.user.text_try_again}</p>
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="success-div">
          <div className="payment-details-div">
            <div className="payment-left-icon">
              <img src={Verified} alt="icon" />
            </div>
            <div className="payment-center-text">{lang.user.text_authorize_payment}</div>
            <div className="payment-right-icon">
              <img src={greenCross} alt="icon" />
            </div>
          </div>
          {/* <div className="payment-detail-row-two">
            <p className="payment-text-two">{lang.user.text_process_further}</p>
          </div> */}
        </div>
      );
    }
  }

  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-user-account-payment-details');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('response-user-account-payment-details');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };


  getPaymentHistoryTable = () => {
    //const userAccountID = this.props.profile.id;
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'Invoices?accountId=' + userAccountID,//'511E903C-39D7-4C70-9127-08DA08F97394'
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_payment_history',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_payment_history(response) {
    console.log('handle payment history response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        historyData: response.result
        // .map((data, index) => {
        //   return {
        //     bill_name: Autoload.MainHelper.checkProperty(data, 'invoiceTitle'),
        //     amount: Autoload.MainHelper.checkProperty(data, 'totalAmount'),
        //     date: moment(Autoload.MainHelper.checkProperty(data, 'invoiceDate')).format('DD.MM.YYYY'),
        //     id: Autoload.MainHelper.checkProperty(data, 'id'),
        //   };
        // }),
      });
    }
    //this.ShowLoader();
  }

  authorizePayment=()=>{
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.account_api_baseurl + userAccountID + '/authorizePayment',
        'PUT',
        {
            accountId: userAccountID,
            paymentMethod: null
        },
        null,
        false,
        true,
        false,
        this,
        'handle_account_authorize_payment',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
}
handle_account_authorize_payment(response){
    console.log('handle_account_authorize_payment: ', response);
    if(response.status){
        setCookie('PaymentAuthorizeInProgress', '1');
        setCookie('paymentReAuthorizeInProgress', '0');
        setCookie('SubscriptionSelected', this.props.subscribtionSelected);
        this.props.setSelectedSubscriptionForPaymentConfirmation(this.props.subscribtionSelected);
        setCookie('PaymentAuthorizeRoute', this.props.route);
        //window.location.href = response.result;
        window.open(response.result, '_self');
    }
}

reAuthorizePayment = () => {
  let accountId = this.props.profile.accountId;
  let payload = { accountId: accountId, paymentMethod: null };

  Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.api_base_url + `user-api/Accounts/${accountId}/reauthorizePayment`,
    'PUT',
    payload,
    null,
    false,
    true,
    false,
    this,
    'handleChangePaymentOptionResponse',
    null,
    false,
    '',
    { Authorization: Autoload.Utils.getAuthToken() }
  );
};
handleChangePaymentOptionResponse = (response) => {
  if (response.result) {
    setCookie('PaymentAuthorizeInProgress', '0');
    setCookie('paymentReAuthorizeInProgress', '1');
    //window.location.href = response.result;
    window.open(response.result, '_self');
  }
};

  render() {
    const {holderName, holderCardNo, openPaymentRedirectModal, type, openPaymentStatusModal, skeletonLoading, isTablet} = this.state;
    console.log('this.state.holderCardNo: ', this.state.holderName);
    console.log('type_1: ', type);
    return (
      <>
        <div id="empty-user-account-payment-details"></div>
        {this.state.heading && this.props.accountData.accountPaymentStatus === 'AUTHORIZED' ?
        <>
        <div className="account-width payment" style={{marginBottom: '33px'}} id="response-user-account-payment-details">
            <Row style={{marginBottom: '16px', marginTop: '25px'}}>
              <Col xs={12}>
                <div className="payment-heading-main" style={{fontSize: '16px'}}>
                  {/* {lang.general.text_direct_debit} */}
                  {skeletonLoading ? 
                <Skeleton visible={skeletonLoading} style={{width: '160px', height: '24px'}}/>
                :
                'Zahlungsmethode'}
                  {/* {this.state.heading === 'creditcard' ? 'Kreditkarte' : lang.general.text_direct_debit} */}
                </div>
              </Col>
            </Row>
            <Stack spacing={0} style={{border: '1px solid #DADAE5', marginBottom: '15px', borderRadius: '3px', background: 'white'}}>
              <Stack style={{borderBottom: '1px solid #DADAE5', padding : '7px 10px'}} spacing={5}>
                    <div className="payment-head">{'Zahlungsmittel'}</div>
                    <div className="payment-text" style={{textTransform: 'capitalize'}}>{this.state.heading === 'creditcard' ? 'Kreditkarte' : this.state.heading === 'directdebit' ? 'Lastschrifteinzug' : this.state.heading}</div>
              </Stack>
              {this.state.holderName &&
              <Stack style={{borderBottom: '1px solid #DADAE5', padding : '7px 10px'}} spacing={5}>
                    <div className="payment-head">{lang.general.text_Inhaber}</div>
                    <div className="payment-text">{this.state.holderName}</div>
              </Stack>}
              <Group style={{ padding : '7px 10px'}} grow>
                    <Stack spacing={5}>
                      <div className="payment-head" style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        {this.state.heading === 'directdebit' ? 'IBAN' : this.state.heading === 'creditcard' ? this.state.cardLabel : this.state.heading === 'paypal' ? 'Konto' : this.state.holderCardNo.split(' ')[0]}
                      </div>
                      <div className="payment-text">{this.state.heading === 'directdebit' ? this.state.consumerAccount : this.state.heading === 'creditcard' ? `xxxx xxxx xxxx ${this.state.cardNumber}` : this.state.heading === 'paypal' ? this.state.consumerAccount : `xxxx xxxx xxxx ${this.state.holderCardNo.split(' ')[1]}`}</div>
                    </Stack>
                    <Group style={{justifyContent: 'flex-end'}}>
                      {this.state.heading === 'directdebit' ? 
                        <Group spacing={5}>
                          <img src={'https://www.mollie.com/external/icons/payment-methods/sofort.svg'} />
                          <img src={'https://www.mollie.com/external/icons/payment-methods/directdebit.svg'} />
                        </Group>
                        :
                        this.state.heading === 'creditcard' ? 
                        <Group><img src={'https://www.mollie.com/external/icons/payment-methods/creditcard.svg'}/></Group>
                        :
                        this.state.heading === 'paypal' &&
                        <Group><img src={'https://www.mollie.com/external/icons/payment-methods/paypal.svg'}/></Group>
                      }
                    </Group>
              </Group>
              {this.state.consumerBic &&
              <Stack style={{borderTop: '1px solid #DADAE5', padding : '7px 10px'}} spacing={5}>
                    <div className="payment-head">{'BIC'}</div>
                    <div className="payment-text">{this.state.consumerBic}</div>
              </Stack>}
            </Stack>
            {this.props.accountData.accountPaymentStatus === 'PAYMENT_ERROR' &&
            <Stack style={{marginBottom: '15px'}}>
              <span style={{color: '#dc3545', fontSize: '12px', fontWeight: '500', whiteSpace: 'nowrap'}}>{'Mit dieser Zahlungsmethode ist ein Fehler aufgeteten. Bitte reativieren Sie die Zahlungsmethode.'}</span>
            </Stack>}
            {/* <Row className="next-line-payment">
              <Col xs={12}>
                <div className="payment-head">{lang.general.text_Status}</div>
                <div className="payment-text">
                  {this.state.accountStatus === 'valid' || 'Valid' ? (
                    <p style={{ color: '#059266' }}>
                      <img src={Verified} alt="icon" style={{ marginRight: '5px' }} />
                      aktiv
                    </p>
                  ) : this.state.accountStatus === 'invalid' || 'InValid' || 'inValid' ? (
                    <p style={{ color: '#E8193C' }}>
                      <img src={RedVerified} alt="icon" style={{ marginRight: '5px' }} />
                      Fehler
                    </p>
                  ) : (
                    <p>
                      <img src={NotVerified} alt="icon" style={{ marginRight: '5px' }} />
                      in Prüfung
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="next-line-payment">
              <Col xs={12}>
                <div className="payment-head">{lang.general.text_Erstellt_am}</div>
                <div className="payment-text">{this.state.accountCreated}</div>
              </Col>
            </Row> */}
            <ColorButton
              buttonConfig={{ type: 'submit' }}
              text={lang.newKeys.text_Change_payment_option}
              className="gray-btn"
              onClick={()=>this.setState({openPaymentRedirectModal: true, type: 'reAuthorize'})}
            />
            {/* <Stack spacing={0}>
              <div className="payment-head last-text" style={{paddingLeft: '10px', whiteSpace: !isTablet && 'nowrap'}}>{'Zur Änderung Ihres Zahlungsmittels leiten wir Sie jetzt an unserem Zahlungsanbieter mollie weiter. Sie werden im Anschluss wieder zurück geleitet. Es wird keine Zahlung getätigt.'}</div>
            </Stack> */}
        </div>
        <div>{this.state.showPaymentMessage && this.renderPaymentChangeResponse()}</div>

        <div className='divider'/>
        </>
        :
        <>
        <Stack className="account-width payment" spacing={skeletonLoading && 0} style={{width: '100%'}} id="response-user-account-payment-details">
          <Row style={{marginBottom: '16px', marginTop: '25px'}}>
            <Col xs={12}>
              <div className="payment-heading-main" style={{fontSize: '16px'}}>
                {/* {lang.general.text_direct_debit} */}
                {skeletonLoading ? 
                <Skeleton visible={skeletonLoading} style={{width: '143px', height: '24px'}}/>
                :
                'Zahlungsmethode'}
                {/* {this.state.heading === 'creditcard' ? 'Kreditkarte' : lang.general.text_direct_debit} */}
              </div>
            </Col>
          </Row>
          <Stack style={{width: '75%', justifyContent: 'center', marginBottom: '15px'}}>
              {skeletonLoading ? 
              <Stack spacing={15}>
                <Skeleton visible={skeletonLoading} style={{width: '357px', height: '181px'}}/>
                <Skeleton visible={skeletonLoading} style={{width: '181px', height: '35px'}}/>
              </Stack>
              
              :
              <>
              <EmptyStateContainer
              heading="Noch kein Zahlungsmittel hinterlegt."
              subheading={"Sie müssen ein Zahlungsmittel hinterlegen, um bezahle Services von hellohousing zu nutzen."}
              icon={RentIcon}
              showBtn={true}
              styleMainContainer={{margin: '0', padding: '20px', background: 'white'}}
              btnText={'Zahlungsmethode hinzufügen'}
              btnHandler={()=>this.setState({openPaymentRedirectModal: true})}
              btnContainerStyle={{display: 'flex', width: '100%', justifyContent: 'center'}}
              //textAfterBtn={lang.general.text_forwareded_to_mollie}
              />
              </>}
          </Stack>
        </Stack>

        <div className='divider'/>
        </>
        }

        <Stack spacing={10}>
          <Group style={{marginTop: '15px'}}>
            {skeletonLoading ? 
              <Skeleton visible={skeletonLoading} style={{width: '160px', height: '24px'}}/>
              :
              <span className="payment-heading-main">Rechnungen</span>}
          </Group>
          <Group>
            <Skeleton visible={skeletonLoading}>
              <ProfileHistoryRecord
                data={this.state.historyData}
                labels={['Datum', 'Abonnement', 'Betrag', 'Status']}
                labelsClassName={['bill-name', 'date', 'amount']}
                className="record-list-table payment-history"
                // parentContext={this}
              />
            </Skeleton>
          </Group>
        </Stack>
        <PaymentTemplateModal
        open={openPaymentRedirectModal}
        toggle={()=>this.setState({openPaymentRedirectModal: false})}
        type={type}
        />
        {openPaymentStatusModal &&
        <PaymentTemplateSuccessModal
        open={openPaymentStatusModal}
        toggle={()=>this.setState({openPaymentStatusModal: false},()=> this.getPaymentDetails())}
        type={type}
        history={this.props.history}
        reAuthorize={()=>
          this.setState({openPaymentRedirectModal: true, type: 'reAuthorize', openPaymentStatusModal: false})
          //this.reAuthorizePayment()
        }
        authorize={()=>
          this.setState({openPaymentRedirectModal: true, type: 'authorize', openPaymentStatusModal: false})
          //this.authorizePayment()
        }
        onNext={()=> this.setState({openPaymentStatusModal: false},()=> this.getPaymentDetails())}
        getPaymentDetails={()=> this.getPaymentDetails()}
        getPaymentHistoryTable={()=> this.getPaymentHistoryTable()}
        />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const actions={
  setSelectedSubscriptionForPaymentConfirmation
}

export default connect(mapStateToProps, actions)(PaymentDetails);
