import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Box, Group, Modal, Overlay, Stack, Radio, RadioGroup, TextInput, Popper, Popover, CheckboxGroup, Checkbox, Loader, Tabs, Drawer, Text } from '@mantine/core';
import { InputLabel, MenuItem, Select, FormControl, TableContainer, TableHead, TableBody, TableCell, TableRow, Table, Tooltip, IconButton, OutlinedInput } from '@material-ui/core';
import { ColorButton, DeleteModal, EmptyStateContainer, FiltersContainer, MultiselectDropdown, RecordOptions, SearchContainer, SwitchContainer } from '../../../../neoverv';
import PenIcon from '../../../../assets/images/module/general/PenIcon.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import Bank from '../../../../assets/images/module/general/Bank.svg';
import {
  changeCurrentProperty
} from '../../../../redux/action/General/generalActions';
import {getRentalUnitTenants} from '../../../../redux/action/Tenant/TenantActions'
import dayjs from 'dayjs';
import moment from 'moment';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import DatePicker from 'react-datepicker';
import { NumericFormat } from 'react-number-format';
import Icon from "../../../../components/Icon";
import UpdateTenantRentModal from '../../../../neoverv/components/Module/General/CustomModal/UpdateTenantRentModal';
import { load, unload } from "@finapi/web-form";
import FinApiWebFormModal from '../../../../neoverv/components/Module/General/CustomModal/FinApiWebFormModal';
import { PageHeaderNew } from '../../../elements';
import accounting from 'accounting';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import UpdateIcon from '@material-ui/icons/Update';
import AssignTransactionModal from '../../../../neoverv/components/Module/General/CustomModal/AssignTransactionModal';
import AddBankConnectionModal from '../../../../neoverv/components/Module/General/CustomModal/AddBankConnectionModal';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import TransactionDetailModal from '../../../../neoverv/components/Module/General/CustomModal/TransactionDetailModal';
import BankConnectionExistModal from '../../../../neoverv/components/Module/General/CustomModal/BankConnectionExistModal';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import CloseIcon from '@material-ui/icons/Close';
import RangeDatePicker from '../../../../neoverv/components/Module/General/CustomModal/DateRangePicker';
import { DateRangePicker } from '@mantine/dates';
import SelectOptionFromBankConnectionOrAccountModal from '../../../../neoverv/components/Module/General/CustomModal/SelectOptionFromBankConnectionOrAccountModal';
import SelectAccountsForBankConnectionModal from '../../../../neoverv/components/Module/General/CustomModal/SelectAccountsForBankConnectionModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

const {checkProperty} = Autoload.MainHelper;
let rentalUnits = [];
let rentalListData = [];
export class PaymentsList extends Component {
  openTenantSelectionModalRef = null;

  constructor(props) {
    super(props);
    dayjs.locale('de');
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      tableHeaderLabels: [{name: 'Sender / Empfänger', width: '15%'}, {name: 'Datum', width: '5%'}, {name: 'Betrag', width: '5%'}, {name: 'Beschreibung', width: '20%'}, {name: 'IBAN', width: 'auto'}, {name: 'Beleg', width: 'auto'}],
      openWebFormModal: false,
      transactions: [
        // {
        //   senderName: 'Ten1',
        //   bookingDate: dayjs().format('DD.MM.YYYY'),
        //   amount: 450,
        //   reference: '11023837578/ ----',
        //   senderIban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
        //   senderBic: 'xxxxxxxx',
        //   receipts: []
        // }
      ],
      assignTransactionsModalOpen: false,
      addBankConnectionModalOpen: false,
      selectedBank: null,
      deleteBankConnectionModalOpen: false,
      skeletonLoading: false,
      openTransactionDetailModal: false,
      selectedTransaction: null,
      selectedBankConnectionData: null,
      bankConnectionsData: [],
      openBankConnectionExistModal: false,
      webFormUrl: null,
      //bankData: {name: 'Sparkasse Chemnitz', iconUrl: 'https://cdn.finapi.io/assets/images/banks-2024.18.1/icons/DE_SPARKASSE/default.png'},
      loader: false,
      selectedAccount: true,
      openDrawer: false,
      filters: {
        amount: {
          min: null,
          max: null
        },
        bookingDate: {
          startDate: null,
          endDate: null
        }
      },
      transactionLoader: false,
      openSelectOptionFromBankConnectionOrAccountModal: false,
      openAddAccountModal: false,
      bankAccounts: [],
      selectedBankAccount: null,
      transactionsOffset: 0,
      notFound: false
    };

  }

  componentDidMount(){
    if(this.props.rentalUnits.length === 0){
      this.props.getRentalUnitTenants([]);
      if(this.props.propertySelected && this.props.propertySelected.id !== undefined){
        this.bind_rental_unit_list_data();
      }
    }
    this.setState({loader: true}, ()=> this.getBankConnections());
  }

  getBankConnections=()=>{
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/account',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankConnections',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetBankConnections(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankConnections: ', response);
      if(response.result.length > 0){
        this.setState({bankConnectionsData: response.result.map((bankConnection)=> ({...bankConnection, bankData: null, bankAccounts: []})), selectedBankConnectionData: response.result[0]}, ()=> {
          //this.putUpdateTransaction();
          this.getBankAccountsByProperties();
          this.getBank();
        });
      }else{
        this.setState({selectedBankConnectionData: null});
      }
    }else if(response.status === false && response.statuscode === 400 && response.message === 'NOT_FOUND'){
      this.setState({//selectedBankConnectionData: null, 
        loader: false});
    }else {
      this.setState({loader: false});
    }
  }

  getBankAccountsByProperties=()=>{
    let propertyId = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts/Property/'+ propertyId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankAccountsByProperties',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetBankAccountsByProperties(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankAccountsByProperties: ', response.result);

      let {bankConnectionsData} = this.state;
      bankConnectionsData = bankConnectionsData.map((bankConnection)=> {
        var allRelatedBankAccounts= response.result.filter((account)=> account.bankConnectionId === bankConnection.id);
        bankConnection.bankAccounts = allRelatedBankAccounts.length > 0 ? allRelatedBankAccounts : [];
        return bankConnection;
      });
      this.setState({bankConnectionsData, selectedBankAccount: response.result.length > 0 ? response.result[0] : null}, ()=> {
        response.result.map((bankAccount, index)=> this.postTransactions(bankAccount.id, response.result.length === (index + 1)));
      });
    }else{
      this.setState({loader: false});
    }
  }

  deleteBankAccountsByProperties=()=>{
    const {selectedBankAccount} = this.state;
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `bankAccountProperties/Property/${propertySelected.id}/BankAccount/${selectedBankAccount.id}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteBankAccountsByProperties',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteBankAccountsByProperties(response){
    this.setState({deleteBankConnectionModalOpen: false, transactions: [], transactionsOffset: 0}, ()=> this.getBankAccountsByProperties());
  }

  postBankAccountByBankConnectionId=(id=null)=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts',
      'POST',
      {
        bankConnectionId: id ? id : selectedBankConnectionData.id
      },
      null,
      false,
      true,
      false,
      this,
      'handlePostBankAccountByBankConnectionId',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    )
  }

  handlePostBankAccountByBankConnectionId(response){
    if(response.status && response.statuscode === 200){
      setTimeout(() => {
        this.getBankAccountByBankConnectionId();  
      }, 1000);
    }
  }

  getBankAccountByBankConnectionId=(bankConnectionId)=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankAccounts/bankConnection/'+ (bankConnectionId ? bankConnectionId : selectedBankConnectionData.id),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankAccountByBankConnectionId',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    )
  }

  handleGetBankAccountByBankConnectionId(response){
    const {bankConnectionsData} = this.state;
    if(response.status && response.statuscode === 200){
      let bankAccounts = response.result;
      bankAccounts = bankAccounts.filter((bankAccount)=> bankConnectionsData.find((bankConnection)=> bankConnection.bankAccounts.find((bankConnectionAccount)=> bankConnectionAccount.id === bankAccount.id) === undefined) !== undefined);
      this.setState({bankAccounts, openAddAccountModal: true});
    }
  }

  getBank=()=>{
    const {bankConnectionsData} = this.state;
    bankConnectionsData.map((bankConnection)=> 
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'banks/' + bankConnection.bankId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBank',
        {bankConnection},
        false,
        '',
        {
          // 'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      )
    ); 
  }

  handleGetBank(response){
    let {bankConnectionsData} = this.state;
    if(response.status && response.statuscode === 200){
      bankConnectionsData= bankConnectionsData.map((bankConnection)=> {
        if(bankConnection.id === response.other.bankConnection.id){
          bankConnection.bankData= response.result;
        }
        return bankConnection;
      })
      this.setState({bankConnectionsData, selectedBankConnectionData: bankConnectionsData[0]});
    }
  }

  getTransactions=()=>{
    this.setState({transactionLoader: true});
    const {selectedBankConnectionData, selectedBankAccount, transactionsOffset} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'transactions/BankAccount/' + selectedBankAccount?.id+`?fetch=10&offset=${transactionsOffset}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTransactions',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTransactions(response){
    const {transactions} = this.state;
    if(response.status && response.statuscode === 200){
      this.setState({transactions: [...transactions, ...response.result.map((transaction)=> ({...transaction, receipts: []}))]}, ()=> {
        if(response.result.length > 0){
          response.result.map((transaction)=> this.getRecieptsForTransaction(transaction.id));
        }
      });
    }else if(response.message === 'Not Found' && response.statuscode === 404){
      this.setState({notFound: true});
    }
    this.setState({loader: false, transactionLoader: false});
  }

  getRecieptsForTransaction=(transactionId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'recieptsForTransaction?transactionId=' + transactionId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRecieptsForTransaction',
      {transactionId},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRecieptsForTransaction(response){
    let {transactions} = this.state;
    if(response.status && response.statuscode === 200){
      transactions= transactions.map((transaction)=> {
        if(transaction.id === response.other.transactionId){
          transaction.receipts= response.result;
        }
        return transaction;
      });

      this.setState({transactions});
    }
  }

  postTransactions=(id=null, eol=true)=>{
    const {selectedBankConnectionData, selectedBankAccount} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'transactions',
      'POST',
      {
        bankAccountId: id ? id : selectedBankAccount?.id
      },
      null,
      false,
      true,
      false,
      this,
      'handlePostTransaction',
      {eol},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostTransaction(response){
    if(response.other.eol){
      this.getTransactions();
    }
  }

  handlePutUpdateTransaction(response){
    this.getTransactions();
  }


  putUpdateTransaction=()=>{
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'updateTransactions/bankConnection/' + selectedBankConnectionData?.id,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePutUpdateTransaction',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutUpdateTransaction(response){
    this.getTransactions();
  }

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_rental_unit = (response) => {
    if (response.result === null || response.result && response.result.length === 0) {
      //this.ShowLoader();
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }); 
    }
  };
  handle_get_list_rental_tenants = (response) => {
    rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });

      console.log('tenant of rental unit', tenantsData, rentalUnits);
    }

    //pass data received to redux
    //this.ShowLoader();
    this.props.getRentalUnitTenants(rentalUnits);
  };


  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  getTableHeader = () => {
    const {tableHeaderLabels} = this.state;

    return(
      <TableRow>
      {tableHeaderLabels.map((label)=>
        <TableCell width={label.width} style={{textAlign: label.name === 'Betrag' && 'right'}}>
          {label.name}
        </TableCell>
      )}
      </TableRow>
    )
  };

  onClickForCells=(transaction)=>{
    this.setState({openTransactionDetailModal: true, selectedTransaction: transaction});
  }
  
  getTableBody = () => {
    const {transactions} = this.state;
    return transactions.map((transaction)=> (
      <TableRow style={{background: '#FFFFFF', cursor: 'pointer'}}>
        <TableCell width={'20%'} style={{height: '71px'}} onClick={()=> this.onClickForCells(transaction)}>
          {transaction.counterpartName}
        </TableCell>
        <TableCell onClick={()=> this.onClickForCells(transaction)}>
          {dayjs(transaction.bankBookingDate).format(dayjs(transaction.bankBookingDate).isSame(dayjs()) ? 'hh:mm Uhr' : 'DD.MM.YYYY')}
        </TableCell>
        <TableCell width={'10%'} style={{textAlign: 'right'}} onClick={()=> this.onClickForCells(transaction)}>
          <span style={{color: transaction.amount < 0 ? '#E8193C' : '#059266', whiteSpace: 'nowrap'}}>{accounting.formatMoney(transaction.amount, { symbol: "€",  format: "%v %s", precision: 2, thousand: '.', decimal: ',' })}</span>
        </TableCell>
        <TableCell className='cover-space' onClick={()=> this.onClickForCells(transaction)}>
          {transaction.purpose}
        </TableCell>
        <TableCell style={{minWidth: '150px'}} className='cover-space' onClick={()=> this.onClickForCells(transaction)}>
          {transaction.counterpartIban}
        </TableCell>
        <TableCell>
          <Group noWrap>
            <MultiselectDropdown
            className="cost-allocation-receipts"
            tableView={true}
            newTable={true}
            //displayValue2="receiptDate"
            // options={this.state.receipts.filter((receipt)=> receipt.relevantBillingPeriods.find((bpId)=> bpId === billingPeriodId) //&& receipt.utilityBillingRelevant
            // )}
            // selectedValues={this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === id && selected.receiptId === receipt.id)))} // Preselected value to persist in dropdown
            // onSelect={this.onReceiptSelect} // Function will trigger on select event
            // onRemove={this.onReceiptRemove} // Function will trigger on remove event
            // displayValue="name"
            //placeholder={utilityData.isSecured ? '' : this.props.data.costTypeName !== '' && "Wählen"}
            //viewDetails={true}
            multiSelect
            //disable={utilityData.isSecured || this.props.data.costTypeName === ''}
            //toggleViewDetailsModal={this.toggleViewDetailsModal}
            stopOpen={true}
            onClick={()=> this.setState({assignTransactionsModalOpen: true, selectedTransaction: transaction})}
            />
            {true ? UtilityBillingAssignedIcon('#9A9AB5') : UtilityBillingAssignedIcon('#5555FF')}
          </Group>
        </TableCell>
        {/* <TableCell>
          <ColorButton
          text={'Beleg zuordnen'}
          className={'gray-btn'}
          onClick={()=> this.setState({assignTransactionsModalOpen: true})}
          />
        </TableCell> */}
        {/* <TableCell>
          <RecordOptions
          listOfItems={[
              {
                text: 'Assign To Receipt',
                leftImage: 
                <EditIcon 
                style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/>,
                openFunction: (()=> null)
              },
              {
                text: 'Assign To Rent',
                leftImage: 
                  <EditIcon 
                  style={{
                  color: '#5655FE',
                  fontSize: '15px'
                  }}/>,
                openFunction: (()=> null)
              }]
          }
          buttonStyle={{border: 'none', background: 'white'}}
          />
        </TableCell> */}
      </TableRow>
    ))
  };

  openAddBankConnection=()=>{
    const {selectedBankConnectionData} = this.state;
    // if(selectedBankConnectionData){
    //   // this.setState({openBankConnectionExistModal: true});
    //   this.setState({addBankConnectionModalOpen: true});
    // }else{
    //   this.setState({addBankConnectionModalOpen: true});
    // }

    this.setState({openSelectOptionFromBankConnectionOrAccountModal: true});
  }

  renderBankDetailsCard=(isActive=false)=>{
    const {selectedBankConnectionData, bankData, bankConnectionsData, selectedBankAccount} = this.state;
    return (
      <>
        {bankConnectionsData.map((bankConnection)=>
        bankConnection.bankAccounts.map((bankAccount)=> 
        <div style={{display: 'flex', flexDirection: 'column'}} className={`bank-details-card ${selectedBankAccount?.id === bankAccount.id && 'selected-bank-connection'}`} onClick={()=> this.setState({selectedBankConnectionData: bankConnection, selectedBankAccount: bankAccount, transactions: [], transactionsOffset: 0}, ()=> this.getTransactions())}>
            <Stack spacing={5}>
              <div style={{display: 'flex', alignItems: 'center', width: 'max-content'}}>
                <img src={bankConnection?.bankData?.iconUrl} style={{width: '16px'}}/>
                <span className='bank-details-card-heading-2' style={{marginLeft: 5}}>{`${bankConnection?.bankData?.name}`}</span>
              </div>
              <Stack spacing={0}>
                <Group noWrap>
                  <span className='bank-details-card-account-name'>Art: </span>
                  <span className='bank-details-card-iban'>{bankAccount?.accountType ? bankAccount?.accountType : '-'}</span>
                </Group>
                <Group noWrap>
                  <span className='bank-details-card-account-name'>BIC: </span>
                  <span className='bank-details-card-iban'>{bankAccount?.bic ? bankAccount?.bic : '-'}</span>
                </Group>
                <Group noWrap>
                  <span className='bank-details-card-account-name'>IBAN: </span>
                  <span className='bank-details-card-iban'>{bankAccount?.iban ? bankAccount?.iban : '-'}</span>
                </Group>
                <Group noWrap>
                  <span className='bank-details-card-account-name'>Inhaber: </span>
                  <span className='bank-details-card-iban'>{bankAccount?.accountHolderName ? bankAccount?.accountHolderName : '-'}</span>
                </Group>
                <Group noWrap>
                  <span className='bank-details-card-account-name'>Saldo: </span>
                  <span className='bank-details-card-iban'>{accounting.formatMoney(bankAccount?.balance, '€', 2, '.', ',', '%v %s')}</span>
                </Group>
              </Stack>
            </Stack>
        </div>
        )
        )}
        <Group //className='add-new-bank-connection-card' position='right'
        >
          <Tooltip title={'Konto hinzufügen'}>
            <IconButton 
              aria-label="filter list"
              onClick={()=> this.openAddBankConnection()}
              style={{
                background: '#5655FE',
                border: '1px solid #5555FF', 
                boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                padding: '5px',
                cursor: 'pointer',
                opacity: 1
                }}>
                <AddIcon 
                style={{
                  color: 'white',
                  fontSize: '15px'
                  }}/>
            </IconButton>
          </Tooltip>
        </Group>
      </>
    );
  }

  renderAccountDetailsCard=(isActive=false)=>{
    const {selectedBankConnectionData, bankData, selectedAccount} = this.state;
    return (
    <Group className={`bank-details-card ${selectedAccount && 'selected-bank-connection'}`} spacing={26} noWrap>
      <Stack spacing={5}>
        <Stack spacing={0}>
          <Group>
            <span className='bank-details-card-account-name'>BIC: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.bic ? selectedBankConnectionData?.bic : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>IBAN: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.iban ? selectedBankConnectionData?.iban : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>Inhaber: </span>
            <span className='bank-details-card-iban'>{selectedBankConnectionData?.firstName ? `${selectedBankConnectionData?.firstName} ${selectedBankConnectionData?.lastName}` : '-'}</span>
          </Group>
          <Group>
            <span className='bank-details-card-account-name'>Saldo: </span>
            <span className='bank-details-card-iban'>{accounting.formatMoney(selectedBankConnectionData?.accountBalance, '€', 2, '.', ',', '%v %s')}</span>
          </Group>
        </Stack>
      </Stack>
    </Group>);
  }

  actionButtons= ()=> {
    const {selectedBankConnectionData} = this.state;
    return (
      <Group spacing={10} noWrap>
        {/* add new account */}
        <Tooltip title={'Konto hinzufügen'}>
          <IconButton 
            aria-label="filter list"
            onClick={()=> this.openAddBankConnection()}
            style={{
              background: '#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: 1
              }}>
              <AddIcon 
              style={{
                color: 'white',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* update account */}
        <Tooltip title={'Konto aktualisieren'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> selectedBankConnectionData && this.setState({loader: true}, ()=> this.postTransactions())}
          style={{
            background: 'white',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: 'pointer',
            marginRight: '10px',
            opacity: !selectedBankConnectionData ? 0.5 : 1
          }}
          disabled={!selectedBankConnectionData}
          >
              <UpdateIcon 
              style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* delete account */}
        <Tooltip title={'Konto löschen'}>
          <IconButton 
          aria-label="filter list"
          //disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> this.setState({deleteBankConnectionModalOpen: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: !selectedBankConnectionData ? 0.5 : 1
          }}
          disabled={!selectedBankConnectionData}>
            <DeleteIcon 
            style={{
              color: '#E8193C',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>
        {/* filter */}
        <Tooltip title={'Filtern'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> this.setState({openDrawer: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          opacity: 1
          }}>
            <FilterListIcon 
            style={{
            color: '#5655FE',
            fontSize: '15px'
            }}/>
          </IconButton>
        </Tooltip>
      </Group>
    );
  }

  openWebForm=(url)=>{
    this.setState({openWebFormModal: true, webFormUrl: url, addBankConnectionModalOpen: false});
  }

  deleteBankConnection=(id=null)=>{
    if(id === null){
      this.setState({loader: true});
    }
    
    const {selectedBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/' + (id ? id : selectedBankConnectionData.id),
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteBankConnection',
      {id},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeleteBankConnection(response){
    if(response.status && response.statuscode === 200){
      if(response.other.id === null){
        showNotification({
          message: 'bank connection successfully deleted.',
          icon: <img src={RedTick} alt='red-tick'/>,
          id: 'deleteBankConnection'
        });
      }
    }
    this.setState({deleteBankConnectionModalOpen: false, transactions: [], transactionsOffset: 0}, ()=> response.other.id === null && this.getBankConnections());
  }

  handleChange = (event, type) => {
    let {filters} = this.state; 

    if(type === 'minCost'){
      filters.amount.min = event.target.value;
    }

    if(type === 'maxCost'){
      filters.amount.max = event.target.value;
    }

    if(type === 'bookingDate'){
      filters.bookingDate.startDate = event[0];
      filters.bookingDate.endDate = event[1];
    }

    this.setState({filters});
  };

  handleScroll = (e) => {
    console.log('handleScroll: ', e);
    const {loader, transactionsOffset, notFound, transactionLoader} = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && transactionLoader === false && notFound === false) { 
      this.setState({transactionsOffset: transactionsOffset + 10},()=> this.getTransactions());
    }
  }

  render() {
    const {isMobile, isTablet, openWebFormModal, assignTransactionsModalOpen, addBankConnectionModalOpen, selectedBank, deleteBankConnectionModalOpen, skeletonLoading, openTransactionDetailModal, selectedTransaction, transactions, selectedBankConnectionData, openBankConnectionExistModal, webFormUrl, loader, bankConnectionsData, openDrawer, filters, transactionLoader, openSelectOptionFromBankConnectionOrAccountModal, openAddAccountModal, bankAccounts, selectedAccount, selectedBankAccount} = this.state;
    const {propertySelected} = this.props;

    console.log('bankConnectionsData: ', bankConnectionsData);
    console.log('selectedAccount: ', selectedAccount);
    return (
      <Stack id='owner-document' spacing={0}>
        <Group className={'mt-3'}>
        
        </Group>
        <Group>
          <FiltersContainer
          style={{marginBottom: '0px', width: '100%'}}
          styleSection1={{width: '100%'}}
          hideSection2={true}
          hideSection3={true}
          searchElement={
            <Stack style={{width: '100%'}}>
              {<div style={{display: 'flex'}}>
                {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/bank' && 
                item.breadcrumbs.map((breadcrumb)=>
                breadcrumb.type === 'tour' ? breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                  <img src={TourIcon} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px',}}>{breadcrumb.label}</span>
                </div>
                :
                breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                  <img src={HelpLogo} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                )
                )}
              </div>}
              <Group style={{width: '100%'}} noWrap position='apart'>
                <SearchContainer
                placeholder={'Suche zahlungen...'}
                value={null}
                //onChange={this.handleSearchHandler}
                disabled={false}
                className={isMobile ? "mobile-annual-reading-search" : "annual-reading-search"}
                />
                {this.actionButtons()}
              </Group>
            </Stack>
          }
          />
        </Group>
        <div className='divider' />
        <Stack style={{marginTop: '16px'}}>
          {loader ?
          <Stack style={{width: '100%', height: '50vh'}} align='center' justify={'center'}>
            <Group position='center' align='center'>
              <Loader/>
            </Group>
          </Stack>
          :
          selectedBankConnectionData ?
          <>
            {bankConnectionsData.length > 0 &&
            <>
              <Group><span className='bank-account-heading'>Konto</span></Group>
              <Group spacing={0}>
                <Group noWrap className='bank-connections-list' grow align={'center'}>
                  {this.renderBankDetailsCard()}
                </Group>
              </Group>
            </>}
            
            {/* <Group><span className='bank-account-heading'>Konto</span></Group> */}
            {/* <Group spacing={0}>
              <Group noWrap className='bank-connections-list' grow align={'center'}>
                {this.renderAccountDetailsCard()}
              </Group>
            </Group> */}
            <Group style={{marginTop: '20px'}}><span className='bank-account-heading'>Zahlungen</span></Group>
            {
            transactionLoader && transactions.length === 0 ?
            <Group style={{width: '100%', marginTop: '100px'}} position='center' align={'center'}>
              <Loader/>
            </Group>
            :
            transactions.length > 0 ? 
            <Group style={{paddingLeft: '3px'}}>
              <TableContainer style={{maxHeight: '400px'}} onScroll={this.handleScroll}>
                <Table className='rental-income-list-table'>
                  <TableHead>
                    {this.getTableHeader()}
                  </TableHead>
                  <TableBody>
                    {this.getTableBody()}
                    {transactionLoader && 
                    <TableRow>
                      <TableCell colSpan={6} style={{textAlign: 'center'}}>
                        <Loader variant='dots'/>
                      </TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </Group>
            :
            <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
              <EmptyStateContainer
              heading={'No transactions found'}
              subheading={'No transactions data was found.'}
              showBtn={false}
              icon={Bank}
              />
            </Group>}
          </>
          :
          <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
            <EmptyStateContainer
            heading={'Es wurde keine Bankverbindung gefunden.'}
            subheading={'Richten Sie eine Bankverbindung ein, um Kontoumsätze abzurufen.'}
            showBtn={true}
            btnText={'Bankverbindung einrichten'}
            btnHandler={()=> this.openAddBankConnection()}
            icon={Bank}
            />
          </Group>}
        </Stack>
        
        {addBankConnectionModalOpen &&
        <AddBankConnectionModal
        open={addBankConnectionModalOpen}
        propertySelected={this.props.propertySelected}
        currentBankConnectionData={selectedBankConnectionData}
        toggle={()=> this.setState({addBankConnectionModalOpen: false})}
        openWebForm={(url, newBankConnectionData)=> {
          this.setState({selectedBankConnectionData: newBankConnectionData}, ()=> this.getBank());
          this.openWebForm(url);

        }}
        deleteBankConnection={(id)=> this.deleteBankConnection(id)}
        />}

        {assignTransactionsModalOpen &&
        <AssignTransactionModal
        open={assignTransactionsModalOpen}
        selectedTransaction={selectedTransaction}
        selectedBankConnectionData={selectedBankConnectionData}
        toggle={()=> this.setState({assignTransactionsModalOpen: false, selectedTransaction: null})}
        />}

        {openWebFormModal &&
        <FinApiWebFormModal
        open={openWebFormModal}
        webFormUrl={webFormUrl}
        heading={'Bankverbindung hinzufügen'}
        toggle={()=> this.setState({openWebFormModal: false})}
        getBankConnections={()=> this.setState({loader: true, openWebFormModal: false, openAddAccountModal: true}, ()=> this.postBankAccountByBankConnectionId())}
        deleteBankConnection={()=> this.deleteBankConnection()}
        />}

        {deleteBankConnectionModalOpen &&
        <DeleteModal
        open={deleteBankConnectionModalOpen}
        record={null}
        textShowModal={'Konto löschen'}
        typeText={`Sind Sie sicher, dass Sie das Bankkonto (IBAN: ${selectedBankAccount?.iban ? selectedBankAccount?.iban : '-'}) löschen wollen? Alle Konto- und Zahlungsinformationen gehen unwiderruflich verloren.`}
        showtext={false}
        handleDelete={()=> {
          let deleteBankConnection = bankConnectionsData.find((bankConnectionData)=> bankConnectionData.bankAccounts.find((bankAccount)=> selectedBankAccount?.id === bankAccount.id) !== undefined);
          if(deleteBankConnection){
            if(deleteBankConnection.bankAccounts.length > 1){
              this.deleteBankAccountsByProperties();
            }else{
              this.deleteBankConnection();
            }
          }else if(bankConnectionsData.length > 0){
            this.deleteBankConnection();
          }
        }}
        toggle={()=> this.setState({deleteBankConnectionModalOpen: false})}
        showDeleteText={false}
        rightButtonText={'Konto löschen'}
        leftButtonText={'Abbrechen'}
        />}

        {openTransactionDetailModal &&
        <TransactionDetailModal
        open={openTransactionDetailModal}
        transaction={selectedTransaction}
        toggle={()=> this.setState({openTransactionDetailModal: false, selectedTransaction: null})}
        />}

        {openBankConnectionExistModal &&
        <BankConnectionExistModal
        open={openBankConnectionExistModal}
        toggle={()=> this.setState({openBankConnectionExistModal: false})}
        openAddBankModal={()=> this.setState({addBankConnectionModalOpen: true, openBankConnectionExistModal: false})}
        />}

        {openSelectOptionFromBankConnectionOrAccountModal &&
        <SelectOptionFromBankConnectionOrAccountModal
        open={openSelectOptionFromBankConnectionOrAccountModal}
        bankConnectionsData={bankConnectionsData}
        getBankAccountByBankConnectionId={(bankConnectionId)=> {
          this.getBankAccountByBankConnectionId(bankConnectionId);
          this.setState({openSelectOptionFromBankConnectionOrAccountModal: false})
        }}
        toggle={()=> this.setState({openSelectOptionFromBankConnectionOrAccountModal: false})}
        openAddBankModal={()=> this.setState({addBankConnectionModalOpen: true, openSelectOptionFromBankConnectionOrAccountModal: false})}
        openAddAccountModal={()=> this.setState({openAddAccountModal: true, openSelectOptionFromBankConnectionOrAccountModal: false})}
        />}

        {openAddAccountModal &&
        <SelectAccountsForBankConnectionModal
        open={openAddAccountModal}
        propertySelected={propertySelected}
        bankAccounts={bankAccounts}
        toggle={()=> this.setState({openAddAccountModal: false})}
        getBankConnections={()=> this.setState({openAddAccountModal: false}, ()=> this.getBankConnections())}
        />}
        <Drawer
        opened={openDrawer}
        onClose={() => this.setState({openDrawer: false})}
        padding={'10px'}
        overlayOpacity={0.5}
        position="right"
        size={'300px'}
        styles={{
          header: {
            display: 'none'
          }
        }}
        >
          <Stack>
            <Stack spacing={0}>
              <Group position='apart' style={{marginBottom: '2px'}}>
                <Text sx={(theme) => ({ color: '#111147', fontSize: '16px', margin: '10px' })}>Zahlungen filtern</Text>
                <IconButton size='medium'
                onClick={()=> this.setState({openDrawer: false})}
                >
                  <CloseIcon style={{color: '#727293'}}/>
                </IconButton>
              </Group>
              <div style={{marginTop: 0, marginBottom: 0}} className='divider'/>
            </Stack>
            <Stack spacing={0}>
              <div style={{margin: '0px 15px 15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Betrag</InputLabel>
                <div style={{display: 'flex'}}>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={filters.amount.min}
                    onChange={(e)=> this.handleChange(e, 'minCost')}
                    endAdornment={'€'}
                    placeholder={'Min.'}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                    style={{height: '40px'}}
                  />
                </FormControl>
                <div style={{width: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#111147'}}> - </span></div>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                      id="outlined-adornment-weight"
                      value={filters.amount.max}
                      onChange={(e)=> this.handleChange(e, 'maxCost')}
                      endAdornment={'€'}
                      placeholder={'Max.'}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      labelWidth={0}
                      style={{height: '40px'}}
                  />
                </FormControl>
                </div>
              </div>
              <div style={{margin: '0px 15px 15px'}}>
                  <InputLabel id="demo-simple-select-outlined-label">Datum</InputLabel>
                  <DateRangePicker
                  locale='de'
                  inputFormat="DD.MM.YYYY"
                  value={[filters.bookingDate.startDate, filters.bookingDate.endDate]}
                  onChange={(value)=> this.handleChange(value, 'bookingDate')}
                  styles={{
                    input: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      fontWeight: '400',
                      height: '40px'
                    },
                    wrapper: {
                      height: '40px'
                    }
                  }}
                  />
              </div>
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};

const actions = {
  changeCurrentProperty,
  getRentalUnitTenants
}

export default connect(mapStateToProps, actions)(PaymentsList);
