import React, { Component, forwardRef } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ColorButton, FiltersContainer, HeadingContainer, SearchContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { withRouter } from 'react-router-dom';
import MessagingEditor from './MessagingEditor';
import { IconButton, TableContainer, Tooltip } from '@material-ui/core';
import { Box, CloseButton, Group, Input, MultiSelect, Select, Stack } from '@mantine/core';
import Up from '../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../assets/images/module/general/SelectArrowDown.svg';
import MessagingEditorMantine from './MessagingEditorMantine';
import Avatar from '../../../../assets/images/module/general/userAvatar.svg';
import defaultAvatar from '../../../../assets/images/module/general/defaultAvatarPicture.svg';
import SearchIcon from '../../../../assets/images/module/general/SearchIcon.svg';
import AddTenantBlue from '../../../../assets/images/module/general/AddTenantBlue.svg';
import AddAllTenantsBlue from '../../../../assets/images/module/general/AddAllTenantsBlue.svg';
import RemoveAllTenantsBlue from '../../../../assets/images/module/general/RemoveAllTenantsBlue.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import NewMessageInnerContent from './NewMessageInnerContent';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { setUtilityBillingData, setCurrentBillingId } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

  function Value({
    value,
    label,
    onRemove,
    classNames,
    ...others
  }) {
    console.log('Value: ', value, label, others);
    return (
        <Group spacing={5} className='message-card-ten-cards' key={value} noWrap style={{height: '20.6px', maxHeight: '20.6px', background: '#F2F2FC', paddingRight: 3, marginRight: '3px'}}>
            <Group noWrap spacing={5} style={{minWidth: '55px'}}>
                <img src={others.picture} style={{height: '18.6px', maxHeight: '18.6px'}}/>
                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{others.name}</span>
            </Group>
            <Group>
                <CloseButton
                onMouseDown={onRemove}
                variant="transparent"
                size={15}
                iconSize={14}
                tabIndex={-1}
                />
            </Group>
        </Group>
    );
  }
  
  const Item = forwardRef(({ label, value, ...others }, ref) => {
    console.log('Item: ', label, value, others);
    //const Flag = flags[value];
    return (
      <div ref={ref} {...others}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={10}>
            <img src={others.picture}/>
          </Box>
          <div>{others.name}</div>
        </Box> */}
        <Group spacing={5} className='message-card-ten-cards' key={value} style={{height: '20.6px', maxHeight: '20.6px', background: 'transparent', width: 'auto'}}>
            <img src={others.picture} style={{height: '18.6px', maxHeight: '18.6px'}}/>
            <span>{others.name}</span>
        </Group>
      </div>
    );
  });

class NewMessage extends Component {
  state = {
    tenant: [{
        value: 'id1',
        name: 'Ten1',
        picture: defaultAvatar,
        label: 'Ten1'
    }, {
        value: 'id2',
        name: 'Ten2',
        picture: Avatar,
        label: 'Ten2'
    }, {
        value: 'id3',
        name: 'Ten3',
        picture: defaultAvatar,
        label: 'Ten3'
    }, {
        value: 'id4',
        name: 'Ten4',
        picture: Avatar,
        label: 'Ten4'
    }, {
        value: 'id5',
        name: 'Ten5',
        picture: defaultAvatar,
        label: 'Ten5'
    }, 
    // {
    //     value: 'id6',
    //     name: 'Ten6',
    //     picture: defaultAvatar,
    //     label: 'Ten6'
    // }, {
    //     value: 'id7',
    //     name: 'Ten7',
    //     picture: Avatar,
    //     label: 'Ten7'
    // }, {
    //     value: 'id8',
    //     name: 'Ten8',
    //     picture: defaultAvatar,
    //     label: 'Ten8'
    // }, {
    //     value: 'id9',
    //     name: 'Ten9',
    //     picture: Avatar,
    //     label: 'Ten9'
    // }, {
    //     value: 'id10',
    //     name: 'Ten10',
    //     picture: defaultAvatar,
    //     label: 'Ten10'
    // }, {
    //     value: 'id11',
    //     name: 'Ten11',
    //     picture: defaultAvatar,
    //     label: 'Ten11'
    // }, {
    //     value: 'id12',
    //     name: 'Ten12',
    //     picture: Avatar,
    //     label: 'Ten12'
    // }, {
    //     value: 'id13',
    //     name: 'Ten13',
    //     picture: defaultAvatar,
    //     label: 'Ten13'
    // }, {
    //     value: 'id14',
    //     name: 'Ten14',
    //     picture: Avatar,
    //     label: 'Ten14'
    // }, {
    //     value: 'id15',
    //     name: 'Hoffmann Newmann',
    //     picture: defaultAvatar,
    //     label: 'Ten15'
    // }
    ],
    selectedTenants: [],
    dropdownIcon: Down
  };

  myRef=null;

  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  componentWillUnmount(){
    this.props.setCurrentBillingId(null);
    this.props.setUtilityBillingData([]);
  }

  render() {
    const {tenant, selectedTenants, dropdownIcon} = this.state;
    const message = this.props.location.state ? this.props.location.state.message : null;
    const messageType = this.props.location.state ? this.props.location.state.messageType !== undefined ? this.props.location.state.messageType : null : null;
    const accessLink = this.props.location.state ? this.props.location.state.accessLink !== undefined ? this.props.location.state.accessLink : null : null;
    const utilityBilling = this.props.location.state ? this.props.location.state.utilityBilling !== undefined ? this.props.location.state.utilityBilling : null : null;
    const recipients = this.props.location.state ? this.props.location.state.recipients !== undefined ? this.props.location.state.recipients : null : null;
    const forward = this.props.location.state ? this.props.location.state.forward !== undefined ? this.props.location.state.forward : false : false;
    const replyAll = this.props.location.state ? this.props.location.state.replyAll !== undefined ? this.props.location.state.replyAll : false : false;
    const rentalPaymentId = this.props.location.state ? this.props.location.state.rentalPaymentId !== undefined ? this.props.location.state.rentalPaymentId : null : null;
    console.log('myRef: ', this.myRef);
    console.log('message_123: ', message);
    return (
      <div className="messages-list-screen">
        <Group>
            <div style={{display: 'flex'}}>
            {breadcrumbsContent.map((item)=> item.area === '/messaging/newMessage' && 
            item.breadcrumbs.map((breadcrumb)=>
            breadcrumb.type === 'tour' ? breadcrumb.view === 'desktop' &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                <img src={TourIcon} />
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            :
            breadcrumb.view === 'desktop' &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                <img src={HelpLogo} />
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            ))}
            </div>
        </Group>
        <div className="divider"></div>
        <NewMessageInnerContent 
        history={this.props.history} 
        draftMessageDetails={message} 
        placeholders={{
          recipient: (message?.templateType !== 'Tenant' && message?.templateType !== 'RentalPayment' && !utilityBilling && !rentalPaymentId && messageType !== 'Tenant'), 
          tenant: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || utilityBilling || rentalPaymentId || messageType === 'Tenant'), 
          property: true, 
          rentalUnit: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId), 
          billingPeriod: utilityBilling ? true : false, 
          balance: utilityBilling ? true : false, 
          prepayments: utilityBilling ? true : false, 
          billingStatementTotalCosts: utilityBilling ? true : false, 
          billingStatementAdvancePayments: utilityBilling ? true : false, 
          billingStatementBalance: utilityBilling ? true : false, 
          rentMonth: (message?.templateType === 'RentalPayment' || rentalPaymentId),//(rentalPaymentId || message?.rentalPaymentId), 
          recievedRent: (message?.templateType === 'RentalPayment' || rentalPaymentId),//(rentalPaymentId || message?.rentalPaymentId), 
          payableRent: (message?.templateType === 'RentalPayment' || rentalPaymentId),//(rentalPaymentId || message?.rentalPaymentId), 
          userName: true, 
          companyName: true, 
          netRent: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || rentalPaymentId || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId),
          advancePayment: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || rentalPaymentId || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId),
          totalRent: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || rentalPaymentId || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId), 
          moveInDate: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || rentalPaymentId || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId),
          moveOutDate: (message?.templateType === 'Tenant' || message?.templateType === 'RentalPayment' || rentalPaymentId || messageType === 'Tenant'),//(rentalPaymentId || message?.rentalPaymentId),
        }}  
        utilityBilling={utilityBilling} 
        billingPeriod={utilityBilling ? `${dayjs(utilityBilling.billingPeriod.startDate).format('DD.MM.YYYY')} - ${dayjs(utilityBilling.billingPeriod.endDate).format('DD.MM.YYYY')}` : false} 
        showUtilityBillingMessages={utilityBilling ? true : false}
        recipients={recipients}
        forward={forward}
        replyAll={replyAll}
        messageType={messageType}
        rentalPaymentId={rentalPaymentId}
        accessLink={accessLink}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    profile: state.generalReducer.profile,
  };
};

const mapDispatchToProps = {
  setUtilityBillingData,
  setCurrentBillingId
};
export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
