import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class EmailExistErrorModal extends Component {
  state = {
    descriptionMsg: 'Belege bequem per Email bereitstellen.',
    descriptionMsg2: 'Alle Email-Anhänge (nur Bilder und PDF-Dateien), die Sie an diese Email senden, werden automatisch als Einzelbelege hier bereitgestellt.',
    descriptionMsg3: 'Am besten Sie speichern die Email-Adresse direkt in Ihrem Email-Programm ab, so dass Sie sie immer zur Hand haben.',
    accessLink: 'Access Link',
    hover: false,
    click: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    console.log('this.props.selectedTenant: ', this.props.selectedTenant);
    this.setState({accessLink: this.props.selectedTenant.isActivated === false ? `http://${window.location.href.includes('app') ? 'app' : 'dev'}.hellohousing.de:8081/signup?${this.props.selectedTenant.passwordResetCode}` : 'User activated account and verified email.'})
  }

  handleMouseEnter=()=>{
    this.setState({hover: true});
  }
  handleMouseLeave=()=>{
    this.setState({hover: false});
  }
  
  render() {
    const { open, toggle, className, selectedTenant } = this.props;
    const {descriptionMsg, descriptionMsg2, descriptionMsg3, accessLink, hover, click, isMobile, isTablet} = this.state;
    
    return (
      <CustomModal
        heading={'Email bereits verwendet'}
        headingClassName={'tenant-access-details-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <div>
            <div>
                <Row>
                    <Col xs={12}>
                        <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Die Email-Adresse des Mieters wird bereits für ein hellohousing Nutzerkonto verwendet. Bitte geben Sie eine andere Email-Adresse an, um das Nutzerkonto zu aktivieren.</span>
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" style={{margin: '22px 0px 0px', display: 'flex', justifyContent: 'space-between'}} id="receipt-add-edit-btns">
                <div>
                  <ColorButton
                    onClick={() => {
                      toggle();
                    }}
                    className="gray-btn"
                    text={'Schließen'}
                  />
                </div>
                <div>
                  <ColorButton
                    onClick={() => {
                      this.props.history.push(`/user/tenant/information?tenantId=${selectedTenant}&tenantAdministration=false`);
                      //this.props.history.push(`/user/tenant/information?tenantId=${ten.id}&tenantAdministration=false`)
                    }}
                    className="purple-btn"
                    text={'Email ändern'}
                  />
                </div>
              </div>
              </div>
          </div>
      </CustomModal>
    );
  }
}

export default EmailExistErrorModal;
