import { Group, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomModal from '.';
import ColorButton from '../Buttons/ColorButton';
import plan from '../../../../../assets/images/module/general/plan.svg';
import planBlue from '../../../../../assets/images/module/general/planBlue.svg';
import planWhite from '../../../../../assets/images/module/general/planWhite.svg';
import skyscraper from '../../../../../assets/images/module/general/skyscraper.svg';
import skyscraperBlue from '../../../../../assets/images/module/general/skyscraperBlue.svg';
import skyscraperWhite from '../../../../../assets/images/module/general/skyscraperWhite.svg';

const MOBILE_SIZE = 992;

class AddPropertyTypeModal extends Component {
    state={
        porpertyTypes: [
            {
                type: 'CONDOMINIUM'
            },
            {
                type: 'REGULAR'
            }
        ],
        hover1: false,
        hover2: false,
        selectedType: '',
        isMobile: window.innerWidth <= MOBILE_SIZE,
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps){
        if(prevProps.open !== this.props.open){
            this.setState({selectedType: ''});
        }
    }

    handleResize = () => {
        if (window.innerWidth <= MOBILE_SIZE) {
          this.setState({ isMobile: true });
        } else {
          this.setState({ isMobile: false });
        }
      };

    handleMouseEnter=(type)=>{
        this.setState({[type]: true});
    }

    handleMouseLeave=(type)=>{
        this.setState({[type]: false});
    }

    render(){
        const { open, toggle, className, edit, next } = this.props;
        const { hover1, hover2, selectedType, isMobile } = this.state;
        return(
            <CustomModal
            heading={'Art der Immobilie'}
            headingClassName={'property-type-modal-heading'}
            open={open}
            toggle={() => {
                toggle();
            }}
            className={`${className}`}>
                <Stack>
                    <Group>
                        <span style={{fontWeight: '400', fontFamily: 'Inter, sans-sarif', fontSize: '14px'}}>Bitte wählen Sie, welche Art der Immobilie Sie anlegen wollen.</span>
                    </Group>
                    <Group grow>
                        <Group position='center'
                        className={isMobile ? 'property-type-button-mobile' : 'property-type-button'}
                        onMouseEnter={()=> this.handleMouseEnter('hover2')}
                        onMouseLeave={()=> this.handleMouseLeave('hover2')}
                        onClick={()=> this.setState({selectedType: 'REGULAR'})}
                        style={{background: selectedType === 'REGULAR' && '#E3E3FC', height: '170px'}}
                        align='flex-start'>
                            <Stack spacing={'5px'}>
                                <Group position={'apart'} noWrap align={isMobile && 'flex-start'}>
                                    <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === 'REGULAR' ? '#5555FF' : hover2 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Gesamtes Objekt</span>
                                    <img style={{marginTop: isMobile && '3px'}} src={selectedType === 'REGULAR' ? skyscraperBlue : hover2 ? skyscraperBlue : skyscraper} width='16px' height={'16px'} />
                                </Group>
                                <span style={{textAlign: '', fontWeight: '400', color: selectedType === 'REGULAR' ? '#252563' : '#252563'}}>Sie wollen ein Mietshaus, gemischte Immobilie oder Gewerbeobjekt mit allen darin befindlichen Mieteinheiten verwalten.</span>
                            </Stack>
                        </Group>
                        <Group position='center'
                        className={isMobile ? 'property-type-button-mobile' : 'property-type-button'}
                        onMouseEnter={()=> this.handleMouseEnter('hover1')}
                        onMouseLeave={()=> this.handleMouseLeave('hover1')}
                        onClick={()=> this.setState({selectedType: 'CONDOMINIUM'})}
                        style={{background: selectedType === 'CONDOMINIUM' && '#E3E3FC', height: '170px'}}
                        align='flex-start'>
                            <Stack spacing={'5px'}>
                                <Group position={'apart'} noWrap align={isMobile && 'flex-start'}>
                                    <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === 'CONDOMINIUM' ? '#5555FF' : hover1 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Einzelne Einheit</span>
                                    <img style={{marginTop: isMobile && '3px'}} src={selectedType === 'CONDOMINIUM' ? planBlue : hover1 ? planBlue : plan} width='16px' height={'16px'} />
                                </Group>
                                <span style={{textAlign: '', fontWeight: '400', color: selectedType === 'CONDOMINIUM' ? '#252563' : '#252563'}}>Sie wollen eine einzelne Eigentumswohnung oder Gewerbeeinheit verwalten.</span>
                            </Stack>
                        </Group>
                    </Group>
                    <Group position={'apart'}>
                        <div>
                        <ColorButton
                        className="gray-btn"
                        text={'Abbrechen'}
                        onClick={toggle}
                        />
                        </div>
                        <div>
                        <ColorButton
                        className="purple-btn"
                        text={'Weiter'}
                        onClick={()=> next(selectedType)}
                        disabled={selectedType === ''}
                        />
                        </div>
                    </Group>
                </Stack>
            </CustomModal>
        )
    }

}

const actions = {
    // addPropertyData,
    // setPropertyList,
    // changeCurrentProperty,
};
const mapStateToProps = (state) => {
    return {
      propertySelected: state.generalReducer.property,
      properties: state.generalReducer.allProperties,
      profileData: state.generalReducer.profile,
      accountID: state.generalReducer.account_api_id,
      fuelTypes: state.generalReducer.fuelTypes
    };
};
export default connect(mapStateToProps, actions)(AddPropertyTypeModal);