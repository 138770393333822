import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class TenantTypeChangeToSelfAlertModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className } = this.props;
    
    return (
      <CustomModal
        heading={'Selbstnutzer wählen?'}
        headingClassName={'tenant-type-change-to-self-alert-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <div>
            <div>
                <Row>
                    <Col xs={12}>
                        <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Bei der Änderung des Nutzertyps zu „Selbstnutzer“, gehen Angaben zur Höhe der Miete und Vorauszahlungen verloren. Wollen Sie fortfahren?</span>
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" style={{margin: '22px 0px 0px', display: 'flex', justifyContent: 'space-between'}} id="receipt-add-edit-btns">
                <div>
                  <ColorButton
                    onClick={() => {
                      toggle();
                    }}
                    className="gray-btn"
                    text={'Abbrechen'}
                  />
                </div>
                <div>
                  <ColorButton
                    onClick={() => this.props.proceed()}
                    className="red-btn"
                    text={'Fortfahren'}
                  />
                </div>
              </div>
              </div>
          </div>
      </CustomModal>
    );
  }
}

export default TenantTypeChangeToSelfAlertModal;
