import React, { Component } from 'react';
import { HeadingContainer, TransparentButton, ColorButton, DeleteModal, SwitchContainer } from '../../../../../neoverv';
import { Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import {
  updateUserProfile,
  Account_info,
  updateProfilePicture,
  setProfileData,
  setCompanyLogo,
  updateCompanyLogo,
  setAccountData
} from '../../../../../redux/action/General/generalActions';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import BlurLogoUpload from '../../../../../assets/images/module/general/blurLogoUpload.svg';
import { Group, Skeleton, Stack } from '@mantine/core';
import BuyPremiumModal from '../../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import { showNotification } from '@mantine/notifications';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang2 = Setting[Setting.language_default].newKeys;
const { checkProperty, loader } = Autoload.MainHelper;
const MOBILE_SIZE = 500;

export class CompanyDetails extends Component {
  state = {
    email: '',
    emailErr: false,
    firstName: '',
    firstnameErr: false,
    lastName: '',
    lastnameErr: false,
    address: '',
    addressErr: false,
    city: '',
    cityErr: false,
    zip: null,
    zipErr: false,
    company: '',
    companyErr: false,
    file: '',
    fileToUpload: '',
    deleteAccountModal: false,
    fileUploadErr: { state: false, msg: '' },
    isMobile: window.innerWidth < MOBILE_SIZE,
    skeletonLoading: false,
    buyPremiumModalOpen: false,
    accountData: null,
    enableLogoUse: false
  };

  componentDidMount() {
    this.setState({skeletonLoading: true});
    this._fetch_UserInfoData();
    // this.getUserPicture();
    // const { profile } = this.props;
    // // console.log(profile, 'profile');
    // this.setState({
    //   email: checkProperty(profile, 'email'),
    //   name: checkProperty(profile, 'name'),
    //   address: checkProperty(profile, 'address'),
    //   city: checkProperty(profile, 'city'),
    //   zip: checkProperty(profile, 'zip', null),
    //   company: checkProperty(profile, 'company'),
    //   file: checkProperty(profile, 'file'),
    // });
  }
  toggleDeleteAccountModal = () => {
    this.setState({ deleteAccountModal: !this.state.deleteAccountModal });
  };

  _get_UserProfilePic = () => {
    console.log('this.props.profile picture', this.props.profile);
    const userAccountID = this.props.profile.id;
    console.log(userAccountID, 'userAccountID profile');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + userAccountID + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_pic',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_pic = (response) => {
    console.log('user profile picture', response);
    // if (response.status == false) {
    //   Autoload.Redirection.redirection_error();
    // }
    if (response.status == true && response.statuscode == 200) {
      this.setState({ file: response.result ? response.result : '' });
    }
  };

  _fetch_UserInfoData() {
    console.log('this.props.profile', this.props.profile);
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.account_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
      //Autoload.Setting.swagger_mocked_api_baseUrl + '/users/1',
      Autoload.Setting.user_api_baseurl + this.props.profile.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_user_list_data_one',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_get_user_list_data_one(response) {
    console.log('response of profile api one', response.result);
    this.props.Account_info(response.result.accountId);
    // api one - company city zipcode street streetNo
    // this.setState({ email: response.result[0].email });
    var data = response.result;
    console.log(data, 'data profile api one ');

    setTimeout(() => {
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.user_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
        Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_user_list_data_two',
        { data: data },
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }, 500);
  }
  handle_get_user_list_data_two(response) {
    console.log('response of profile api two', response);

    // console.log(response.other.data.result);
    // const fullname =
    //   Autoload.MainHelper.checkProperty(response.other.data, 'firstName') +
    //   ' ' +
    //   Autoload.MainHelper.checkProperty(response.other.data, 'lastName');
    // console.log(fullname, 'fullname');

    this.setState({
      // name: fullname,
      firstName: Autoload.MainHelper.checkProperty(response.other.data, 'firstName'),
      lastName: Autoload.MainHelper.checkProperty(response.other.data, 'lastName'),
      email: Autoload.MainHelper.checkProperty(response.other.data, 'email'),
      accountID: Autoload.MainHelper.checkProperty(response.other.data, 'accountId'),
      address: Autoload.MainHelper.checkProperty(response.result, 'addressStreet'),
      city: Autoload.MainHelper.checkProperty(response.result, 'addressCity'),
      zip: Autoload.MainHelper.checkProperty(response.result, 'addressZipCode'),
      company: Autoload.MainHelper.checkProperty(response.result, 'addressCompany'),
      accountNo: Autoload.MainHelper.checkProperty(response.result, 'accountNo'),
      companyEmail: Autoload.MainHelper.checkProperty(response.result, 'companyEmail'),
      companyPhoneNumber: Autoload.MainHelper.checkProperty(response.result, 'companyPhoneNumber'),
      file: Autoload.MainHelper.checkProperty(response.result, 'pictureLocation'),
      enableLogoUse: Autoload.MainHelper.checkProperty(response.result, 'useOwnLogo'),
      accountData: response.result,
    },()=> this.setState({prevState: this.state}));
    this.props.setProfileData({
      ...this.props.profile,
      firstName: Autoload.MainHelper.checkProperty(response.other.data, 'firstName'),
      lastName: Autoload.MainHelper.checkProperty(response.other.data, 'lastName'),
      email: Autoload.MainHelper.checkProperty(response.other.data, 'email'),
      accountID: Autoload.MainHelper.checkProperty(response.other.data, 'accountId'),
      address: Autoload.MainHelper.checkProperty(response.result, 'addressStreet'),
      city: Autoload.MainHelper.checkProperty(response.result, 'addressCity'),
      zip: Autoload.MainHelper.checkProperty(response.result, 'addressZipCode'),
      company: Autoload.MainHelper.checkProperty(response.result, 'addressCompany'),
      accountNo: Autoload.MainHelper.checkProperty(response.result, 'accountNo'),
      companyEmail: Autoload.MainHelper.checkProperty(response.result, 'companyEmail'),
      companyPhoneNumber: Autoload.MainHelper.checkProperty(response.result, 'companyPhoneNumber'),
      pictureLocation: Autoload.MainHelper.checkProperty(response.result, 'pictureLocation'),
      useOwnLogo: Autoload.MainHelper.checkProperty(response.result, 'useOwnLogo'),
    });

    this.props.setCompanyLogo(Autoload.MainHelper.checkProperty(response.result, 'useOwnLogo'));
    this.ShowLoader();
  }

  _put_userAccountInfo() {
    this.setState({skeletonLoading: true});
    const userAccountID = this.props.profile.accountId;
    //let btnelement = document.getElementById('save-btn-userProfile-info');
    const userInforData = {
      ...this.state.accountData,
      accountId: userAccountID,
      addressCompany: this.state.company,
      companyEmail: this.state.companyEmail,
      companyPhoneNumber: this.state.companyPhoneNumber,
      addressStreet: this.state.address,
      addressCity: this.state.city,
      addressZipCode: this.state.zip,
      useOwnLogo: this.state.enableLogoUse
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID,
      'PUT',
      userInforData,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_put_userCompanyInfo',
      { userInforData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_put_userCompanyInfo(response) {
    console.log('put user info data one', response);
    let btnelement = document.getElementById('save-btn-userProfile-info');
    // this.props.Account_info(123);
    // let profileData = {
    //   email: response.result.email,
    //   firstName: response.result.firstName,
    //   lastName: response.result.lastName,
    //   phoneNumber: response.result.phoneNumber,
    //   accountId: response.result.accountId
    // };
    if(response.status){
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Company': response.other.userInforData.addressCompany,
        'Company Email': response.other.userInforData.companyEmail,
        'Company Phone': response.other.userInforData.companyPhoneNumber,
      });
      window.Intercom('trackEvent', 'profile-updated');
      this.props.setAccountData(response.result);
      showNotification({
        id: 'save_account_info_successful',
        icon: <img src={GreenTick} alt='green-tick'/>,
        message: 'Die Änderungen wurden übernommen.',
      })
    }else{
      showNotification({
        id: 'save_account_info_failed',
        message: 'Die Änderungen wurden nicht gespeichert.',
        icon: <img src={RedCross} alt='red-tick'/>,
      });
    }

    if (this.state.file) {
      if (this.state.fileToUpload) {
        this.putCompanyPicture(this.props.profile.accountId, null);
      }else{
        this.setState({skeletonLoading: false});
        this._fetch_UserInfoData();
      }
    }else{
      this.setState({skeletonLoading: false});
      this._fetch_UserInfoData();
    }
  }

  putCompanyPicture(id, updatedUserData) {
    const payload = new FormData();
    payload.append('file', this.state.file ? this.state.fileToUpload : null);
    let btnelement = document.getElementById('save_userProfile_picture');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + id + '/picture',
      'PUT',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'put_company_picture_Response',
      null,
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  put_company_picture_Response(response) {
    console.log('put_company_picture_Response', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      //this.props.updateProfilePicture(response.result);
      this.props.updateCompanyLogo(response.result);
    }

    this.setState({skeletonLoading: false});
    this._fetch_UserInfoData();
    // ;
    // this.updateProperties(response.other.updated_property_response);
  }

  deleteCompanyPicture(id, updatedUserData) {
    let btnelement = document.getElementById('delete-user-profile-button_id');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + id + '/picture',
      'DELETE',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_delete_Company_Picture',
      { cancelbtn: btnelement.children[0], updatedUserData: updatedUserData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_delete_Company_Picture(response) {
    // ;
    console.log('handle_delete_Company_Picture', response);
    // ;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.setState({
      file: null,
    });
    //update  api run
    // this.update_User_Profile(response.other.Profiledata);
  }

  cancelSubscriptionAPi() {
    const {currentSubscription} = this.props;
    if(currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER') {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments/' + this.props.currentSubscription.subscriptionAssignment + '/cancel',
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_cancel_subscription',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this.deleteAccount();
    }
  }

  handle_cancel_subscription(response) {
    this.setState({ openModal: !this.state.openModal });
    if(response.status){
      var metadata = {
        subscriptionKey: this.props.currentSubscription.subscriptionKey
      };
      window.Intercom('trackEvent', 'plan-cancelled', metadata);
      showNotification({
        message: 'Die Kündigung Ihres aktuellen Abonnements war erfolgreich.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      setTimeout(() => {
        this.deleteAccount();  
      }, 1000);
    }
    else{
      showNotification({
        message: 'Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    
  }

  deleteAccount() {
    loader(true);
    let btnelement = document.getElementById('delete-user-profile');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountID + '/DeActivate',
      'PUT',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_delete_userAccount',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_delete_userAccount(response) {
    console.log('delete user account', response);
    // debugger;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    
    window.Intercom('trackEvent', 'account-deleted');
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Account Deleted': true,
      'Current Plan': 'CANCELLED'
    });
    // Autoload.MainHelper.redirection('/login');
    setTimeout(() => {
      loader(false);
      Autoload.Utils.clear_session_data();
      window.location.href = "/login";
      window.Intercom('shutdown');
    }, 4000);
  }

  handlePictureUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      // var pattern = /image-*/;
      // console.log('file type', file, file.type)
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // console.log('width', this.width, 'height', this.height)
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${fileDimensionErr ? 'dimension' : ''
              }`;
            classContext.setState({
              fileUploadErr: { state: true, msg },
            });
          } else {
            classContext.setState({
              file: URL.createObjectURL(file),
              fileToUpload: file,
              fileUploadErr: { state: false, msg: '' },
            });
            classContext.savePersonalChanges();
          }
        };
      };
    }
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handlePictureUpload_general = (e) => {
    const _put_userAccountInfo = ()=> this._put_userAccountInfo();
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      let fileSizeErr= false;
      const file = e.target.files[0];
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      const fileSize = file.size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 5) {
        fileSizeErr=true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      const props = this.props;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr || fileSizeErr) {
            const msg = `Ungültige Datei ${fileTypeErr ? 'Art' : ''} ${fileTypeErr && fileDimensionErr ? 'und' : ''} ${fileDimensionErr ? 'dimension' : ''} ${fileSizeErr ? 'Dateigröße größer als 5 MB' : ''}`;
            classContext.setState({ fileUploadErr: { state: true, msg } });
          } else {
            if(file.size > 300000){
              Autoload.Utils.compressImage(file, res=>
              classContext.setState({
                file: URL.createObjectURL(file),
                fileToUpload: res,
                fileUploadErr: { state: false, msg: '' },
              },()=> _put_userAccountInfo()))
            }else{
              classContext.setState({
                file: URL.createObjectURL(file),
                fileToUpload: file,
                fileUploadErr: { state: false, msg: '' },
              },()=> _put_userAccountInfo());
            }
            // const payload = new FormData();
            // payload.append('fil', file, file.name);
            // Autoload.HttpRequestAxios.HttpRequest(
            //   Autoload.Setting.property_api_base_url + 'Properties/' + props.propertyData.id + '/Picture',
            //   'PUT',
            //   payload,
            //   null,
            //   false,
            //   true,
            //   false,
            //   classContext,
            //   '_handlePictureUpload',
            //   null,
            //   false,
            //   '',
            //   {
            //     'Content-Type': 'application/json',
            //     Authorization: Autoload.Utils.getAuthToken(),
            //   }
            // );
          }
        };
      };
    }
  };

  validatePersonalData = () => {
    this.setState({
      firstnameErr: false,
      lastnameErr: false,
      companyErr: false,
      addressErr: false,
      emailErr: false,
      cityErr: false,
      zipErr: false,
    });
    let emailErr,
      firstnameErr,
      addressErr,
      zipErr,
      companyErr,
      cityErr,
      lastnameErr,
      fileErr = false;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    const validEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !validEmail.test(this.state.email)) {
      emailErr = true;
    }
    if (!this.state.firstName || !validName.test(this.state.firstName)) {
      firstnameErr = true;
    }
    if (!this.state.lastName || !validName.test(this.state.lastName)) {
      lastnameErr = true;
    }
    if (!this.state.address) {
      addressErr = true;
    }
    if (!this.state.city) {
      cityErr = true;
    }
    if (!this.state.zip || this.state.zip === null) {
      zipErr = true;
    }
    if (this.state.company && !validName.test(this.state.company)) {
      companyErr = true;
    }
    if (
      this.state.fileToUpload !== null &&
      this.state.fileToUpload !== '' &&
      this.state.fileToUpload !== undefined &&
      this.state.fileUploadErr.state
    ) {
      fileErr = true;
    }

    this.setState({ emailErr, firstnameErr, addressErr, zipErr, companyErr, cityErr, lastnameErr });
    if (emailErr || firstnameErr || addressErr || zipErr || companyErr || cityErr || fileErr || lastnameErr) {
      return false;
    }
    this.savePersonalChanges();
    return true;
  };

  // savePersonalChanges = () => {
  //   const { name, email } = this.state;
  //   let data = {
  //     userId: 1,
  //     accountId: this.props.profile.id,
  //     firstName: name.indexOf(' ') > 0 ? name.substr(0, name.indexOf(' ')) : name,
  //     lastName: name.indexOf(' ') > 0 ? name.substr(name.indexOf(' ') + 1) : '',
  //     email: email,
  //   };
  //   const userAccountID = this.props.profile.id;
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.swagger_mocked_api_baseUrl + 'users/' + userAccountID,
  //     'PUT',
  //     null,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     'handle_update_profile',
  //     null,
  //     false,
  //     '',
  //     {
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  //   // const payload = { ...this.state };
  //   // this.props.updateUserProfile(payload);
  // };
  // handle_update_profile = (response) => {};
  ShowLoader = () => {
    console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-user-account-personal-details');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('response-user-account-personal-details');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});  
  };

  render() {
    console.log(this.props.profile, this.state.accountID, 'this.props.profile');
    const {isMobile, skeletonLoading, buyPremiumModalOpen, enableLogoUse} = this.state;
    const {currentSubscription, accountData} = this.props;

    return (
      <>
        <div id="empty-user-account-personal-details"></div>
        <div className="account-width personal" id="response-user-account-personal-details" style={{ display: 'block', width: isMobile ? '100%' : '380px' }}>
          {/* <Form> */}
          
          {/* <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label> {lang2.text_first_name} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                  <Form.Control
                  id="person-name"
                  name="person-name"
                  isInvalid={this.state.firstnameErr}
                  type="text"
                  value={this.state.firstName}
                  onChange={(e) => {
                    this.onInputChange('firstName', e.target.value);
                  }}
                  />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {lang.text_invalid_full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label> {lang2.text_last_name} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-name"
                  name="person-name"
                  isInvalid={this.state.lastnameErr}
                  type="text"
                  value={this.state.lastName}
                  onChange={(e) => {
                    this.onInputChange('lastName', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {lang.text_invalid_full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_company} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-company"
                  name="person-company"
                  isInvalid={this.state.companyErr}
                  type="text"
                  value={this.state.company}
                  onChange={(e) => {
                    this.onInputChange('company', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_company_name}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_email} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-email"
                  name="person-email"
                  isInvalid={this.state.emailErr}
                  type="text"
                  value={this.state.email}
                  onChange={(e) => {
                    this.onInputChange('email', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_email}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          <label className='tooltips company-contact-information-label' style={{marginTop: '25px', marginBottom: '18px'}}> {'Kontaktdaten'}</label>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_company} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-address"
                  name="person-address"
                  isInvalid={this.state.companyErr}
                  type="text"
                  value={this.state.company}
                  onChange={(e) => {
                    this.onInputChange('company', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.company && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_company}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {'Anschrift'} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-address"
                  name="person-address"
                  isInvalid={this.state.addressErr}
                  type="text"
                  value={this.state.address}
                  onChange={(e) => {
                    this.onInputChange('address', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.address && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_address}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Group>
                <Form.Label> {lang.text_city} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-city"
                  name="person-city"
                  isInvalid={this.state.cityErr}
                  type="text"
                  value={this.state.city}
                  onChange={(e) => {
                    this.onInputChange('city', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.city && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_city}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label> {lang.text_zip_code} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-zip"
                  name="person-zip"
                  isInvalid={this.state.zipErr}
                  type="number"
                  value={this.state.zip}
                  onChange={(e) => {
                    this.onInputChange('zip', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.zip && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_zip_code}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_company_email} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-email"
                  name="person-email"
                  isInvalid={this.state.companyErr}
                  type="text"
                  value={this.state.companyEmail}
                  onChange={(e) => {
                    this.onInputChange('companyEmail', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.companyEmail && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_company}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_company_phone} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-phone"
                  name="person-phone"
                  isInvalid={this.state.companyErr}
                  type="text"
                  value={this.state.companyPhoneNumber}
                  onChange={(e) => {
                    this.onInputChange('companyPhoneNumber', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.companyPhoneNumber && this._put_userAccountInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_company}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          



          <Row>
            <Col xs={12}>
              <label className='tooltips company-logo-upload-label' style={{marginTop: '18px', marginBottom: '20px'}}> {'Eigenes Logo'}</label>
              {currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ?
                <div style={{background: 'white', padding: '16px', height: '280px', borderRadius: '4px'}}>
                  <Stack align={'center'} style={{height: '100%', justifyContent: 'center'}}>
                    <Stack spacing={0} style={{}}>
                      <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Ihr eigenes Logo verwenden.</span>
                      <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing {currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : currentSubscription.subscriptionKey.split('_')[0] === 'PREMIUM' && 'Professional'}.</span>
                    </Stack>
                    <Group>
                      <div>
                        <ColorButton
                        text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Freischalten') : 'Freischalten') : 'Freischalten'}
                        className='purple-btn'
                        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                        />
                      </div>
                    </Group>
                    <Group>
                      <span style={{color: '#0E0E1D', fontSize: '12px', textAlign: 'center'}}>Verwenden Sie ein eigenes Logo auf allen Mieter-Dokumenten wie Betriebskostenabrechnungen und Ableseprotokollen.</span>
                    </Group>
                  </Stack>
                </div>
                :
                <div className="profile-image-container" style={{margin: '0 0 18px 0', flexDirection: 'column'}}>
                  <div style={{marginBottom: '20px'}}>
                    <Skeleton visible={skeletonLoading} width={190}>
                    <SwitchContainer
                    className={'tooltips company-logo-enable-app-use'}
                    onChange={() => {
                      this.setState({enableLogoUse: !enableLogoUse}, ()=> {
                        this.props.setCompanyLogo(this.state.enableLogoUse);
                        this._put_userAccountInfo();
                      });
                    }}
                    checked={enableLogoUse}
                    switchText={'Eigenes Logo verwenden'}
                    disabled={false}
                    />
                    </Skeleton>
                  </div>
                  {this.state.enableLogoUse && skeletonLoading &&
                  <Skeleton visible={skeletonLoading} mb={'xl'} height={72} width={142} style={{marginRight: '24px', marginBottom: '16px'}}/>}
                  {this.state.enableLogoUse &&
                  <>
                    <div style={{ minWidth:"108px", width: '142px', height: '72px', display: skeletonLoading ? 'none' : 'block', marginRight: '30px', marginBottom: '20px'}}>
                      <img alt="profile" style={{height: '72px'}} src={currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' ? (this.state.file ? this.state.file : '/hello-housing-logo.svg') : '/hello-housing-logo.svg'} />
                    </div>
                    
                    <div className="upload-container" style={{marginTop: 0}}>
                      <div className="property-input-box">
                        <div id="save_userProfile_picture">
                        {skeletonLoading?
                          <Skeleton visible={skeletonLoading} width={125} height={36} style={{marginBottom: '13px'}}/>
                          :  
                          <label className="upload-btn gray-btn"
                          onClick={()=> this.setState({buyPremiumModalOpen: currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? true : false})}
                          style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            fontFamily: 'sans-serif',
                            padding: '7px 11px'
                          }} htmlFor="uploadProfileImage_general">
                            {'Logo hochladen'}
                          </label>}
                          {currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && 
                          <input
                            id="uploadProfileImage_general"
                            name="uploadProfileImage_general"
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={this.handlePictureUpload_general}
                          />}
                        </div>
                        <div id="delete-user-profile-button_id">
                          <button
                            className="delete-propertypicture-button"
                            // onClick={() => this.deletePropertyPicture(this.props.propertyData.id)}
                            onClick={(e) => {
                              e.preventDefault();
                              currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.setState({ file: '', fileToUpload: '', fileUploadErr: { state: false, msg: '' } },()=> this.deleteCompanyPicture(this.props.profile.accountId, null));
                            }}
                          >
                            <Skeleton visible={skeletonLoading}>
                              {lang.text_delete}
                            </Skeleton>
                          </button>
                        </div>
                      </div>
                      <span className="file-desc" style={{whiteSpace: 'nowrap'}}>{lang.text_upload_photo_ins_svg}</span>
                      <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                    </div>
                  </>}
                </div>}
              {/* <div className="profile-image-container">
                <div className="profile-image">
                  <img
                    // style={{objectFit:'contain'}}
                    alt="profile"
                    src={
                      this.state.file
                        ? this.state.file
                        : 'https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png'
                    }
                  />
                </div>
                <div className="upload-container">
                  <label className="upload-btn" htmlFor="uploadProfileImage">
                    {lang.text_upload_photo}
                  </label>
                  <input
                    id="uploadProfileImage"
                    name="uploadProfileImage"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={this.handlePictureUpload}
                  />
                  <span className="file-desc"> {lang.text_upload_photo_ins} </span>
                  <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                </div>
              </div> */}
            </Col>
          </Row>
          {/* <Row style={{ marginTop: '15px' }}> */}
          {/* <Col xl={4} lg={4} md={3} xs={12}> */}
          {/* <div style={{ display: 'flex', marginTop: '18px', justifyContent: 'space-between' }}>
            <div id="save-btn-userProfile-info">
            <Skeleton visible={skeletonLoading}>
              <ColorButton
                onClick={(e) => {
                  e.preventDefault();
                  this._put_userAccountInfo();
                }}
                buttonConfig={{ type: 'submit' }}
                className="purple-btn user-profile-cancel-btn"
                buttonStyle={{width: '100px'}}
                text={lang.text_Save_Changes}
              />
            </Skeleton>
            </div>
            <div>
            <Skeleton visible={skeletonLoading}>
              <ColorButton
                onClick={() => this.props.history.push('/dashboard')}
                className="gray-btn user-profile-cancel-btn"
                buttonStyle={{width: '100px'}}
                text={lang.text_cancel}
              />
            </Skeleton>
            </div>
          </div> */}
          {/* </Col>
          </Row> */}

          {/* </Row> */}
          {/* </Form> */}

          <div className="danger-zone">
            {/* <HeadingContainer heading={lang.text_danger_zone} className="danger-heading delete-account" /> */}
            <label className='tooltips delete-account mb-3' style={{marginTop: '18px'}}> {lang.text_danger_zone}</label>
            <div id="delete-user-profile">
            <Skeleton visible={skeletonLoading}>
              <TransparentButton
                className="danger-btn"
                onClick={() => {
                  this.toggleDeleteAccountModal();
                  window.Intercom('trackEvent', 'account-deletion-started');
                  // this._delete_userProfileAccount(this.props.profile.id);
                }}
                text={lang.text_delete_your_account}
                textStyle={{textAlign: 'left'}}
              />
            </Skeleton>
            </div>
          </div>
        </div>
        <DeleteModal
          open={this.state.deleteAccountModal}
          className="delete-rent-modal"
          toggle={this.toggleDeleteAccountModal}
          record={this.state.accountID}
          handleDelete={() => this.cancelSubscriptionAPi()}
          // showInfo={[this.props.propertyData.name,]}
          type={'Nutzerprofil'}
          showtext={false}
          typeText={
            <div>
              <p>
                Sind Sie sicher, dass Sie Ihr hellohousing Konto löschen wollen? Sie können die hellohousing App dann nicht mehr nutzen und auch nicht mehr auf Ihre Daten zugreifen.
              </p>
              <p>
                <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>Hinweis:</span> hellohousing speichert Ihre Daten für weitere 90 Tage. Danach werden die Daten <span style={{fontWeight: 'bold'}}>automatisch und unwiderruflich</span> gelöscht. Sie können sich an den hellohousing Service (<a href='mailto:service@hellohousing.de'>service@hellohousing.de</a>) wenden, um Ihre Daten sofort löschen zu lassen oder eine Wiederherstellung des Nutzerkontos zu beantragen.
              </p>
              <p>
                Sind Sie sicher, dass Sie mit der Löschung des Nutzerkontos fortfahren wollen?
              </p>
            </div>
          }
          modalId="delete-data-property"
          showDeleteText={false}
          textShowModal='Nutzerkonto löschen'
        />
        <BuyPremiumModal
        heading={'Premium'}
        open={buyPremiumModalOpen}
        text={<span style={{fontSize: '15px', color: '#727293', fontWeight: '400'}}>Schalte weitere Mieteinheiten und vieles mehr mit dem <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: '#13135C', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium-Paket' : 'Professional-Paket'}</span> frei.</span>}
        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
        onCancel={()=> this.setState({buyPremiumModalOpen: false})}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    currentSubscription: state.generalReducer.currentSubscription,
    companyLogoEnable: state.generalReducer.companyLogoEnable,
    accountData: state.generalReducer.accountData,
  };
};

const actions = {
  updateUserProfile,
  Account_info,
  updateProfilePicture,
  setProfileData,
  setCompanyLogo,
  updateCompanyLogo,
  setAccountData
};

export default connect(mapStateToProps, actions)(CompanyDetails);
