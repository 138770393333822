/*
  created by Rija
  added on 2/1/2021
  @desc meter main page to swtich between different options
*/

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ColorButton, HeadingContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { withRouter } from 'react-router-dom';
import MessagingEditor from './MessagingEditor';
import MessagesList from './MessagesList';
import NewMessage from './NewMessage';
import MessageDetail from './MessageDetail';
import MessageTemplatesModal from '../../../../neoverv/components/Module/General/CustomModal/MessageTemplatesModal';
import { connect } from 'react-redux';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { loader, checkProperty } = Autoload.MainHelper;

const MOBILE_SIZE = 992;

const routes = [
  {
    name: 'messaging',
    className: 'tooltips messaging',
    route: '/messages',
    label: 'Messaging',
    heading: 'Nachrichten',
  },
//   {
//     name: 'master',
//     className: 'tooltips meter-data',
//     route: '/meter/master',
//     label: lang.meter.text_master_data,
//     heading: lang.newKeys.text_heading_metermaster_data,
//   },
//   {
//     name: 'master',
//     className: 'tooltips data-transfer',
//     route: '/meter/data_transfer',
//     label: 'Datentransfer',
//     heading: 'Datentransfer',
//   },
];

class List extends Component {
  state = {
    active: '/meter/reading',
    isMobile: window.innerWidth <= MOBILE_SIZE,
  };
  changePage(route) {
    this.setState({ active: route });
    this.props.history.push(route);
    //Autoload.MainHelper.redirection(route);
  }
  changeHeading() {
    const activeRoute = routes.filter((route) => {
      return window.location.pathname === route.route;
    });
    return activeRoute.length > 0 ? activeRoute[0].heading : routes[0].heading;
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const {isMobile} = this.state;
    const {currentMessageData} = this.props;

    console.log('currentMessageData: ', currentMessageData);
    let pathname = this.props.location.pathname;
    console.log(pathname);
    if (pathname === '/meter') {
      pathname = routes[0].route;
    }

    console.log('this.props.history: ', this.props.history);
    return (
      <div className="meterClass">
        <HeadingContainer
          heading={this.changeHeading()}
          headingClassName={'messaging-heading tooltips'}
          className="messaging-container"
          rightContent={
            (window.location.href.includes('/newMessage') || window.location.href.includes('/messageDetail')) &&
            <ColorButton
            id={'utility-message-draft-3'}
            text={'Zurück'}
            className={'gray-btn'}
            onClick={()=> {
              loader(true);
              setTimeout(() => {
                loader(false);
                if(currentMessageData?.templateType === 'RentalPayment'){
                  this.props.history.push('/user/rentIncomeTracking');
                }else{
                  this.props.history.push('/messaging/messages');
                }
                //this.props.history.push(window.location.href.includes('/newMessage') ? '/messaging/messages' : window.location.href.includes('/messageDetail') && '/messaging/messages');
              }, 600);
            }}
            />}
          //headingClassName={window.location.pathname === '/meter/reading' ? routes[0].className : window.location.pathname === '/meter/master' ? routes[1].className : routes[2].className}
        //   rightContent={
        //     <ButtonGroup>
        //       {routes.map((btn, idx) => {
        //         return (
        //           <Button
        //             key={idx}
        //             className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}
                   
        //             `}
        //             style={{width: '110px'}}
                    
        //             // style={{
        //             //   color: btn.route === pathname ? '#111147' : '#727293',
        //             //   borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
        //             //   fontSize: btn.route === pathname ? '14px' : '14px',
        //             //   marginRight: '25px',
        //             //   padding: '32px 0px',
        //             // }}
        //             onClick={() => {
        //               this.changePage(btn.route);
        //             }}
        //           >
        //             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //               <span style={{marginTop: '-1px'}}>
        //                 {btn.label}
        //               </span>
        //             </div>
        //           </Button>
        //         );
        //       })}
        //     </ButtonGroup>
        //   }
        />
        <div>
          <Route path={this.props.match.url} exact component={MessagesList} />
          <Route path={this.props.match.url + '/messages'} exact component={MessagesList} />
          <Route path={this.props.match.url + '/newMessage'} exact component={NewMessage} />
          <Route path={this.props.match.url + '/messageDetail'} exact component={MessageDetail} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      currentMessageData: state.generalReducer.currentMessageData,
  };
};

export default connect(mapStateToProps)(withRouter(List));
