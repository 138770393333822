import React, { Component } from 'react';
import Switch from 'react-switch';
import RecordOptions from '../../General/RecordOptions/index.js';
import { DeleteModal, AddCostTypeModal } from '../../../../index.js';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload.js';
import GrayTick from '../../../../../assets/images/module/general/GrayTick.svg';
import VerifiedSvg from '../../../../../assets/images/verifiedSvg';
import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
class costTypeRow extends Component {
  constructor() {
    super();
    this.state = { checked: false, openDelete: false, openEditCostType: false };
    this.handleChange = this.handleChange.bind(this);
  }

  //switch handler
  handleChange(checked) {
    this.setState({ checked });
  }

  //delete modal
  toggleDeleteModal = () => {
    this.setState({
      openDelete: !this.state.openDelete,
      //switch state
    });
  };

  handleDelete = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('ct-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'CostTypes/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handle_delete_list_data',
      { id: id },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_delete_list_data = (response) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if(response.status === true){
      showNotification({
        message: 'Die Kostenart wurde gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
      });
    } else {
      showNotification({
        message: 'Diese Kostenart wird noch verwendet und konnte nicht gelöscht werden.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    
    this.props.parent.bind_list_cost_type_data();
    this.toggleDeleteModal();
  };

  toggleEditCostTypeModal = () => {
    this.setState({
      openEditCostType: !this.state.openEditCostType,
    });
  };

  render() {
    const { showCostData, showCostWholeData } = this.props;
    // console.log(this.props.costTypeData, 'show this.props.costTypeData data');
    return (
      <>
        {/* /////////////////////////// web view ///////////////////////// */}

        <tr className="table-row">
          <td className="name-cost">{showCostData.costTypeName}</td>

          <td className="allocation-key-cost">
            {showCostData.allocationKey && showCostData.allocationKey.allocationKeyName}
          </td>

          {/* <td className="table-costype-active-inactive active-cost">
            <div style={{ marginTop: '5px' }}>
              <Switch
                onChange={this.handleChange}
                // checked={this.state.checked}
                checked={showCostData.status}
                checkedIcon={false}
                uncheckedIcon={false}
                height={16}
                width={28}
                onColor="#4949F5"
              />
            </div>
            <div className="active-inactive">{showCostData.status === true ? 'Active' : 'Inactive'}</div>
          </td> */}
          <td className="table-costype-active-inactive active-cost" style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ marginTop: '5px' }}>
              {showCostData.systemCostType === true ? (
                // <span>
                //   <img src={Verified} alt="icon" />
                // </span>
                <VerifiedSvg fill='rgba(86, 85, 255, 1.00)'/>
              ) : (
                <span>
                  <img src={GrayTick} alt="icon" />
                </span>
              )}
            </div>
          </td>
          <td className="dot-cost">
            {showCostData.costTypeIdentifier !== 'HEATING_TOTAL' &&
            <RecordOptions
              toggleDeleteModal={() => this.toggleDeleteModal()}
              toggleEditModal={() => this.toggleEditCostTypeModal()}
              showDeleteButton={!showCostData.systemCostType}
            />}
          </td>
        </tr>

        {/* /////////////////////////// mobile view ///////////////////////// */}

        <div className="cost-type-card">
          {/* /////////////////////////// left side ///////////////////////// */}
          {/* <div className="left-side">
            <ul>
              <li className="gray-text">Name</li>
              <li className="black-text">{showCostData.costTypeName}</li>
            </ul>
            <ul>
              <li className="gray-text">Allocation key</li>
              <li className="black-text">{showCostData.allocationKey && showCostData.allocationKey.allocationKeyName}</li>
            </ul>
          </div> */}

          <div className="left-side" style={{ width: '90%' }}>
            <ul>
              <li className="gray-text">{lang.property.costType_tablehead_name}</li>
              <li className="black-text">{showCostData.costTypeName}</li>
            </ul>
            <ul style={{ display: 'flex', marginTop: '10px' }}>
              <div style={{ width: '70%' }}>
                <li className="gray-text">{lang.property.costType_tablehead_allocationkeys}</li>
                <li className="black-text allocation-measure">
                  {showCostData.allocationKey && showCostData.allocationKey.allocationKeyName}
                </li>
              </div>
              <div>
                <li className="gray-text">{lang.newKeys.text_system_cost_type}</li>
                <li>
                  {showCostData.systemCostType === true ? (
                    // <span>
                    //   <img src={Verified} alt="icon" />
                    // </span>
                    <VerifiedSvg fill='rgba(86, 85, 255, 1.00)'/>
                  ) : (
                    <span>
                      <img src={GrayTick} alt="icon" />
                    </span>
                  )}
                </li>
              </div>
            </ul>
            <ul></ul>
          </div>

          {/* /////////////////////////// right side ///////////////////////// */}
          {/* <div className="center-side">
            <ul>
              <div style={{ marginTop: '5px' }}>
                {showCostData.systemCostType === true ? (
                  <span>
                    <img src={Verified} alt="icon" />
                  </span>
                ) : (
                  <span>
                    <img src={GrayTick} alt="icon" />
                  </span>
                )}
              </div>
            </ul>
          </div> */}

          {/* /////////////////////////// right side ///////////////////////// */}
          <div className="right-side">
            {showCostData.costTypeIdentifier !== 'HEATING_TOTAL' &&
            <RecordOptions
              toggleDeleteModal={() => this.toggleDeleteModal()}
              toggleEditModal={() => this.toggleEditCostTypeModal()}
            />}
          </div>
        </div>

        {this.state.openDelete && (
          <DeleteModal
            record={showCostData}
            parent={this.props.parent}
            className="delete-receipt-modal"
            open={this.state.openDelete}
            toggle={() => this.toggleDeleteModal()}
            handleDelete={(id) => this.handleDelete(id)}
            modalId="ct-delete-modal-footer"
            type={lang.newKeys.text_single_costtype}
            showtext={false}
            typeText={'Sind Sie sicher, dass Sie diese Kostenart löschen wollen?'}
            showDeleteText={false}
            textShowModal={'Kostenart löschen'}
          />
        )}

        {this.state.openEditCostType && (
          <AddCostTypeModal
            parent={this.props.parent}
            open={this.state.openEditCostType}
            toggle={this.toggleEditCostTypeModal}
            className="add_cost_type_modal"
            edit={true}
            showCostData={showCostWholeData}
            record={showCostData}
            recordData={this.props.costTypeData}
            handleEdit={(data) => this.handleEdit(data)}
            callBackFunction={() => this.props.parent.bind_list_cost_type_data()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allocationData: state.allocationKeyReducer,
  };
};

export default connect(mapStateToProps)(costTypeRow);
