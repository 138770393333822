import React from 'react';
import { ColorButton } from '../../../../index';
import '../../../../scss/components/emptyState.scss';
import Help from '../../../../../assets/images/module/general/Help.svg';
import { disabled } from 'glamor';

const EmptyStateContainer = ({ heading, subheading, icon, btnHandler, btnText, btnContainerStyle=null, className, showBtn, styleMainContainer, textAfterBtn= null, materialIcon=false, iconWidth=null }) => {
  return (
    <div>
      <div className={className}>
        <div style={styleMainContainer} className="main-container empty-state-container">
          <div className="icon-container">
            {materialIcon ? <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>{icon}</div> : <img src={icon} width={iconWidth} className="empty-state-icon" alt="empty" />}
          </div>
          <h4 className="empty-state-heading">{heading}</h4>
          <h5 className="empty-state-subheading">{subheading}</h5>
          {showBtn && (
            <>
            <div style={btnContainerStyle}>
              <div>
                <ColorButton
                  type="button"
                  text={btnText}
                  onClick={() => {
                    btnHandler();
                  }}
                  className="purple-btn"
                />
              </div>
            </div>
            {textAfterBtn !== null && <h5 className="empty-state-subheading">{textAfterBtn}</h5>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

EmptyStateContainer.defaultProps = {
  heading: '',
  subheading: '',
  icon: Help,
  btnHandler: () => {
    // console.log('btn empty state');
  },
  btnText: 'Add',
  showBtn: true,
};
export default EmptyStateContainer;
