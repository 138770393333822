import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import HeadingContainerNew from '../../../../neoverv/components/Module/General/HeadingContainerNew';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import AllPartnersList from './AllPartnersList';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];



class List extends Component {
  state = {
  };

  componentDidMount(){
  }

  componentDidUpdate(){
  }

  render() {
    return (
      <div className="partners-main-container">
        <HeadingContainerNew
          heading={'Partner'}
          className="partners-heading-container"
          rightContent={null}
          breadcrumbsClassName={'partners-breadcrumbs'}
        />
        <div>
          <Route path={this.props.match.url} exact component={AllPartnersList} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    refreshDocumentsData: state.generalReducer.refreshDocumentsData,
  };
};

export default connect(mapStateToProps, null)(withRouter(List));
