import { Group, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { useLocation, useHistory, matchPath } from "react-router-dom";

import Icon from './Icon';

// Single menu item
export const MainMenuItem = ({ icon, route, children, href, target, color=null, ...props }) => {
  const [ hovered, setHovered ] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleNavigate = (event) => {
    event.preventDefault();

    if (route) {
      history.push(route);
      history.replace(route);
    }

    if (href) {
      window.open(href);
    }
  }

  const pathMatches = () => matchPath(pathname, { path: route });

  return (
    <Group 
      sx={{
        cursor: 'pointer',
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 20
      }}
      onClick={handleNavigate} 
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      {...props}
    >
      {pathMatches() && (
        <div 
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: -3,
            width: 6,
            borderRadius: 3,
            backgroundColor: 'rgba(86, 85, 255, 1.00)'
          }}
        />
      )}
      {icon && <Icon name={icon} color={pathMatches() || hovered ? color ? color : 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)'} />}
      <Text className={route === '/documents' ? 'main-menu-documents-text tooltips' : route === '/messaging' && 'main-menu-messaging-text tooltips'} sx={{ color: pathMatches() || hovered ? color ? color : 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)' }}>{children}</Text>
    </Group>
  )
}

// Main app menu
const MainMenu = (props) => {
  return (
    <Stack spacing={6}>
      <MainMenuItem icon="dashboard" route="/dashboard">Übersicht</MainMenuItem>
      <MainMenuItem icon="home" route="/property">Immobilie</MainMenuItem>
      <MainMenuItem icon="users" route="/user">Mieter</MainMenuItem>
      <MainMenuItem icon="receipt" route="/accounting">Buchhaltung</MainMenuItem>
      <MainMenuItem icon="meter" route="/meter">Ablesungen</MainMenuItem>
      <MainMenuItem icon="tap" route="/utility-billing">Betriebskosten</MainMenuItem>
      <MainMenuItem icon="messenger" route="/messaging">Nachrichten</MainMenuItem>
      <MainMenuItem icon="documents" route="/documents">Dokumente</MainMenuItem>
      <MainMenuItem icon="tenantPortal" route="/tenantPortal">Mieterportal</MainMenuItem>
    </Stack>
  )
}

export default MainMenu