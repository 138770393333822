import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Grid, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import BankBlue from '../../../../../assets/images/module/general/Bank.svg';
import Bank from '../../../../../assets/images/module/general/BankGrey.svg';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class SelectOptionFromBankConnectionOrAccountModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    selectedType: null,
    selectedBankConnectionId: null
  };
  
  constructor(props) {
    super(props);
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
    this.setState({[type]: false});
}
  renderBankConnectionsTable=()=>{
    const {bankConnectionsData} = this.props;
    const {selectedBankConnectionId} = this.state;
    return(
      <Stack style={{width: '100%', marginBottom: '10px'}}>
        <Group>
          <span style={{fontWeight: '400', fontSize: '14px', fontFamily: 'Inter, sans-serif'}}>Bitte wählen Sie eine Bank aus und klicken Sie auf Weiter, um fortzufahren.</span>
        </Group>
        <TableContainer style={{maxHeight: '380px'}} id='banks-search-table'>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className='banks-table-header' style={{paddingTop: '5px'}}>
                  Name
                </TableCell>
                <TableCell className='banks-table-header' style={{paddingTop: '5px'}}>
                  Stadt
                </TableCell>
                <TableCell className='banks-table-header' style={{paddingTop: '5px'}}>
                  BIC
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {bankConnectionsData
            .map((bankConnection)=>
            <TableRow className={`bank-cards ${selectedBankConnectionId === bankConnection.id && 'selected-bank'} banks-table-row`} onClick={()=> this.setState({selectedBankConnectionId: bankConnection.id})}>
              <TableCell className='banks-table-cell'>
                <Group spacing={10} noWrap>
                  {bankConnection?.bankData?.iconUrl ? 
                  <img src={bankConnection?.bankData?.iconUrl} style={{maxWidth: '30px'}}/>
                  :
                  <Group style={{width: '30px', height: '30px', background: '#5555FF', lineHeight: '34px', borderRadius: '4px'}} position='center' align={'center'} >
                    <span style={{color: 'white', fontWeight: 'bold'}}>{bankConnection?.bankData?.name[0]}</span>
                  </Group>}
                  <span style={{wordBreak: 'break-word'}}>{bankConnection?.bankData?.name}</span>
                </Group>
                
              </TableCell>
              <TableCell className='banks-table-cell'>
                {bankConnection?.bankData?.city}
              </TableCell>
              <TableCell className='banks-table-cell'>
                {bankConnection?.bankData?.bic}
              </TableCell>
            </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    )
  }
  render() {
    const { open, toggle, className, openAddBankModal, getBankAccountByBankConnectionId } = this.props;
    const { hover1, hover2, selectedType, isMobile, selectedBankConnectionId } = this.state;
    
    return (
      <CustomModal
        heading={'Konto ersetzen'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        // style={{maxWidth: '450px'}}
      >
        <Stack>
            <Stack>
                <Group>
                    <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Do you want to add a new bank connection or want to add bank account from existing bank connection?</span>
                </Group>

                <Group grow noWrap>
                  <div>
                    <CardSelection
                    title={'Bank'}
                    subText={'To add a new bank connection.'}
                    icon={Bank}
                    blueIcon={BankBlue}
                    hover={hover1}
                    hoverName={'hover1'}
                    selectedType={selectedType}
                    type={'BANK_CONNECTION'}
                    disabled={false}
                    onClick={()=> {
                      this.setState({selectedType: 'BANK_CONNECTION'});
                    }}
                    handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                    handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                    isMobile={isMobile}
                    />
                  </div>
                  <div>
                    <CardSelection
                    title={'Account'}
                    subText={'To add a new account from existing connection.'}
                    icon={TenantIcon}
                    blueIcon={TenantIconBlue}
                    hover={hover2}
                    hoverName={'hover2'}
                    selectedType={selectedType}
                    type={'ACCOUNT'}
                    disabled={false}
                    onClick={()=> {
                      this.setState({selectedType: 'ACCOUNT'});
                    }}
                    handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                    handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                    isMobile={isMobile}
                    />
                  </div>
                </Group>
                {selectedType === 'ACCOUNT' && this.renderBankConnectionsTable()}
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={selectedType === 'ACCOUNT' ? 'Weiter' : 'Hinzufügen'}
                    className='purple-btn'
                    onClick={()=> selectedType === 'ACCOUNT' ? getBankAccountByBankConnectionId(selectedBankConnectionId) : openAddBankModal()}
                    disabled={selectedType === null ? true : selectedType === 'ACCOUNT' ? selectedBankConnectionId ? false : true : false}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default SelectOptionFromBankConnectionOrAccountModal;
