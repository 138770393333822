import React, { Component } from 'react';
import CustomModal from './index';
import { ColorButton, HeadingContainer, TransparentButton, InfoField, Avatar } from '../../../../index';
import Info from '../../../../../assets/images/module/general/Info.svg';
import { Row, Col } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import DownloadIcon from '../../../../../assets/images/module/general/DownloadIcon.svg';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import TapIconDark from '../../../../../assets/images/module/general/newIconReceipt.svg';
import TapIconLight from '../../../../../assets/images/module/general/UtilityIcon.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import MinusIcon from '../../../../../assets/images/module/general/subtracticon.svg';
import PartnerDashboardIcon from '../../../../../assets/images/module/general/PartnerDashboardIcon.svg';
import PartnerPopOverCloseIcon from '../../../../../assets/images/module/general/PartnerPopOverCloseIcon.svg';
import PartnerContent from '../../../../../assets/partner-content.json';
import moment from 'moment';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import Autoload from '../../../../Autoload';
import AvatarPlaceholder from '../../../SVGs/avatarPlaceholder';
import Pdf from '@mikecousins/react-pdf';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import {
  setUtilityBillingData,
} from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import NotificationIcon from '../../../SVGs/notificationIcon';
import { Group, Popover, Stack } from '@mantine/core';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

class ReceiptDetailModal extends Component {
  state = {
    file: this.props.record && this.props.record.file_name ? this.props.record.file_name : Receipt,
    expanded: false,
    billingSplit: [],
    costType: '-',
    openPartner: false,
    receiptPartner: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {record} = this.props;
    this.props.record && this.props.record.id !== undefined && this._getAllBillingPeriodSplit();
    let costType = record && record.cost_type && record.cost_type.length > 0
    ? record.cost_type.length > 1
      ? this.state.expanded
        ? record.cost_type
            .join(',\n')
            .split('\n')
            .map((i) => <p style={{ marginBottom: 1 }}>{i}</p>)
        : record.cost_type[0]
      : record.cost_type[0]
    : record && record.selectedCostTypes && 
    record.selectedCostTypes.length > 0 ? <p style={{ marginBottom: 1 }}>{record.selectedCostTypes[0].costTypeName}</p> 
    : '-';
    this.setState({costType}, ()=> {
      if(costType === 'Gebäudeversicherung'){
        setTimeout(() => {
          this.setState({openPartner: true, receiptPartner: PartnerContent.find((content)=> content['receipt-visible'])});  
        }, 1000);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.props.record && this.props.record.id !== undefined && this._getAllBillingPeriodSplit();
    }
  }
  getFilePreview = (file) => {
    console.log(file, 'file in preview');
    // if (file) {
    let re = /(?:\.([^.]+))?$/;
    var extension = re.exec(file)[1];
    extension = extension && extension.split('?')[0];
    
    if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
      return <Avatar className="image-preview" image={file} size="xl" color="black" />
    } else if (typeof(file) === 'object') { 
      const url =URL.createObjectURL(file);
      return (
        <div style={{display: 'flex', backgroundColor:'grey', justifyContent: 'center', alignItems: 'center', padding: 2}}>
          <Pdf file={url} scale={0.31} />
        </div>
      );
    } else {
      return <AvatarPlaceholder />
    }
  };
  // onDownloadClick = (record) => {
  //   window.open(record && record.file_name ? record.file_name : Receipt, '_blank');
  // };

  _getAllBillingPeriodSplit=()=> {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_basic_base_url + 'BillingPeriodsSplit/GetAllBy?receiptId=' + this.props.record.id, // not according to swagger
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getBillingSplitResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _getBillingSplitResponse(response) {
    console.log('_getBillingSplitResponse: ', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      
      this.setState({billingSplit: response.result});
    }
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>
        Kosten, die auf mehrere Betriebskostenperioden entfallen, werden nach Kalendertagen aufgeteilt.
      </span>
    </Tooltip>
  );

  renderTooltipForAssigned = (props, assigned) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>
        {assigned ? 'Abgerechnet' : 'Nicht abgerechnet'}
      </span>
    </Tooltip>
  );
  navigateToBilling = (utilityBillingId, costTypeName) => {
    this.props.history && this.props.history.push(`/utility-billing/utility?currentBillingId=${utilityBillingId}`, {costTypeName: costTypeName});
  };

  render() {
    const { className, open, closeShow, record, onEditClick, onExpansionClick } = this.props;
    const { costType, openPartner, receiptPartner } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <CustomModal
          heading={record && record.name ? record.name : ''}
          headingClassName={'receipt-management-overview-receipt'}
          open={open}
          toggle={closeShow}
          className={`${className}`}
        >
          <div>
            <div className="modal-inner-body show-receipt-container">
              <div className="receipt-side-img">
                {/* <Avatar 
                            image={getFilePreview(record && record.file_name)}
                             size='xl' /> */}
                {/* <embed
                            src={record && record.file_name?record.file_name:''}
                            type="application/pdf"
                            frameBorder="0"
                            // scrolling="auto"
                            height="200px"
                            width="100%"
                        ></embed> */}
                {/* <iframe
                            src={record && record.file_name?record.file_name:''}
                            frameBorder="0"
                            // scrolling="auto"
                            height="200px"
                            width="100%"
                        ></iframe> */}
                {this.getFilePreview(record && record.file_name)}
                {/* {  window.open(record && record.file_name ? record.file_name : Receipt, "_blank", )} */}

                {record.file_name ? 
                (<>
                  <a
                    download={record && `${record.name}.pdf`}
                    target="_blank"
                    href={record && record.file_name && typeof(record.file_name) === 'object' ? URL.createObjectURL(record.file_name )
                    : record && record.file_name ? record.file_name : Receipt}
                    className={`download-icon ${record && record.file_name ? '' : 'disable'}`}
                  >
                    <img className={record && record.file_name ? '' : 'disable'} src={DownloadIcon} alt="dw" />
                  </a>
                  <img
                    className={`expand-icon ${record && record.file_name ? '' : 'disable'}`}
                    src={ExpandIcon}
                    alt="dw"
                    onClick={() => record && record.file_name && onExpansionClick && onExpansionClick()}
                  />
                </>) : null
              }
              </div>
              <div className="show-receipt-content">
                <HeadingContainer
                  heading={lang.receipt.text_receipt_details}
                  rightContent={
                    <div className="d-flex align-items-center">
                      <TransparentButton
                        onClick={() => onEditClick()}
                        text={lang.general.text_edit}
                        className="tc-light-purple mb-0 p-0"
                        textStyle={{color: 'rgb(86, 85, 254)'}}
                      />
                    </div>
                  }
                />
                <Row className="text-row">
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={'Belegnummer'}
                      text={record && record.receiptNumber ? record.receiptNumber : '-'}
                      labelClassName="text-xs"
                    />
                  </Col>
                </Row>
                <Row className="text-row">
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={lang.receipt.text_receipt_name}
                      text={record && record.name ? record.name : '-'}
                      labelClassName="text-xs"
                      leftTextImage={{ img: record.utilityBillingRelevant ? TapIconDark : TapIconLight, style: { width: '10px', height: '12px' } }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={lang.receipt.text_amount}
                      // text={ record && record.cost?`${record.cost}€`:''}
                      // text={Autoload.MainHelper.ConverterCurrencyDe(record && record.costs ? record.costs + '' : '')}
                      text={Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(
                        record && record.costs ? record.costs + '' : '-'
                      )}
                      labelClassName="text-xs"
                    />
                  </Col>
                </Row>
                <Row className="text-row">
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={'Kostenart Betriebskosten'}
                      text={record.utilityBillingRelevant ? costType : '-'}
                      labelClassName="text-xs"
                      rightTextImage={
                        record && record.cost_type && record.cost_type.length > 0 && record.cost_type.length > 1
                          ? {
                              img: this.state.expanded ? MinusIcon : AddIcon,
                              handler: () => {
                                this.setState({ expanded: !this.state.expanded });
                              },
                            }
                          : null
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={'Steuerliche Kostenart'}
                      text={record.taxCostType ? record.taxCostType : '-'}
                      labelClassName="text-xs"
                    />
                  </Col>
                </Row>
                <Row className="text-row">
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={lang.receipt.text_date}
                      text={record && record.receiptDate ? moment(record.receiptDate).format('DD.MM.YYYY') : ''}
                      labelClassName="text-xs"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={lang.receipt.text_time_frame}
                      text={record && record.utilityBillingRelevant && record.time_frame ? record.time_frame : 
                      record  && record.utilityBillingRelevant && record.receiptPeriodStart && record.receiptPeriodEnd ? 
                      `${moment(record.receiptPeriodStart).format('DD.MM.YYYY')} - ${moment(record.receiptPeriodEnd).format('DD.MM.YYYY')}` 
                      : '-'}
                      labelClassName="text-xs"
                    />
                  </Col>
                </Row>
                <Row className="text-row">
                  <Col xs={12} md={12}>
                    <InfoField
                      className="receipt-info-field"
                      labelText={'Notizen'}
                      text={record?.notes ? record?.notes : '-'}
                      labelClassName="text-xs"
                      cardValueClassName="text-value-full-width"
                    />
                  </Col>
                </Row>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div>
                        <InfoField
                          className="receipt-info-field"
                          labelText={lang.receipt.text_billing_split}
                          
                          labelClassName="text-xs"
                        />
                    </div>
                    {/* <div style={{marginLeft: 10, display:'flex'}} className="add-property-modal-text-Icon">
                      <OverlayTrigger placement="top" delay={{ show: 450, hide: 1000 }} overlay={this.renderTooltip}>
                            <img
                              src={Info}
                              alt="info"
                              width="16px"
                              height="16px"
                            />
                      </OverlayTrigger>
                    </div> */}
                  </div>
                  <Row className="text-row">
                    <Col>
                        <div style={{marginTop: record.utilityBillingRelevant ? 15 : 0}}>
                          {record.utilityBillingRelevant ? this.state.billingSplit && this.state.billingSplit
                          // .filter((value, index, self) =>
                          // index === self.findIndex((t) => (
                          //   t.billingPeriodStartDate === value.billingPeriodStartDate && t.billingPeriodEndDate === value.billingPeriodEndDate
                          // )))
                          .map((item)=> 
                          <div onClick={()=> item.utilityBillingId && this.navigateToBilling(item.utilityBillingId, costType)} style={{ cursor: item.utilityBillingId && 'pointer' }}>
                          <Row style={{marginTop: 10}} >
                            <Col xs={7}>
                            <p className="card-value">
                              {`${dayjs(item.billingPeriodStartDate, 'YYYY-MM-DDT00:00:00').format('DD.MM.YYYY')} - ${dayjs(item.billingPeriodEndDate, 'YYYY-MM-DDT00:00:00').format('DD.MM.YYYY')} (${item.proRataDays} Tage)`}
                            </p>
                            </Col>
                            <Col>
                            <p className="card-value" style={{textAlign: 'end'}}>
                              {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(item.proRataCosts + '')}
                            </p>
                            </Col>
                            <Col>
                            <p className="card-value" style={{textAlign: 'end'}}>
                              {item.isReceiptAssignedToUtilityBilling ? 
                                <OverlayTrigger placement="right" delay={{ show: 450, hide: 400 }} overlay={(props)=> this.renderTooltipForAssigned(props, true)}>
                                    {UtilityBillingAssignedIcon('#5555FF')}
                                </OverlayTrigger> : 
                                <OverlayTrigger placement="right" delay={{ show: 450, hide: 400 }} overlay={(props)=> this.renderTooltipForAssigned(props, false)}>
                                  {UtilityBillingAssignedIcon('#727293')}
                                </OverlayTrigger>
                              }
                            </p>
                            </Col>
                          </Row>
                          </div>) : <p className="card-value">{'-'}</p>}
                        </div>
                    </Col>
                  </Row>
              </div>
            </div>
            {/* <div className="show-modal-footer">
              <ColorButton
                buttonConfig={{ type: 'submit' }}
                onClick={closeShow}
                className="gray-btn"
                text={lang.receipt.text_close_preview}
              />
            </div> */}
            <div style={{height: '60px', display: 'flex', alignItems: 'center'}}>
              {costType === 'Gebäudeversicherung' &&
              <Group>
                <Popover
                opened={receiptPartner && openPartner}
                target={<img src={PartnerDashboardIcon} style={{marginLeft: '8px'}} onMouseEnter={()=> this.setState({openPartner: true})} />}
                zIndex={99999}
                position='right'
                withArrow
                styles={{
                  popover: {
                    width: '425px'
                  },
                  inner: {
                    padding: '3px 15px 15px'
                  },
                  body: {
                    borderRadius: '10px'
                  }
                }}
                >
                  {receiptPartner && <Stack spacing={0}>
                    <Group position='right'>
                      <img src={PartnerPopOverCloseIcon} width={'12px'} style={{marginRight: '-11px', cursor: 'pointer'}} onClick={()=> this.setState({openPartner: false})}/>
                    </Group>
                    <Group noWrap>
                      <span className='partner-receipt-popover-text'>{receiptPartner['receipt-text']}</span>
                      <div>
                        <button onClick={()=> this.props.history.push('partner?partnerId='+receiptPartner['partner-id'])} className='partner-dashboard-card-button'>
                          <span style={{whiteSpace: 'nowrap'}}>{'Mehr erfahren'}</span>
                        </button>
                      </div>
                    </Group>  
                  </Stack>}
                  
                </Popover>
              </Group>}
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
  };
};

const actions = {
  setUtilityBillingData,
};


export default connect(mapStateToProps, actions)(ReceiptDetailModal);