import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class TenantResetConfirmationModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className } = this.props;
    
    return (
      <CustomModal
        heading={'Zugang zurücksetzen'}
        headingClassName={'tenant-access-details-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <div>
            <div>
                <Row>
                    <Col xs={12}>
                        <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Wenn Sie den Mieterzugang zurücksetzen, kann sich der Mieter mit den aktuellen Zugangsdaten nicht mehr anmelden. Der Mieter muss sich erneut für das Mieterportal registrieren. Sind Sie sicher, dass Sie fortfahren wollen?</span>
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" style={{margin: '22px 0px 0px', display: 'flex', justifyContent: 'space-between'}} id="receipt-add-edit-btns">
                <div>
                  <ColorButton
                    onClick={() => {
                      toggle();
                    }}
                    className="gray-btn"
                    text={'Schließen'}
                  />
                </div>
                <div>
                  <ColorButton
                    onClick={() => this.props.resetTenant()}
                    className="red-btn"
                    text={'Zurücksetzen'}
                  />
                </div>
              </div>
              </div>
          </div>
      </CustomModal>
    );
  }
}

export default TenantResetConfirmationModal;
