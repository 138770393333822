import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Box, Group, Modal, Overlay, Stack, Radio, RadioGroup, TextInput, Popper, Popover, CheckboxGroup, Checkbox, Skeleton } from '@mantine/core';
import { InputLabel, MenuItem, Select, FormControl, TableContainer, TableHead, TableBody, TableCell, TableRow, Table, TableSortLabel, Tooltip, IconButton } from '@material-ui/core';
import TenantAccessDetails from './TenantAccessDetails';
import TenantAdministrateConsumption from './TenantAdministrateConsumption';
import TenantPortalPreview from './TenantPortalPreview';
import TenantAdministrationList from './TenantAdministrationList';
import { ColorButton, EmptyStateContainer, SearchContainer, SwitchContainer } from '../../../../neoverv';
import TenantAdministrateDocuments from './TenantAdministrateDocuments';
import TenantAdministrateMessages from './TenantAdministrateMessages';
import PenIcon from '../../../../assets/images/module/general/PenIcon.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import Avatar from '../../../../assets/images/module/general/UserAccountBlue.svg';
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import {
  changeCurrentProperty,
} from '../../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import moment from 'moment';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import DatePicker from 'react-datepicker';
import { NumericFormat } from 'react-number-format';
import Icon from "../../../../components/Icon";
import UpdateTenantRentModal from '../../../../neoverv/components/Module/General/CustomModal/UpdateTenantRentModal';
import { load, unload } from "@finapi/web-form";
import FinApiWebFormModal from '../../../../neoverv/components/Module/General/CustomModal/FinApiWebFormModal';
import accounting from 'accounting';
import UpdateAllTenantRentalReceivedRentModal from '../../../../neoverv/components/Module/General/CustomModal/UpdateAllTenantRentalReceivedRentModal';
import { setCurrentTenant, loadingTenantsData } from '../../../../redux/action/Tenant/TenantActions';
import * as XLSX from 'xlsx';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;
dayjs.locale('de');
const dateRangeForAllTenants=[
  {
    value: 'current',
    label: `Aktueller Monat (${dayjs().format('MMMM YYYY')})`, 
    date: {
      start: dayjs().startOf('month'), 
      end: dayjs().endOf('month')
    }
  },
  {
    value: 'last',
    label: `Letzter Monat (${dayjs().subtract(1, 'month').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().subtract(1, 'month').startOf('month'), 
      end: dayjs().subtract(1, 'month').endOf('month')
    }
  },
  {
    value: 'custom',
    label: `Benutzerdefiniert`, //(${dayjs().format('MMMM YYYY')})
    date: {
      start: new Date(moment().startOf('month')), 
      end: new Date(moment().endOf('month'))
    }
  },
]

const dateRangeForSpecificTenant=[
  {
    value: 'current',
    label: `Aktuelles Jahr (${dayjs().startOf('year').format('MMMM YYYY')} - ${dayjs().endOf('month').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().startOf('year'), 
      end: dayjs().endOf('month')
    }
  },
  {
    value: 'last',
    label: `Letztes Jahr (${dayjs().subtract(1, 'year').startOf('year').format('MMMM YYYY')} - ${dayjs().subtract(1, 'year').endOf('year').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().subtract(1, 'year').startOf('year'), 
      end: dayjs().subtract(1, 'year').endOf('year')
    }
  },
  {
    value: 'custom',
    label: `Benutzerdefiniert`, //(${dayjs().startOf('year').format('MMMM YYYY')} - ${dayjs().endOf('month').format('MMMM YYYY')})
    date: {
      start: new Date(moment().startOf('year')), 
      end: new Date(moment().endOf('month'))
    }
  },
]

export class TenantRentalIncomeTracking extends Component {
  openTenantSelectionModalRef = null;

  constructor(props) {
    super(props);
    dayjs.locale('de');
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      selectedDateRange: 'current',
      dateRangeOptions: dateRangeForSpecificTenant,
      tableHeaderLabels: ['Mieter', 'Mieteinheit', 'Monat', 'Vertragsmiete', 'Zahlungseingang', 'Miete erhalten', 'Aktion'],
      openDateRangeModal: false,
      selectedDateRangeForModal: 'current',
      selectedTenant: [],
      tenantOptions: [
          // {
          //   value: 'all',
          //   label: 'Alle',
          //   name: '',
          //   tenantId: '',
          //   rentalUnit: '',
          //   rentalUnitId: ''
          // }
      ],
      openTenantSelectionModal: false,
      selectedTenantForModal: [],
      searchTenant: null,
      openUpdateTenantRentModal: false,
      selectedRentModalData: null,
      openWebFormModal: false,
      rentalPayments: [],
      offset: 0,
      stopOffsetChange: false,
      fetch: 100,
      fetchLoader: false,
      listLoader: false,
      orderBy: 'Monat',
      order: 'desc',
      allCheckBox: false,
      openUpdateAllTenantRentalReceivedRentModal: false,
      rentalUnits: [],
      exportRentalIncomePopoverOpen: false,
      exportRentalIncomeTablePopoverOpen: false
    };

  }

  componentDidMount = () => {
    this.componentDidMountStuff();
  };

  componentDidMountStuff=()=>{
    const {rentalUnits} = this.props;
    window.addEventListener('resize', this.handleResize);
    this.setState({fetchLoader: true}, ()=> this.bind_rental_unit_list_data());
  }

  bind_rental_unit_list_data = () => {
    console.log('test_property: ', this.props.propertySelected);
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit_1',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_rental_unit_1(response){
    if(response.status && response.statuscode === 200){
      if (response.result === null || (response.result && response.result.length === 0)) {
        this.setState({fetchLoader: false, listLoader: false});
      }else{
        let rentalUnits= response.result.map((rentalUnit)=> {
          rentalUnit.tenantsData=[];
          return rentalUnit;
        })
        this.setState({rentalUnits},()=>{
          response.result.map((rentalUnit, index)=> {
            Autoload.HttpRequestAxios.HttpRequest(
              Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + rentalUnit.id,
              'GET',
              null,
              null,
              false,
              true,
              false,
              this,
              'handle_get_list_rental_tenants',
              {rentalUnitId: rentalUnit.id, rentalUnitsLength: response.result.length, index},
              false,
              '',
              {
                //'Content-Type': 'application/json',
                Authorization: Autoload.Utils.getAuthToken(),
              }
            );
          });
        });
      }
    }

    if(response.status === false){
      this.setState({fetchLoader: false, listLoader: false});
    }
  }

  handle_get_list_rental_tenants(response){
    let {rentalUnits} = this.state;
    if(response.status && response.statuscode === 200){
      rentalUnits.map((rentalUnit)=> {
        if(rentalUnit.id === response.other.rentalUnitId){
          rentalUnit.tenantsData = response.result;
        }
        return rentalUnit
      });
      this.setState({rentalUnits},()=> {
        if(response.other.rentalUnitsLength === (response.other.index+1)){
          setTimeout(() => {
            let allTenantsList = this.state.tenantOptions;
            let selectedTenantForModal = this.state.selectedTenantForModal;
            let selectedTenant = this.state.selectedTenant;
            rentalUnits.map((rentalUnit)=>
            rentalUnit.tenantsData.map((tenant, index)=> {
              if(tenant.vacant === false){
                allTenantsList.push({
                  value: tenant.id,
                  label: `${tenant.fullName} (${rentalUnit.name})`, //(${tenant.in} - ${tenant.out ? tenant.out : 'nicht verfügbar'})
                  name: tenant.fullName,
                  tenantId: tenant.id,
                  rentalUnit: rentalUnit.name,
                  rentalUnitId: rentalUnit.id
                });
                selectedTenantForModal.push(tenant.id);
                selectedTenant.push(tenant.id);
              }
            }));

            this.setState({
              tenantOptions: allTenantsList,
              selectedTenantForModal,
              selectedTenant
            },()=> setTimeout(() => {
              this.evaluateRentalPayments()
            }, 600));
          }, 600);
        }
      });
    }

    if(response.status === false){
      if(response.other.rentalUnitsLength === (response.other.index+1)){
        this.setState({fetchLoader: false, listLoader: false});
      }
    }
  }

  trackScrolling = () => {    
    const wrappedElement = document.getElementById('app-content');
    if (this.isBottom(wrappedElement) && !this.state.listLoader) {
      this.setState({
        offset: this.state.stopOffsetChange ? this.state.offset : this.state.offset + 10,
        listLoader: true}, ()=> this.getRentalPayments()//this.getDocumentsByAccountId()//this.getDocumentsByAccountId()
      );
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  getRentalPayments=()=>{
    const {selectedDateRange, dateRangeOptions, offset, fetch} = this.state
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments?propertyId=' + this.props.propertySelected.id + 
      '&startDate='+ dayjs(dateRangeOptions.find((dateRange)=> dateRange.value === selectedDateRange)?.date?.start).format('YYYY-MM-DDT00:00:00') + 
      '&endDate='+ dayjs(dateRangeOptions.find((dateRange)=> dateRange.value === selectedDateRange)?.date?.end).format('YYYY-MM-DDT00:00:00') //+
      //'&offset='+offset+'&fetch='+fetch
      ,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payments',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payments(response){
    const {rentalUnits} = this.state;
    let {rentalPayments} = this.state;
    let allCheckedRentalPayments = 0;
    console.log('handle_get_rental_payments: ', rentalUnits);
    if(response.status && response.statuscode === 200){
      response.result.map((rentalPayment)=> {
        if(rentalPayment.receivedRent >= rentalPayment.expectedRent){
          allCheckedRentalPayments = allCheckedRentalPayments + 1;
        }
        //if(rentalPayments.length === 0 || (rentalPayments.find((value)=> value.id === rentalPayment.id) === undefined)){
          let tenant = null;
          rentalUnits.map((rentalUnit)=>{
            let dataFound = rentalUnit.tenantsData.find((tenant)=> tenant.id === rentalPayment.tenantId);
            tenant = dataFound ? {...dataFound, rentalUnitName: rentalUnit.name} : tenant ? tenant : null;
          });
          rentalPayments.push({
            ...rentalPayment,
            tenant
          });
        //}
      });
      this.setState({rentalPayments, stopOffsetChange: false, allCheckBox: response.result.length === allCheckedRentalPayments});
    }else{
      this.setState({stopOffsetChange: true});
    }
    this.setState({fetchLoader: false, listLoader: false});
  }

  updateRentalPayment=(id, receivedRent)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/'+id,
      'PUT',
      {receivedRent},
      null,
      false,
      true,
      false,
      this,
      'handle_put_rental_payment',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  evaluateRentalPayments=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/evaluateRentalPayments?propertyId='+ this.props.propertySelected.id,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleEvaluateRentalPayments',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleEvaluateRentalPayments(response){
    this.getRentalPayments();
  }

  handle_put_rental_payment(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} />,
        id: 'saveRentPaymentsInformation'
      });
    }
    //this.getRentalPayments();
  }

  updateProperties(updated_property_response) {
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      propertyName: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      name: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      propertyAdress: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
      propertyAddress: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'streetno'
      ),
      propertyCity: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'city'
      ),
      propertyZipCode: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'zipCode'
      ),
      propertyNoOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      propertyAreaOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      totalRentingSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      numberOfRentalUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      cutOffDate: {
        day: 
          Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
          'day',
          '31'
          ),
        month: 
          parseInt(
            Autoload.MainHelper.checkProperty(
              Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
              'month',
              '12'
            )
          )
      },
      pictureLocation: Autoload.MainHelper.checkProperty(updated_property_response, 'pictureLocation'),
      totalOccupantsCount: Autoload.MainHelper.checkProperty(updated_property_response, 'totalOccupantsCount'),
      isConsumptionSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isConsumptionSectionHidden'),
      isDocumentSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isDocumentSectionHidden'),
      isMessageSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isMessageSectionHidden'),
    }

    this.props.changeCurrentProperty(propertySelected);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  handleChange = (event) => {
    this.setState({selectedDateRange: event.target.value});
  };

  onRequestSort = (event, property) => {
    const {order, orderBy} = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc'});
    this.setState({orderBy: property});
  };

  getTableHeader = () => {
    const {tableHeaderLabels, order, orderBy, rentalPayments, allCheckBox} = this.state;
    const createSortHandler = (property) => (event) => {
      this.onRequestSort(event, property);
    };
    return(
      <TableRow>
      {tableHeaderLabels.map((label)=>
      label === 'Miete erhalten' ? 
      <TableCell>
        <Checkbox
        classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
        checked={allCheckBox}
        label={<span style={{whiteSpace: 'nowrap'}}>{label}</span>}
        styles={{
          label: {
            fontSize: '14px',
            marginBottom: 0
          }
        }}
        onChange={()=> this.setState({openUpdateAllTenantRentalReceivedRentModal: true})}
        disabled={rentalPayments.length === 0}
        />
      </TableCell>
      :
      label === 'Aktion' ?
      <TableCell>
        {label}
      </TableCell>
      :
      <TableCell style={{textAlign: label === 'Vertragsmiete' ? 'right' : 'left'}}>
        <TableSortLabel
          active={orderBy === label}
          direction={orderBy === label ? order : 'asc'}
          onClick={createSortHandler(label)}
          hideSortIcon={label === 'Vertragsmiete'}
        >
          {label}
        </TableSortLabel>
      </TableCell>
      )}
      </TableRow>
    )
  };

  onTenantEdit = (id) => {
    this.getRecord(id);
    this.props.history.push('/user/tenant/information?tenantId=' + id);
  };

  getRecord = (id) => {
    const {tenantOptions} = this.state
    const record = tenantOptions.find((rcd) => rcd.tenantId === id);
    this.props.setCurrentTenant(record, record);
  };
  
  getTableBody = () => {
    const {rentalUnits} = this.props;
    const {rentalPayments, selectedTenant, order, orderBy} = this.state;
    return(rentalPayments.filter((rentalPayment)=> selectedTenant.find((tenantId)=> tenantId === rentalPayment.tenantId) !== undefined)
      .sort((a,b)=> orderBy === 'Mieter' ? (order === 'asc' ?  a.tenant?.fullName.toLowerCase().localeCompare(b.tenant?.fullName.toLowerCase()) : b.tenant?.fullName.toLowerCase().localeCompare(a.tenant?.fullName.toLowerCase())) 
    : orderBy === 'Mieteinheit' ? (order === 'asc' ? a.tenant?.rentalUnitName.toLowerCase().localeCompare(b.tenant?.rentalUnitName.toLowerCase()) : b.tenant?.rentalUnitName.toLowerCase().localeCompare(a.tenant?.rentalUnitName.toLowerCase())) 
    : orderBy === 'Monat' ? (order === 'asc' ? dayjs(a.rentalMonth) - dayjs(b.rentalMonth) : dayjs(b.rentalMonth) - dayjs(a.rentalMonth))
    : orderBy === 'Vertragsmiete' ? (order === 'asc' ? a.expectedRent - b.expectedRent : b.expectedRent - a.expectedRent)
    : orderBy === 'Zahlungseingang' && (order === 'asc' ? a.receivedRent - b.receivedRent : b.receivedRent - a.receivedRent)
    //: orderBy === 'Miete erhalten' && (order === 'asc' ? (a.receivedRent >= a.expectedRent) - (b.receivedRent >= b.expectedRent) : (b.receivedRent <= b.expectedRent) - (a.receivedRent <= a.expectedRent))
    )
    .map((rentalPayment)=>{
          return (
            <TableRow style={{background: '#FFFFFF'}} key={rentalPayment.id}>
              <TableCell width={'25%'} style={{height: '71px', cursor: 'pointer'}} onClick={()=> this.onTenantEdit(rentalPayment.tenant?.id)}>
                {rentalPayment.tenant?.fullName}
              </TableCell>
              <TableCell width={'10%'} style={{cursor: 'pointer'}} onClick={()=> this.onTenantEdit(rentalPayment.tenant?.id)}>
                {rentalPayment.tenant?.rentalUnitName}
              </TableCell>
              <TableCell width={'10%'}>
                {dayjs(rentalPayment.rentalMonth).format('MMM YYYY')}
              </TableCell>
              <TableCell style={{textAlign: 'right', cursor: 'pointer'}} onClick={()=> this.onTenantEdit(rentalPayment.tenant?.id)}>
                <span>{rentalPayment.expectedRent ? accounting.formatMoney(rentalPayment.expectedRent, { symbol: "€",  format: "%v %s", precision: 2, thousand: '.', decimal: ',' }) : '0,00 €'}</span> 
              </TableCell>
              <TableCell>
                <Group noWrap align={'center'}>
                  <Group noWrap spacing={0} style={{background: rentalPayment.receivedRent >= rentalPayment.expectedRent ? '#e8f4ea' : ((rentalPayment.receivedRent > 0) && (rentalPayment.receivedRent < rentalPayment.expectedRent)) ? '#f7e3cd' : '#FFCBCB', width: '140px', height: '28px', borderRadius: '15px', padding: '0px 15px'}} position='apart' align={'center'}>
                    {/* <span style={{color: '#E8193C'}}>{'0,00 €'}</span> */}
                    <NumericFormat
                    className={`form-control form-control-transparent-rent-income ${rentalPayment.receivedRent >= rentalPayment.expectedRent ? 'rental-income-payment-column-full-paid' : ((rentalPayment.receivedRent > 0) && (rentalPayment.receivedRent < rentalPayment.expectedRent)) ? 'rental-income-payment-column-partial-paid' : 'rental-income-payment-column-not-paid'}`}
                    value={rentalPayment.receivedRent}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                      let newRentalPayments= rentalPayments.map((rp)=> {
                        if(rp.id === rentalPayment.id){
                          rp.receivedRent = values.floatValue;
                          return rp;
                        }else{
                          return rp;
                        }
                      });

                      this.setState({rentalPayments: newRentalPayments});
                    }}
                    suffix={' €'}
                    //onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 €'
                    // onFocus={()=> this.setState({vatPercentInFocus: true})}
                    onBlur={()=> this.updateRentalPayment(rentalPayment.id, rentalPayment.receivedRent)}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={true}
                    style={{paddingLeft: 10}}
                    />
                    <img src={PenIcon} onClick={()=> this.setState({openUpdateTenantRentModal: true, selectedRentModalData: rentalPayment})} style={{maxWidth: '12px', marginLeft: '2px', cursor: 'pointer'}} width={'12px'}/>
                  </Group>
                </Group>
              </TableCell>
              <TableCell>
                <Group style={{marginTop: '4px'}}>
                  <Checkbox
                  classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                  checked={rentalPayment.receivedRent >= rentalPayment.expectedRent}
                  label={<span style={{whiteSpace: 'nowrap'}}>Miete erhalten</span>}
                  styles={{
                    label: {
                      fontSize: '14px',
                      marginBottom: 0
                    }
                  }}
                  onChange={()=> {
                    let newRentalPayments= rentalPayments.map((rp)=> {
                      if(rp.id === rentalPayment.id){
                        rp.receivedRent = (rentalPayment.receivedRent >= rentalPayment.expectedRent) ? 0 : rentalPayment.expectedRent;
                        return rp;
                      }else{
                        return rp;
                      }
                    });

                    this.setState({rentalPayments: newRentalPayments, allCheckBox: newRentalPayments.filter((rp)=> rp.receivedRent >= rp.expectedRent).length === rentalPayments.length}, ()=> this.updateRentalPayment(rentalPayment.id, ((rentalPayment.receivedRent >= rentalPayment.expectedRent) ? rentalPayment.expectedRent : 0)));
                  }}
                  />
                </Group>
              </TableCell>
              <TableCell>
                <ColorButton
                text={<span style={{whiteSpace: 'nowrap'}}>Mahnung senden</span>}
                className='gray-btn'
                onClick={()=> this.props.history.push('/messaging/newMessage', {messageType: 'tenantRentMessage', tenantId: rentalPayment.tenantId, rentalPaymentId: rentalPayment.id})}
                />
              </TableCell>
            </TableRow>
          )
    }));
  };

  handleDateRangeChange = (newDate, type) => {
    const {selectedTenant} =  this.state;

    let newdateRangeOptions = this.state.dateRangeOptions.map((range, index)=> {
        if(index === 2){
          if(type === 'start'){
            range.date = {
              ...range.date,
              start: newDate
            };
            range = {
              ...range,
              label: selectedTenant !== 'all' && `Benutzerdefiniert` //: `Benutzerdefiniert (${dayjs(newDate).format('MMMM YYYY')})` // (${dayjs(newDate).format('MMMM YYYY')} - ${dayjs(range.date.end).format('MMMM YYYY')}) : 
            }
          }else if(type === 'end'){
            range.date = {
              ...range.date,
              end: new Date(moment(newDate).endOf('M'))
            };
            range = {
              ...range,
              label: selectedTenant !== 'all' ? `Benutzerdefiniert` : range.label //(${dayjs(range.date.start).format('MMMM YYYY')} - ${dayjs(newDate).format('MMMM YYYY')})
            }
          }
        return range;
        }else{
        return range;
        }
    });
    
    this.setState({ dateRangeOptions: newdateRangeOptions });
  };

  updateAllCheckBox=()=> {
    const {rentalPayments, allCheckBox, selectedTenant} = this.state;
    console.log('updateAllCheckBox: ', rentalPayments, selectedTenant);
    if(rentalPayments.length > 0){
      let newRentalPayments= rentalPayments.map((rp)=> {
        if(selectedTenant.find((tenantId)=> rp.tenantId === tenantId) !== undefined){
          rp.receivedRent= allCheckBox ? 0 : rp.expectedRent;
          return rp;
        }else{
          return rp;
        }
      });
  
      this.setState({rentalPayments: newRentalPayments}, async()=> {
        await newRentalPayments.map(async(rp)=> await this.updateRentalPayment(rp.id, rp.receivedRent));
        this.setState({
          allCheckBox: newRentalPayments.filter((rp)=> rp.receivedRent >= rp.expectedRent).length === rentalPayments.length, 
          openUpdateAllTenantRentalReceivedRentModal: false
        });
      });
    }
  }

  downloadExcelTable = () => {
    const { rentalPayments, selectedTenant, order, orderBy } = this.state;
    let firstRow = `Mieteingang ${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}, ${this.props.propertySelected?.propertyZipCode} ${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name} (${moment().format('DD.MM.YYYY')})`;
    

    var aoa = [
      [firstRow, "", "", "", ""],
      ["", "", "", "", ""],
      ['Mieter', 'Mieteinheit', 'Monat', 'Vertragsmiete (EUR)', 'Zahlungseingang (EUR)']
    ];

    let filteredList = rentalPayments.filter((rentalPayment)=> selectedTenant.find((tenantId)=> tenantId === rentalPayment.tenantId) !== undefined);
    filteredList.sort((a,b)=> orderBy === 'Mieter' ? (order === 'asc' ?  a.tenant?.fullName.toLowerCase().localeCompare(b.tenant?.fullName.toLowerCase()) : b.tenant?.fullName.toLowerCase().localeCompare(a.tenant?.fullName.toLowerCase())) 
    : orderBy === 'Mieteinheit' ? (order === 'asc' ? a.tenant.rentalUnitName.toLowerCase().localeCompare(b.tenant.rentalUnitName.toLowerCase()) : b.tenant.rentalUnitName.toLowerCase().localeCompare(a.tenant.rentalUnitName.toLowerCase())) 
    : orderBy === 'Monat' ? (order === 'asc' ? dayjs(a.rentalMonth) - dayjs(b.rentalMonth) : dayjs(b.rentalMonth) - dayjs(a.rentalMonth))
    : orderBy === 'Vertragsmiete' ? (order === 'asc' ? a.expectedRent - b.expectedRent : b.expectedRent - a.expectedRent)
    : orderBy === 'Zahlungseingang' && (order === 'asc' ? a.receivedRent - b.receivedRent : b.receivedRent - a.receivedRent))
    .map((rentalPayment)=>
    aoa.push([ 
      rentalPayment.tenant?.fullName, 
      rentalPayment.tenant?.rentalUnitName,
      dayjs(rentalPayment.rentalMonth).format('MMM YYYY'),
      rentalPayment.expectedRent,
      rentalPayment.receivedRent
    ]));

    aoa.push([ 
      '', 
      '',
      'Gesamt',
      rentalPayments.sort((a,b)=> orderBy === 'Mieter' ? (order === 'asc' ?  a.tenant?.fullName.toLowerCase().localeCompare(b.tenant?.fullName.toLowerCase()) : b.tenant?.fullName.toLowerCase().localeCompare(a.tenant?.fullName.toLowerCase())) 
    : orderBy === 'Mieteinheit' ? (order === 'asc' ? a.tenant.rentalUnitName.toLowerCase().localeCompare(b.tenant.rentalUnitName.toLowerCase()) : b.tenant.rentalUnitName.toLowerCase().localeCompare(a.tenant.rentalUnitName.toLowerCase())) 
    : orderBy === 'Monat' ? (order === 'asc' ? dayjs(a.rentalMonth) - dayjs(b.rentalMonth) : dayjs(b.rentalMonth) - dayjs(a.rentalMonth))
    : orderBy === 'Vertragsmiete' ? (order === 'asc' ? a.expectedRent - b.expectedRent : b.expectedRent - a.expectedRent)
    : orderBy === 'Zahlungseingang' && (order === 'asc' ? a.receivedRent - b.receivedRent : b.receivedRent - a.receivedRent))
    .filter((rentalPayment)=> selectedTenant.find((tenantId)=> tenantId === rentalPayment.tenantId) !== undefined)
    .reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.expectedRent ? a.expectedRent : null), 0),
    rentalPayments.sort((a,b)=> orderBy === 'Mieter' ? (order === 'asc' ?  a.tenant?.fullName.toLowerCase().localeCompare(b.tenant?.fullName.toLowerCase()) : b.tenant?.fullName.toLowerCase().localeCompare(a.tenant?.fullName.toLowerCase())) 
    : orderBy === 'Mieteinheit' ? (order === 'asc' ? a.tenant.rentalUnitName.toLowerCase().localeCompare(b.tenant.rentalUnitName.toLowerCase()) : b.tenant.rentalUnitName.toLowerCase().localeCompare(a.tenant.rentalUnitName.toLowerCase())) 
    : orderBy === 'Monat' ? (order === 'asc' ? dayjs(a.rentalMonth) - dayjs(b.rentalMonth) : dayjs(b.rentalMonth) - dayjs(a.rentalMonth))
    : orderBy === 'Vertragsmiete' ? (order === 'asc' ? a.expectedRent - b.expectedRent : b.expectedRent - a.expectedRent)
    : orderBy === 'Zahlungseingang' && (order === 'asc' ? a.receivedRent - b.receivedRent : b.receivedRent - a.receivedRent))
    .filter((rentalPayment)=> selectedTenant.find((tenantId)=> tenantId === rentalPayment.tenantId) !== undefined)
    .reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.receivedRent ? a.receivedRent : null), 0)  
    ])

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = ["D", "E"];
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:20}
    ];
    worksheet['!cols'] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Mieteingang");
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);
  }

  render() {
    const {isMobile, isTablet, dateRangeOptions, selectedDateRange, openDateRangeModal, selectedDateRangeForModal, selectedTenant, tenantOptions, openTenantSelectionModal, selectedTenantForModal, searchTenant, openUpdateTenantRentModal, selectedRentModalData, openWebFormModal, rentalPayments, fetchLoader, openUpdateAllTenantRentalReceivedRentModal, exportRentalIncomePopoverOpen, exportRentalIncomeTablePopoverOpen, rentalUnits, order, orderBy} = this.state;
    const {currentSubscription, accountData, propertySelected, loadingTenantsDataValue} = this.props;

    console.log('selectedTenant: ', selectedTenant);
    return (
      <Stack>
        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                <Group noWrap spacing={0}>
                    <Group spacing={5}>
                        <span style={{color: '#454576', marginTop: '-2px'}}>Exportieren: </span>
                        <Popover
                        opened={exportRentalIncomeTablePopoverOpen}
                        width={'auto'}
                        position="bottom"
                        withArrow
                        onClose={() => this.setState({exportRentalIncomeTablePopoverOpen: false})}
                        target={
                        <Tooltip title={'Exportieren'}>
                          <IconButton 
                            aria-label="filter list"
                            disabled={rentalUnits.length === 0}
                            onClick={()=> this.setState({exportRentalIncomeTablePopoverOpen: true})} 
                            style={{
                              background: 'white',
                              border: '1px solid #DADAE5', 
                              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                              padding: '5px',
                              cursor: 'pointer',
                              marginRight: '10px',
                              opacity: rentalUnits.length === 0 ? 0.5 : 1
                              }}>
                              <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>
                          </IconButton>
                        </Tooltip>
                        }
                        styles={{
                          inner: { padding: '5px'},
                        }}>
                          <Stack spacing={0}>
                            <Group 
                            style={{cursor: 'pointer'}}
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> rentalUnits.length > 0 && this.downloadExcelTable()}
                            >
                              <img src={XlsxIcon} width={'20px'} />
                              <span>Excel exportieren</span>
                            </Group>
                            <Group 
                            style={{cursor: 'pointer'}} 
                            sx={() => ({
                              padding: '10px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: '#E3E3FC',
                              }
                            })}
                            onClick={()=> window.open(`/rentalPaymentsPdfExport?selectedTenant=${encodeURIComponent(JSON.stringify(selectedTenant))}&selectedDateRange=${encodeURIComponent(JSON.stringify({start: dayjs(dateRangeOptions.find((dateRange)=> dateRange.value === selectedDateRange)?.date?.start), end: dayjs(dateRangeOptions.find((dateRange)=> dateRange.value === selectedDateRange)?.date?.end)}))}&orderBy=${encodeURIComponent(JSON.stringify(orderBy))}&order=${encodeURIComponent(JSON.stringify(order))}`, '_blank')}
                            >
                              <img src={PdfIcon} width={'20px'} />
                              <span>PDF exportieren</span>
                            </Group>
                          </Stack>
                        </Popover>
                    </Group>
                </Group>  
        </div>
        <div style={{marginTop: 0}} className='divider'/>
        <Box sx={{ height: '100%', position: 'relative', marginTop: currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && '40px'}}>
            {(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER') &&
            <Stack align={'center'} style={{ zIndex: 50, position: 'absolute', left: 0, right: 0, marginRight: 'auto', marginLeft: 'auto', marginTop: '200px'}}>
              <Stack spacing={0}>
                <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mieteingänge einfach erfassen</span>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing Premium.</span>
              </Stack>
              <Group>
                <div>
                  <ColorButton
                  text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                  className='purple-btn'
                  onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                  />
                </div>
              </Group>
              <Group style={{width: '460px'}}>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Mieteingänge auf Knopfdruck einfach abgleichen und Mietrückstände direkt aus hellohousing mahnen.</span>
              </Group>
              <Group>
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', '9421578')}>Mieteingangskontrolle kennenlernen</span>
              </Group>
            </Stack>}
          {(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER') && 
          <Overlay opacity={0.5} blur={4} zIndex={5} color={'#F7F7FA'}/>}

          <Stack id='owner-document'>
            <Group position='apart'>
              <Group spacing={15} className={'tenant-rental-income-filters-container'} noWrap style={{overflowX: isMobile && 'scroll', padding: '2px 0px'}}>
                <Popover
                opened={openTenantSelectionModal}
                onClose={() => this.setState({openTenantSelectionModal: false})}
                target={
                  <Group className='selected-card-filter' spacing={7} onClick={()=> this.setState({openTenantSelectionModal: true})}>
                    <span style={{whiteSpace: 'nowrap'}}>{`Mieter: ${selectedTenantForModal.length > 0 ? (selectedTenantForModal.length === tenantOptions.length ? 'Alle' : selectedTenantForModal.length === 1 ? tenantOptions.find((tenant)=> (tenant.value === selectedTenantForModal[0]))?.label : selectedTenantForModal.length > 1 ? 'Mehrere ausgewählt' : 'Nicht ausgewählt') : 'Nicht ausgewählt'}`}</span>
                    {/* <img src={PenIcon}/> */}
                  </Group>
                }
                position='bottom'
                placement='start'
                withArrow={false}
                title={'Mieter auswählen'}
                styles={{
                  title: {
                    fontSize: '14px'
                  }
                }}
                >
                  <Stack spacing={20}>
                    <div>
                      <TextInput 
                      className="meter-readings-searchbar" 
                      placeholder="Suche nach Mieter oder Einheit ..." 
                      value={searchTenant} 
                      icon={<Icon name="search" />} 
                      onChange={(event) => this.setState({searchTenant: event.target.value})} />
                    </div>
                    <Stack spacing={10} className={'tenant-list-scrollable'} style={{maxHeight: '400px', overflowY: 'scroll'}}>
                      {/* <RadioGroup
                        orientation="vertical"
                        value={selectedTenantForModal}
                        onChange={(value)=> this.setState({selectedTenantForModal: value})}
                        required
                      >
                        {tenantOptions.filter((tenant)=> searchTenant !== null ? (tenant.name.toString().toLowerCase().includes(searchTenant.toString().toLowerCase()) || tenant.rentalUnit.toString().toLowerCase().includes(searchTenant.toString().toLowerCase())) : tenant).map((tenant)=> 
                          <Radio value={tenant.value} label={tenant.label} />
                        )}

                      </RadioGroup> */}
                      {(searchTenant === null || searchTenant === '' || searchTenant === undefined) &&
                      <Checkbox 
                      classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                      value="all" 
                      label="Alle" 
                      styles={{
                        label: {
                          fontSize: '14px',
                          marginBottom: 0
                        }
                      }}
                      indeterminate={selectedTenantForModal.length > 0 && selectedTenantForModal.length !== tenantOptions.length} 
                      checked={selectedTenantForModal.length === tenantOptions.length}
                      onChange={(_)=> this.setState({selectedTenantForModal: selectedTenantForModal.length === tenantOptions.length ? [] : tenantOptions.map((tenant)=> tenant.value)})}/>}
                      <CheckboxGroup
                      //defaultValue={selectedTenantForModal}
                      value={selectedTenantForModal}
                      orientation="vertical"
                      onChange={(value)=> this.setState({selectedTenantForModal: value})}
                      classNames={{
                        input: 'tenants-list-checkbox-rental-income-input', 
                        inner: 'tenants-list-checkbox-rental-income-inner'
                      }}
                      styles={{
                        label: {
                          fontSize: '14px',
                          marginBottom: 0
                        }
                      }}
                      >
                        {tenantOptions.filter((tenant)=> searchTenant !== null ? (tenant.name.toString().toLowerCase().includes(searchTenant.toString().toLowerCase()) || tenant.rentalUnit.toString().toLowerCase().includes(searchTenant.toString().toLowerCase())) : tenant).map((tenant)=> 
                          <Checkbox 
                          value={tenant.value} 
                          label={tenant.label} />
                        )}
                      </CheckboxGroup>
                    </Stack>
                    
                    <Group position='apart'>
                        <div>
                          <ColorButton
                          text={'Abbrechen'}
                          className={'gray-btn'}
                          onClick={()=> this.setState({openTenantSelectionModal: false, selectedTenantForModal: selectedTenant})}
                          />
                        </div>
                        <div>
                          <ColorButton
                          text={'Anwenden'}
                          className={'purple-btn'}
                          onClick={()=> {
                            if(selectedTenantForModal.length === 0){
                              showNotification({
                                message: 'please select atleast on tenant.',
                                icon: <img src={RedCross} />
                              });
                            } else{
                              this.setState({
                                openTenantSelectionModal: false, 
                                selectedTenant: selectedTenantForModal
                                // dateRangeOptions: selectedTenantForModal.length !== tenantOptions.length ? 
                                // (dateRangeOptions[0].label !== dateRangeForSpecificTenant[0].label ? dateRangeForSpecificTenant : dateRangeOptions) 
                                // : 
                                // (dateRangeOptions[0].label !== dateRangeForAllTenants[0].label ? dateRangeForAllTenants : dateRangeOptions)
                              });
                            }
                          }}
                          />
                        </div>
                    </Group>
                  </Stack>
                </Popover>


                <Popover
                opened={openDateRangeModal}
                onClose={() => this.setState({openDateRangeModal: false})}
                target={
                  <Group className='selected-card-filter' spacing={7} onClick={()=> this.setState({openDateRangeModal: true})}>
                    <span style={{whiteSpace: 'nowrap'}}>{`Zeitraum: ${dateRangeOptions.find((dateRange)=> dateRange.value === selectedDateRange)?.label}`}</span>
                    {/* <img src={PenIcon}/> */}
                  </Group>
                }
                position='bottom'
                placement='start'
                withArrow={false}
                title={'Zeitraum auswählen'}
                styles={{
                  title: {
                    fontSize: '14px'
                  }
                }}
                >
                  <Stack spacing={30}>
                    <Stack spacing={20}>
                      <RadioGroup
                        orientation="vertical"
                        value={selectedDateRangeForModal}
                        onChange={(value)=> this.setState({selectedDateRangeForModal: value})}
                        required
                      >
                        {dateRangeOptions.map((dateRange)=> 
                          <Radio value={dateRange.value} label={dateRange.label} />
                        )}

                      </RadioGroup>
                      
                      <Group position={isMobile ? 'left' : 'apart'} noWrap={!isMobile} className='rental-income-export-date-range'>
                          <DatePicker
                          id='rental-income-export-date-range-picker'
                          className='form-control'
                          selected={dateRangeOptions[2].date.start}
                          onChange={(date)=> this.handleDateRangeChange(date, 'start')}
                          startDate={dateRangeOptions[2].date.start}
                          selectsStart
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          popperPlacement='bottom'
                          locale={'de'}
                          maxDate={dateRangeOptions[2].date.end}
                          disabled={selectedDateRangeForModal !== 'custom'}
                          />
                          {/* {selectedTenant.length !== tenantOptions.length &&
                            <> */}
                          -
                          <DatePicker
                          id='rental-income-export-date-range-picker'
                          className='form-control'
                          selected={dateRangeOptions[2].date.end}
                          onChange={(date)=> this.handleDateRangeChange(date, 'end')}
                          startDate={dateRangeOptions[2].date.end}
                          selectsStart
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          popperPlacement='bottom'
                          locale={'de'}
                          minDate={dateRangeOptions[2].date.start}
                          disabled={selectedDateRangeForModal !== 'custom'}
                          />
                            {/* </>
                          } */}
                      </Group>
                    </Stack>
                    
                    <Group position='apart'>
                        <div>
                          <ColorButton
                          text={'Abbrechen'}
                          className={'gray-btn'}
                          onClick={()=> this.setState({openDateRangeModal: false, selectedDateRangeForModal: selectedDateRange})}
                          />
                        </div>
                        <div>
                          <ColorButton
                          text={'Anwenden'}
                          className={'purple-btn'}
                          onClick={()=> this.setState({openDateRangeModal: false, selectedDateRange: selectedDateRangeForModal, offset: 0, stopOffsetChange: false, rentalPayments: [], fetchLoader: true}, ()=> this.getRentalPayments())}
                          />
                        </div>
                    </Group>
                  </Stack>
                </Popover>
              </Group>
            </Group>

            <Group style={{width: '100%'}}>
              {(fetchLoader) ? 
              <>
                <Skeleton visible={fetchLoader} style={{width: '100%', height: '16px', marginTop: '20px', zIndex: '1'}}/>
                {[...Array(5)].map((e, i) => <Skeleton visible={fetchLoader} style={{height: '75px', zIndex: '1'}}/>)}
              </>
              :
              <TableContainer>
                <Table className='rental-income-list-table'>
                  <TableHead>
                    {this.getTableHeader()}
                  </TableHead>
                  <TableBody>
                    {rentalPayments?.length > 0 ? 
                      this.getTableBody()
                      :
                      <TableRow>
                        <TableCell colSpan={6}>
                          <EmptyStateContainer
                          heading={'Keine Informationen zu Mieteingängen verfügbar.'}
                          subheading={'Nachdem Sie Mietverhältnisse angelegt haben, können Sie hier die Mieteingänge nachverfolgen.'}
                          showBtn={false}
                          icon={Avatar}
                          styleMainContainer={{wudth: '100%'}}
                          />
                        </TableCell>
                      </TableRow>
                      }
                  </TableBody>
                </Table>
              </TableContainer>}
            </Group>
            {openUpdateTenantRentModal && 
            <UpdateTenantRentModal
            open={openUpdateTenantRentModal}
            selectedRentModalData={selectedRentModalData}
            rentalPaymentsForModal={rentalPayments}
            toggle={()=> this.setState({openUpdateTenantRentModal: false, selectedRentModalData: null})}
            saveRentValues={(newRentalPayments, recievedRent, rentalPaymentId)=> this.setState({rentalPayments: newRentalPayments, openUpdateTenantRentModal: false, selectedRentModalData: null}, ()=> this.updateRentalPayment(rentalPaymentId, recievedRent))}
            />}
            {openWebFormModal &&
            <FinApiWebFormModal
            open={openWebFormModal}
            toggle={()=> this.setState({openWebFormModal: false})}
            />}

            {openUpdateAllTenantRentalReceivedRentModal &&
            <UpdateAllTenantRentalReceivedRentModal
            open={openUpdateAllTenantRentalReceivedRentModal}
            toggle={()=> this.setState({openUpdateAllTenantRentalReceivedRentModal: false})}
            update={()=> this.updateAllCheckBox()}
            />}
          </Stack>
      </Box>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    loadingTenantsDataValue: state.tenantDataReducer.loadingTenantsData
  };
};

const actions = {
  changeCurrentProperty,
  setCurrentTenant
}

export default connect(mapStateToProps, actions)(TenantRentalIncomeTracking);
