import React, { Component } from 'react';
import UploadIcon from '../../../../../assets/images/module/general/UploadIcon.svg';
import PdfIcon from '../../../../../assets/images/module/general/PdfIcon.svg';
import ImgIcon from '../../../../../assets/images/module/general/ImgIcon.svg';
import TrashIcon from '../../../../../assets/images/module/general/TrashIcon.svg';
import DocumentIcon from '../../../../../assets/images/module/general/DocumentIcon.svg';
import DocumentIconBlue from '../../../../../assets/images/module/general/DocumentIconBlue.svg';
import Dropzone from 'react-dropzone';
import Autoload from '../../../../Autoload';
import { ValueText } from '../../../../index';
import { Dropdown } from 'reactstrap';
import {DeleteModal} from '../../../../index';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkEmpty } = Autoload.MainHelper;

class DropFile extends Component {
  state={
    deleteModal: false,
    selectedDeleteFile: null
  }
  showSelectedFile = (file, removeFile) => {
    if (file && file.length > 0) {
      if (file.length > 0) {
        return (
          <div className="file-details">
            <ValueText
              leftImage={{ img: this.props.fileType === 'img' ? ImgIcon : PdfIcon, style: { width: '16px', height: '16px' }, onClick: ()=> this.props.onClick()}}
              text={
                <div style={{cursor: 'pointer', wordBreak: 'break-all'}} onClick={()=> this.props.onClick()}>
                  {typeof file !== 'string' ? file[0].path ? file[0].path : file[0].name : this.props.filename}
                </div>
                // typeof file !== 'string' ?
                // file[0].path : file.split('\\').pop().split('/').pop()
              }
              rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px' }, handler: removeFile }}
            />
          </div>
        );
      } else {
        return (
          <div className="file-details">
            <ValueText className="file-selection-err" text="Invalid file selection" />
          </div>
        );
      }
    }
  };
  showMultiFiles = (files, removeFile) => {
    console.log('show multi files', files)
    let width = document?.getElementById('file-details')?.offsetWidth;
    if (files && files.length > 0) {
      return files.map((file, i) => {
        // console.log('loop', file.path)
        if(this.props.downloadable && file.fileLocation){
          return (
            <div id='file-details' className="file-details">
              
              <ValueText
                leftImage={{ img: file && (file.path.includes('jpg') || file.path.includes('png') || file.path.includes('jpeg') || file.path.includes('svg') || file.path.includes('gif')) ? ImgIcon : file.path.includes('pdf') ? PdfIcon : DocumentIconBlue, style: { width: '16px', height: '16px' }}}
                text={
                  <a href={file.fileLocation} target='_blank' download style={{textDecoration:'none', color: '#111147'}}>
                  {typeof file !== 'string' ? file.path : this.props.filename}
                  </a>
                  // typeof file !== 'string' ?
                  // file[0].path : file.split('\\').pop().split('/').pop()
                }
                //textHandler={()=> <a href={file.fileLocation} target='_blank' download style={{textDecoration:'none'}}/>}
                textStyle={{
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'Inter, sans-serif',
                  textAlign: 'left',
                  marginRight: '8px',
                  width: width ? (width - 60) : 'auto'
                }}
                rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px' }, handler: () => this.setState({selectedDeleteFile: file, deleteModal: true}) }}
              />
              {/* </a> */}
            </div>
          );
        }else{
          return (
            <div id='file-details' className="file-details">
              <ValueText
                leftImage={{ img: file && (file.path.includes('jpg') || file.path.includes('png') || file.path.includes('jpeg') || file.path.includes('svg') || file.path.includes('gif')) ? ImgIcon : file.path.includes('pdf') ? PdfIcon : DocumentIconBlue, style: { width: '16px', height: '16px' } }}
                text={
                  typeof file !== 'string' ? file.path : this.props.filename
                  // typeof file !== 'string' ?
                  // file[0].path : file.split('\\').pop().split('/').pop()
                }
                textStyle={{
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'Inter, sans-serif',
                  textAlign: 'left',
                  marginRight: '8px',
                  width: width ? (width - 60) : 'auto'
                }}
                rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px' }, handler: () => removeFile(file) }}
              />
            </div>
          );
        }
      });
    }
    // else {
    //   return (
    //     <div className="file-details">
    //       <ValueText className="file-selection-err" text="Invalid file selection" />
    //     </div>
    //   );
    // }
  };

  toggleDeleteAccountModal=()=>{
    this.setState({deleteModal: !this.state.deleteModal});
  }
  render() {
    const { maxSize, accept, handleDrop, file, removeFile, filename, customProps, multiSelect = false, downloadable=false, csvFormat=false, receiptModal=false, hideSecondText=false, secondText=null, wrapperStyle, innerWrapperStyle } = this.props;
    console.log(!multiSelect && file && file.length>0 , 'modal props')
    console.log('file: ', file);
    console.log('accept: ', accept);
    return (
      <div style={wrapperStyle}>
        {receiptModal ? (file === null || file.length <= 0) &&
        <div
          className="file-dropzone"
          style={{...innerWrapperStyle, ...{
            cursor: !multiSelect || file || file.length > 0 ? 'default' : 'pointer',
          }}}
        >
          <Dropzone
            maxSize={maxSize}
            accept={accept ? accept : null}
            onDrop={(acceptedFiles, rejectedFiles) => handleDrop(acceptedFiles, rejectedFiles)}
            {...customProps}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={`drag-div  ${!checkEmpty(file) && 'fileSelected'}`} {...getRootProps()}>
                  {!multiSelect && file && file.length>0 ? <input {...getInputProps()} disabled /> : <input {...getInputProps()} />}
                  <img src={UploadIcon} alt="upload" width="16px" height="16px" />
                  <p className="first"> {lang.receipt.text_drag_file} </p>
                  {!hideSecondText && <p className="second"> {this.props.csvFormat ? lang.receipt.text_add_file_restrictions_csv : secondText ? secondText : lang.receipt.text_add_file_restrictions} </p>}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        :
        <div
          className="file-dropzone"
          style={{...innerWrapperStyle, ...{
            cursor: !multiSelect || file || file.length > 0 ? 'default' : 'pointer',
          }}}
        >
          <Dropzone
            maxSize={maxSize}
            accept={accept ? accept : null}
            onDrop={(acceptedFiles, rejectedFiles) => handleDrop(acceptedFiles, rejectedFiles)}
            {...customProps}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={`drag-div  ${!checkEmpty(file) && 'fileSelected'}`} {...getRootProps()}>
                  {!multiSelect && file && file.length>0 ? <input {...getInputProps()} disabled /> : <input {...getInputProps()} />}
                  <img src={UploadIcon} alt="upload" width="16px" height="16px" />
                  <p className="first"> {lang.receipt.text_drag_file} </p>
                  {!hideSecondText && <p className="second"> {this.props.csvFormat ? lang.receipt.text_add_file_restrictions_csv : lang.receipt.text_add_file_restrictions} </p>}
                </div>
              </section>
            )}
          </Dropzone>
        </div>}
        {multiSelect ? this.showMultiFiles(file, removeFile) : this.showSelectedFile(file, removeFile)}
        <DeleteModal
          open={this.state.deleteModal}
          className="delete-rent-modal"
          toggle={this.toggleDeleteAccountModal}
          //record={this.state.accountID}
          handleDelete={() => {removeFile(this.state.selectedDeleteFile); this.toggleDeleteAccountModal();}}
          // showInfo={[this.props.propertyData.name,]}
          type={'Nutzerprofil'}
          showtext={false}
          typeText={
            'Sind Sie sicher, dass Sie dieses Dokument löschen möchten?'
          }
          modalId="delete-data-property"
          showDeleteText={false}
          textShowModal='Dokument löschen'
        />
      </div>
    );
  }
}

DropFile.defaultProps = {
  maxSize: 5242880,
  accept: 'image/jpeg,application/pdf',
  handleDrop: () => {},
  file: null,
  downloadable:false
};

export default DropFile;
