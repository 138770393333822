import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Accordion, Loader } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton, SwitchContainer } from '../../../../neoverv';
import TemperatureNotificationModal from '../../../../neoverv/components/Module/General/CustomModal/TemperatureNotificationModal';
import TilesForTenantPortal from './TilesForTenantPortal';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseButtonIcon from '../../../../assets/images/module/general/CloseButtonIcon.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 0px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    // fontWeight: '500', 
    // lineHeight: '16px',
    // fontFamily: 'Inter, sans-serif',
    // whiteSpace: 'nowrap',
    // width: '200px',
    // maxWidth: '200px',
    // textAlign: 'left'
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: '0px',
    fontSize: '12px',
    fontWeight: '400',
    color:'#727293',
    width: '80px',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}
export class TenantAdministrateTilesSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minimize: true,
            tiles: [
                {
                    html: "<p>test body 1</p>",
                    id: 0,
                    title: null,
                    propertyId: '0000',
                    orderNumber: 1,
                    isHidden: false,

                },
                {
                    html: "<p>test body 2</p>",
                    id: 1,
                    title: null,
                    propertyId: '0000',
                    orderNumber: 2,
                    isHidden: false,

                },
                {
                    html: "<p>test body 3</p>",
                    id: 2,
                    title: null,
                    propertyId: '0000',
                    orderNumber: 3,
                    isHidden: false,

                },
                {
                    id: 'add',
                    html: null,
                    orderNumber: 4,
                    title: 'Add',
                }
                // {
                //     html: null,
                //     id: 1,
                //     title: null,
                // },
                // {
                //     html: null,
                //     id: 2,
                //     title: null,
                // }
            ],
            loader: false,//[]
            dragStart: false
        };
        this.dragTile = React.createRef();
        this.dragTileOver = React.createRef();
    }

    addTile=()=>{
        let {tiles} = this.state;
        tiles.pop();
        tiles.push({
            html: `<p>test body ${tiles.length > 0 ? (tiles[tiles.length - 1].orderNumber + 1) : 1}</p>`,
            id: this.assignTemp(tiles.map((tile)=> tile.id)),
            title: `Test title ${tiles.length > 0 ? (tiles[tiles.length - 1].orderNumber + 1) : 1}`,
            propertyId: '0000',
            orderNumber: tiles.length > 0 ? (tiles[tiles.length - 1].orderNumber + 1) : 1,
            isHidden: false,
        });
        tiles.push({
            id: 'add',
            html: null,
            //orderNumber: tiles.length > 0 ? (tiles[tiles.length - 1].orderNumber + 1) : 1,
            title: 'Add',
        })
        this.setState({tiles});
    }

    randomRecep=()=>{
        return Math.floor((Math.random() * 3));
       }
       
    assignTemp=(tempArr)=>{
        const number = this.randomRecep();
        if (tempArr.includes( number ) ) {
        console.warn( `${number} exists in [${tempArr.join(', ')}]` );
        return this.assignTemp(tempArr);
        }
        console.warn( `adding ${number} to [${tempArr.join(', ')}]` );
        tempArr.push( number );
        return tempArr;
    }

    updateTitle=(id, title)=>{
        let {tiles} = this.state;
        tiles = tiles.map((tile)=> {
            if(tile.id === id){
                tile.title = title;
                return tile;
            }else{
                return tile;
            }
        });
        this.setState({tiles});
    }

    updateHtml=(id, html)=>{
        let {tiles} = this.state;
        tiles = tiles.map((tile)=> {
            if(tile.id === id){
                tile.html = html;
                return tile;
            }else{
                return tile;
            }
        });
        this.setState({tiles});
    }

    deleteTile=(id)=>{
        let {tiles} = this.state;
        tiles = tiles.filter((tile)=> tile.id !== id);
        this.setState({tiles});
    }

    handleSort=()=> {
        let {tiles} = this.state;
        this.setState({loader: true,//[tiles[this.dragTile.current].id, tiles[this.dragTileOver.current].id], 
            dragStart: false},()=>{
            const temp = tiles[this.dragTile.current];
            tiles[this.dragTile.current] = tiles[this.dragTileOver.current];
            tiles[this.dragTileOver.current] = temp;
            
            this.setState({tiles}, ()=> 
            setTimeout(() => {
                tiles = tiles.map((tile, index)=> {
                    tile.orderNumber = (index+1)
                    return tile;
                });
                this.setState({loader: false, tiles});
            }, 500));
        });
      }
  
    render() {
        const {minimize, tiles, loader, dragStart} = this.state;

        return (
            <div 
            style={{
            width: '100%',
            background: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,.08)',
            borderRadius: '4px'
            }}>
                <Accordion
                classNames={{
                    label: 'tenant-administration-accordion-label',
                    contentInner: 'tenant-administration-accordion-inner-content',
                    control: 'tenant-administration-accordion-control',
                    icon: 'tenant-administration-accordion-icon',
                }}

                styles={{
                    content: {
                        display: false && 'none',
                        boxShadow: false && 'none',
                    }
                }}
                iconPosition='right'>
                    <Accordion.Item 
                    label={
                    <Group
                    position='apart'
                    style={{
                        padding: '12.1px 24px',
                        borderBottom: true && minimize === false ? '1px solid #dadae6' : 'none',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        borderBottomLeftRadius: minimize && '4px',
                        borderBottomRightRadius: minimize && '4px',
                        background: 'white',
                        cursor: 'pointer'
                    }}
                    onClick={()=> this.setState({minimize: !minimize})}>
                        <Group className='tenant-administration-tiles-section'>
                            <h5 
                            style={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#0e0e1d',
                                marginBottom: '0px'
                            }}
                            className={'tenant-administration-display-messages-heading tooltips'}>
                                Aushänge
                            </h5>
                            <SwitchContainer
                            checked={true}
                            switchText={'aktivieren'}
                            disabled={false}
                            onChange={()=> null}
                            />
                        </Group>
                        {true && (!minimize ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />)}
                    </Group>}>
                        <div style={{padding: '12.1px 24px 30px'}}>
                            <Stack>
                                <Group style={{width: '100%'}} position='left'>
                                    {tiles.map((tile, index)=>
                                    tile.id === 'add' ?
                                    <Group 
                                    style={{
                                        background: '#F7F7FA', 
                                        padding: '40px',
                                        boxShadow: '0 1px 3px rgba(0,0,0,.08)',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        //width: '433px',
                                        height: (dragStart || loader) ? '150px' : '500px',
                                        //transition: 'height 0.02s',
                                        width: '49%',
                                        opacity: dragStart ? 0.6 : 1
                                    }}
                                    position='center'
                                    align={'center'}
                                    onClick={()=> this.addTile()}>
                                        {loader ?//loader.find((tileId)=> tileId === id) !== undefined ?
                                        <Group position='center' align={'center'}>
                                            <Loader/>
                                        </Group>
                                        :
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#727293'
                                        }}>Hinzufügen</span>}
                                    </Group>
                                    :
                                    <Group
                                    draggable
                                    onDragStart={() => {
                                        this.dragTile.current = index;
                                        this.setState({dragStart: true});
                                    }}
                                    onDragEnter={() => (this.dragTileOver.current = index)}
                                    onDragEnd={this.handleSort}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{
                                        background: '#F7F7FA',
                                        boxShadow: '0 1px 3px rgba(0,0,0,.08)',
                                        borderRadius: '4px',
                                        width: '49%',
                                        padding: '20px',
                                        height: (dragStart || loader) ? '150px' : '500px', // loader.find((tileId)=> tileId === tile.id) !== undefined
                                        //transition: 'height 0.02s',
                                    }}
                                    >
                                        <Group noWrap style={{width: '100%'}}>
                                            <DragIndicatorIcon
                                            style={{
                                                width: '16px', 
                                                color: '#727293', 
                                                cursor: dragStart ? 'grabbing' : 'grab',
                                                marginTop: '4px'
                                            }}
                                            />
                                            {!loader && !dragStart && //loader.find((tileId)=> tileId === tile.id) === undefined
                                            <Group position='apart' style={{width: '100%'}}>
                                                <Group>
                                                    <SwitchContainer
                                                    checked={true}
                                                    switchText={'aktivieren'}
                                                    disabled={false}
                                                    onChange={()=> null}
                                                    />
                                                </Group>
                                                <Group>
                                                    <img src={CloseButtonIcon} style={{cursor: 'pointer', padding: '0px 10px', marginTop: '4px'}} onClick={()=> this.deleteTile(tile.id)}/>
                                                </Group>
                                            </Group>}                                        
                                        </Group>
                                        
                                        <TilesForTenantPortal
                                        html={tile.html}
                                        id={tile.id}
                                        title={tile.title}
                                        orderNumber={tile.orderNumber}
                                        loader={loader}
                                        dragStart={dragStart}
                                        index={index}
                                        updateTitle={(title)=> this.updateTitle(tile.id, title)}
                                        updateHtml={(html)=> this.updateHtml(tile.id, html)}
                                        deleteTile={()=> this.deleteTile(tile.id)}
                                        />
                                    </Group>
                                    )}
                                </Group>
                            </Stack>
                        </div>
                    </Accordion.Item>
                </Accordion>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantAdministrateTilesSection);
