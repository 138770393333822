import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Anchor, Breadcrumbs, Drawer, Group, Loader, Popover, Progress, Skeleton, Stack } from '@mantine/core';
import { Checkbox, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterListIcon from '@material-ui/icons/FilterList';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import RestoreIcon from '@material-ui/icons/Restore';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FileMoveIcon from '../../../../assets/images/module/general/FileMoveIcon.svg';
import FileMoveIconWhite from '../../../../assets/images/module/general/FileMoveIconWhite.svg';
import TenantSingleIconBlue from '../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import DocumentsIcon from '../../../../assets/images/module/general/DocumentsIconBlue.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import FileIcon from '../../../../assets/images/module/general/FileIcon.svg';
import PropertyIcon from '../../../../assets/images/module/general/PropertyIconBlue.svg';
import DocumentsIconsBlue from '../../../../assets/images/module/general/DocumentsIconsBlue.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import TenantPortalIcon from '../../../../assets/images/module/general/TenantPortalIcon.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ButtonGroup, Button } from 'react-bootstrap';
import { AddPropertyModal, ColorButton, DropFile, EmptyStateContainer, FiltersContainer, HeadingContainer, RecordOptions, SearchContainer, SwitchContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { withRouter } from 'react-router-dom';
import AddDocumentModal from '../../../../neoverv/components/Module/General/CustomModal/AddDocumentModal';
import AddFolderModal from '../../../../neoverv/components/Module/General/CustomModal/AddFolderModal';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import dayjs from 'dayjs';
import MoveCopyModalForDocuments from '../../../../neoverv/components/Module/General/CustomModal/MoveCopyModalForDocuments';
import ShareDocumentsModal from '../../../../neoverv/components/Module/General/CustomModal/ShareDocumentsModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { connect } from 'react-redux';
import axios from 'axios';
import APIClient from '../../../../neoverv/config/APIClient';
import { showNotification } from '@mantine/notifications';
import {List, Table as VirtualTable, Column, AutoSizer, MultiGrid} from 'react-virtualized';
import 'react-virtualized/styles.css';
import EditDocumentModal from '../../../../neoverv/components/Module/General/CustomModal/EditDocumentModal';
import DeleteDocumentModal from '../../../../neoverv/components/Module/General/CustomModal/DeleteDocumentModal';
import {
  setRefreshDocumentsData,
  setCurrentFolderId,
  setFiles,
  setRefreshTenantDocumentsData,
  setSharedFolder
} from '../../../../redux/action/General/generalActions';
import {
  setCurrentTenant
} from '../../../../redux/action/Tenant/TenantActions';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import { NumericFormat } from 'react-number-format';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { loader } = Autoload.MainHelper;
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
const myWorker = new Worker("worker.js");

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class DocumentsList extends Component {
  state = {
    addDocumentsModalOpen: false,
    addFolderModalOpen: false,
    moveCopyModalOpen: false,
    shareDocumentsModalOpen: false,
    moveCopyModalType: '',
    addFileFolderPopoverOpen: false,
    addFileFolderPopoverTopRightOpen: false,
    data: [],
    columns: ['Name', 'Geändert', 'Größe', 'showInTenantPortal'//'Geteilt'
    ],
    checkedItems: [],
    breadCrumbs: [],
    offset: 0,
    fetch: 10,
    once: false,
    openFilter: false,
    selectedDocumentType: '-',
    isSharedFilter: false,
    selectedMinSize: null,
    selectedMaxSize: null,
    skeletonLoading: false,
    fetchLoader: false,
    search: null,
    currentFolderId: null,
    currentFolderName: null,
    downloadProgress: 0,
    currentDownloadIndex: 0,
    sortHeaderColumn: null,
    sortType: false,
    editDocumentData: null,
    editDocumentModalOpen: false,
    deleteDocumentModalOpen: false,
    showDropZone: false,
    files: [],
    error: [],
    addPropertyModal: false,
    selectedPropertyType: null,
    addPropertyTypeModal: false,
    hideShareFolder: false,
    showTrashBin: false,
    showRentalUnitsFolder: false,
    rentalUnits: [],
    showTenantsFolders: false,
    selectedRentalUnitId: null,
    selectedTenantId: null,
    breadcrumbsSkeletonLoading: false,
    showTenantDocuments: false,
    tenantId: null,
    tenantData: null,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
  };

  constructor(props){
    super(props);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  componentDidMount(){
    if(this.props.tenantManagement){
      this.setState({showTenantDocuments: this.props.showTenantDocuments, tenantId: this.props.tenantId, tenantData: this.props.tenantData}, ()=> this.restComponentDidMountStuff())
    }else{
      this.restComponentDidMountStuff();
    }

    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
    
  };

  restComponentDidMountStuff=()=>{
    if(this.props.history.location.state && this.props.history.location.state.rentalUnits){
      let rentalUnits = this.props.history.location.state.rentalUnits;
      this.setState({rentalUnits}, ()=>{
        this.preOpeningOfFolders(rentalUnits);
      })
    }else{
      this.setState({breadcrumbsSkeletonLoading: true}, ()=> this.getRentalUnits());
    }
    
    this.props.propertySelected && this.addBreadCrumbs();
    this.props.propertySelected && this.setState({skeletonLoading: true}, ()=> this.getDocumentsByAccountId(this.props.showTenantDocuments ? true : (this.props.history.location.state && this.props.history.location.state.showRentalUnitsFolder) ? true : false));//this.getDocumentsByAccountId();
    !this.props.tenantManagement && this.props.history.replace('/documents');
  }

  componentDidUpdate(){
    const {currentFolderId} = this.state;
    if(this.props.refreshDocumentsData === true){
      this.getDocumentsByAccountId((currentFolderId === 0 || currentFolderId) ? true : false);
      //this.setState({skeletonLoading: true}, ()=> this.getDocumentsByAccountId(this.state.currentFolderId ? true : false));
      this.props.setRefreshDocumentsData(null);
    }
    console.log('refreshTenantDocumentsData', this.props.refreshTenantDocumentsData, this.props.refreshDocumentsData);
    if(this.props.refreshTenantDocumentsData === true){
      
      this.setState({showTenantDocuments: true, skeletonLoading: true}, ()=> {
        //this.preOpeningOfFolders(this.state.rentalUnits);
        this.getDocumentsByAccountId(true);
      });

      //this.getDocumentsByAccountId(this.props.showTenantDocuments ? true : (currentFolderId === 0 || currentFolderId) ? true : false);
      this.props.setRefreshTenantDocumentsData(null);
    }
  }

  componentWillUnmount() {
    //document.removeEventListener('scrollend', this.trackScrolling);
    window.removeEventListener('resize', this.handleResize);
  }

  preOpeningOfFolders=(rentalUnits)=>{
    if(!this.state.showTenantDocuments){
      const {showTenantsFolders=false, showRentalUnitsFolder=false, selectedRentalUnitId=null} = this.state;
      showRentalUnitsFolder && this.setState({showTenantsFolders, showRentalUnitsFolder, selectedRentalUnitId, currentFolderId: 2}, ()=> {
        showRentalUnitsFolder && this.openFolder(2, 'Mieterdokumente');
        showTenantsFolders && this.openFolder(3, rentalUnits?.find((rentalUnit)=> rentalUnit.id === selectedRentalUnitId)?.name);
        this.props.history.replace('/documents', {});
      });
    }

    if(this.props.tenantManagement){
      if(this.state.showTenantDocuments){
        this.openFolder(null, this.props.tenantManagement ? this.state.tenantData.name : this.state.tenantData.fullName);
        this.setState({showTenantsFolders: false, showRentalUnitsFolder: false});
      }
    }else{
      if(this.state.showTenantDocuments){
        this.openFolder(2, 'Mieterdokumente');
        this.openFolder(3, rentalUnits.find((rentalUnit)=> rentalUnit.id === this.state.tenantData.rentalUnitId)?.name);
        this.openFolder(null, this.props.tenantManagement ? this.state.tenantData.name : this.state.tenantData.fullName);
        this.setState({showTenantsFolders: false, showRentalUnitsFolder: false});
      }
    }
  }

  getRentalUnits = () => {
    this.setState({skeletonLoading: true});
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleGetRentalUnits(response){
    if(response.status && response.statuscode === 200){
      response.result.map((rentalUnit, index)=> {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + rentalUnit.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handleGetTenantsByRentalUnitId',
          {rentalUnit, eol: (response.result.length === index+1)},
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      })
    }else{
      this.setState({skeletonLoading: false, breadcrumbsSkeletonLoading: false});
    }
  }

  handleGetTenantsByRentalUnitId(response){
    if(response.status && response.statuscode === 200){
      let {rentalUnits} = this.state;
      if(rentalUnits.find((rentalUnit)=> rentalUnit.id === response.other.rentalUnit.id) === undefined){
        rentalUnits.push({...response.other.rentalUnit, tenants: response.result});
      }
      this.setState({rentalUnits}, ()=> {
        response.other.eol && this.preOpeningOfFolders(rentalUnits);
        // if(response.other.eol && !this.props.showTenantDocuments && this.props.history.location.state){
        //   const {showTenantsFolders=false, showRentalUnitsFolder=false, selectedRentalUnitId=null} = this.props.history.location.state;
        //   showRentalUnitsFolder && this.setState({showTenantsFolders, showRentalUnitsFolder, selectedRentalUnitId, currentFolderId: 2}, ()=> {
        //     showRentalUnitsFolder && this.openFolder(2, 'Mieterdokumente');
        //     //console.log('testing_456: ', rentalUnits.find((rentalUnit)=> rentalUnit.id === selectedRentalUnitId));
        //     showTenantsFolders && this.openFolder(3, rentalUnits?.find((rentalUnit)=> rentalUnit.id === selectedRentalUnitId)?.name);
        //     console.log('history: ', this.props.history);
        //     this.props.history.replace('/documents', {})
        //   });
        // }
  
        // if(response.other.eol && this.props.showTenantDocuments){
        //   this.openFolder(2, 'Mieterdokumente');
        //   this.openFolder(3, rentalUnits.find((rentalUnit)=> rentalUnit.id === this.props.tenantData.rentalUnitId)?.name);
        //   this.openFolder(null, this.props.tenantData.name);
        // }
      });
    }
    
    response.other.eol && this.setState({skeletonLoading: false, breadcrumbsSkeletonLoading: false});
  }

  trackScrolling = () => {    
    const wrappedElement = document.getElementById('app-content');
    if (this.state.data.length > 10 && this.isBottom(wrappedElement) //&& this.state.once === false
    ) {
      console.log('trackScrolling: ', this.state.data.length, this.state.offset + this.state.fetch);
      if(this.state.data.length > (this.state.offset + this.state.fetch)){
        this.setState({
          offset: this.state.offset + 10,//this.state.data.length, 
          //once: true,
          fetchLoader: true}, ()=> null//this.getDocumentsByAccountId()//this.getDocumentsByAccountId()
        );
      }
    }
    // else if(this.state.data > 10 && this.isTop(wrappedElement) && this.state.once === false){
    //   this.setState({
    //     offset: this.state.offset - 10,//this.state.data.length, 
    //     once: true,
    //     fetchLoader: true}, ()=> null//this.getDocumentsByAccountId()//this.getDocumentsByAccountId()
    //   );
    // }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  getDocumentsByAccountId=(isFolderOpen)=>{
    const {propertySelected} = this.props;
    const {showTenantDocuments=false, showTrashBin=false} = this.state;
    const {offset, fetch} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/account?accountId=' + propertySelected.accountId+'&fileTypes=General&fileTypes=TenantDocument',//+'&fileTypes='+ (showTenantDocuments ? 'TenantDocument' : 'General'),//+'&offset='+ offset +'&fetch='+ fetch,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsByAccountId',
      {isFolderOpen},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  downloadDocumentsById= async(id, name, currentIndex=null, endOfDownloads=null)=>{
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handleDownloadDocumentsById',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   },
    //   true
    // );
    // await axios(
    //   APIClient({
    //   method: 'GET',
    //   url: Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
    //   headers: { Authorization: Autoload.Utils.getAuthToken() }
    // })).then((response)=> console.log('downloadDocumentsById: ', response))
    currentIndex === null && loader(true);
    fetch(Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
      {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
      .then(response => {
        response.blob()
        .then(data => {
          //download progress
          //this.setState({downloadProgress: ((this.state.currentDownloadIndex+1) / endOfDownloads) * 100, currentDownloadIndex: this.state.currentDownloadIndex+1 === endOfDownloads ? 0 : this.state.currentDownloadIndex + 1});
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          //var json = JSON.stringify(data),
          //blob = new Blob([json], {type: "octet/stream"}),
          let url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
          loader(false);
        })
      })
  }

  handleDownloadDocumentsById(response){
    console.log('handleDownloadDocumentsById: ', response);
  }

  openFolder=(id, name)=>{
    let {breadCrumbs} = this.state;
    breadCrumbs.find((bc)=> bc.href === id) === undefined ?
    breadCrumbs.splice(breadCrumbs.length -1, 0, {title: name, href: id})
    :
    breadCrumbs.splice(breadCrumbs.findIndex((bc)=> bc.href === id), breadCrumbs.length - (breadCrumbs.findIndex((bc)=> bc.href === id)+1), {title: name, href: id})
    this.setState({breadCrumbs, currentFolderId: id, currentFolderName: name, search: '', checkedItems: [], showTenantsFolders: id === 2 ? false : this.state.showTenantsFolders, selectedRentalUnitId: id === 2 ? null : this.state.selectedRentalUnitId});
    // this.setState({skeletonLoading: true},()=>
    //   Autoload.HttpRequestAxios.HttpRequest(
    //     Autoload.Setting.document_api_base_url + 'documents/' + id,
    //     'GET',
    //     null,
    //     null,
    //     false,
    //     true,
    //     false,
    //     this,
    //     'handleOpenFolder',
    //     {previousFolderInfo: {id, name}},
    //     false,
    //     '',
    //     {
    //       Authorization: Autoload.Utils.getAuthToken(),
    //     }
    //   )
    // );
  }

  handleOpenFolder(response){
    if(response.status && response.statuscode === 200){
      let {breadCrumbs} = this.state;
      breadCrumbs.find((bc)=> bc.href === response.other.previousFolderInfo.id) === undefined &&
      breadCrumbs.splice(breadCrumbs.length -1, 0, {title: response.other.previousFolderInfo.name, href: response.other.previousFolderInfo.id})
      this.setState({data: response.result, breadCrumbs, currentFolderId: response.other.previousFolderInfo.id, currentFolderName: response.other.previousFolderInfo.name});
    }
    this.setState({skeletonLoading: false});
  }

  getDocumentsByPropertyId=(isFolderOpen)=>{
    const {propertySelected} = this.props;
    const {offset, fetch} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/property?propertyId=' + propertySelected.id+'&fileTypes=General', //+'&offset='+ offset +'&fetch='+ fetch,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsByPropertyId',
      {isFolderOpen},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsByPropertyId(response){
    const {propertySelected} = this.props;
    if(response.status && response.statuscode === 200){
      if(response.other.isFolderOpen){
        this.setState({data: response.result, checkedItems: []});
      }else{
        let {breadCrumbs} = this.state;
        breadCrumbs = [breadCrumbs[0], breadCrumbs[breadCrumbs.length - 1]];
        // if(this.state.data.length > 0){
        //   let {data} = this.state;
        //   response.result.map((dt)=> data.push(dt));
        //   this.setState({data, currentFolderId: null, currentFolderName: null, breadCrumbs});
        // }else{
          this.setState({data: response.result, currentFolderId: null, currentFolderName: null, breadCrumbs, checkedItems: []});
        //}
        //this.setState({data: this.state.data.length > 0 ? [...this.state.data, response.result] : response.result});
      }
    }
    this.setState({once: false, skeletonLoading: false, fetchLoader: false});
  }

  handleGetDocumentsByAccountId(response){
    console.log('handleGetDocumentsByAccountId: ', response);
    const {propertySelected} = this.props;
    const {showTenantDocuments=false, tenantId} = this.state;
    const {hideShareFolder} = this.state;
    if(response.status && response.statuscode === 200){
      if(response.other.isFolderOpen){
        this.setState({data: response.result.filter((dt)=> showTenantDocuments ? dt.referencedObjectId === tenantId : dt).filter((dt)=> dt.propertyId !== null ? dt.propertyId === propertySelected.id : dt), checkedItems: []});
      }else{
        let {breadCrumbs} = this.state;
        breadCrumbs = [breadCrumbs[0], breadCrumbs[breadCrumbs.length - 1]];
        // if(this.state.data.length > 0){
        //   let {data} = this.state;
        //   response.result.map((dt)=> data.push(dt));
        //   this.setState({data, currentFolderId: null, currentFolderName: null, breadCrumbs});
        // }else{
          this.setState({data: response.result.filter((dt)=> showTenantDocuments ? dt.referencedObjectId === tenantId : dt).filter((dt)=> dt.propertyId !== null ? dt.propertyId === propertySelected.id : dt), currentFolderId: null, currentFolderName: null, breadCrumbs, checkedItems: []});
        //}
        //this.setState({data: this.state.data.length > 0 ? [...this.state.data, response.result] : response.result});
      }
    }else if(response.status === false && response.statuscode === 400 && response.message === 'NOT_FOUND'){
      this.setState({data: [], currentFolderId: null, currentFolderName: null, checkedItems: [], hideShareFolder: false, showTrashBin: false}, ()=> this.props.propertySelected && this.addBreadCrumbs());
    }
    this.setState({once: false, skeletonLoading: false, fetchLoader: false});

    // if(!this.props.showTenantDocuments && this.props.history.location.state){
    //   const {showTenantsFolders=false, showRentalUnitsFolder=false, selectedRentalUnitId=null} = this.props.history.location.state;
    //   showRentalUnitsFolder && this.setState({currentFolderId: 2});
    // }
  }

  addBreadCrumbs=()=>{
    const {addFileFolderPopoverOpen, rentalUnits} = this.state;
    const {showTenantDocuments=false, tenantData, tenantManagement} = this.props;
    console.log('tenantData: ', tenantData);
    this.setState({breadCrumbs: tenantManagement ?
      [{ title: 'Add', href: 'add' }]
      :
      [{ title: 
        <Tooltip title={this.props.propertySelected.propertyName}>
          <Group spacing={5} onClick={()=> this.setState({hideShareFolder: false, skeletonLoading: true, showTrashBin: false, showRentalUnitsFolder: false, showTenantsFolders: false, selectedRentalUnitId: null, selectedTenantId: null, currentFolderId: null, showTenantDocuments: false, sortHeaderColumn: 'Geändert', sortType: 'asc'}, ()=> this.getDocumentsByAccountId())} noWrap>
            <span style={{maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{//showTenantDocuments ? tenantData.name : 
            this.props.propertySelected.propertyName}</span>
            <HomeIcon 
            style={{
            color: '#5655FE',
            fontSize: '15px'
            }}/>
          </Group>
        </Tooltip>, href: 'documents/root' },
      { title: 'Add', href: 'add' }],
      sortHeaderColumn: 'Geändert', 
      sortType: 'asc'
    });
  }

  downloadFiles=()=>{
    const {data} = this.state;
    let {checkedItems} = this.state;
    checkedItems = checkedItems.filter((checkedItem)=> data.find((dt)=> dt.id === checkedItem && !dt.isDirectory) !== undefined)
    checkedItems.map((checkedItem, index)=>
    {
      let dt= data.find((dt)=> dt.id === checkedItem);
      dt && this.downloadDocumentsById(dt.id, dt.name, index, checkedItems.length);
    });
    this.setState({checkedItems});
  }

  deleteFiles=()=>{
    const {checkedItems, data} = this.state;
    checkedItems.map((checkedItem, index)=>
    {
      let dt= data.find((dt)=> dt.id === checkedItem);
      this.deleteDocumentById(dt.id, index, checkedItems.length);
    })
  }

  showSelectionError=()=> showNotification({
    message: 'Bitte wählen Sie Dokumente oder Ordner aus.',
    icon: <img src={RedCross} alt='red-cross'/>,
    id: 'filesNotSelectedError',
  })

  actionButtons=()=> {
    const {checkedItems, addFileFolderPopoverTopRightOpen, selectedMaxSize, selectedMinSize, hideShareFolder, showTrashBin, showRentalUnitsFolder, showTenantsFolders, isMobile} = this.state;
    console.log('actionButtons: ', showRentalUnitsFolder, showTenantsFolders);
    return (
      <Group spacing={10} noWrap position={isMobile && (showTrashBin ? 'right' : 'apart')}>
        {/* add file/folder */}
        {!showTrashBin &&
        <Popover
        opened={addFileFolderPopoverTopRightOpen}
        width={'auto'}
        position="bottom"
        withArrow
        onClose={() => this.setState({addFileFolderPopoverTopRightOpen: false})}
        target={
        <Tooltip title={'Hinzufügen'}>
          <IconButton 
            aria-label="filter list"
            disabled={showRentalUnitsFolder || showTenantsFolders}
            onClick={()=> this.setState({addFileFolderPopoverTopRightOpen: true})} 
            style={{
              background: '#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
              }}>
              <AddIcon 
              style={{
                color: 'white',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        }
        styles={{
          inner: { padding: '5px'},
        }}>
          <Stack spacing={0}>
            <Group 
            style={{cursor: 'pointer'}}
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> this.setState({addDocumentsModalOpen: true, addFileFolderPopoverTopRightOpen: false})}>
              <NoteAddIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/>
              <span>Datei hochladen</span>
            </Group>
            <Group 
            style={{cursor: 'pointer'}} 
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> this.setState({addFolderModalOpen: true, addFileFolderPopoverTopRightOpen: false})}>
              <CreateNewFolderIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/>
              <span>Neuer Ordner</span>
            </Group>
          </Stack>
        </Popover>}
        {/* copy file/folder */}
        {!showTrashBin &&
        <Tooltip title={'Kopieren'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> checkedItems.length > 0 ? this.setState({moveCopyModalOpen: true, moveCopyModalType: 'copy'}) : this.showSelectionError()}
          style={{
            background: checkedItems.length > 0 ? '#5655FE' : 'white',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: 'pointer',
            marginRight: '10px',
            opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
            }}>
            <FileCopyIcon 
            style={{
              color: checkedItems.length > 0 ? 'white': '#5655FE',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>}
        {/* move file/folder */}
        {!showTrashBin &&
        <Tooltip title={'Verschieben'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> checkedItems.length > 0 ? this.setState({moveCopyModalOpen: true, moveCopyModalType: 'move'}) : this.showSelectionError()}
          style={{
          background: checkedItems.length > 0 ? '#5655FE' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
          }}>
            <img src={checkedItems.length > 0 ? FileMoveIconWhite : FileMoveIcon}/>
          </IconButton>
        </Tooltip>}
        {/* download files */}
        {!showTrashBin &&
        <Tooltip title={'Herunterladen'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> checkedItems.length > 0 ? this.downloadFiles() : this.showSelectionError()} 
          style={{
          background: checkedItems.length > 0 ? '#5655FE' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
          }}>
              <DownloadIcon size='14px' width='11px' height='11px' fill={checkedItems.length > 0 ? 'white' : '#5655FE'}/>
          </IconButton>
        </Tooltip>}
        {/* share files */}
        {/* {!showTrashBin &&
        <Tooltip title={'Teilen'}>
          <IconButton 
          aria-label="filter list"
          disabled={hideShareFolder}
          onClick={()=> checkedItems.length > 0 ? this.shareDocuments() : this.showSelectionError()} 
          style={{
          opacity: hideShareFolder ? 0.7 : 1,
          background: checkedItems.length > 0 ? '#5655FE' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px'
          }}>
            <FolderSharedIcon 
            style={{
              color: checkedItems.length > 0 ? 'white' : '#5655FE',
              fontSize: '15px'
            }}/>
          </IconButton>
        </Tooltip>} */}
        {/* restore file/folder */}
        {showTrashBin &&
        <Tooltip title={'Wiederherstellen'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> checkedItems.length > 0 ? checkedItems.map((item)=> this.restoreDocument(item))//this.setState({deleteDocumentModalOpen: true})//this.deleteFiles() 
          : this.showSelectionError()} 
          style={{
          background: checkedItems.length > 0 ? '#5655FE' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
          }}>
            <RestoreIcon 
            style={{
              color: checkedItems.length > 0 ? 'white' : '#5655FE',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>}
        {/* delete file/folder */}
        <Tooltip title={'Löschen'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> checkedItems.length > 0 ? (showTrashBin ? this.setState({deleteDocumentModalOpen: true}) : this.moveDocumentToTrashBin(checkedItems))//this.setState({deleteDocumentModalOpen: true})//this.deleteFiles() 
          : this.showSelectionError()} 
          style={{
          background: checkedItems.length > 0 ? '#E8193C' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
          }}>
            <DeleteIcon 
            style={{
              color: checkedItems.length > 0 ? 'white' : '#E8193C',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>
        {/* filter */}
        <Tooltip title={'Filtern'}>
          <IconButton 
          aria-label="filter list"
          disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> this.setState({openFilter: true})} 
          style={{
          background: (selectedMinSize > 0 || selectedMaxSize > 0) ? '#5655FE' : 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          opacity: (showRentalUnitsFolder || showTenantsFolders) ? 0.5 : 1
          //marginRight: '10px'
          }}>
            <FilterListIcon 
            style={{
            color: (selectedMinSize > 0 || selectedMaxSize > 0) ? 'white' : '#5655FE',
            fontSize: '15px'
            }}/>
          </IconButton>
        </Tooltip>
      </Group>
    );
  }

  shareDocuments=()=>{
    const {checkedItems, data, hideShareFolder} = this.state;
    loader(true);

    if(checkedItems.length > 0){
      checkedItems.map((checkedItem, index)=> {
        const formData = new FormData();
        const item = data.find((dt)=> dt.id === checkedItem);
        formData.append('Name', item.name); 
        item.referencedObjectId && formData.append('referencedObjectId', item.referencedObjectId);
        formData.append('isReadOnly', item.isReadOnly);        
        formData.append('isHiddenForTenantPortal', item.isHiddenForTenantPortal);
        formData.append('isShared', true);
        
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents/'+ checkedItem,
        'PUT',
        formData,
        null,
        false,
        true,
        false,
        this,
        'handleShareDocument',
        {eol: (checkedItems.length - 1) === index},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
      });
    }
  }

  handleShareDocument(response){
    if(response.status && response.statuscode === 200){
      if(response.other.eol){
        loader(false);
        this.getDocumentsByAccountId();
      }
    }else{
      loader(false); 
    }
  }

  convertFileSize=(sizeString)=>{
    if(sizeString){
      if(sizeString.includes('KB')){
        return parseFloat(sizeString) / 1000;
      }else if(sizeString.includes('GB')){
        return parseFloat(sizeString) * 1000;
      }else if(sizeString.includes('TB')){
        return parseFloat(sizeString) * 1000000;
      }else if(sizeString.includes('B') && (!sizeString.includes('M') && !sizeString.includes('G') && !sizeString.includes('T') && !sizeString.includes('K'))){
        return parseFloat(sizeString) / 1000000;
      }else {
        console.log('convertFileSize: ', parseFloat(sizeString));
        return parseFloat(sizeString);
      }
    }else{
      return 0;
    }
  }

  restoreDocument=(id)=>{
    let payload={
      documentIds: [id]
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/restore/recycleBin',//+id+'/RecycleBin',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleRestoreDocument',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleRestoreDocument(response){
    const {currentFolderId} = this.state;
    if(response.status && response.statuscode === 200){
      this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.getDocumentsByAccountId((currentFolderId === 0 || currentFolderId === 1 || currentFolderId) ? true : false))
      showNotification({
        message: 'Dokument wiederhergestellt',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'retoreDocument'
      })
    }
  }
  

  getTableData=()=>{
    const {data, checkedItems, selectedDocumentType, isSharedFilter, selectedMaxSize, selectedMinSize, search, currentFolderId, offset, fetch, sortHeaderColumn, sortType, hideShareFolder, showTrashBin, showTenantDocuments, isMobile} = this.state;
    console.log('getTableData: ', offset);
    console.log('getTableData: ', data.filter((row)=> hideShareFolder ? row.propertyId === null : row));
    console.log('showTrashBin: ', data
    .filter((row)=> showTrashBin ? row.isDeleted : !row.isDeleted)
    .filter((row)=> hideShareFolder ? row.propertyId === null : row.propertyId !== null)
    .filter((row)=> search !== null ? row.name.toLowerCase().includes(search.toLowerCase()) : row)
    .filter((row)=> (search !== null && search !== '') ? row : (currentFolderId && (currentFolderId !== 0 || currentFolderId !== 1) ? row.parentDirectoryId === currentFolderId : row.parentDirectoryId === null))
    .filter((row)=> selectedDocumentType !== '-' ? selectedDocumentType === row.fileType : row)
    .filter((row)=> isSharedFilter ? row.isShared : row)
    .filter((row)=> (selectedMinSize > 0 && selectedMaxSize > 0) ? 
      this.convertFileSize(row.fileSize) <= selectedMaxSize && this.convertFileSize(row.fileSize) >= selectedMinSize
      :
      (selectedMinSize > 0 && selectedMaxSize === null) ?
      this.convertFileSize(row.fileSize) >= selectedMinSize
      :
      (selectedMinSize === null && selectedMaxSize > 0) ?
      this.convertFileSize(row.fileSize) <= selectedMaxSize
      :
      row)
      .sort((a,b)=> sortHeaderColumn === 'Name' ? sortType ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()) :
      sortHeaderColumn === 'Geändert' ? sortType ? dayjs(a.updatedOn).isBefore(b.updatedOn, 'minutes') : dayjs(b.updatedOn).isBefore(a.updatedOn, 'minutes') :
      sortHeaderColumn === 'Größe' && sortType ? this.convertFileSize(a.fileSize) - this.convertFileSize(b.fileSize) : this.convertFileSize(b.fileSize) - this.convertFileSize(a.fileSize))
    , 
    currentFolderId);
    console.log('selectedMaxSize, selectedMinSize: ', selectedMaxSize, selectedMinSize);
    return (
      data
      //.filter((row, index)=> index > offset && index < (offset+fetch))
      .filter((row)=> showTrashBin ? row.isDeleted : !row.isDeleted)
      .filter((row)=> showTrashBin ? row : hideShareFolder ? row.propertyId === null : row.propertyId !== null)
      .filter((row)=> search !== null ? row.name.toLowerCase().includes(search.toLowerCase()) : row)
      .filter((row)=> (search !== null && search !== '') ? row : (currentFolderId && (currentFolderId !== 0 && currentFolderId !== 1) ? row.parentDirectoryId === currentFolderId : (showTrashBin ? (currentFolderId !== 1 ? row.parentDirectoryId === currentFolderId : row.parentDirectoryId === null) : row.parentDirectoryId === null))) //showTrashBin ? row.isDirectory === false : 
      .filter((row)=> selectedDocumentType !== '-' ? selectedDocumentType === row.fileType : row)
      .filter((row)=> isSharedFilter ? row.isShared : row)
      .filter((row)=> (selectedMinSize > 0 && selectedMaxSize > 0) ? 
      this.convertFileSize(row.fileSize) <= selectedMaxSize && this.convertFileSize(row.fileSize) >= selectedMinSize
      :
      (selectedMinSize > 0 && selectedMaxSize === null) ?
      this.convertFileSize(row.fileSize) >= selectedMinSize
      :
      (selectedMinSize === null && selectedMaxSize > 0) ?
      this.convertFileSize(row.fileSize) <= selectedMaxSize
      :
      row)
      .filter((row)=> showTrashBin ? row : showTenantDocuments ? row.fileType === 'TenantDocument' : row.fileType === 'General')
      .sort((a,b)=> sortHeaderColumn === 'Name' ? sortType ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()) :
      sortHeaderColumn === 'Geändert' ? sortType ? dayjs(a.updatedOn).isBefore(b.updatedOn, 'minutes') : dayjs(b.updatedOn).isBefore(a.updatedOn, 'minutes') :
      sortHeaderColumn === 'Größe' && sortType ? this.convertFileSize(a.fileSize) - this.convertFileSize(b.fileSize) : this.convertFileSize(b.fileSize) - this.convertFileSize(a.fileSize))
      //.filter((row, index)=> index <= (offset+fetch))
      .map((row)=> {
        let recordOptions = [
          {
            text: 'Umbennen',
            leftImage: 
            <EditIcon 
            style={{
            color: '#5655FE',
            fontSize: '15px'
            }}/>,
            openFunction: (()=> this.setState({editDocumentModalOpen: true, editDocumentData: row}))
          },
          {
            text: 'Kopieren',
            leftImage: 
              <FileCopyIcon 
              style={{
              color: '#5655FE',
              fontSize: '15px'
              }}/>,
            openFunction: (()=> this.setState({moveCopyModalOpen: true, moveCopyModalType: 'copy', checkedItems: [row.id]}))
          },
          {
            text: 'Verschieben',
            leftImage: <img src={FileMoveIcon}/>,
            openFunction: (()=> this.setState({moveCopyModalOpen: true, moveCopyModalType: 'move', checkedItems: [row.id]}))
          },
          {
            text: 'Herunterladen',
            leftImage: <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>,
            openFunction: (()=> this.setState({checkedItems: [row.id]}, ()=> this.downloadFiles()))
          },
          {
            text: 'Löschen',
            leftImage: 
            <DeleteIcon 
            style={{
              color: '#E8193C',
              fontSize: '15px'
              }}/>,
            openFunction: (()=> this.setState({checkedItems: [row.id]}, ()=> //setTimeout(() => {
              this.moveDocumentToTrashBin(this.state.checkedItems)
            //}, 600)//this.setState({deleteDocumentModalOpen: true})//this.deleteFiles()
            ))
          }
        ]
        return (
        <TableRow style={{background: '#FFFFFF'}}>
          <TableCell width={!isMobile ? '5%' : '1%'} style={{paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}>
            <Checkbox
            checked={checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined}
            style={{color: checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined ? '#5555FF' : '#9A9AB5'}}
            inputProps={{ 'aria-labelledby': 0 }}
            onClick={()=> {
                this.setState({checkedItems: checkedItems && checkedItems.length !== 0 ? (checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined) ? checkedItems.filter((ci)=> ci !== row.id) : [...checkedItems, row.id] : [row.id]});
            }}
            />
          </TableCell>
          <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> row.isDirectory ? this.openFolder(row.id, row.name) : this.downloadDocumentsById(row.id, row.name)}>
            <Group noWrap>
            {row.isDirectory ? 
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/> 
            : 
            <DescriptionIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            }
            <span style={{maxWidth: isMobile && (window.innerWidth/2.1), fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{row.name}</span>
            </Group>
          </TableCell>
          {!isMobile &&
          <>
            <TableCell width={'5%'}>
              {`${dayjs.utc(showTrashBin ? row.deletedOn : row.updatedOn).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(dayjs(showTrashBin ? row.deletedOn : row.updatedOn).isBefore(dayjs(), 'day') ? 'DD.MM.YYYY' : 'HH:mm')} ${dayjs(showTrashBin ? row.deletedOn : row.updatedOn).isBefore(dayjs(), 'day') ? '' : 'Uhr'}`}
            </TableCell>
            
            <TableCell style={{textAlign: 'right'}} width={'5%'}>
              {row.fileSize ? (row.fileSize[row.fileSize.length-2] === 'M' || row.fileSize[row.fileSize.length-2] === 'K') ? row.fileSize?.replace('.', ',').split(/(?=.{2}$)/).join(' ') : row.fileSize?.replace('.', ',').split(/(?=.{1}$)/).join(' ') : '-'}
            </TableCell>
            {this.state.showTenantDocuments &&
            <TableCell width={'5%'}>
              <Group position='center'>
                <SwitchContainer
                //switchText={'isHiddenForTenantPortal'}
                checked={!row.isHiddenForTenantPortal}
                disabled={false}
                onChange={()=> this.updateDocument({...row, isHiddenForTenantPortal: !row.isHiddenForTenantPortal})}
                />
              </Group>
            </TableCell>}
          </>}
          <TableCell style={{textAlign: 'right', paddingLeft: isMobile && 0}} width={!isMobile && '6%'}>
            <RecordOptions
              listOfItems={
                showTrashBin ? [
                  {
                    text: 'Wiederherstellen',
                    leftImage: 
                    <RestoreIcon 
                    style={{
                      color: '#5655FE',
                      fontSize: '15px'
                      }}/>,
                    openFunction: (()=> this.restoreDocument(row.id))
                  },
                  {
                    text: 'Löschen',
                    leftImage: 
                    <DeleteIcon 
                    style={{
                      color: '#E8193C',
                      fontSize: '15px'
                      }}/>,
                    openFunction: (()=> this.setState({checkedItems: [row.id]}, ()=> this.setState({deleteDocumentModalOpen: true})//this.deleteFiles()
                    ))
                  }
                ]
                :
                [
                ...recordOptions,
                (showTenantDocuments && isMobile) && {
                  text: row.isHiddenForTenantPortal === true ? 'Im Mieterportal anzeigen' : 'Im Mieterportal nicht anzeigen',
                  leftImage: <img src={TenantPortalIcon} width={'15px'}/>,
                  openFunction: (()=> this.setState({checkedItems: [row.id]}, ()=> //setTimeout(() => {
                    this.moveDocumentToTrashBin(this.state.checkedItems)
                  //}, 600)//this.setState({deleteDocumentModalOpen: true})//this.deleteFiles()
                  ))
                }
              ]}
              style={{marginRight: '0px'}}
              buttonStyle={{border: 'none', background: 'white'}}
            />
          </TableCell>
          {/* <TableCell>
            {row.isShared ?
            <FolderSharedIcon 
            style={{
              color: '#8B88F5',
              fontSize: '20px'
            }}/> :
            '-'}
          </TableCell> */}
        </TableRow>
        );
      })
    );
  }

  getTableHeader=()=>{
    const {checkedItems, data, sortHeaderColumn, sortType, currentFolderId, showTrashBin, hideShareFolder, search, selectedDocumentType, isSharedFilter, selectedMaxSize, selectedMinSize, isMobile} = this.state;
    const currentData = data.filter((row)=> showTrashBin ? row.isDeleted : !row.isDeleted)
    .filter((row)=> showTrashBin ? row : hideShareFolder ? row.propertyId === null : row.propertyId !== null)
    .filter((row)=> search !== null ? row.name.toLowerCase().includes(search.toLowerCase()) : row)
    .filter((row)=> (search !== null && search !== '') ? row : (currentFolderId && (currentFolderId !== 0 && currentFolderId !== 1) ? row.parentDirectoryId === currentFolderId : (showTrashBin ? (currentFolderId !== 1 ? row.parentDirectoryId === currentFolderId : row.parentDirectoryId === null) : row.parentDirectoryId === null))) //showTrashBin ? row.isDirectory === false : 
    .filter((row)=> selectedDocumentType !== '-' ? selectedDocumentType === row.fileType : row)
    .filter((row)=> isSharedFilter ? row.isShared : row)
    .filter((row)=> (selectedMinSize > 0 && selectedMaxSize > 0) ? 
    this.convertFileSize(row.fileSize) <= selectedMaxSize && this.convertFileSize(row.fileSize) >= selectedMinSize
    :
    (selectedMinSize > 0 && selectedMaxSize === null) ?
    this.convertFileSize(row.fileSize) >= selectedMinSize
    :
    (selectedMinSize === null && selectedMaxSize > 0) ?
    this.convertFileSize(row.fileSize) <= selectedMaxSize
    :
    row);
    let {columns} = this.state;
    if((window.location.href.includes('user/tenant/documents?') ? this.props.showTenantDocuments : this.state.showTenantDocuments) && !columns.find((column)=> column === 'showInTenantPortal')){
      columns.splice(2, 0, 'showInTenantPortal')
    }
    // else{
    //   const index = columns.indexOf('showInTenantPortal');
    //   if (index > -1) {
    //     columns.splice(index, 1);
    //   }
    // }

    console.log('columns: ', columns);
    //columns = this.props.showTenantDocuments ?  : columns;
    console.log('testing: ', checkedItems.length, currentData.length);
    let checkBox = (
      <TableCell width={!isMobile && '5%'} style={{padding: isMobile && 0}}>
        <Checkbox
          checked={currentData.length > 0 && checkedItems.length === currentData.length}
          style={{color: currentData.length > 0 && checkedItems.length === currentData.length ? '#5555FF' : '#9A9AB5'}}
          inputProps={{ 'aria-labelledby': 0 }}
          onClick={()=> this.setState({checkedItems: checkedItems.length === currentData.length ? [] : currentData.map((item)=> item.id)})}
          indeterminate={checkedItems.length !== 0 && checkedItems.length !== currentData.length && true}
        />
      </TableCell>
    );

    // let contextMenu = (

    // )
    const headerRow =(column)=> {
      return (
        column === 'showInTenantPortal' && (window.location.href.includes('user/tenant/documents?') ? this.props.showTenantDocuments : this.state.showTenantDocuments) === false ?
          ''
          :
          <TableCell style={{textAlign: column === 'Größe' && 'right', cursor: 'pointer'}} onClick={()=> this.setState({sortHeaderColumn: column, sortType: !this.state.sortType})}>
            {column === 'showInTenantPortal' ?
              <Group className='tooltips  tenant-documents-table-header-switch-column' position='center'>Mieterportal</Group>
            :
            <TableSortLabel
            active={sortHeaderColumn === column}
            direction={sortType ? 'desc' : 'asc'}
            style={{textAlign: 'left'}}
            >
              {showTrashBin ? column === 'Geändert' ? 'Gelöscht am' : column : column}
            </TableSortLabel>}
          </TableCell>
      );
    }

    return (
      <TableRow>
        {checkBox}
        {columns.map((column, index)=> {
          if(isMobile){
            if(index === 0){
              return headerRow(column);
            }
          }else{
            return headerRow(column);
          }
        })}

      </TableRow>
    );
  }

  updateDocument=(updateData)=>{
    const data = new FormData();
      
    data.append('Name', updateData.name);
    // data.append('accountId', propertySelected.accountId);
    updateData.propertyId && data.append('propertyId', updateData.propertyId);
    updateData.referencedObjectId && data.append('referencedObjectId', updateData.referencedObjectId);
    data.append('isReadOnly', updateData.isReadOnly);        
    data.append('isHiddenForTenantPortal', updateData.isHiddenForTenantPortal);
    data.append('isShared', updateData.isShared);
    // data.append('isDirectory', false);
    // data.append('isReadOnly', false);
    // data.append('isShared', false);
    // data.append('fileType', 'General');
    // currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);

    Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.document_api_base_url + 'documents/'+ updateData.id,
    'PUT',
    data,
    null,
    false,
    true,
    false,
    this,
    'handleUpdateDocument',
    null,
    false,
    '',
    {
      //'Content-Type': 'application/json',
      Authorization: Autoload.Utils.getAuthToken(),
    },
    true
    )
  }

  handleUpdateDocument(response){
    if(response.status && response.statuscode === 200){
      this.getDocumentsByAccountId(true);
      showNotification({
        message: 'Änderung übernommen',
        icon: <img src={GreenTick} alt='green-tick'/>,
      })
    }
  }

  rowRenderer=({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  })=>{
    const {data, checkedItems, selectedDocumentType, isSharedFilter, selectedMaxSize, selectedMinSize, search, currentFolderId, offset, fetch} = this.state;
    console.log('getTableData: ', offset);
    let row = data[index];
    return (
        <Group style={{background: '#FFFFFF', marginToo: '8px'}}>
          <Group width={'5%'}>
            <Checkbox
            checked={checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined}
            style={{color: checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined ? '#5555FF' : '#9A9AB5'}}
            inputProps={{ 'aria-labelledby': 0 }}
            onClick={()=> {
                this.setState({checkedItems: checkedItems && checkedItems.length !== 0 ? (checkedItems && checkedItems.length !== 0 && checkedItems.find((checkedItem)=> checkedItem === row.id) !== undefined) ? checkedItems.filter((ci)=> ci !== row.id) : [...checkedItems, row.id] : [row.id]});
            }}
            />
          </Group>
          <Group width={'50%'} style={{cursor: 'pointer'}} onClick={()=> row.isDirectory ? this.openFolder(row.id, row.name) : this.downloadDocumentsById(row.id, row.name)}>
            <Group noWrap>
            {row.isDirectory ? 
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/> 
            : 
            <DescriptionIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>}
            <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{row.name}</span>
            </Group>
          </Group>
          <Group>
            {`${dayjs(row.updatedOn).format(dayjs(row.updatedOn).isBefore(dayjs(), 'day') ? 'DD.MM.YYYY' : 'HH:mm')} ${dayjs(row.updatedOn).isBefore(dayjs(), 'day') ? '' : 'Uhr'}`}
          </Group>
          {/* <TableCell style={{textTransform: 'capitalize'}}>
            {row.fileType === 'TenantDocument' ? 'Mieterdokumente' : row.fileType === 'General' && 'Allgemein'}
          </TableCell> */}
          <Group style={{textAlign: 'right'}}>
            {row.fileSize}
          </Group>
          {/* <TableCell>
            {row.isShared ?
            <FolderSharedIcon 
            style={{
              color: '#8B88F5',
              fontSize: '20px'
            }}/> :
            '-'}
          </TableCell> */}
        </Group>
        );
      }

  renderDrawer=()=>{
    const {openFilter, selectedDocumentType, isSharedFilter, selectedMaxSize, selectedMinSize} = this.state
    return (
      <Drawer
      opened={openFilter}
      position={'right'}
      size="300px"
      overlayOpacity={0.5}
      onClose={()=> this.setState({openFilter: false})}
      withCloseButton={false}
      >
        <Stack style={{padding: '10px', height: '100vh'}} justify='space-between'>
          <Stack spacing={2}>
            <Group position='apart'>
              <span className='documents-filter-title'>Dokumente filtern</span>
              <IconButton size='medium'
              onClick={()=> this.setState({openFilter: false})}
              >
                <CloseIcon style={{color: '#727293'}}/>
              </IconButton>
            </Group>
            <Divider/>  
            <Stack spacing={0}>
              {/* <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Dokumentart</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedDocumentType}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'fileType')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    <MenuItem value={'General'}><span style={{fontStyle: 'italic'}} defaultValue>Allgemein</span></MenuItem>
                    <MenuItem value={'TenantDocument'}><span style={{fontStyle: 'italic'}} defaultValue>Mieterdokumente</span></MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Größe der Dokumente</InputLabel>
                <div style={{display: 'flex'}}>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  {/* <OutlinedInput
                    id="outlined-adornment-weight"
                    value={selectedMinSize}
                    onChange={(e)=> this.handleChange(e, 'minSize')}
                    endAdornment={'MB'}
                    placeholder={'Min.'}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                    style={{height: '40px'}}
                  /> */}
                  <NumericFormat 
                  className={'form-control'}
                  value={selectedMinSize}
                  thousandSeparator="." 
                  decimalSeparator=","
                  placeholder='Min. MB'
                  style={{textAlign: 'right', height: '40px'}}
                  decimalScale={2}
                  fixedDecimalScale={2}
                  suffix={' MB'}
                  allowNegative={false}
                  onValueChange={(values, sourceInfo) => {
                      this.handleChange(values.floatValue ? values.floatValue : 0, 'minSize')
                  }}
                  />
                </FormControl>
                <div style={{width: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#111147'}}> - </span></div>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  {/* <OutlinedInput
                      id="outlined-adornment-weight"
                      value={selectedMaxSize}
                      onChange={(e)=> this.handleChange(e, 'maxSize')}
                      endAdornment={'MB'}
                      placeholder={'Max.'}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      labelWidth={0}
                      style={{height: '40px'}}
                  /> */}
                  <NumericFormat 
                  className={'form-control'}
                  value={selectedMaxSize}
                  thousandSeparator="." 
                  decimalSeparator=","
                  placeholder='Max. MB'
                  style={{textAlign: 'right', height: '40px'}}
                  decimalScale={2}
                  fixedDecimalScale={2}
                  suffix={' MB'}
                  allowNegative={false}
                  onValueChange={(values, sourceInfo) => {
                      this.handleChange(values.floatValue ? values.floatValue : 0, 'maxSize')
                  }}
                  />
                </FormControl>
                </div>
              </div>
              {/* <div style={{margin: '0px 15px'}}>
                <SwitchContainer
                className="heating-cost-switch"
                onChange={() => this.setState({isSharedFilter: !isSharedFilter})}
                checked={isSharedFilter}
                switchText={'Wird geteilt'}
                disabled={false}
                />
              </div> */}
            </Stack>
          </Stack>
          <div style={{margin: '5px', display: 'flex', justifyContent: 'space-between'}}>
              <ColorButton
              disabled={selectedDocumentType === '-' && selectedMaxSize === '' && selectedMinSize === '' && isSharedFilter === false}  
              onClick={(e)=> this.handleReset(e)}
              className={(selectedDocumentType === '-' && selectedMaxSize === '' && selectedMinSize === '' && isSharedFilter === false) ? "gray-btn" : 'purple-btn'}
              text={'Zurücksetzen'}
              buttonStyle={{
                cursor: (selectedDocumentType === '-' && selectedMaxSize === '' && selectedMinSize === '' && isSharedFilter === false) && 'not-allowed',
                margin: '0 4px 0 4px'
              }}
              />
              <ColorButton
              onClick={()=> this.setState({openFilter: false})}
              className={"gray-btn"}
              text={'Schließen'}
              buttonStyle={{
                margin: '0 4px 0 4px'
              }}
              />
          </div> 
        </Stack> 
      </Drawer>
    )
  }

  handleChange = (value, type) => {
    console.log('handleChange_value: ', value);
    // if(type === 'fileType'){
    //   this.setState({selectedDocumentType: value});
    // }
    if(type === 'minSize' || type === 'maxSize'){
      type === 'minSize' ? this.setState({selectedMinSize: value}) : this.setState({selectedMaxSize: value})
    }
  };

  handleReset = (e) => {
    this.setState({
      selectedDocumentType: '-',
      // selectedCostType: '-',
      // selectedBillingPeriod: '-',
      selectedMinSize: 0,
      selectedMaxSize: 0,
      isSharedFilter: false
    }, ()=> this.setState({
      selectedMinSize: null,
      selectedMaxSize: null
    }))
  }

  deleteDocumentById=(id, currentIndex, endOfDeletion)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/'+id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteDocumentById',
      {endOfDeletion, currentIndex},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeleteDocumentById(response){
    if((response.other.endOfDeletion-1) === response.other.currentIndex){
      const {currentFolderId, currentFolderName} = this.state;
      this.getDocumentsByAccountId(currentFolderId ? true : false);
      showNotification({
        message: 'Inhalte gelöscht',
        icon: <img src={RedTick} alt='red-tick'/>,
      })
    }
  }

  handleFileChange = (acceptedFile, rejectedFile) => {
    console.log('acceptedFile: ', acceptedFile);
    if (acceptedFile.length > 0) {
      if(acceptedFile.length > 1){
        //acceptedFile.map((file)=> this.addDocApi(file));
      }else{
        //this.addDocApi(acceptedFile[0]);
      }
      let {files} = this.state;
      if(files.length > 0){
        acceptedFile.map((af)=> files.find((file)=> file.path === af.path) === undefined && files.push(af));
        this.setState({files, addDocumentsModalOpen: true},()=> {
          if(this.state.showTenantDocuments){
            let tenantfiles = files.map((file)=> { return {file: file, tenantId: this.state.tenantId} });
            this.props.setFiles(tenantfiles);
            this.props.setSharedFolder(this.state.hideShareFolder)
            this.props.setCurrentFolderId(this.state.currentFolderId);
          }else{
            this.props.setFiles(files);
            this.props.setSharedFolder(this.state.hideShareFolder)
            this.props.setCurrentFolderId(this.state.currentFolderId); 
          }
        });
      }else{
        this.setState({files: acceptedFile, addDocumentsModalOpen: true}, ()=> {
          if(this.state.showTenantDocuments){
            let tenantfiles = acceptedFile.map((file)=> { return {file: file, tenantId: this.state.tenantId} });
            this.props.setFiles(tenantfiles);
            this.props.setSharedFolder(this.state.hideShareFolder)
            this.props.setCurrentFolderId(this.state.currentFolderId);
          }else{
            this.props.setFiles(acceptedFile);
            this.props.setSharedFolder(this.state.hideShareFolder)
            this.props.setCurrentFolderId(this.state.currentFolderId);
          }
        });
      }
    }
    if (rejectedFile.length > 0) {
      console.log('rejectedFile: ', rejectedFile);
      rejectedFile.map((file, index)=>
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Datei zu groß'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  <Group>Es ist ein Fehler aufgetreten. Das Dokument ist zu groß. Maximal erlaubte Dokumentgröße ist 100 MB.</Group>
                  {this.state.error.map((err)=> 
                    <Group noWrap spacing={2}>
                      <span style={{
                        // maxWidth: '290px', 
                        // overflowX: 'hidden', 
                        // textOverflow: 'ellipsis', 
                        // whiteSpace: 'nowrap'
                        }}>
                          {`Dateiname: ${err.fileName}`}
                      </span>
                      {/* <span>{'->'}</span>
                      <span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span> */}
                    </Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          })
        }
        if (err.code === 'file-invalid-type') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Dateityp ungültig'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  {this.state.error.map((err)=> <Group noWrap spacing={2}><span style={{maxWidth: '290px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{`Dateiname: ${err.fileName}`}</span><span>{'->'}</span><span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span></Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          });
        }
      }));
      // let {files} = this.state;
      // files.filter()
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }
  };

  renderShareFolder=()=>{
    const {isMobile} = this.state;
    return(
      <TableRow style={{background: '#FFFFFF'}}>
          <TableCell width={!isMobile && '5%'} style={{height: '71px', paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}/>
          <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> this.setState({hideShareFolder: true},()=> this.openFolder(0, 'Gemeinsame Dokumente'))}>
            <Group noWrap>
              <FolderSharedIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/> 
              <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Gemeinsame Dokumente'}</span>
            </Group>
          </TableCell>
          <TableCell style={{textAlign: 'right'}} colSpan={4}/>
        </TableRow>
    )
  }

  renderTrashFolder=()=>{
    const {isMobile} = this.state;
    return(
      <TableRow style={{background: '#FFFFFF'}}>
          <TableCell width={!isMobile && '5%'} style={{height: '71px', paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}/>
          <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> this.setState({showTrashBin: true},()=> {
            // this.getDocumentsByAccountId(false);
            this.openFolder(1, 'Gelöschte Dokumente');
            })}>
            <Group noWrap>
              <DeleteIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/> 
              <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Gelöschte Dokumente'}</span>
            </Group>
          </TableCell>
          <TableCell style={{textAlign: 'right'}} colSpan={4}/>
        </TableRow>
    )
  }

  renderTenantFolder=()=>{
    const {isMobile} = this.state;
    return(
      <TableRow style={{background: '#FFFFFF'}}>
          <TableCell width={!isMobile && '5%'} style={{height: '71px', paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}/>
          <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> this.setState({showRentalUnitsFolder: true, sortHeaderColumn: 'Name', sortType: 'asc'},()=> {
            this.openFolder(2, 'Mieterdokumente');
            this.getRentalUnits();
            })}>
            <Group noWrap>
              <FolderIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/> 
              <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Mieterdokumente'}</span>
            </Group>
          </TableCell>
          <TableCell style={{textAlign: 'right'}} colSpan={3}/>
        </TableRow>
    )
  }

  moveDocumentToTrashBin=(ids)=>{
    let payload={
      documentIds: ids
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/move/recycleBin',//'documents/'+id+'/RecycleBin',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleMoveDocumentsToTrashBin',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleMoveDocumentsToTrashBin(response){    
    if(response.status && response.statuscode === 200){
      //his.props.getDocuments();
      this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.state.showTenantDocuments ? this.props.setRefreshTenantDocumentsData(true) : this.props.setRefreshDocumentsData(true));
      //this.props.toggle();
      showNotification({
        message: 'Verschoben nach Gelöschte Dokumente',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'moveToTrashBin'
      });
    }
  }

  getRentalUnitsTable=()=>{
    const {rentalUnits, sortHeaderColumn, sortType, isMobile} = this.state;
    console.log('getRentalUnitsTable: ', rentalUnits);
    if(rentalUnits.length > 0){
      return rentalUnits
      .sort((a,b)=> sortHeaderColumn === 'Name' && sortType ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
      .map((rentalUnit)=>
      <TableRow style={{background: '#FFFFFF'}}>
        <TableCell width={!isMobile ? '5%' : '10px'} style={{height: '71px', paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}/>
        <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> this.setState({showTenantsFolders: true, selectedRentalUnitId: rentalUnit.id},()=> this.openFolder(3, rentalUnit.name))}>
            <Group noWrap>
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/> 
            <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{rentalUnit.name}</span>
            </Group>
          </TableCell>
          <TableCell colSpan={3}/>
      </TableRow>)
    }else{
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <EmptyStateContainer
            heading={'Sie haben noch keine Mietverhältnisse angelegt.'}
            subheading={'Legen Sie zunächst Mietverhältnisse an, um Mieterdokumente abzulegen.'}
            showBtn={true}
            btnText={'Mieter anlegen'}
            btnHandler={()=> this.props.history.push('/user')}
            icon={TenantSingleIconBlue}
            />
          </TableCell>
        </TableRow>
      );
    }
  }

  getTenantsTable=()=>{
    const {rentalUnits, showTenantsFolders, selectedRentalUnitId=null, sortHeaderColumn, sortType, isMobile} = this.state;
    return rentalUnits.map((rentalUnit)=>{
      if(showTenantsFolders && selectedRentalUnitId && selectedRentalUnitId === rentalUnit.id){
        if(rentalUnit.tenants.length > 0 && rentalUnit.tenants.find((tenant)=> tenant.vacant === false) !== undefined){
          return rentalUnit.tenants
          .sort((a,b)=> sortHeaderColumn === 'Name' && sortType ? a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()) : b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase()))
          .map((tenant)=> 
          tenant.vacant === false &&
          <TableRow style={{background: '#FFFFFF'}}>
            <TableCell width={!isMobile ? '5%' : '10px'} style={{height: '71px', paddingLeft: isMobile && 0, paddingRight: isMobile && 0}}/>
            <TableCell width={'50%'} style={{cursor: 'pointer', paddingLeft: isMobile && 5}} onClick={()=> {
              this.props.setCurrentTenant(tenant, {
                createdOn: "",
                current: tenant.isLastRealTenant,
                email: tenant.email,
                id: tenant.id,
                in: tenant.moveInDate,
                name: tenant.fullName,
                note: tenant.notes,
                out: tenant.moveOutDate,
                phone: tenant.phone,
                rentalUnitId: tenant.rentalUnitId,
                type: tenant.contractType,
                updatedOn: "",
                vacant: tenant.vacant
              });
              //this.props.history.push(`/documents?tenantId=${tenant.id}`, {showTenantDocuments: true, tenantId: tenant.id, tenantData: tenant, rentalUnits})
              this.props.history.push(`/documents?tenantId=${tenant.id}`);
              this.setState({showTenantDocuments: true, tenantId: tenant.id, tenantData: tenant, skeletonLoading: true, sortHeaderColumn: null, sortType: false}, ()=> {
                this.preOpeningOfFolders(rentalUnits);
                this.getDocumentsByAccountId(true);
              });
              //this.props.history.push(`/user/tenant/documents?tenantId=${tenant.id}&tenantAdministration=false`, {showTenantDocuments: true, tenantId: tenant.id, tenantData: tenant, rentalUnits})
            }}>
                <Group noWrap>
                <FolderIcon 
                style={{
                  color: '#5655FE',
                  fontSize: '20px'
                }}/> 
                <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{tenant.fullName}</span>
                </Group>
              </TableCell>
              <TableCell colSpan={3}/>
          </TableRow>);
        }else{
          return (
            <TableRow>
              <TableCell colSpan={5}>
                <EmptyStateContainer
                heading={'Sie haben noch keine Mietverhältnisse angelegt.'}
                subheading={'Legen Sie zunächst Mietverhältnisse an, um Mieterdokumente abzulegen.'}
                showBtn={true}
                btnText={'Mieter anlegen'}
                btnHandler={()=> this.props.history.push('/user')}
                icon={TenantSingleIconBlue}
                />
              </TableCell>
            </TableRow>
          );
        }
        
      }
      });
  }

  goBackToRentalUnits=()=> {
    const {tenantData} = this.state;
    //this.props.history.push('/documents');
    this.setState({showRentalUnitsFolder: true, showTenantsFolders: true, selectedRentalUnitId: tenantData.rentalUnitId, showTenantDocuments: false, sortHeaderColumn: 'Name', sortType: 'asc'}, ()=> this.preOpeningOfFolders(this.state.rentalUnits));
  }

  goBackToMieterDocukmenteFolder=()=>{
    this.props.history.replace('/documents', {});
    this.setState({showRentalUnitsFolder: true, showTenantsFolders: false, selectedRentalUnitId: null, showTenantDocuments: false, sortHeaderColumn: 'Name', sortType: 'asc'},()=> this.preOpeningOfFolders(this.state.rentalUnits));
    //this.props.history.push('/documents', {showRentalUnitsFolder: true, rentalUnits})
  }


  render() {
    const {addDocumentsModalOpen, addFolderModalOpen, moveCopyModalOpen, data, moveCopyModalType, checkedItems, breadCrumbs, shareDocumentsModalOpen, addFileFolderPopoverOpen, skeletonLoading, fetchLoader, search, currentFolderId, currentFolderName, downloadProgress, editDocumentData, editDocumentModalOpen, deleteDocumentModalOpen, showDropZone, files, hideShareFolder, showTrashBin, showRentalUnitsFolder, rentalUnits, showTenantsFolders, selectedRentalUnitId, breadcrumbsSkeletonLoading, showTenantDocuments=false, tenantData, tenantId} = this.state;
    const {properties, propertySelected, tenantManagement} = this.props;
    console.log('addFileFolderPopoverOpen: ', addFileFolderPopoverOpen);
    console.log('downloadProgress: ', downloadProgress);
    console.log('currentFolderId: ', currentFolderId);
    console.log('rentalUnits: ', rentalUnits);
    console.log('testing_123: ', showTenantsFolders, showRentalUnitsFolder, selectedRentalUnitId);
    console.log('data_123: ', data);
    console.log('showTenantDocuments: ', showTenantDocuments);
    return (
      <div className="documents-list-container" 
      // onDragEnd={(e)=> {
      //   e.preventDefault(); 
      //   this.setState({showDropZone: false})
      // }} 
      onDrop={(e)=> {
        e.preventDefault(); 
        if(e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0){
          let files = Array.from(e.dataTransfer.files).map((file)=> {
            let fl= file;
            fl.path = file.name;
            return fl;
          });
          let acceptedFiles = files.filter((file)=> file.size <= 100000000);
          let rejectedFiles = files.filter((file)=> file.size > 100000000).map((file)=> {
            return {file, errors: [{code: "file-too-large", message: "File is larger than 100000000 bytes"}]}
          })
          console.log('rejectedFiles: ', rejectedFiles);
          this.handleFileChange(acceptedFiles, rejectedFiles);
        }else{
          this.setState({showDropZone: false});
        }
      }}
      onDragOver={(e)=> e.preventDefault()}>
        <FiltersContainer
        searchElement={
          <SearchContainer
          value={search}
          onChange={(e)=> this.setState({search: e.target.value})}
          disabled={false}
          placeholder={'Suche nach Dokumenten'}
          className={'document-search-container'}
          />
        }
        sortElement={properties.length > 0 && this.actionButtons()}
        />
        {!tenantManagement && <div className='divider'/>}
        <Stack>
          <Group style={{height: '27px'}}>
            <Skeleton visible={breadcrumbsSkeletonLoading}>
            <Breadcrumbs 
            separator={
            <ArrowForwardIosIcon 
            style={{
              color: '#5655FE',
              fontSize: '15px',
              overflow: 'scroll'
              
            }}/>}
            styles={{    
              root: { overflowX: 'scroll' },
            }}
            classNames={{
              root: 'documents-breadcrumbs-scrollbar'
            }}
            >
              {/* <Anchor style={{color: '#5555FF'}}>
                <span onClick={()=> this.getDocumentsByAccountId()}>{this.props.propertySelected.propertyName}</span>
              </Anchor> */}
              {properties.length > 0 && breadCrumbs.map((item, index) => (
                <Anchor key={index} style={{color: '#5555FF'}}>
                  {item.title === 'Add' ? 
                  currentFolderId !== 1 && currentFolderId !== 2 && currentFolderId !== 3 && <Popover
                  opened={addFileFolderPopoverOpen}
                  width={'auto'}
                  position="bottom"
                  withArrow
                  onClose={() => this.setState({addFileFolderPopoverOpen: false})}
                  target={
                  <Tooltip title={'Hinzufügen'}>
                    <IconButton 
                      aria-label="filter list"
                      onClick={()=> this.setState({addFileFolderPopoverOpen: true})} 
                      style={{
                        background: '#5655FE',
                        border: '1px solid #DADAE5', 
                        boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                        padding: '5px',
                        cursor: 'pointer'
                        }}>
                        <AddIcon 
                        style={{
                          color: 'white',
                          fontSize: '15px'
                          }}/>
                    </IconButton>
                  </Tooltip>
                  }
                  styles={{
                    inner: { padding: '5px'},
                  }}>
                    <Stack spacing={0}>
                      <Group 
                      style={{cursor: 'pointer'}}
                      sx={() => ({
                        padding: '10px',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: '#E3E3FC',
                        }
                      })}
                      onClick={()=> this.setState({addDocumentsModalOpen: true, addFileFolderPopoverOpen: false})}>
                        <NoteAddIcon 
                        style={{
                          color: '#5655FE',
                          fontSize: '20px'
                        }}/>
                        <span>Datei hochladen</span>
                      </Group>
                      <Group 
                      style={{cursor: 'pointer'}} 
                      sx={() => ({
                        padding: '10px',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: '#E3E3FC',
                        }
                      })}
                      onClick={()=> this.setState({addFolderModalOpen: true, addFileFolderPopoverOpen: false})}>
                        <CreateNewFolderIcon 
                        style={{
                          color: '#5655FE',
                          fontSize: '20px'
                        }}/>
                        <span>Neuer Ordner</span>
                      </Group>
                    </Stack>
                  </Popover>
                  :
                  item.href !== 'documents/root' ? 
                  <Tooltip title={item.title}>
                    <span style={{maxWidth: 'F', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '20px', textDecoration: 'none'}} onClick={()=> item.href === null ? this.setState({skeletonLoading: true}, ()=> {this.preOpeningOfFolders(rentalUnits); this.getDocumentsByAccountId(true);}) : item.href === 2 && showTenantDocuments ? this.goBackToMieterDocukmenteFolder() : item.href === 3 && showTenantDocuments ? this.goBackToRentalUnits() : this.openFolder(item.href, item.title)}>{item.title}</span>
                  </Tooltip>
                  :
                  <span style={{maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '20px', textDecoration: 'none'}} onClick={()=> showTenantDocuments ? this.props.history.push('/documents') : this.openFolder(item.href, item.title)}>{item.title}</span>}
                </Anchor>
              ))}
            </Breadcrumbs>
            </Skeleton>
          </Group>
          {skeletonLoading ? 
          <Stack spacing={0}>
            <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px', marginTop: '30px'}}/>
            {[...Array(10)].map((e, i) => <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
          </Stack>
          :
          <div onDragEnter={()=> this.setState({showDropZone: true})} onDragEnd={(e)=> this.setState({showDropZone: false})
          }>
            {properties.length > 0 ? 
            <Group noWrap align={'flex-start'}>
              {
              <Stack style={{width: '100%', marginBottom: '20px'}} id={'mainContainer'}>
                {/* {showDropZone ?
                <div style={{height: '100vh', width: '100%'}}>
                  <DropFile
                  downloadable
                  accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
                  customProps={{ multiple: true }}
                  handleDrop={this.handleFileChange}
                  file={[]}
                  //removeFile={this.removeFile}
                  multiSelect={true}
                  maxSize={100000000}
                  hideSecondText={true}
                  />
                </div>
                : */}
                <TableContainer>
                  <Table className='documents-list-table'>
                    <TableHead>
                      {this.getTableHeader()}
                    </TableHead>
                    <TableBody style={{borderCollapse: 'separate', borderSpacing: '8px'}}>
                      {!window.location.href.includes('documents?') && currentFolderId === null && this.renderTenantFolder()}
                      {!showTenantDocuments && !hideShareFolder && currentFolderId === null && this.renderShareFolder()}
                      {!showTrashBin && currentFolderId === null && !showTenantDocuments && this.renderTrashFolder()}
                      {showRentalUnitsFolder ?
                      showTenantsFolders ?
                      this.getTenantsTable()
                      :
                      this.getRentalUnitsTable()
                      :
                      (currentFolderId && currentFolderId !== 0 && currentFolderId !== 1 ? 
                      data.filter((dt)=> dt.parentDirectoryId === currentFolderId).length > 0 
                      : 
                      currentFolderId === 0 ? data.filter((dt)=> dt.propertyId === null && dt.isDeleted === false).length > 0
                      : 
                      currentFolderId === null ? data.filter((dt)=> dt.parentDirectoryId === null && dt.propertyId !== null && dt.isDeleted === false).length > 0 
                      :
                      data.length > 0) ?
                      <>
                        {this.getTableData()}
                        {!showTrashBin ?
                        <TableRow>
                          <TableCell colSpan={6}>
                            <DropFile
                            downloadable
                            accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
                            customProps={{ multiple: true }}
                            handleDrop={this.handleFileChange}
                            file={[]}
                            //removeFile={this.removeFile}
                            multiSelect={true}
                            maxSize={100000000}
                            hideSecondText={true}
                            />
                          </TableCell>
                        </TableRow>
                        :
                        (data.find((dt)=> dt.propertyId === propertySelected.id && dt.isDeleted) === undefined && data.find((dt)=> dt.propertyId === null && dt.isDeleted) === undefined) &&
                        <TableCell colSpan={5}>
                          <EmptyStateContainer
                          heading={'Es gibt derzeit keine gelöschten Dokumente.'}
                          subheading={'Wenn Sie Dokumente löschen, finden Sie diese hier.'}
                          showBtn={false}
                          //btnText={'Hinzufügen'}
                          //btnHandler={()=> this.setState({addFileFolderPopoverTopRightOpen: true})}
                          materialIcon={true}
                          icon={<DeleteIcon 
                            style={{
                              color: checkedItems.length > 0 ? 'white' : '#5655FE',
                              fontSize: '15px'
                              }}/>}
                          />
                        </TableCell>}
                      </>
                      :
                      hideShareFolder ?
                      <TableRow>
                        <TableCell colSpan={5}>
                          <EmptyStateContainer
                          heading={'Es sind noch keine gemeinsamen Dokumente vorhanden.'}
                          subheading={'Dokumente, die Sie hier ablegen, sind für alle Ihre Immobilien sichtbar.'}
                          showBtn={false}
                          materialIcon={true}
                          //btnText={'Hinzufügen'}
                          //btnHandler={()=> this.setState({addFileFolderPopoverTopRightOpen: true})}
                          icon={<FolderSharedIcon 
                            style={{
                              color: checkedItems.length > 0 ? 'white' : '#5655FE',
                              fontSize: '18px'
                              }}/>}
                          />
                        </TableCell>
                      </TableRow>
                      :
                      !showTrashBin ?
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Group style={{width: '100%'}} position={'center'}>
                            <EmptyStateContainer
                            heading={'Hier sind noch keine Dokumente vorhanden.'}
                            subheading={'Laden Sie Dokumente hoch, die hier angezeigt werden sollen.'}
                            showBtn={true}
                            btnText={'Hinzufügen'}
                            btnContainerStyle={{display: 'flex', justifyContent: 'center'}}
                            btnHandler={()=> this.setState({addFileFolderPopoverTopRightOpen: true})}
                            icon={DocumentsIconsBlue}
                            />
                          </Group>
                        </TableCell>
                      </TableRow>
                      :
                      <TableRow>
                        <TableCell colSpan={5}>
                          <EmptyStateContainer
                          heading={'Es gibt derzeit keine gelöschten Dokumente.'}
                          subheading={'Wenn Sie Dokumente löschen, finden Sie diese hier.'}
                          showBtn={false}
                          materialIcon={true}
                          //btnText={'Hinzufügen'}
                          //btnHandler={()=> this.setState({addFileFolderPopoverTopRightOpen: true})}
                          icon={<DeleteIcon 
                            style={{
                              color: checkedItems.length > 0 ? 'white' : '#5655FE',
                              fontSize: '15px'
                              }}/>}
                          />
                        </TableCell>
                      </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* } */}
                  {/* <VirtualTable
                  width={document.getElementById('app-content')?.offsetWidth}
                  height={document.getElementById('app-content')?.offsetHeight}
                  headerStyle={{fontWeight: '400', color: '#727293'}}
                  headerHeight={40}
                  rowHeight={30}
                  rowStyle={{width: '100%'}}
                  rowCount={data.length}
                  rowGetter={({index}) => data[index]}>
                    <Column label="Name" dataKey="name" width={document.getElementById('app-content')?.offsetWidth * 0.5} />
                    <Column label="Geändert" dataKey="updatedOn" width={90} />
                    <Column label="Größe" dataKey="fileSize" width={300} />
                  </VirtualTable>  */}
                {fetchLoader && 
                <Group style={{width: '100%'}} grow>
                  <Loader variant="dots" size={'md'} color={'#5555FF'}/>
                </Group>}
              </Stack>}
              {/* {this.recentlyUsedDocuments()} */}
            </Group>
            :
            <Group style={{width: '100%'}} position={'center'}>
              <EmptyStateContainer
              heading={'Dokumente sind noch nicht verfügbar.'}
              subheading={'Legen Sie eine Immobilie an, um hier Dokumente ablegen zu können.'}
              showBtn={true}
              btnText={'Immobilie anlegen'}
              btnHandler={()=> this.setState({addPropertyTypeModal: true})}
              icon={PropertyIcon}
              />
            </Group>}
          </div>}
        </Stack>
        {addDocumentsModalOpen &&
        <AddDocumentModal
        open={addDocumentsModalOpen}
        currentFolderId={currentFolderId}
        history={this.props.history}
        files={files}
        showTenantDocuments={showTenantDocuments}
        tenantData={tenantData}
        tenantId={tenantId}
        hideShareFolder={hideShareFolder}
        allDocumentsInCurrentFolder={data.filter((row)=> currentFolderId ? row.parentDirectoryId === currentFolderId : row.parentDirectoryId === null)}
        toggle={()=> this.setState({addDocumentsModalOpen: false, files: []})}
        getDocuments={()=> {
          this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.getDocumentsByAccountId((currentFolderId === 0 || currentFolderId) ? true : false));
        }
        }
        />
        }
        {addFolderModalOpen &&
        <AddFolderModal
        open={addFolderModalOpen}
        currentFolderId={currentFolderId}
        hideShareFolder={hideShareFolder}
        history={this.props.history}
        showTenantDocuments={showTenantDocuments}
        tenantData={tenantData}
        tenantId={tenantId}
        toggle={()=> this.setState({addFolderModalOpen: false})}
        getDocuments={()=> {this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.getDocumentsByAccountId(showTenantDocuments ? true : (currentFolderId === 0 || currentFolderId) ? true : false));}}
        />}
        {moveCopyModalOpen &&
        <MoveCopyModalForDocuments
        open={moveCopyModalOpen}
        toggle={()=> this.setState({moveCopyModalOpen: false})}
        history={this.props.history}
        data={data}
        type={moveCopyModalType}
        checkedItems={checkedItems}
        currentFolderId={currentFolderId}
        hideShareFolder={hideShareFolder}
        showTenantDocuments={showTenantDocuments}
        rentalUnits={rentalUnits}
        tenantData={tenantData}
        tenantId={tenantId}
        getDocuments={()=> {
          this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.props.setRefreshDocumentsData(true)//this.getDocumentsByAccountId(currentFolderId ? true : false)
          );
        }}
        />}
        {editDocumentModalOpen &&
        <EditDocumentModal
        open={editDocumentModalOpen}
        toggle={()=> this.setState({editDocumentModalOpen: false, editDocumentData: null})}
        history={this.props.history}
        data={editDocumentData}
        showTenantDocuments={showTenantDocuments}
        tenantData={tenantData}
        tenantId={tenantId}
        documentsInCurrentFolder={data.filter((dt)=> currentFolderId === null ? dt.parentDirectoryId === null : dt.parentDirectoryId === currentFolderId)}
        getDocuments={()=> {
          this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> this.getDocumentsByAccountId((currentFolderId === 0 || currentFolderId) ? true : false));
        }}
        />}
        {deleteDocumentModalOpen &&
        <DeleteDocumentModal
        open={deleteDocumentModalOpen}
        toggle={()=> this.setState({deleteDocumentModalOpen: false})}
        showTrashBin={showTrashBin}
        history={this.props.history}
        data={data}
        checkedItems={checkedItems}
        currentFolderId={currentFolderId}
        showTenantDocuments={showTenantDocuments}
        tenantData={tenantData}
        tenantId={tenantId}
        getDocuments={()=> {
          this.setState({data: [], offset: 0, skeletonLoading: true}, ()=> showTenantDocuments ? this.props.setRefreshTenantDocumentsData(true) : this.props.setRefreshDocumentsData(true)//this.props.setRefreshDocumentsData(true)//this.getDocumentsByAccountId(currentFolderId ? true : false)
          );
        }}
        />}
        {this.state.addPropertyModal &&
        <AddPropertyModal
        className="add_proprty_modal"
        edit={false}
        open={this.state.addPropertyModal}
        toggle={() => {
          this.setState({addPropertyModal: false});
        }}
        propertyType={this.state.selectedPropertyType}
        />}
        {this.state.addPropertyTypeModal &&
        <AddPropertyTypeModal
          className="add_proprty_type_modal"
          open={this.state.addPropertyTypeModal}
          toggle={() => {
            this.setState({addPropertyTypeModal: false});
          }}
          next={(selectedPropertyType)=> {
            this.setState({selectedPropertyType});
            this.setState({addPropertyTypeModal: false, addPropertyModal: true});
          }}
        />
        }
        {/* {shareDocumentsModalOpen &&
        <ShareDocumentsModal
        open={shareDocumentsModalOpen}
        toggle={()=> this.setState({shareDocumentsModalOpen: false})}
        data={data}
        type={moveCopyModalType}
        checkedItems={checkedItems}
        />} */}
        {this.renderDrawer()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    refreshDocumentsData: state.generalReducer.refreshDocumentsData,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData,
    properties: state.generalReducer.allProperties
  };
};

const actions = {
  setRefreshDocumentsData,
  setFiles,
  setCurrentFolderId,
  setRefreshTenantDocumentsData,
  setCurrentTenant,
  setSharedFolder
};

export default connect(mapStateToProps, actions)(DocumentsList);
