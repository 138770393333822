import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Loader, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import Autoload from '../../../../Autoload';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';
import accounting from 'accounting';
import MeterIcon from '../../../../../assets/images/module/general/blueEmptyMeter.svg'; 

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

export class MeterLogsDetailMeterReadingsModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    reading: null,
    loader: false
  };
  
  constructor(props) {
    super(props);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
    this.getReadings();
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
    
  };

  getReadings=()=> {
    const {readingId} = this.props;
    this.setState({loader: true},()=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url+ 'readings/'+readingId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetReadings',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    ));
  }

  handleGetReadings(response){
    if(response.statuscode === 200 && response.status){
      this.setState({reading: response.result});
    }
    this.setState({loader: false});
  }

  renderTableHeader=()=> {
  const {isMobile} = this.state;
  return (
    <TableHead>
      <TableRow>
        <TableCell width={'30%'} className='meter-logs-detail-meter-reading-table-header-cell'>Zählernummer</TableCell>
        {!isMobile &&
        <>
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Bezeichnung</TableCell>
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Zählertyp</TableCell>
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Standort</TableCell>
        </>}
        <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Ablesezeitpunkt</TableCell>
        <TableCell style={{textAlign: 'right'}} className='meter-logs-detail-meter-reading-table-header-cell'>Wert</TableCell>
        <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Einheit</TableCell>
      </TableRow>
    </TableHead>
  )}

  renderTableBody=()=>{
    const {reading, isMobile} = this.state;

    return (
      <TableBody>
        {reading?.meterReadings?.length > 0 && reading?.meterReadings.map((meterReading)=> 
          <TableRow className='meter-logs-detail-meter-reading-table-body-row'>
            <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
              {meterReading?.meter?.meterNo}
            </TableCell>
            {!isMobile &&
            <>
              <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
                {meterReading?.meter?.meterName}
              </TableCell> 
              <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
                {meterReading?.meter?.allocationKey?.allocationKeyName}
              </TableCell>
              <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
                {meterReading?.meter?.location}
              </TableCell>
            </>}
            <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
              {dayjs.utc(meterReading?.meterReadingDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY (HH:mm)') }
            </TableCell>
            <TableCell style={{textAlign: 'right'}} className='meter-logs-detail-meter-reading-table-body-cell'>
              {accounting.formatNumber(meterReading?.currentValue, 2, '.', ',')}
            </TableCell>
            <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
              {meterReading?.meter?.allocationKey?.units ? meterReading?.meter?.allocationKey?.units : '-'}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }
  
  render() {
    const { open, toggle, className, update } = this.props;
    const {reading, isMobile, loader} = this.state;

    return (
      <CustomModal
        heading={'Verarbeitete Zählerwerte'}
        headingClassName={'meter-logs-details-meter-readings-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{minWidth: !isMobile && '1000px'}}
      >
        <Stack>
            <Group position='center' align={'center'} style={{minHeight: loader && '300px'}}>
              {loader ?
              <Loader size={'md'}/>
              :
              reading?.meterReadings?.length > 0 ?
              <TableContainer>
                <Table>
                  {this.renderTableHeader()}
                  {this.renderTableBody()}
                </Table>
              </TableContainer>
              :
              <EmptyStateContainer
              heading={'Keine Werte gefunden'}
              subheading={'Es wurden keine Zählerwerte gefunden.'}
              icon={MeterIcon}
              showBtn={false}
              styleMainContainer={{
                marginTop: '30px',
                marginBottom: '30px'                
              }}
              />}
            </Group>
            <Group position='right'>
                <div>
                    <ColorButton
                    text={'Schließen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default MeterLogsDetailMeterReadingsModal;
