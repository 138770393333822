import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import HeadingContainerNew from '../../../../neoverv/components/Module/General/HeadingContainerNew';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Stack } from '@mantine/core';
import partnerContent from '../../../../assets/partner-content.json';
import { ColorButton } from '../../../../neoverv';
import PartnerDetailModal from '../../../../neoverv/components/Module/General/CustomModal/PartnerDetailModal';
import RightArrowPartner from '../../../../assets/images/module/general/RightArrowPartner.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const routes = [
  {
    name: 'all',
    route: '/partner/all',
    heading: 'Alle',
  },
  {
    name: 'services',
    route: '/partner/services',
    heading: 'Dienstleistungen',
  },
  {
    name: 'service',
    route: '/partner/service',
    heading: 'Dienstleistung',
  },
  {
    name: 'renting',
    route: 'partner/renting',
    heading: 'Vermietung'
  },
  {
    name: 'insurance',
    route: '/partner/insurance',
    heading: 'Versicherung',
  },
  {
    name: 'tax',
    route: '/partner/taxReturn',
    heading: 'Steuererklärung',
  }
];

class AllPartnerList extends Component {
  state = {
    selectedPartner: null,
    openPartnerDetailModal: false,
    selectedRoute: 'Alle'
  };

  componentDidMount(){
    var url = new URL(window.location.href);
    var partnerId = url.searchParams.get("partnerId");
    console.log('partnerId: ', partnerId);
    if(partnerId){
      let selectedPartner = partnerContent.find((partner)=> partner['partner-id'] === partnerId);
      this.setState({selectedPartner: selectedPartner !== undefined ? selectedPartner : null, openPartnerDetailModal: true});
    }
  }

  componentDidUpdate(){
  }

  changePage(name) {
    //this.props.history.push(route);
    this.setState({selectedRoute: name});
  }

  render() {
    const {selectedPartner, openPartnerDetailModal, selectedRoute} = this.state;
    let pathname = this.props.location.pathname;
    if (pathname === '/partner') {
      pathname = routes[0].route;
    }
    return (
      <div className="partners-list-main-container">
                <div style={{ display: 'flex', cursor: 'pointer' }} className='custom-navbar'>
          {routes.map((btn, idx) => {
            if((btn.heading === 'Alle') || (partnerContent.find((content)=> content.category.find((cat)=> cat === btn.heading) !== undefined) !== undefined)){
              return (
                <p
                  key={idx}
                  className={`switch-btn ${btn.heading}-btn ${btn.heading === selectedRoute && 'active'}`}
                  style={{
                    color: btn.heading === selectedRoute ? '#111147' : '#727293',
                    borderBottom: btn.heading === selectedRoute ? '3px solid #5555FF' : null,
                    fontSize: btn.heading === selectedRoute ? '14px' : '14px',
                    marginRight: '25px',
                    padding: '20px 0px',
                    whiteSpace: 'nowrap'
                  }}
                  onClick={() => {
                    this.changePage(btn.heading);
                  }}
                >
                  {btn.heading}
                </p>
              );
            }
          })}
        </div>
        <hr style={{ marginTop: '-18px' }} />
        <Stack className='partner-list-page'>
          {partnerContent.filter((content)=> selectedRoute !== 'Alle' ? content.category.find((cat)=> cat === selectedRoute) !== undefined : content).map((content)=>
          <Group className='partner-list-page-row' noWrap style={{cursor: 'pointer'}} onClick={()=> this.setState({selectedPartner: content, openPartnerDetailModal: true}, ()=> this.props.history.replace({pathname: '/partner', search: `partnerId=${content['partner-id']}`}))}>
            <Group noWrap align={'flex-start'} className={'partner-list-page-row-left-side'}>
              <img src={content.icon} alt={content.icon} width={'30px'} height={'30px'} />
              <Stack spacing={10}>
                <Group className='partner-list-row-heading' position='apart'>
                  {content.heading}
                  <Stack className={'partner-list-page-row-right-side'} style={{alignItems: 'flex-end', justifyContent: 'space-between', height: '100%'}}>
                    {content['promo-tags'].map((tag)=>
                    <Group className={`partner-list-page-row-popular-badge ${content.popular && 'badge-background-color-white'}`}>
                      <span style={{whiteSpace: 'nowrap'}}>{tag}</span>
                    </Group>)}
                  </Stack>
                </Group>
                <Group className='partner-list-row-tags'>
                  {content.tags.map((tag)=> <Group className='partner-list-row-tag-badge'>{tag}</Group>)}
                </Group>
                <Group className='partner-list-row-description' >
                  <span>{content['overview-text']} <span style={{fontWeight: '400', color: '#5555FF', whiteSpace: 'nowrap'}}>Mehr erfahren <img src={RightArrowPartner} style={{marginTop: '-1px', width: '12px', marginLeft: '2px'}}/></span></span>
                </Group>
              </Stack>
            </Group>
          </Group>
          )}
        </Stack>
        {openPartnerDetailModal &&
        <PartnerDetailModal
        opened={openPartnerDetailModal}
        toggle={()=> this.setState({openPartnerDetailModal: false, selectedPartner: null}, ()=> this.props.history.replace({pathname: '/partner', search: ''}))}
        partnerData={selectedPartner}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    refreshDocumentsData: state.generalReducer.refreshDocumentsData,
  };
};

export default connect(mapStateToProps, null)(withRouter(AllPartnerList));
