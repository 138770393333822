import React, { useState } from 'react';
import { RecordOptions, DeleteModal, MeterModal } from '../../../../index.js';
import MeterIcon from '../../../../../assets/images/module/general/MeterIcon.svg';
import { deleteMasterData, editMasterData } from '../../../../../redux/action/Meter/meterActions';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import MeterDataHandler from '../../../../../neoverv/helpers/DataHandler/Meter';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { config } from '../../../../config/Setting.js';
import APIClient from '../../../../config/APIClient.js';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
function MeterMasterDataRecord({
  data,
  className,
  labels,
  deleteMasterData,
  editMasterData,
  currentMeter,
  parentContext,
  allocationKeys = [],
  fetchPropertyMeters,
  getAllocationKeysByProperty,
  isMobile,
  isTablet,
  history
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(0);

  const toggleDeleteModal = () => setOpenDelete(!openDelete);
  const toggleEditReadingModal = () => setOpenEdit(!openEdit);

  const handleDelete = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const btnelement = document.getElementById('meter_master_delete_modal');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      parentContext,
      'handle_delete_meter_response',
      { rowId: id, currentMeter, closeModalFunc: toggleDeleteModal },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  const handleEdit = async (updatedData) => {
    console.log('handleEdit: ', updatedData);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const btnelement = document.getElementById('meter-button-container');
    updatedData.rentalUnitId = updatedData.meterType === 'PROPERTY' ? null : updatedData.rentalUnitId;
    updatedData.rentalUnitName = updatedData.meterType === 'PROPERTY' ? null : updatedData.rentalUnitName;
    const payload = MeterDataHandler.generateMeterDatawithAllocationKey(updatedData);
    console.log(payload, 'payload edit data');
    
    await APIClient({
      method: 'PUT',
      url: config.meter + 'meters/' + updatedData.id,
      data: payload,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
      .then((respone) => {
        console.log('APIClient: ', respone);
        toggleEditReadingModal();
        loader.style.display = 'none';
        fetchPropertyMeters();
        getAllocationKeysByProperty();
        showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='green-tick'/>
        })
      })
      .catch((error) => {
        if(error.toString().includes('409')){
          showNotification({
            id: 'meterDuplicate',
            //title: 'Fehler',
            message: "Diese Zählernummer wird bereits verwendet. Bitte wählen Sie eine andere Zählernummer.",
            icon: <img src={RedCross} alt='red-tick'/>,
            style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
          });
          loader.style.display = 'none';
        }else{
          console.log('APIClient: ', error);
          toggleEditReadingModal();        
          loader.style.display = 'none';
          showNotification({
            message: "Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.",
            icon: <img src={RedCross} alt='red-tick'/>,
            style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
          });
        } 
      });
  };

  const getTableLabels = () => {
    return labels.map((label, index) => {
      return (
        <td className="master-table-labels d-none d-md-table-cell" 
        key={index}>
          {label}
        </td>
      );
    });
  };

  /* 
    @desc finding allocation key name from allocations props
    @return allocation (name)
  */
  // const _getAllocationKey = (allKey) => {
  //   console.log(allKey, 'keys allocation');
  //   const akey = allocationKeys && allocationKeys.find((allocationKeyObj) => allKey === allocationKeyObj.id);
  //   console.log(allocationKeys, 'allocationKeys', akey);
  //   return akey ? akey.allocationKeyName : '-';
  // };

  const getTableData = () => {
    return data.map((row, rowIndex) => {
      console.log(row, 'row data master');
      return (
        <tr key={rowIndex} className={`master-table-data ${row.lastBillingPeriodId !== null && 'archive-meter'}`} style={{cursor: 'pointer'}} onClick={()=> {setCurrentSelection(rowIndex); toggleEditReadingModal();}}>
          <td //className="meter-icon "
          style={{paddingLeft: '27px', width: '25%'}}
          >
            <img src={MeterIcon} alt="tenant" />
            <span className={`master-meterno`}
            > {`${row.meterNo} ${row.lastBillingPeriodId !== null ? '(archiviert)' : ''}`}</span>
          </td>
          <td //className="master-type d-none d-md-table-cell"
          style={{width: '25%'}}
          >{row.meterName}</td>
          {/* <td className="master-type d-none d-md-table-cell">{_getAllocationKey(row.allocationKey)}</td> */}
          <td //className="master-type d-none d-md-table-cell"
          style={{width: '25%'}}
          >{`${row.allocationKey.allocationKeyName} ${(row.allocationKey.units && row.allocationKey.units !== '-') ? `(${row.allocationKey.units})` : ''}`}</td>
          <td //className="master-location d-none d-md-table-cell"
          >{row.location}</td>
          <td //className="master-dots "
          style={{textAlign: 'end', paddingRight: '27px'}}
          >
            <RecordOptions
              toggleDeleteModal={() => toggleDeleteModal()}
              toggleEditModal={() => toggleEditReadingModal()}
              getRecord={()=> setCurrentSelection(rowIndex)}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    data.length > 0 && (
      <>
        <table className={`table borderless ${className} mb-0`}>
          <tbody>
            <tr className="master-table-heading">{getTableLabels()}</tr>
            {getTableData()}
          </tbody>
          <DeleteModal
            modalId="meter_master_delete_modal"
            record={data[currentSelection]}
            className="delete-receipt-modal"
            open={openDelete}
            toggle={() => toggleDeleteModal()}
            handleDelete={(id) => handleDelete(id)}
            type={'Zähler'}
            showtext={false}
            showDeleteText={false}
            textShowModal={'Zähler löschen'}
            typeText={
              'Sind Sie sicher, dass Sie diesen Zähler löschen wollen? Alle Ablesedaten dieses Zählers werden dabei ebenfalls gelöscht!'
            }
          />
          {openEdit && (
            <MeterModal
              history={history}
              record={data[currentSelection]}
              className="add-edit-receipt-modal meter-edit-modal"
              currentMeter={currentMeter}
              edit={true}
              open={openEdit}
              handleEdit={(updatedData) => handleEdit(updatedData)}
              toggle={() => toggleEditReadingModal()}
              propertyId={parentContext.props.propertySelected && parentContext.props.propertySelected.id}
            />
          )}
        </table>
      </>
    )
  );
}

MeterMasterDataRecord.defaultProps = {
  data: [],
  labels: [],
};

export default connect(null, { deleteMasterData, editMasterData })(MeterMasterDataRecord);
